import _ from "lodash";

class CadastreNumbersConverter {

  static parseNumbers(numbers) {
    return _.chain(numbers)
      .split(" ")
      .filter()
      .value();
  }

  static encodeNumbers(numbers, splitter = " ") {
    if (numbers === null) {
      return numbers;
    }
    return numbers.join(splitter);
  }

  static getSplitedNumbers(numbers) {
    return numbers.split(" ");
  }
}

export default CadastreNumbersConverter;