import _ from "lodash";

import actions from "./constructor/actions";

const getDefaultState = () => {
  return {
    count: 1,
    DATA_LOADING: false,
    DATA_LOADING_ERROR: null,
    /**
         * Манифест редактируемого приложения
         */
    appManifest: null,
    etag: "",
    objectProperties: null,
    dataProperties: null,
    annotationProperties: null,
    classes: null,
    classTree: [],
    applications: null,
    applicationsInfo: null,
    savedManifest: null,
    /**
         * Все источики данных
         */
    savedDataSource: null,
    dataSource: null,
    dataSources: null,
    dataSourcesInfo: null,
    /**
         * Все шаблоны фильтров
         */
    filterTemplates: null,
    importManifest: null
  };
};
export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    DATA_LOADING_ERROR: state => {
      return state.DATA_LOADING_ERROR;
    },
    DATA_LOADING: state => {
      return state.DATA_LOADING;
    },
    importManifest: state => {
      return state.importManifest;
    },
    menuItems: state => {
      return _.get(state.appManifest, "manifest.menuItems", []);
    },
    SAVED_MANIFEST: state => {
      return state.savedManifest;
    },
    APPLICATIONS_INFO: state => {
      return state.applicationsInfo;
    },
    SAVED_DATASOURCE: state => {
      return state.savedDataSource;
    },
    CURRENT_DATASOURCE: state => {
      return state.dataSource;
    },
    APPLICATIONS: state => {
      return state.applications;
    },
    FILTERTEMPLATES: state => {
      return state.filterTemplates;
    },
    DATASOURCES: state => {
      return state.dataSources;
    },
    DATASOURCES_INFO: state => {
      return state.dataSourcesInfo;
    },
    CLASSES: state => {
      return state.classes;
    },
    ANNOTATIONPROPERTIES: state => {
      return state.annotationProperties;
    },
    DATAPROPERTIES: state => {
      return state.dataProperties;
    },
    OBJECTPROPERTIES: state => {
      return state.objectProperties;
    },
    CLASSES_GRAPH: state => {
      return state.classesGraph;
    },
    MANIFEST: state => {
      return state.appManifest;
    },
    etag: state => {
      return state.etag;
    },
    /**
         * Получение индекса действия
         *
         * @param {String} actionId ID действия
         */
    ACTION_INDEX: state => actionId => {
      return _.chain(state.appManifest)
        .get("manifest.actions", [])
        .findIndex({ actionId: actionId })
        .value();
    },
    /**
         * Получение действия
         *
         * @param {String} actionId ID действия
         */
    ACTION: state => actionId => {
      return _.chain(state.appManifest)
        .get("manifest.actions", [])
        .find({ actionId: actionId })
        .value();
    },
    /**
         * Получение индекса страницы
         *
         * @param {String} pageId ID страницы
         */
    PAGE_INDEX: state => pageId => {
      return _.chain(state.appManifest)
        .get("manifest.pages", [])
        .findIndex({ pageId: pageId })
        .value();
    },
    /**
         * Получение страницы
         *
         * @param {String} pageId ID страницы
         */
    PAGE: state => pageId => {
      return _.chain(state.appManifest)
        .get("manifest.pages", [])
        .find({ pageId: pageId })
        .value();
    },
    /**
         * Получение индекса компонента
         *
         * @param {String} pageId ID страницы
         * @param {String} pageItemId ID компонента
         */
    PAGE_ITEM_INDEX: state => (pageId, pageItemId) => {
      let page = _.chain(state.appManifest)
        .get("manifest.pages", [])
        .find({ pageId: pageId })
        .value();
      return _.chain(page)
        .get("pageItems", [])
        .findIndex({ pageItemId: pageItemId })
        .value();
    },
    /**
         * Получение компонента
         *
         * @param {String} pageId ID страницы
         * @param {String} pageItemId ID компонента
         */
    PAGE_ITEM: state => (pageId, pageItemId) => {
      let page = _.chain(state.appManifest)
        .get("manifest.pages", [])
        .find({ pageId: pageId })
        .value();
      return _.chain(page)
        .get("pageItems", [])
        .find({ pageItemId: pageItemId })
        .value();
    },

    dataSourcesForSelect: (state, getters) => {
      return _.map(getters["MANIFEST"].manifest.dataSources, ds => {
        let dataSourceData = _.find(getters.DATASOURCES, { id: ds.applicationDataSourceId });
        let dataSourceName = dataSourceData ? dataSourceData.name : _.get(ds, "metadata.class.name");
        return {
          value: ds.applicationDataSourceId,
          label: dataSourceName,
          display: dataSourceName
        };
      });
    },

    classTree: state => {
      return state.classTree;
    }
  },
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    setImportManifest: (state, value) => {
      state.importManifest = value;
    },
    SET_APPLICATIONS_INFO: (state, value) => {
      state.applicationsInfo = value;
    },
    SET_SAVED_MANIFEST: (state, value) => {
      state.savedManifest = value;
    },
    SET_DATA_LOADING_ERROR: (state, value) => {
      state.DATA_LOADING_ERROR = value;
    },
    SET_DATA_LOADING: (state, value) => {
      state.DATA_LOADING = value;
    },
    SET_CURRENT_DATASOURCE: (state, value) => {
      state.dataSource = value;
    },
    SET_SAVED_DATASOURCE: (state, value) => {
      state.savedDataSource = value;
    },
    SET_FILTERTEMPLATES: (state, filterTemplates) => {
      state.filterTemplates = filterTemplates;
    },
    SET_DATASOURCES: (state, dataSources) => {
      state.dataSources = dataSources;
    },
    SET_DATASOURCES_INFO: (state, dataSourcesInfo) => {
      state.dataSourcesInfo = dataSourcesInfo;
    },
    SET_CLASSES: (state, classes) => {
      state.classes = classes;
    },
    SET_DATASOURCE_CLASS_TREE: (state, classTree) => {
      state.classTree = classTree;
    },
    SET_ANNOTATIONPROPERTIES: (state, property) => {
      state.annotationProperties = property;
    },
    SET_DATAPROPERTIES: (state, property) => {
      state.dataProperties = property;
    },
    SET_OBJECTPROPERTIES: (state, property) => {
      state.objectProperties = property;
    },
    SET_MANIFEST: (state, manifest) => {
      state.appManifest = manifest;
    },

    setEtag: (state, etag) => {
      state.etag = etag;
    },

    SET_APPLICATIONS: (state, applications) => {
      state.applications = applications;
    },
    /**
         * Изменнеие значения в манифесте
         *
         * @param {Object} state
         * @param {Object} data Инфррмация об изменяемом поле
         * {
         *  path: "manifest.info.iconName", //Путь для сохранения в манифесте
         *  value: "fa fas-times", //Сохраняемое значение
         * }
         */
    SET_MANIFEST_FIELD: (state, data) => {
      _.set(state.appManifest, data.path, data.value);
    },
    /**
         * Добавление значения в массив поля манифеста
         *
         * @param {Object} state
         * @param {Object} data Инфррмация об изменяемом поле
         * {
         *  path: "manifest.pages.0.pageItems.0.pageItemActions", //Путь до массива в манифесте
         *  value: "new_action", //Сохраняемое значение
         * }
         */
    ADD_TO_MANIFEST_FIELD: (state, data) => {
      let manifestArray = _.get(state.appManifest, data.path);
      manifestArray.push(data.value);
    },
    /**
         * Удаление значения в массиве поля манифеста
         *
         * @param {Object} state
         * @param {Object} data Инфррмация об удаляемом поле
         * {
         *  path: "manifest.page", //Путь до массива в манифесте
         *  index: "1", //Сохраняемое значение
         * }
         */
    REMOVE_FROM_MANIFEST_FIELD: (state, data) => {
      _.get(state.appManifest, data.path)?.splice(data.index, 1);
    },
    /**
         * Сохранение новой страницы приложения
         *
         * @param {*} state
         * @param {Object} pageData Данные страницы
         */
    SAVE_MANIFEST_PAGE: (state, pageData) => {
      state.appManifest.manifest.pages.push(pageData);
    },
    /**
         * Сохранение нового действия приложения
         *
         * @param {*} state
         * @param {Object} pageData Данные действия
         */
    SAVE_MANIFEST_ACTION: (state, actionData) => {
      state.appManifest.manifest.actions.push(actionData);
    },
    /**
         * Сохранение нового компонента приложения
         *
         * @param {*} state
         * @param {Object} pageData Данные компонента и ID страницы
         * {
         *  pageId: 'dsdfsdfc',  // ID  страницы
         *  pageItemData: {...},  // Данные компонента
         * }
         */
    SAVE_MANIFEST_PAGE_ITEM: (state, data) => {
      let page = _.chain(state.appManifest)
        .get("manifest.pages", [])
        .find({ pageId: data.pageId })
        .value();
      page.pageItems.push(data.pageItemData);
    },
    SAVE_MANIFEST_DS: (state, dsData) => {
      state.appManifest.manifest.dataSources.push(dsData);
    },
    /**
         * Сохранение новой настройки для компонента
         *
         * @param {*} state
         * @param {Object} data
         * {
         *  itemPath: "manifest.pages.1.pageItems.2", // Путь до компонента в state.appManifest
         *  settingId: "Fields", // id настройки
         *  settingData: "", // значение настройки
         * }
         */
    ADD_PAGE_ITEM_SETTING: (state, data) => {
      let item = _.get(state.appManifest, data.itemPath);
      item.pageItemSettings.push({ pageItemSettingId: data.settingId, value: data.settingData });
    },
    /**
         * Удаление настройки для компонента
         *
         * @param {*} state
         * @param {Object} data
         * {
         *  itemPath: "manifest.pages.1.pageItems.2", // Путь до компонента в state.appManifest
         *  settingId: "Fields", // id настройки
         * }
         */
    REMOVE_PAGE_ITEM_SETTING: (state, data) => {
      let item = _.get(state.appManifest, data.itemPath);
      item.pageItemSettings = _.filter(item.pageItemSettings, item => item.pageItemSettingId != data.settingId);
    }
  },
  actions
};