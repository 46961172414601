import _ from "lodash";
import actions from "./mapParameters/actions";

export default {
  namespaced: true,
  state() {
    return {
      mapMainParameters: [],
      layerGroups: [],
      currentLayerGroup: null,
      currentLayer: null
    };
  },
  getters: {
    mapMainParameters: state => {
      return state.mapMainParameters;
    },
    layerGroups: state => {
      return state.layerGroups;
    },
    customLayerGroups: state => {
      let baseLayerGroupIndex = _.findIndex(state.layerGroups, group => group.id === state.mapMainParameters.baseLayersGroupId);
      let layerGroupsForLayerPage = _.cloneDeep(state.layerGroups);
      layerGroupsForLayerPage.splice(baseLayerGroupIndex, 1);
      return layerGroupsForLayerPage;
    },
    currentLayerGroup: state => {
      return state.currentLayerGroup;
    },
    currentLayer: state => {
      return state.currentLayer;
    }
  },
  mutations: {
    SET_DATA_LOADING_ERROR: (state, value) => {
      state.dataLoadingError = value;
    },

    SET_DATA_LOADING: (state, value) => {
      state.dataLoading = value;
    },
    setMapMainParameters: (state, mapMainParameters) => {
      state.mapMainParameters = mapMainParameters;
    },
    setLayerGroups: (state, layerGroups) => {
      state.layerGroups = layerGroups;
    },
    setCurrentLayerGroup: (state, currentLayerGroup) => {
      state.currentLayerGroup = currentLayerGroup;
    },
    setCurrentLayer: (state, currentLayer) => {
      state.currentLayer = currentLayer;
    }
  },
  actions
};