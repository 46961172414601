<template>
  <div class="default-data-view">
    <div class="default-data-view_name">
      <PlatoInlineEdit
        :value="viewName"
        class="default-data-view_name-select"
        placeholder="Название отображения"
        type="Text"
        :editable="editableName"
        @change="changeName"
      />
      <PlatoButtonGroup
        v-if="writeAccess"
        class="default-data-view_name-action"
      >
        <PlatoButton
          v-if="!editableName"
          :loading="loading"
          icon="trash"
          type="danger-action"
          @click="removeDataView(viewName)"
        />
      </PlatoButtonGroup>
    </div>

    <div
      v-if="!editableName && writeAccess"
      class="default-data-view_block"
    >
      <div class="default-data-view_block-name">
        Использовать по умолчанию
      </div>
      <div class="default-data-view_block-value">
        <PlatoInlineEdit
          :value="isDefaultView"
          type="Boolean"
          :disabled="dataLoading"
          @change="changeDefaultView"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { scopes } from "@/store/Access";

export default {
  props: {
    dataSourceId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      viewName: "",
      isDefaultView: false,
      loading: false
    };
  },

  computed: {
    ...mapGetters({
      dataLoading: "DATA_LOADING",
      hiddenColumns: "grouping/hiddenColumns",
      dataViewName: "dataView/dataViewName",
      editableName: "dataView/editableName"
    }),

    grouping() {
      return this.$store.getters["grouping/grouping"](this.dataSourceId);
    },

    applicationId() {
      return this.$store.getters.MANIFEST_ID;
    },

    writeAccess() {
      return this.$store.getters.writeAccess({ scope: scopes.APPLICATION, resource: this.applicationId });
    },

    activeFilters() {
      return this.$store.getters["filters/activeFilters"](this.dataSourceId);
    },

    appliedSorting() {
      return this.$store.getters["sorting/sorting"](this.dataSourceId);
    },

    dataView() {
      return this.$store.getters["dataSourceDataView"](this.dataSourceId);
    },

    isCurrentViewDefaultView() {
      let defaultDataView = this.$store.getters["dataSourceDefaultDataView"](this.dataSourceId);
      return defaultDataView?.name === this.viewName;
    },

    showSaveButton() {
      return this.isDefaultView !== this.isCurrentViewDefaultView;
    }
  },

  watch: {
    dataViewName: {
      immediate: true,
      handler(value) {
        this.viewName = value;
        this.isDefaultView = this.isCurrentViewDefaultView;
      }
    }
  },

  methods: {
    ...mapActions([
      "addDataSourceDataView",
      "updateDataSourceDataView",
      "deleteDataSourceDataView",
      "setDataSourceDefaultDataView",
      "deleteDataSourceDefaultDataView"
    ]),

    ...mapActions("dataView", [
      "setDataViewName",
      "setEditDefaultView",
      "setEditOperation"
    ]),

    changeName(value) {
      this.viewName = value;
      this.setDataViewName(value);
    },

    async removeDataView(name) {
      this.loading = true;
      this.Modal.confirm({
        title: "Удаление представления данных",
        message: `Вы уверены, что хотите удалить представление данных - ${name}`,
        type: "danger",
        onConfirm: async () => {
          await this.deleteDataSourceDataView({ dataSourceId: this.dataSourceId, name });
        }
      });
    },

    changeDefaultView(value) {
      this.isDefaultView = value;
      let isEdit = false;
      let operation = "";

      if (this.isDefaultView !== this.isCurrentViewDefaultView) {
        isEdit = true;
        if (this.isDefaultView === false && this.isCurrentViewDefaultView === true) {
          operation = "delete";
        }
        else {
          operation = "setView";
        }
      }

      this.setEditDefaultView(isEdit);
      this.setEditOperation(operation);
    }
  }
};
</script>
<style lang="scss" scoped>
.default-data-view {
    display: flex;
    flex-direction: column;

    &_name{
      display: flex;
      align-items: center;

      &-select {
        display: flex;
        margin-left: -6px;
        margin-right: 10px;
        font-size: 20px;
      }

      &-action{
        display: flex;
        flex: 1 0 35px;
      }
    }

    &_block{
      display: flex;
      flex-direction: column;
      align-items: baseline;

      &-name{
        font-size: 14px;
        font-weight: 600;
        padding: 5px 7px;
        min-width: 140px;
      }

      &-value{
        padding: 0 0 0 13px;
      }
    }
}
</style>