<template>
  <PlatoButton
    ref="platoChipButton"
    v-click-outside="clickOutside"
    :style="chipStyle"
    :active="active"
    :type="`${type} chip`"
    :icon="icon"
    :component-style="componentStyle"
    @mouseenter.native="isPopperOpened = true"
    @mouseleave.native="isPopperOpened = false"
    @click="chipClick"
  >
    {{ label }}
    <PlatoPopper
      v-if="showHint && isPopperOpened || clicked"
      :target-element="$refs.platoChipButton.$el"
      placement="top"
      :offset="[0, 8]"
      @click.native.stop.prevent="() => {}"
    >
      <div class="chip">
        <div
          v-if="hint"
          class="chip_alias"
        >
          {{ hint }}
        </div>
        <div class="chip_label">
          {{ label }}
        </div>
      </div>

      <div
        v-if="loading"
        class="chip__loader-wrap"
      >
        <PlatoLoader
          class="chip__loader"
          mini
        />
      </div>

      <div
        v-if="!loading && showMoreInfo && navigationObjectLoaded"
        class="info-wrapper"
      >
        <div
          v-for="(prop, index) in navigationObject.ownProperties"
          :key="index"
          class="own-property"
        >
          <div class="own-property__name">
            {{ prop.name }}
          </div>

          <div class="own-property__value">
            {{ prop.value }}
          </div>
        </div>
      </div>
    </PlatoPopper>
  </PlatoButton>
</template>

<script>
import NavigationObjectConverter from "./NavigationObjectConverter.js";
const navigationObjectConverter = new NavigationObjectConverter();

export default {
  name: "PlatoChip",
  props: {
    label: {
      type: String,
      default: ""
    },
    hint: {
      type: String,
      default: ""
    },
    showHint: {
      type: Boolean,
      default: true
    },
    active: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "custom"
    },
    componentStyle: {
      type: Object,
      default: () => {}
    },
    maxWidth: {
      type: [Number, String],
      default: ""
    },
    minWidth: {
      type: [Number, String],
      default: ""
    },
    icon: {
      type: String,
      default: null
    },
    showMoreInfo: {
      type: Boolean,
      default: false
    },
    instanceId: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      isPopperOpened: false,
      loading: false,
      clicked: false
    };
  },

  computed: {
    chipStyle() {
      const { maxWidth } = this;
      const { minWidth } = this;
      const style = {};

      style["maxWidth"] = maxWidth;
      style["minWidth"] = minWidth;

      return style;
    },

    navigationObjectLoaded() {
      return this.navigationObject && this.instanceId && this.instanceId == this.navigationObject.id;
    },

    navigationObject() {
      let exploreInstance = this.$store.getters["EXPLORE_INSTANCE"];
      if (exploreInstance) {
        let navigationObjectName = this.$store.getters["getNavigationObjectName"](exploreInstance);
        return navigationObjectConverter.createNavigationObjectForCard(exploreInstance, navigationObjectName);
      }
      return null;
    }
  },

  methods: {
    async chipClick() {
      this.$emit("click");

      if (this.showMoreInfo && !this.navigationObjectLoaded) {
        this.clicked = true;
        this.loading = true;
        await this.$store.dispatch("GET_EXPLORE_INSTANCE", this.instanceId);
        this.loading = false;
      }
      else if (this.navigationObjectLoaded) {
        this.clicked = false;
      }
    },

    clickOutside() {
      this.clicked = false;
      this.$store.dispatch("clearExploreInstance");
    }
  }
};
</script>

<style lang="scss" scoped>
.plato-popper {
  user-select: text;
  cursor: auto;
}
.info-wrapper {
  max-width: 500px;
  overflow: auto;
  max-height: 400px;
  margin-top: 5px;
  padding: 0 5px;
}
.own-property {
  margin-top: 10px;
  &__name {
    font-size: 12px;
    display: -webkit-box;
    color: #6b778c;
    text-wrap: wrap;
    text-align: left;
  }
  &__value {
    font-size: 14px;
    color: #172b4d;
    overflow: hidden;
    display: -webkit-box;
    max-width: 500px;
    -webkit-line-clamp: 3;
    overflow-wrap: break-word;
    text-wrap: wrap;
    text-align: left;
  }
}

.chip {
  align-items: flex-start;
  font-weight: normal;
  font-size: 14px;
  & ~ & {
    margin-top: 10px;
  }
  &_alias {
    margin-right: 15px;
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 12px;
    font-weight: normal;
    color: #6b778c;
    text-align: left;
  }
  &_label {
    line-height: 14px;
    text-align: left;
  }
  &__loader {
    &-wrap {
      display: flex;
      justify-content: center;
      padding-top: 5px;
      padding-bottom: 16px;
    }
  }
}
</style>