<template>
  <div class="breadcrumb">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  provide() {
    return {
      BreadcrumbParent: this
    };
  },
  props: {
    separator: {
      type: String,
      default: "/",
      required: false
    },
    separatorIcon: {
      type: String,
      default: "",
      required: false
    }
  }
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.breadcrumb {
  cursor: default;
  &-item {
    color: $N200;
    &-icon,
    &-separator {
      margin: 0 5px !important;
    }
    &:last-child {
      .breadcrumb-item-icon {
        display: none;
      }
      .breadcrumb-item-separator {
        display: none !important;
      }
    }
  }
  &-link {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: $B300;
    }
    &:active {
      color: $B500;
      text-decoration: none;
    }
  }
}
</style>
