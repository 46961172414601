import _ from "lodash";
import StateControllerBase from "@/store/StateControllerBase";
import { convertFilter } from "../FilterConverter.js";
import { DataClient } from "@/PlatoAPI";
import { setLoadingState } from "@/store/decorators/setLoadingState";

const dataClient = new DataClient();

class Actions extends StateControllerBase {
  async SET_DATA_LOADING_ERROR(context, value) {
    context.commit("setDataLoadingError", value);
  }

  async SET_DATA_LOADING(context, value) {
    context.commit("setDataLoading", value);
  }

  increaseTake(context, dataSourceId) {
    context.commit("increaseTake", dataSourceId);
  }

  resetTake(context, dataSourceId) {
    context.commit("resetTake", dataSourceId);
  }

  @setLoadingState
  async deleteInstances(_context, { dataSourceId, ids }) {
    await dataClient.deleteInstances(dataSourceId, ids);
  }

  @setLoadingState
  async getMetadata({ commit }, dataSourceId) {
    const data = await dataClient.getMetadata(dataSourceId);
    commit("setMetadata", { data: data, dataSourceId: dataSourceId });
  }

  @setLoadingState
  async getDataSourceData({ getters, commit, dispatch, rootGetters }, { dataSourceId, aliases }) {
    let take = getters.take(dataSourceId);

    let searchKeyword = rootGetters["searchKeywords/searchKeywords"](dataSourceId);
    let activeFilters = rootGetters["filters/activeFilters"](dataSourceId);
    let sorting = rootGetters["sorting/sorting"](dataSourceId);

    let filtersForRequest = _.flatMap(activeFilters, filter => convertFilter(filter));
    let sortingForRequest = _.map(sorting, sort => ({ propertyAlias: sort.alias, direction: sort.sortType }));

    const body = {
      skip: getters.skip,
      take: take,
      filters: filtersForRequest,
      searchKeyword: searchKeyword,
      requestedPropertiesAliases: _.filter(aliases),
      orderByProperties: sortingForRequest
    };

    let response = await dataClient.getData(dataSourceId, body);
    if (response.items == null) {
      response.items = [];
    }

    commit("setObjects", { data: response.items, dataSourceId: dataSourceId, totalCount: response.totalCount, take });
    dispatch("refreshUrl", { pushToHistory: false }, { root: true });
  }

  resetState(context) {
    context.commit("resetState");
  }
}

export default (new Actions).asPlainObject();