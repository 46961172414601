<template>
  <div
    v-if="!_.isEmpty(_.filter(line.properties))"
    class="plato-card-body-line"
  >
    <div
      v-for="(property, index) in line.properties"
      :key="index"
      class="plato-card-body-line__field"
    >
      <div
        v-if="!property"
      />

      <PlatoInlineEdit
        v-else-if="property.type == 'own'"
        class="plato-card__body_content_col_field"
        :label="property.header.name"
        :value="property.value"
        :is-generated="property.header.isGenerated"
        :editable="!readOnly && !loading && !property.readOnly && !(property.header.tableType === 'File' && isNew)"
        :type="property.header.tableType"
        :xsd-type="property.header.xsdType"
        :is-photo="getIsPhoto(property)"
        :context="{
          dataSourceId: dataSourceId,
          alias: property.header.alias,
          propName: property.header.name
        }"
        @change="(val, callback) => $emit('saveField','own', property, val, callback)"
      />

      <PlatoInlineEditLinked
        v-else-if="property.type == 'linked'"
        class="plato-card__body_content_col_field"
        :metadata="metadata"
        :property="property"
        :data-source-id="dataSourceId"
        :is-table-view="isSingle"
        :can-edit="getCanEdit(property)"
        :can-click="getCanClick(property)"
        :can-add="getCanAdd(property)"
        :can-edit-element="getCanEditElement(property)"
        :show-more-info="showMoreInfo"
        @setLinkedProperties="$emit('setLinkedProperties')"
        @saveField="(type, prop, value) => $emit('saveField', type, property, value)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { annotationProperties, getAnnotationPropertyValue } from "@/components/utils/annotationProperty/AnnotationProperty.js";
import { scopes } from "@/store/Access";

export default {
  props: {
    line: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: true
    },
    isNew: {
      type: Boolean,
      required: true
    },
    isSingle: {
      type: Boolean,
      required: true
    },
    dataSourceId: {
      type: String,
      required: true
    },
    showMoreInfo: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      loading: "card/dataLoading"
    }),

    metadata() {
      return this.$store.getters["card/metadata"](this.dataSourceId);
    },

    writeAccess() {
      return this.$store.getters.writeAccess({ scope: scopes.APPLICATION_DATA, resource: this.dataSourceId });
    }
  },

  methods: {
    getIsPhoto(property) {
      return getAnnotationPropertyValue(this.metadata, property.header.alias, annotationProperties.PLATO_IS_PHOTO);
    },

    getCanEdit(prop) {
      return !this.readOnly && prop.header.isEditable && !prop.header.parentAlias && this.writeAccess;
    },

    getCanClick(prop) {
      return prop.header.isEditable;
    },

    getCanAdd(prop) {
      return !this.readOnly && !this.isNew && !!prop.field?.createCardAction && prop.isEditable && !prop.header.parentAlias && this.writeAccess;
    },

    getCanEditElement(prop) {
      return !this.readOnly && !this.isNew && !!prop.field?.editCardAction && prop.isEditable && this.writeAccess;
    }
  }
};
</script>

<style lang="scss" scoped>
.plato-card-body-line {
  display: flex;
  align-items: flex-start;
  gap: 10px;

  &__field {
      flex: 1;
      width: 0;
      margin-bottom: 5px;
  }
}
</style>