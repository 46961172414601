<template>
  <div>
    <PlatoButton
      :ref="'button-' + _uid"
      :active="show"
      :disabled="disabled"
      :type="type"
      :icon="buttonIcon"
      :title="title"
      class="plato-context-menu"
      @click="show = !show"
    >
      <span
        v-if="text"
        v-text="text"
      />
    </PlatoButton>

    <PlatoPopper
      v-if="show"
      slot="after"
      v-click-outside="hide"
      :target-element="$refs['button-' + _uid].$el"
      :offset="[0, 0]"
      :placement="'bottom-end'"
      popper-class="plato-context-menu__popper"
    >
      <slot :hide="hide" />
    </PlatoPopper>
  </div>
</template>

<script>
import _ from "lodash";

import { mapGetters } from "vuex";

export default {
  name: "PlatoContextMenu",
  components: {},
  props: {
    verticalDots: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    },
    icon: {
      type: String
    },
    text: {
      type: String
    },
    title: {
      type: String,
      default: ""
    },
    showIndicator: {
      type: Boolean,
      require: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      show: false,
      popupAlign: {
        horizontal: "right",
        vertical: "top"
      },
      anchorAlign: {
        horizontal: "left",
        vertical: "bottom"
      }
    };
  },
  computed: {
    ...mapGetters({
      activeTour: "onboarding/activeTour"
    }),

    buttonIcon() {
      if (!_.isNil(this.icon)) {
        return this.icon;
      }

      if (this.verticalDots) {
        return "ellipsis-v";
      }

      return "ellipsis-h";
    }
  },
  watch: {
    show(value) {
      if (value) {
        this.$store.dispatch("onboarding/nextTourStep");
      }
    },
    showIndicator(value) {
      if (value) {
        this.show = false;
      }
    }
  },
  mounted() {
    this.show = false;
  },
  methods: {
    hide() {
      if (this.activeTour) {
        if (this.activeTour.currentStep.id == "recordActionsTour_step_four" || this.activeTour.currentStep.id == "parameterizationTour_step_two") {
          return;
        }
        else {
          this.show = false;
        }
      }
      else {
        this.show = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
[max-width-400] {
  .plato-context-menu__popper {
    max-width: 400px;
  }
}
.plato-context-menu {
  &__popper {
    z-index: 4000;
    background-color: #fff;
    align-items: center;
    border-radius: 5px;
    max-width: 250px;
    min-width: 160px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    &_item {
      max-width: 250px;
      text-align: left;
      display: flex;
      flex-direction: column;
    }
  }
  ::v-deep {
    .plato-context-menu__btn {
      justify-content: flex-start;
      width: 100%;
    }
  }
}
</style>


