import _ from "lodash";

const getDefaultState = () => {
  return {
    hiddenColumns: [],
    grouping: [],
    listGroupingOptions: []
  };
};

export default {
  namespaced: true,
  state: getDefaultState,

  getters: {
    hiddenColumns: state => {
      return state.hiddenColumns;
    },

    listGroupingOptions: state => dataSourceId => {
      return _.chain(state.listGroupingOptions)
        .find({ dataSourceId })
        .get("options", [])
        .value();
    },

    grouping: state => dataSourceId => {
      return _.chain(state.grouping)
        .find({ dataSourceId })
        .get("property", {})
        .value();
    }
  },

  mutations: {
    setHiddenColumns: (state, hiddenColumns) => {
      state.hiddenColumns = hiddenColumns;
    },

    clearHiddenColumns: state=> {
      state.hiddenColumns = [];
    },

    setGroupingOptions: (state, { dataSourceId, options }) =>{
      state.listGroupingOptions.push({ dataSourceId, options });
    },

    setGrouping: (state, { dataSourceId, property }) => {
      let prop = _.find(state.grouping, { dataSourceId });

      if (prop) {
        prop.property = property;
        return;
      }

      state.grouping.push({ dataSourceId, property });
    },

    clearGrouping: (state, dataSourceId) => {
      state.grouping = _.reject(state.grouping, { dataSourceId });
    },

    resetState: state => {
      Object.assign(state, getDefaultState());
    }
  },

  actions: {
    setHiddenColumns({ commit }, hiddenColumns) {
      commit("setHiddenColumns", hiddenColumns);
    },

    clearHiddenColumns({ commit }) {
      commit("clearHiddenColumns");
    },

    setGroupingOptions({ commit }, { dataSourceId, options }) {
      commit("setGroupingOptions", { dataSourceId, options });
    },

    setGrouping({ commit }, { dataSourceId, property }) {
      commit("setGrouping", { dataSourceId, property });
    },

    clearGrouping({ commit }, dataSourceId) {
      commit("clearGrouping", dataSourceId);
    },

    resetState(context) {
      context.commit("resetState");
    }
  }
};