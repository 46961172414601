<template>
  <PlatoEdit
    :label="label"
    :is-generated="isGenerated"
  >
    <Component
      :is="component"
      :value="value"
      v-bind="context"
      :placeholder="placeholder"
      :editable="editable"
      @change="change"
      @open="emitOpen"
    />
  </PlatoEdit>
</template>

<script>
import SkeletonLoader from "@/components/package/Loader/SkeletonLoader.vue";

export default {
  name: "PlatoInlineEdit",

  components: {
    SkeletonLoader
  },

  props: {
    context: {
      type: Object,
      default: () => {}
    },
    value: {
      type: [String, Object, Number, Boolean, Array],
      default: null
    },
    placeholder: {
      type: String,
      default: "(нет данных)"
    },
    label: {
      type: String,
      default: ""
    },
    editable: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      required: true,
      validator: function(value) {
        let validationResult =
          [
            "Text",
            "Numeric",
            "DateTimeUTC",
            "Geometry",
            "Select",
            "Boolean",
            "Icons",
            "MultilineText",
            "Switch",
            "File",
            "Cadastre",
            "SingleFile",
            "Color",
            "RichText",
            "HTML"
          ].indexOf(value) !== -1;

        if (!validationResult) {
          console.error(value);
        }

        return validationResult;
      }
    },
    xsdType: {
      type: String,
      default: ""
    },
    isPhoto: {
      type: Boolean,
      default: false
    },
    isGenerated: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    component() {
      let component = null;

      switch (this.type) {
      case "File":
        if (this.isPhoto) {
          component = import("@/components/package/InlineEdit/FileEdit/FilesEdit.vue");
          break;
        }
        else {
          component = import("@/components/package/InlineEdit/VueAtlaskit/field-renderers/FileEditableRenderer");
          break;
        }
      case "SingleFile":
        component = import("@/components/package/InlineEdit/VueAtlaskit/field-renderers/SingleFileEditableRenderer");
        break;
      case "Cadastre":
        component = import("@/components/package/InlineEdit/VueAtlaskit/field-renderers/CadastreEditableRenderer");
        break;
      case "HTML":
        component = import("@/components/package/InlineEdit/VueAtlaskit/field-renderers/HTMLEditableRenderer");
        break;
      case "Boolean":
        component = import("@/components/package/Switch/PlatoBooleanSwitch.vue");
        break;
      case "RichText":
        component = import("@/components/package/InlineEdit/RichTextEdit/RichTextEdit.vue");
        break;
      case "Text":
        if (this.xsdType == "rdf:text") {
          component = import("@/components/package/InlineEdit/VueAtlaskit/field-renderers/MultiLineEditableRenderer");
          break;
        }
        else {
          component = import("@/components/package/InlineEdit/VueAtlaskit/field-renderers/StringLineEditableRenderer");
          break;
        }
      case "Numeric":
        component = import("@/components/package/InlineEdit/VueAtlaskit/field-renderers/NumberFloatEditableRenderer");
        break;
      case "DateTimeUTC":
        component = import("@/components/package/InlineEdit/VueAtlaskit/field-renderers/DateEditableRenderer");
        break;
      case "Checkbox":
        component = import("@/components/package/Select/PlatoCheckboxSelect.vue");
        break;
      case "Icons":
        component = import("@/components/package/Icons/PlatoIconSelect.vue");
        break;
      case "Select":
        component = import("@/components/package/Select/PlatoSelect.vue");
        break;
      case "Color":
        component = import("@/components/package/ColorPicker/PlatoColorPicker.vue");
        break;
      case "Geometry":
        component = import("@/components/package/InlineEdit/GeometryEdit/GeometryEdit.vue");
        break;
      default:
        return null;
      }

      return () => ({
        component,
        loading: SkeletonLoader,
        delay: 0
      });
    }
  },

  methods: {
    change(value, callback) {
      this.$emit("change", value, callback);
      this.$emit("input", value);
    },

    emitOpen(value, id) {
      this.$emit("open", value, id);
    }
  }
};
</script>