import _ from "lodash";
import StateControllerBase from "@/store/StateControllerBase";
import { DataClient, ExploreClient } from "@/PlatoAPI";
import { setLoadingState } from "@/store/decorators/setLoadingState";

const dataClient = new DataClient();
const exploreClient = new ExploreClient();

function getInstancePropertiesForInsert(instance) {
  let ownProperties = _.reduce(instance.ownProperties, (result, prop) => {
    if (prop.value != null && prop.value != "") {
      result.push({ alias: prop.alias, value: "" + prop.value });
    }
    return result;
  }, []);
  let linkedProperties = _.reduce(instance.linkedProperties, (result, prop) => {
    if (prop.value != null && prop.value != "") {
      result.push({ alias: prop.alias, value: prop.value });
    }
    return result;
  }, []);
  return _.concat(ownProperties, linkedProperties);
}

class Actions extends StateControllerBase {
  async SET_DATA_LOADING_ERROR(context, value) {
    context.commit("setDataLoadingError", value);
  }

  async SET_DATA_LOADING(context, value) {
    context.commit("setDataLoading", value);
  }

  deleteCurrentObject({ commit }) {
    commit("deleteCurrentObject");
  }

  setEditingCurrentObject({ commit }, object) {
    commit("setEditingCurrentObject", object);
  }

  async getGeneratedData({ commit }, dataSourceId) {
    let data = await dataClient.getGeneratedData(dataSourceId);

    data.data.forEach(field => {
      commit("changeNewObjectField", field);
    });
  }

  @setLoadingState
  async getFullObject({ dispatch }, { dataSourceId, instanceId }) {
    let body = {
      skip: 0,
      take: 1,
      filters: [],
      searchKeyword: "",
      instanceIds: [instanceId]
    };
    let response = await dataClient.getDataWithHeaders(dataSourceId, body);
    dispatch("currentObject/setETag", response.headers.etag, { root: true });
    return response.data;
  }

  @setLoadingState
  async getFullCurrentObject({ commit, rootGetters, dispatch }, aliases = []) {
    let currentObject = rootGetters["currentObject/currentObject"];
    if (_.isEmpty(currentObject)) {
      commit("setCurrentObject", null);
      return;
    }

    let body = {
      skip: 0,
      take: 1,
      filters: [],
      searchKeyword: "",
      requestedPropertiesAliases: _.filter(aliases),
      instanceIds: [currentObject.id]
    };

    commit("setCurrentObject", null);
    let response = await dataClient.getDataWithHeaders(currentObject.dataSourceId, body);
    commit("setCurrentObject", response.data.items[0]);
    dispatch("currentObject/setETag", response.headers.etag, { root: true });
  }

  removeFullCurrentObject({ commit }) {
    commit("setCurrentObject", null);
  }

  setLinkedPropertyAlias({ commit, dispatch }, { linkedPropertyAlias, refreshUrl = true }) {
    commit("setLinkedPropertyAlias", linkedPropertyAlias);

    if (refreshUrl) {
      dispatch("refreshUrl", { pushToHistory: false }, { root: true });
    }
  }

  setLinkedDataSourceId({ commit, dispatch }, { linkedDataSourceId, refreshUrl = true }) {
    commit("setLinkedDataSourceId", linkedDataSourceId);

    if (refreshUrl) {
      dispatch("refreshUrl", { pushToHistory: false }, { root: true });
    }
  }

  setLinkedObjectId({ commit, dispatch }, { linkedObjectId, refreshUrl = true }) {
    commit("setLinkedObjectId", linkedObjectId);

    if (refreshUrl) {
      dispatch("refreshUrl", { pushToHistory: false }, { root: true });
    }
  }

  setStartPageId({ commit, dispatch }, { startPageId, refreshUrl = true }) {
    commit("setStartPageId", startPageId);

    if (refreshUrl) {
      dispatch("refreshUrl", { pushToHistory: false }, { root: true });
    }
  }

  setSectorId({ commit, dispatch }, { sectorId, refreshUrl = true }) {
    commit("setSectorId", sectorId);

    if (refreshUrl) {
      dispatch("refreshUrl", { pushToHistory: false }, { root: true });
    }
  }

  @setLoadingState
  async deleteInstance(_context, { dataSourceId, id }) {
    await dataClient.deleteInstance(dataSourceId, id);
  }

  @setLoadingState
  async getParentExploreInstance(context, id) {
    let instance = await exploreClient.getInstanceById(id);
    context.commit("setParentExploreInstance", instance);
  }

  async removeParentExploreInstance(context) {
    context.commit("setParentExploreInstance", "");
  }

  addNewObject(context, metadata) {
    context.commit("addNewObject", metadata);
  }

  changeNewObjectField(context, field) {
    context.commit("changeNewObjectField", field);
  }

  @setLoadingState
  async saveChangedFields({ getters, dispatch }, dataSourceId) {
    let fields = _.values(getters.changedFields(dataSourceId));
    await dispatch("currentObject/saveCurrentObject", { fields, dataSourceId }, { root: true });
    dispatch("clearChangedFields", dataSourceId);
    dispatch("changedObject/setChangedObjectDataSourceId", dataSourceId, { root: true });
  }

  addChangedFields(context, field) {
    context.commit("addChangedFields", field);
  }

  clearChangedFields(context, dataSourceId) {
    context.commit("clearChangedFields", dataSourceId);
  }

  async saveNew({ getters, commit }, dataSourceId) {
    let properties = getInstancePropertiesForInsert(getters.newObject);
    if (!_.isEmpty(properties)) {
      const property = JSON.stringify({
        properties: properties
      });

      let instance = await dataClient.insertData(dataSourceId, property);

      commit("setLastNewObjectInstanceId", instance.instanceId);
      commit("clearNewObject");
    }
  }

  async getMetadata({ commit, dispatch }, dataSourceId) {
    const metadata = await dataClient.getMetadata(dataSourceId);
    commit("addMetadata", { metadata, dataSourceId });
    dispatch("addNewObject", metadata);
  }

  resetState(context) {
    context.commit("resetState");
  }

  async getExternalDataSourceData(context, { dataSourceId, searchKeyword }) {
    return await dataClient.getExternalDataSourceData(dataSourceId, searchKeyword);
  }

  async getLinkedDataSourceMetadata(context, dataSourceId) {
    return await dataClient.getMetadata(dataSourceId);
  }

  setNewObjectType({ commit }, type) {
    commit("setNewObjectType", type);
  }

  async saveExternalInstance(context, { dataSourceId, instance }) {
    let properties = getInstancePropertiesForInsert(instance);
    if (!_.isEmpty(properties)) {
      const property = JSON.stringify({
        properties: properties
      });

      await dataClient.insertData(dataSourceId, property);
    }
  }

  @setLoadingState
  async updateExternalInstance(context, { dataSourceId, instanceId }) {
    let body = {
      request: {
        dataSourceId: dataSourceId,
        skip: 0,
        take: 1,
        instanceIds: [instanceId]
      }
    };
    return await dataClient.updateExternalDataSourceInstance(body);
  }
}

export default (new Actions).asPlainObject();