<template>
  <div
    class="plato-component-header"
  >
    <PlatoComponentHeaderLeft
      :title="title"
      :subtitle="subtitle"
    />
    <PlatoComponentHeaderRight
      :actions="actions"
      :show-delete-button="showDeleteButton"
      :disable-delete-button="disableDeleteButton"
      :request-aliases="requestAliases"
      :change-add-action-view="changeAddActionView"
      @confirmDeleteInstance="$emit('confirmDeleteInstance')"
      @triggerAction="context => $emit('triggerAction', context)"
      @change-header-view="$emit('change-header-view')"
    />
  </div>
</template>

<script>
import PlatoComponentHeaderLeft from "./PlatoComponentHeaderLeft.vue";
import PlatoComponentHeaderRight from "./PlatoComponentHeaderRight.vue";

export default {
  name: "PlatoComponentHeader",
  components: { PlatoComponentHeaderLeft, PlatoComponentHeaderRight },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: [String, Number],
      default: ""
    },
    actions: {
      type: Array,
      default: () => []
    },
    showDeleteButton: {
      type: Boolean,
      default: false
    },
    disableDeleteButton: {
      type: Boolean,
      default: false
    },
    requestAliases: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      headerWidth: null
    };
  },
  computed: {
    changeAddActionView() {
      return this.headerWidth < 400;
    }
  },
  mounted() {
    let ro = new ResizeObserver(entries => {
      this.headerWidth = entries[0].contentRect.width;
    });

    ro.observe(document.querySelector(".plato-component-header"));
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
@import "../styles/variables.scss";

.plato-component-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 15px 16px;

  &__left_name {
    font-size: 20px;
    font-weight: 500;
  }

  &__right {
    display: flex;
    align-items: right;
  }
}
</style>