import actions from "./index/shellActions";

const getDefaultState = () => {
  return {
    applications: [],
    shellNavItems: {
      main: [],
      bottom: []
    },
    shellNavLogo: {
      href: "",
      name: ""
    },
    shellManifest: {},
    shellPage: null,
    menuCanCollapse: false,
    versions: null
  };
};

export default {
  state: getDefaultState,
  getters: {
    SHELL_PAGE: state => {
      return state.shellPage;
    },

    APPLICATIONS: state => {
      return state.applications;
    },

    versions: state => {
      return state.versions;
    },

    SHELL_MANIFEST: state => {
      return state.shellManifest;
    },

    NAV_ITEMS: state => {
      return state.shellNavItems;
    },

    NAV_LOGO: state => {
      return state.shellNavLogo;
    },

    MENU_CAN_COLLAPSE: state => {
      return state.menuCanCollapse;
    },

    portalApplication: state => {
      return state.shellManifest?.portalApplication;
    }
  },
  mutations: {
    setInnerErrorCode: (state, value) => {
      state.innerErrorCode = value;
    },

    resetShellState: state => {
      Object.assign(state, getDefaultState());
    },

    SET_SHELL_PAGE: (state, value) => {
      state.shellPage = value;
    },

    setVersions: (state, value) => {
      state.versions = value;
    },

    SET_APPLICATIONS: (state, applications) => {
      state.applications = applications;
    },

    /**
     * Определение элементов бокового меню
     *
     * @param {Array} items эдементы меню (false загружает меню оболочки)
     * [
     *   {
     *      href: "/navigator", // ссылка поп которой будет выполнен переход
     *      name: "Навигатор данных", //название которое будет отображаться
     *      iconName: "project-diagram", // класс иконки
     *      items: [...], // дочерние элементы, описываются так же как родительские
     *      isOpened: true, // раскрыт ли список дочерних элементов
     *   },
     * ]
     */
    SET_NAV_ITEMS: (state, items) => {
      state.shellNavItems = items;
    },

    /**
     * Установка параметров logo для бокового меню
     *
     * @param {Object} logoInfo информация о логитипе в боковой панели
     * (false загружает логтип оболоки)
     * {
     *  href: "/", // ссылка поп которой будет выполнен переход
     *  name: "", // отображаемый текст
     *  icon: "/img/shell/logo.png", // путь до изображения
     * }
     */
    SET_NAV_LOGO: (state, logoInfo) => {
      if (logoInfo) {
        state.shellNavLogo = {
          href: logoInfo?.href ? logoInfo.href : state.shellManifest?.href ? `${state.shellManifest.href}` : null,
          name: logoInfo?.name ? logoInfo.name : state.shellManifest?.name ? `${state.shellManifest.name}` : null,
          icon: logoInfo?.icon ? logoInfo.icon : state.shellManifest?.icon ? `${state.shellManifest.icon}` : null
        };
      }
      else {
        state.shellNavLogo = {
          href: "/",
          name: state.shellManifest?.title,
          icon: state.shellManifest?.icon ? `${state.shellManifest.icon}` : null
        };
      }
    },

    SET_SHELL_MANIFEST: (state, manifest) => {
      state.shellManifest = manifest;
    },

    SET_MENU_CAN_COLLAPSE: (state, value) => {
      state.menuCanCollapse = value;
    }
  },
  actions
};