<template>
  <PlatoButtonGroup>
    <PlatoChip
      :title="filter.name"
      :label="label"
      :show-hint="false"
      @click="openFilter"
    />

    <PlatoButton
      type="light-blue chip"
      icon="xmark"
      @click="removeFilter"
    />
  </PlatoButtonGroup>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    filter: {
      type: Object,
      required: true
    }
  },
  computed: {
    count() {
      return this.filter.value.length;
    },
    label() {
      let label = "";
      if (this.filter.name.length >= 22) {
        label = `${this.filter.name.substr(0, 22)}...`;
      }
      else {
        label = `${this.filter.name}:`;
      }

      switch (this.filter.type) {
      case "SelectionDynamic":
        label += ` ${this.count} элем.`;
        break;
      case "Range":
        if (!_.isEmpty(this.filter.value.gteValue)) {
          label += ` от ${this.filter.value.gteValue}`;
        }
        if (!_.isEmpty(this.filter.value.lteValue)) {
          label += ` до ${this.filter.value.lteValue}`;
        }
        break;
      case "Boolean":
        if (this.filter.value) {
          label += " Да";
        }
        else {
          label += " Нет";
        }
        break;
      default :
        label = this.filter.name;
        break;
      }

      return label;
    }
  },
  methods: {
    removeFilter() {
      this.$emit("remove", this.filter);
    },
    openFilter() {
      this.$emit("open", this.filter);
    }
  }
};
</script>