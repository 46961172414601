<template>
  <div class="app-wrap">
    <slot />
  </div>
</template>

<script>

export default {
  name: "PlatoApp"
};
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
@import "../styles/variables.scss";

.app-wrap{
  position: relative;
}
</style>
