<template>
  <div v-if="currentObject && !loading">
    <template v-for="(group, index) in groups">
      <PlatoCardBodyGroup
        v-if="!isEmptyGroup(group)"
        :key="index"
        :group="group"
        :read-only="readOnly"
        :is-new="isNew"
        :data-source-id="dataSourceId"
        :is-single="false"
        :show-more-info="showMoreInfo"
        @saveField="saveField"
        @setLinkedProperties="setLinkedProperties"
        @fly-to="$emit('flyTo')"
      />
    </template>

    <div
      v-if="isShowNoContent"
      class="plato-card__body_no-content_sector"
    >
      Нет данных
    </div>
  </div>
  <PlatoLoader
    v-else-if="loading"
  />
</template>

<script>
import _ from "lodash";

import platoCardBodyDataMixin from "./platoCardBodyDataMixin.js";
import PlatoCardBodyGroup from "./PlatoCardBodyGroup.vue";
import { annotationProperties, getAnnotationPropertyValue } from "@/components/utils/annotationProperty/AnnotationProperty.js";

export default {
  components: {
    PlatoCardBodyGroup
  },

  mixins: [platoCardBodyDataMixin],

  props: {
    sector: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: true
    },
    isNew: {
      type: Boolean,
      required: true
    },
    dataSourceId: {
      type: String,
      required: true
    },
    showEmpty: {
      type: Boolean,
      required: true
    },
    nameSetting: {
      type: Object,
      default: null
    },
    showMoreInfo: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      linkedProperties: null
    };
  },

  computed: {
    groups() {
      let groups = [];

      _.cloneDeep(this.sector).groups.forEach(group => {
        let newGroup = {
          name: group.name,
          lines: []
        };

        group.lines.forEach(line => {
          let newLine = {
            colCount: line.colCount,
            properties: []
          };

          _.values(line.fields[0]).forEach(field => {
            let property = _.cloneDeep(_.find(this.ownProperties, prop => prop.header.alias == field.propertyAlias)
            || _.find(this.linkedProperties, prop => prop.header.alias == field.propertyAlias));

            if (!property) {
              newLine.properties.push(null);
              return;
            }

            if (property.type == "linked" && _.isEmpty(property.values)) {
              let properties = _.filter(this.linkedProperties, v => !_.isEmpty(v.values));
              let flatValues = _.flatMapDeep(properties, "values");
              let prop = _.find(properties, prop => _.some(flatValues, value => !_.some(prop.values, v => v.linkedInstanceId == value.instanceId)));
              property.isAnchor = true;

              if (prop) {
                let flatPropValues = _.chain(prop.values).flatMapDeep().filter("linkedInstanceId").filter("value").value();

                flatPropValues.forEach(value => {
                  property.values.push([{
                    instanceId: _.get(value, "linkedInstanceId")
                  }]);
                });
              }
            }

            property.header.name = field.displayName || property.header.name;

            if (property.type == "linked" && (this.showEmpty || !_.isEmpty(property.values))) {
              property.field = field;
              property.header.linkedProperties = this.getIncludedLinkedProperties(property.header.alias);

              if (_.isEmpty(_.flatMapDeep(property.header.linkedProperties, "values")) && property.isAnchor) {
                if (this.showEmpty) {
                  property.values = [];
                  newLine.properties.push(property);
                }
                else {
                  newLine.properties.push(null);
                }
              }
              else {
                newLine.properties.push(property);
              }
            }
            else if (property.type == "linked" && !(this.showEmpty || !_.isEmpty(property.values))) {
              newLine.properties.push(null);
            }
            else if ((this.showEmpty ||
            ((property.value !== null &&
              property.value !== "") &&
                (property.header.tableType != "Geometry" ||
                  (property.header.tableType === "Geometry" &&
                    property.value.coordinates.length != 0)))) &&
          //поля которые не показываются при создание новой записи
          (property.header.tableType != "Geometry" || !this.isNew)) {
              newLine.properties.push(property);
            }
            else {
              newLine.properties.push(null);
            }
          });

          if (!_.isEmpty(newLine.properties)) {
            newGroup.lines.push(newLine);
          }
        });

        groups.push(newGroup);
      });

      return groups;
    },

    isShowNoContent() {
      let props = _.chain(this.groups).flatMapDeep("lines").flatMapDeep("properties").filter().value();

      return _.isEmpty(props);
    },

    aliases() {
      let aliases = [];

      let lines = _.chain(this.sector.groups).flatMapDeep("lines").flatMapDeep("fields").value();

      lines.forEach(line => {
        for (const fieldName in line) {
          if (line[fieldName].ownPropertyAlias) {
            aliases.push(line[fieldName].ownPropertyAlias);
          }
          else if (!Object.prototype.hasOwnProperty.call(line[fieldName], "ownPropertyAlias")) {
            let property = _.find(this.metadata.linkedProperties, { alias: line[fieldName].propertyAlias });
            if (property) {
              aliases.push(property.properties[0].alias);
            }
            else {
              aliases.push(line[fieldName].propertyAlias);
            }
          }
          else if (line[fieldName].propertyAlias) {
            aliases.push(line[fieldName].propertyAlias);
          }
        }
      });

      let photoProps = _.filter(this.metadata.ownProperties, property => property.tableType == "File"
        && getAnnotationPropertyValue(this.metadata, property.alias, annotationProperties.PLATO_IS_PHOTO));
      aliases.push(..._.map(photoProps, "alias"));

      let parentProp = _.find(this.metadata.linkedProperties, property => getAnnotationPropertyValue(this.metadata, property.alias, annotationProperties.PLATO_TO_PARENT));
      aliases.push(_.get(parentProp, "properties[0].alias"));

      if (this.nameSetting) {
        aliases.push(this.nameSetting.ownPropertyAlias);
        aliases.push(this.nameSetting.propertyAlias);
        aliases.push(this.nameSetting.value);
      }

      return aliases;
    }
  },

  methods: {
    isEmptyGroup(group) {
      return !_.some(group.lines, line => _.some(line.properties, prop => prop !== null));
    },

    getIncludedLinkedProperties(alias) {
      let properties = _.filter(this.linkedProperties, linkedProperty => linkedProperty.header.parentAlias == alias);

      let collectedProperties = [...properties];

      properties.forEach(property => {
        collectedProperties = [...collectedProperties,
          ...this.getIncludedLinkedProperties(property.header.alias)];
      });

      return collectedProperties;
    }
  }
};
</script>

<style>

</style>