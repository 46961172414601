import _ from "lodash";
import StateControllerBase from "@/store/StateControllerBase";
import { convertFilter } from "../FilterConverter.js";
import { DataClient } from "@/PlatoAPI";
import { setLoadingState } from "@/store/decorators/setLoadingState";

const dataClient = new DataClient();
class Actions extends StateControllerBase {
  async SET_DATA_LOADING_ERROR(context, value) {
    context.commit("setDataLoadingError", value);
  }

  async SET_DATA_LOADING(context, value) {
    context.commit("setDataLoading", value);
  }

  setTake({ commit }, take) {
    commit("setTake", take);
  }

  @setLoadingState
  async getMetadata({ commit }, dataSourceId) {
    const data = await dataClient.getMetadata(dataSourceId);
    commit("setMetadata", { data: data, dataSourceId: dataSourceId });
  }

  @setLoadingState
  async getDataSourceData({ getters, commit, dispatch, rootGetters }, { dataSourceId, ids }) {
    let searchKeyword = rootGetters["searchKeywords/searchKeywords"](dataSourceId);
    let activeFilters = rootGetters["filters/activeFilters"](dataSourceId);
    let sorting = rootGetters["sorting/sorting"](dataSourceId);

    let filtersForRequest = _.flatMap(activeFilters, filter => convertFilter(filter));
    let sortingForRequest = _.map(sorting, sort => ({ propertyAlias: sort.alias, direction: sort.sortType }));

    const body = {
      skip: getters.skip,
      take: getters.take,
      filters: filtersForRequest,
      searchKeyword: searchKeyword,
      orderByProperties: sortingForRequest,
      instanceIds: ids
    };

    let response = await dataClient.getLargeData(dataSourceId, body);

    commit("setObjects", { data: response.items, dataSourceId: dataSourceId });
    dispatch("refreshUrl", { pushToHistory: false }, { root: true });
  }

  resetState(context) {
    context.commit("resetState");
  }
}

export default (new Actions).asPlainObject();