import _ from "lodash";
import PlatoModal from "./PlatoModal.vue";
import Vue from "vue";

const show = options => {
  const $body = document.querySelector("body");
  const $node = document.createElement("div");
  $body.appendChild($node);

  new Vue({
    el: $node,
    methods: {
      async onClose(e) {
        this.$refs.modal.visible = false;
        try {
          if (typeof options.onConfirm === "function" && e.ok) {
            await options.onConfirm(e);
          }

          if (typeof options.onClose === "function") {
            await options.onClose(e);
          }

          if (typeof options.onCancel === "function" && !e.ok) {
            await options.onCancel(e);
          }
        }
        finally {
          this.$nextTick(() => this.$destroy());
        }
      }
    },
    render(createElement) {
      return createElement(PlatoModal, {
        ref: "modal",
        props: {
          type: options.type,
          title: options.title,
          message: options.message,
          cancel: options.cancel,
          showCancel: options.showCancel
        },
        on: {
          close: this.onClose
        }
      });
    }
  });
};

const confirm = options => show(_.tap(options, o => o.cancel = true));

export default {
  confirm,
  show
};