<template>
  <div class="plato-card-body-group">
    <div
      v-if="group.name"
      class="plato-card-body-group__header"
    >
      <Icon icon="circle-bookmark" />
      <div class="plato-card-body-group__name">
        {{ group.name }}
      </div>
    </div>

    <PlatoCardBodyLine
      v-for="(line, index) in group.lines"
      :key="index"
      :line="line"
      :read-only="readOnly"
      :is-new="isNew"
      :data-source-id="dataSourceId"
      :is-single="isSingle"
      :show-more-info="showMoreInfo"
      @saveField="(type, prop, value) => $emit('saveField', type, prop, value)"
      @setLinkedProperties="$emit('flyTo')"
      @fly-to="$emit('flyTo')"
    />
  </div>
</template>

<script>
import PlatoCardBodyLine from "./PlatoCardBodyLine";

export default {
  components: {
    PlatoCardBodyLine
  },

  props: {
    group: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: true
    },
    isNew: {
      type: Boolean,
      required: true
    },
    isSingle: {
      type: Boolean,
      required: true
    },
    dataSourceId: {
      type: String,
      required: true
    },
    showMoreInfo: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.plato-card-body-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  &__header {
    margin-top: 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: stretch;
    padding-left: 7px;
  }

  &__name {
    font-size: 16px;
    margin-left: 5px;
  }
}
</style>