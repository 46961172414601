import _ from "lodash";
import StateControllerBase from "@/store/StateControllerBase";
import { ApplicationClient, DataClient, DataSourceClient, ExploreClient } from "@/PlatoAPI";
import { Download } from "@/components/utils/Files/FileDownloader.js";
import { setLoadingState } from "@/store/decorators/setLoadingState";

const applicationClient = new ApplicationClient();
const dataClient = new DataClient();
const dataSourceClient = new DataSourceClient();
const exploreClient = new ExploreClient();

const manifestTemplate = () => ({
  actions: [],
  mainColor: "#005480",
  dataSources: [],
  info: {
    applicationId: "app_" + Math.random().toString(36).substring(7),
    description: "",
    iconName: "",
    name: ""
  },
  pages: []
});

class Actions extends StateControllerBase {
  async SET_DATA_LOADING_ERROR(context, value) {
    context.commit("SET_DATA_LOADING_ERROR", value);
  }

  async SET_DATA_LOADING(context, value) {
    context.commit("SET_DATA_LOADING", value);
  }

  resetState(context) {
    context.commit("resetState");
  }

  @setLoadingState
  async GET_FILTERTEMPLATES(context) {
    const data = await dataSourceClient.getAllFilterTemplates();
    context.commit("SET_FILTERTEMPLATES", data);
  }

  @setLoadingState
  async DELETE_FILTERTEMPLATE(_context, id) {
    await dataSourceClient.deleteFilterTemplate(id);
  }

  @setLoadingState
  async SAVE_FILTERTEMPLATE(_context, filterTemplate) {
    await dataSourceClient.saveFilterTemplate(filterTemplate);
  }

  SET_CURRENT_DATASOURCE(context, value) {
    context.commit("SET_CURRENT_DATASOURCE", value);
  }

  @setLoadingState
  async GET_DATASOURCE({ commit }, id) {
    const response = await dataSourceClient.getDataSource(id);
    commit("SET_CURRENT_DATASOURCE", response.data);
  }

  @setLoadingState
  async GET_DATASOURCES_INFO(context) {
    const data = await dataSourceClient.getAllDataSourcesInfo();
    context.commit("SET_DATASOURCES_INFO", data);
  }

  @setLoadingState
  async GET_DATASOURCES(context) {
    const data = await dataSourceClient.getAllDataSources();
    context.commit("SET_DATASOURCES", data);
  }

  @setLoadingState
  async GET_ANNOTATIONPROPERTIES(context) {
    const data = await exploreClient.getAllAnnotationProperties();
    context.commit("SET_ANNOTATIONPROPERTIES", data.annotationProperties);
  }

  @setLoadingState
  async GET_DATAPROPERTIES(context) {
    const data = await exploreClient.getAllDataProperties();

    context.commit("SET_DATAPROPERTIES", data.dataProperties);
  }

  @setLoadingState
  async GET_OBJECTPROPERTIES(context) {
    const data = await exploreClient.getAllObjectProperties();

    context.commit("SET_OBJECTPROPERTIES", data.objectProperties);
  }

  @setLoadingState
  async GET_CLASSES(context) {
    const data = await exploreClient.getAllClasses();

    context.commit("SET_CLASSES", data);
  }

  @setLoadingState
  async GET_DATASOURCE_CLASS_TREE({ commit }, { classesId, classes }) {
    let allClasses = classes || await exploreClient.getAllClasses()
      .catch(e => {
        throw new Error(`Ошибка при запросе классов:\n${e.status}`);
      });
    let dataSourceClassTree = [];

    if (allClasses) {
      let getClassName = id => {
        if (allClasses) {
          let currentClass = _.find(allClasses.items, ["id", id]);
          if (!currentClass || !currentClass.annotationProperties) {
            return "Неизвестно";
          }
          else {
            return _.find(currentClass.annotationProperties, ["type", "rdfs:label"]).value;
          }
        }
      };

      let getChildClass = currentClass => {
        dataSourceClassTree.push(currentClass);
        let childClass = _.find(allClasses.items, { id: currentClass.id });
        childClass.children.forEach(child => {
          getChildClass({
            id: child.id,
            name: getClassName(child.id)
          });
        });
      };

      classesId.forEach(classId => {
        getChildClass({
          id: classId,
          name: getClassName(classId)
        });
      });
    }

    commit("SET_DATASOURCE_CLASS_TREE", dataSourceClassTree);
  }

  @setLoadingState
  async DELETE_DATASOURCE(_context, dsId) {
    await dataSourceClient.deleteDataSource(dsId);
  }

  @setLoadingState
  async SAVE_DATASOURCE({ commit, getters }, dataSource) {
    const data = await dataSourceClient.saveDataSource(dataSource, getters.dataSourceEtag);
    commit("SET_SAVED_DATASOURCE", data);
  }

  @setLoadingState
  async ADD_DS(context, dsId) {
    const metadata = await dataClient.getMetadata(dsId);
    const dataSource = { applicationDataSourceId: dsId, metadata };
    context.commit("SAVE_MANIFEST_DS", dataSource);
  }

  @setLoadingState
  async copyDataSource(_context, id) {
    await dataSourceClient.copyDataSource(id);
  }

  /**
   * Запрос и установка манифеста приложения
   *
   * @param {*} context
   * @param {String} manifestId ID приложения
   */
  @setLoadingState
  async INIT_MANIFEST(context, manifestId) {
    const response = await applicationClient.getAplication(manifestId);
    const data = response.data;

    let metadataError = null;

    /**
         * Для ускорения загрузки все запросы информации об источниках данных
         * все запросы подготавливаются в массив promises и одновременно отправляются на сервер
         */

    for (const dataSource of data.manifest.dataSources) {
      try {
        dataSource.metadata = await dataClient.getMetadata(dataSource.applicationDataSourceId);
      }
      catch (error) {
        metadataError = error;
      }
    }

    context.commit("SET_MANIFEST", data);
    context.commit("setEtag", response.headers.etag);

    if (metadataError) {
      throw metadataError;
    }
  }

  /**
   * Обновление пунктов бокового меню в конструкторе источников данных
   *
   * @param {*} context
   * @param {String} id ID источника данных
   */
  REFRESH_DATASOURCE_NAV_ITEMS(context, id) {
    let menuItems = [];

    menuItems.push({
      href: "/dataSources",
      title: "Источники данных",
      icon: "database",
      isOpened: true,
      items: _.map(context.state.dataSourcesInfo,
        ds => {
          return {
            href: `/dataSources/${ds.id}`,
            title: ds.name,
            icon: "genderless"
          };
        })
    });

    let applications = _.filter(context.rootState.applications, application => {
      return _.find(application.manifest.dataSources, ["applicationDataSourceId", id]);
    });

    if (!_.isEmpty(applications)) {
      menuItems.push({
        href: "/constructor/",
        title: "Приложения",
        icon: "file",
        isOpened: true,
        items: _.map(applications,
          app => {
            return {
              href: `/constructor/app/${app.id}`,
              title: app.manifest.info.name,
              icon: "genderless"
            };
          })
      });
    }

    context.dispatch(
      "SET_NAV_ITEMS", { main: menuItems }, { root: true }
    );
  }

  refreshMenuItems(context) {
    let menuItems = context.state.appManifest.manifest.menuItems;
    let appPages = _.filter(context.state.appManifest.manifest.pages, { type: 0 });

    let pages = _.filter(menuItems, item => item.menuPage);
    let includedPages = _.chain(menuItems)
      .filter(item => item.menuFolder)
      .flatMap(item => item.menuFolder.menuItems)
      .value();

    let flatMenuPages = _.map([...pages, ...includedPages], "menuPage");
    let diffAdd = _.differenceBy(appPages, flatMenuPages, "pageId");
    let diffRemove = _.differenceBy(flatMenuPages, appPages, "pageId");

    let remainedMenuItems = _.filter(menuItems, page => page.menuFolder || !_.some(diffRemove, { pageId: page.menuPage.pageId }));

    remainedMenuItems.forEach(item => {
      if (item.menuFolder) {
        item.menuFolder.menuItems = _.filter(item.menuFolder.menuItems, page => !_.some(diffRemove, { pageId: page.menuPage.pageId }));
      }
    });

    let newMenuPages = _.map(diffAdd, item => ({ menuPage: { pageId: item.pageId } }));

    context.commit("SET_MANIFEST_FIELD", { path: "manifest.menuItems", value: [...remainedMenuItems, ...newMenuPages] });
  }

  SET_MANIFEST_FIELD({ commit }, { path, value }) {
    commit("SET_MANIFEST_FIELD", { path, value });
  }

  /**
   * Обновление пунктов бокового меню в конструкторе манифеста
   */
  REFRESH_NAV_ITEMS(context) {
    let appManifest = context.state.appManifest;
    let menuItems = [{
      href: `/constructor/app/${appManifest.id}`,
      title: "Главная",
      icon: "home"
    }];

    menuItems.push({
      href: `/constructor/app/${appManifest.id}/menu`,
      title: "Меню",
      icon: "folders"
    });

    menuItems.push({
      href: `/constructor/app/${appManifest.id}/pages`,
      title: "Страницы",
      icon: "file",
      isOpened: true,
      items: _.chain(appManifest.manifest.pages)
        .filter(page => page.type == 0)
        .map(page => {
          return {
            href: `/constructor/app/${appManifest.id}/page/${page.pageId}`,
            title: page.name,
            icon: page.iconName
          };
        })
        .value()
    });

    menuItems.push({
      href: "/constructor/actions",
      title: "Действия",
      icon: "chart-network",
      items: _.map(appManifest.manifest.actions,
        action => {
          return {
            href: `/constructor/app/${appManifest.id}/action/${action.actionId}`,
            title: action.name,
            icon: "shapes"
          };
        })
    });

    menuItems.push({
      href: "/dataSources",
      title: "Источники данных",
      icon: "database",
      items: _.map(appManifest.manifest.dataSources,
        ds => {
          return {
            href: `/dataSources/${ds.applicationDataSourceId}`,
            title: _.get(
              _.find(context.state.dataSources, ["id", ds.applicationDataSourceId]),
              "name",
              "Неизвестно"
            ),
            icon: "genderless"
          };
        })
    });

    context.dispatch("SET_NAV_ITEMS", { main: menuItems }, { root: true });
  }

  /**
   * Сохранение нового приложения
   *
   * @param {Object} newManifestData манифест приложения
   */
  @setLoadingState
  async SAVE_NEW_MANIFEST({ commit }, newManifestData) {
    let manifestForSave = _.merge(manifestTemplate(), newManifestData);
    let savedManifest = await applicationClient.saveApplication(manifestForSave);

    commit("SET_SAVED_MANIFEST", savedManifest);
  }

  /**
   * Создание копии приложения
   *
   * @param {String} appId ID приложения
   */
  @setLoadingState
  async copyApplication(_context, appId) {
    await applicationClient.copyApplication(appId);
  }

  /**
   * Удаление приложения
   *
   * @param {String} appId ID приложения
   */
  @setLoadingState
  async DELETE_MANIFEST(_context, appId) {
    await applicationClient.deleteApplication(appId);
  }

  /**
   * Сохранение приложения
   *
   * @param {*} _context
   * @param {Object} data
   * {
   *  manifestId: "app__dds", // ID приложения
   *  manifestData: {...} // манифест приложения
   * }
   */
  @setLoadingState
  async UPDATE_MANIFEST({ getters, dispatch }, data) {
    await applicationClient.updateApplication(data.manifestId, data.manifestData, getters.etag);
    await dispatch("INIT_MANIFEST", data.manifestId);
  }

  @setLoadingState
  async GET_APPLICATIONS_INFO({ commit }) {
    const data = await applicationClient.getAllApplicationsInfo();
    commit("SET_APPLICATIONS_INFO", data);
  }

  @setLoadingState
  async importApplication(_context, { applicaiton, replace }) {
    await applicationClient.importApplication(applicaiton, replace);
  }

  async exportApplication(_context, applicaiton) {
    let file = await applicationClient.exportApplication(applicaiton.id);
    Download(file, applicaiton.manifest.info.name + ".zip");
  }

  @setLoadingState
  async getApplicationImportInfo({ commit }, applicaiton) {
    let manifest = await applicationClient.getApplicationImportInfo(applicaiton);
    commit("setImportManifest", manifest);
  }

  async pasteParameters({ getters }, { pageId, pageItemId }) {
    let settings = await navigator.clipboard.readText();
    let parsedSettings = JSON.parse(settings);
    let pageItem = getters.PAGE_ITEM(pageId, pageItemId);
    pageItem.pageItemSettings = parsedSettings;
  }
  async copyParameters({ getters }, { pageId, pageItemId }) {
    let pageItem = getters.PAGE_ITEM(pageId, pageItemId);
    let settings = JSON.stringify(pageItem.pageItemSettings);
    await navigator.clipboard.writeText(settings);
  }
}

export default (new Actions).asPlainObject();