import _ from "lodash";
import vClickOutside from "v-click-outside";
import vScrollTo from "vue-scrollto";
import Vue from "vue";

import eventBus from "./bus";
import Plato from "./components/package/components";
import router from "./routes";
import Shell from "./Shell.vue";
import store from "./store";

import "../public/scss/main.scss";
import "./filters";

Vue.prototype._ = _;
Vue.use(vClickOutside);
Vue.use(vScrollTo);

/**
 * Добавление пакета компонентов Plato
 */
Vue.use(Plato);

Vue.config.productionTip = false;
Vue.prototype.$eventBus = eventBus;

Vue.prototype.$showError = error => {
  Vue.prototype.notification.danger({
    title: error.message,
    message: error.details || ""
  });

  throw error;
};

Vue.config.errorHandler = (err, _vm, _info) => Vue.prototype.$showError(err);

export default new Vue({
  store,
  router,
  render: h => h(Shell)
}).$mount("#app");
