import StateControllerBase from "@/store/StateControllerBase";
import { AdminClient, ApplicationClient, ConfigurationClient, DataSourceClient, ExploreClient, MapClient, ShellClient } from "@/PlatoAPI";
import { setLoadingState } from "@/store/decorators/setLoadingState";

const adminClient = new AdminClient();
const dataSourceClient = new DataSourceClient();
const applicationClient = new ApplicationClient();
const shellClient = new ShellClient();
const configurationClient = new ConfigurationClient();
const mapClient = new MapClient();
const exploreClient = new ExploreClient();

class Actions extends StateControllerBase {
  async SET_DATA_LOADING_ERROR(context, value) {
    context.commit("SET_DATA_LOADING_ERROR", value);
  }

  async SET_DATA_LOADING(context, value) {
    context.commit("SET_DATA_LOADING", value);
  }

  resetState(context) {
    context.commit("resetState");
  }

  /**
   * Обновление пунктов бокового меню в администрирорвании
   */
  setAdminNavItems(context) {
    let menuItems = [
      {
        href: "/admin/security",
        title: "Безопасность",
        icon: "shield-alt",
        scopes: ["adminUsers", "adminServices", "adminRoles", "adminPermissions"],
        isOpened: true
      },
      {
        href: "/admin/pages",
        title: "Страницы",
        scopes: ["adminPages"],
        icon: "pager"
      },
      {
        href: "/admin/shell",
        title: "Настройки платформы",
        scopes: ["adminShell"],
        icon: "browser"
      },
      {
        href: "/admin/configurations",
        title: "Конфигурационные параметры служб",
        scopes: ["adminConfiguration"],
        icon: "cogs"
      },
      {
        href: "/admin/map",
        title: "Единая картографическая основа",
        scopes: ["adminMap"],
        icon: "map"
      },
      {
        href: "/admin/database",
        title: "Импорт и экпорт базы данных",
        scopes: ["adminDatabase"],
        icon: "database"
      }
    ];
    context.dispatch("SET_NAV_ITEMS", { main: menuItems }, { root: true });
  }

  @setLoadingState
  async getMapParameters({ commit }) {
    let mapParameters = await mapClient.getBaseLayers();
    commit("setMapParameters", mapParameters);
  }

  @setLoadingState
  async saveMapParameters({ dispatch }, mapParameters) {
    await mapClient.saveMapParameters(mapParameters);
    await dispatch("getMapParameters");
  }

  @setLoadingState
  async getBaseLayers({ commit }) {
    let baseLayers = await mapClient.getBaseLayers();
    commit("setBaseLayers", baseLayers);
  }

  @setLoadingState
  async saveBaseLayer({ dispatch }, baseLayer) {
    await mapClient.saveBaseLayer(baseLayer);
    await dispatch("getBaseLayers");
  }

  @setLoadingState
  async deleteBaseLayer({ dispatch }, id) {
    await mapClient.deleteBaseLayer(id);
    await dispatch("getBaseLayers");
  }

  @setLoadingState
  async getMapProjections({ commit }) {
    let projections = await shellClient.getMapProjections();
    commit("setMapProjections", projections);
  }

  @setLoadingState
  async saveMapProjection({ dispatch }, mapProjection) {
    await shellClient.saveMapProjection(mapProjection);
    await dispatch("getMapProjections");
  }

  @setLoadingState
  async deleteMapProjection({ dispatch }, id) {
    await shellClient.deleteMapProjection(id);
    await dispatch("getMapProjections");
  }

  @setLoadingState
  async getServiceConfigurations({ commit }) {
    let configurations = await configurationClient.getServiceConfigurations();
    commit("setServiceConfigurations", configurations);
  }

  @setLoadingState
  async saveServiceConfiguration({ dispatch }, configuration) {
    await configurationClient.saveServiceConfiguration(configuration);
    await dispatch("getServiceConfigurations");
  }

  @setLoadingState
  async deleteServiceConfiguration({ dispatch }, key) {
    await configurationClient.deleteServiceConfiguration(key);
    await dispatch("getServiceConfigurations");
  }

  @setLoadingState
  async resetServiceConfigurations({ dispatch }) {
    await configurationClient.resetServiceConfigurations();
    await dispatch("getServiceConfigurations");
  }

  @setLoadingState
  async getUsers({ commit }) {
    let body = {
      searchKeyword: "",
      take: 10000,
      sorting: []
    };

    let users = await adminClient.getUsers(body);
    commit("setUsers", users);
  }

  async getUsersForTable({ commit }, { searchKeyword, take, sorting }) {
    let body = {
      searchKeyword,
      take,
      sorting
    };

    let users = await adminClient.getUsers(body);
    commit("setUsersForTable", users);
  }

  @setLoadingState
  async saveUser({ dispatch }, user) {
    await adminClient.saveUser(user);
    await dispatch("getUsers");
  }

  @setLoadingState
  async deleteUser({ dispatch }, userId) {
    await adminClient.deleteUser(userId);
    await dispatch("getUsers");
  }

  @setLoadingState
  async getServices({ commit }) {
    let body = {
      searchKeyword: "",
      take: 10000,
      sorting: []
    };

    let services = await adminClient.getServices(body);
    commit("setServices", services);
  }

  async getServicesForTable({ commit }, { searchKeyword, take, sorting }) {
    let body = {
      searchKeyword,
      take,
      sorting
    };

    let services = await adminClient.getServices(body);
    commit("setServicesForTable", services);
  }

  @setLoadingState
  async saveService({ dispatch }, service) {
    await adminClient.saveService(service);
    await dispatch("getServices");
  }

  @setLoadingState
  async deleteService({ dispatch }, serviceId) {
    await adminClient.deleteService(serviceId);
    await dispatch("getServices");
  }

  @setLoadingState
  async getRoles({ commit }) {
    let body = {
      searchKeyword: "",
      take: 10000,
      sorting: []
    };

    let roles = await adminClient.getRoles(body);
    commit("setRoles", roles);
  }

  async getRolesForTable({ commit }, { searchKeyword, take, sorting }) {
    let body = {
      searchKeyword,
      take,
      sorting
    };

    let roles = await adminClient.getRoles(body);
    commit("setRolesForTable", roles);
  }

  @setLoadingState
  async saveRole({ dispatch }, role) {
    let id = await adminClient.saveRole(role);
    await dispatch("getRoles");
    return id;
  }

  @setLoadingState
  async deleteRole({ dispatch }, roleId) {
    await adminClient.deleteRole(roleId);
    await dispatch("getRoles");
  }

  @setLoadingState
  async getPermissions({ commit }) {
    let permissions = await adminClient.getPermissions();
    commit("setPermissions", permissions);
  }

  @setLoadingState
  async getAccesses({ commit }) {
    let accesses = await adminClient.getAccesses();
    commit("setAccesses", accesses);
  }

  @setLoadingState
  async savePermission({ dispatch }, permission) {
    await adminClient.savePermission(permission);
    await dispatch("getPermissions");
  }

  @setLoadingState
  async deletePermission({ dispatch }, permissionId) {
    await adminClient.deletePermission(permissionId);
    await dispatch("getPermissions");
  }

  @setLoadingState
  async saveAccess({ dispatch }, access) {
    await adminClient.saveAccess(access);
    await dispatch("getAccesses");
  }

  @setLoadingState
  async deleteAccess({ dispatch }, accessId) {
    await adminClient.deleteAccess(accessId);
    await dispatch("getAccesses");
  }

  @setLoadingState
  async getScopes({ commit }) {
    let scopes = await adminClient.getScopes();
    commit("setScopes", scopes);
  }

  @setLoadingState
  async getShellManifest({ commit }) {
    let manifest = await shellClient.getShellManifestInfo();
    commit("setShellManifest", manifest);
  }

  @setLoadingState
  async saveShellManifest({ dispatch }, manifest) {
    await shellClient.saveShellManifestInfo(manifest);
    await dispatch("getShellManifest");
  }

  @setLoadingState
  async getShellPages({ commit }) {
    let pages = await shellClient.getShellPages();
    commit("setShellPages", pages);
  }

  @setLoadingState
  async getShellPagesInfo({ commit }) {
    let pages = await shellClient.getShellPagesInfo();
    commit("setShellPagesInfo", pages);
  }

  @setLoadingState
  async saveShellPage({ dispatch }, page) {
    await shellClient.saveShellPage(page);
    await dispatch("getShellPages");
  }

  @setLoadingState
  async deleteShellPage({ dispatch }, pageId) {
    await shellClient.deleteShellPage(pageId);
    await dispatch("getShellPages");
  }

  @setLoadingState
  async getApplications({ commit }) {
    let applications = await applicationClient.getAllApplicationsInfo();
    commit("setApplications", applications);
  }

  @setLoadingState
  async getDataSources({ commit }) {
    let dataSources = await dataSourceClient.getAllDataSourcesInfo();
    commit("setDataSources", dataSources);
  }

  @setLoadingState
  async getClasses({ commit }) {
    let classes = await exploreClient.getAllClasses();
    commit("setClasses", classes);
  }

  @setLoadingState
  async getInstances({ commit }, classId) {
    let instances = await exploreClient.getInstances(classId, 0, 1000);
    commit("setInstances", instances);
  }
}

export default (new Actions).asPlainObject();