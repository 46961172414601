import DataViewSelector from "./FilterLine/DataViewComponents/DataViewSelector.vue";
import FieldSelector from "@/components/constructor/manifest/pageItem/pageItemSettings/components/FieldSelector.vue";
import GroupFieldSelector from "@/components/constructor/manifest/pageItem/pageItemSettings/components/GroupFieldSelector.vue";
import PlatoApp from "./App/PlatoApp.vue";
import PlatoAppCard from "./AppCard/PlatoAppCard.vue";
import PlatoCard from "./Card/PlatoCard.vue";
import PlatoContextMenu from "./ContextMenu/PlatoContextMenu.vue";
import PlatoEdit from "./InlineEdit/PlatoEdit.vue";
import PlatoFilterComplex from "./FilterLine/PlatoFilterComplex.vue";
import PlatoFilterLine from "./FilterLine/PlatoFilterLine.vue";
import PlatoFilterPanel from "./FilterLine/PlatoFilterPanel.vue";
import PlatoInlineEdit from "./InlineEdit/PlatoInlineEdit.vue";
import PlatoInlineEditLinked from "./InlineEdit/PlatoInlineEditLinked.vue";
import PlatoList from "./List/PlatoList.vue";
import PlatoMarkSearch from "./MarkSearch/PlatoMarkSearch.vue";
import PlatoNavMenu from "./NavMenu/PlatoNavMenu.vue";
import PlatoPopper from "./Popper/PlatoPopper.vue";
import PlatoTag from "./Tags/PlatoTag.vue";
import PlatoTags from "./Tags/PlatoTags.vue";
import PlatoTooltip from "./Tooltip/PlatoTooltip.vue";
import Table from "./Table/Table.vue";

const PlatoLoader = () => import("./Loader/PlatoLoader");

import Notification from "./Atlas/Notification/Notification.vue";
import NotificationMethod from "./Atlas/Notification/NotificationMethod.js";

import PlatoAside from "./Atlas/Aside/PlatoAside.vue";
import PlatoAsideHeader from "./Atlas/Aside/PlatoAsideHeader.vue";

import Card from "./Atlas/Card/Card.vue";
import CardHeader from "./Atlas/Card/CardHeader.vue";

import Breadcrumb from "./Atlas/Breadcrumb/Breadcrumb.vue";
import BreadcrumbItem from "./Atlas/Breadcrumb/BreadcrumbItem.vue";

import PageHeader from "./Atlas/PageHeader/PageHeader.vue";

import Icon from "@/components/package/Icons/Icon.vue";
import ImageIcon from "@/components/package/Icons/ImageIcon.vue";
import PlatoButton from "./Buttons/PlatoButton.vue";
import PlatoButtonGroup from "./Buttons/PlatoButtonGroup.vue";
import PlatoCheckbox from "@/components/package/Checkboxes/PlatoCheckbox.vue";
import PlatoChip from "./Buttons/PlatoChip.vue";
import PlatoChipGroup from "./Buttons/PlatoChipGroup.vue";
import PlatoInput from "./Input/PlatoInput.vue";
import PlatoSearch from "./Input/PlatoSearch.vue";
import PlatoSlideColorPicker from "./ColorPicker/PlatoSlideColorPicker.vue";

import PlatoModal from "./Modal/PlatoModal.vue";
import PlatoModalMethod from "./Modal/PlatoModalMethod.js";

const Components = {
  PlatoApp,
  PlatoAppCard,
  PlatoAside,
  PlatoAsideHeader,
  PlatoCard,
  PlatoContextMenu,
  DataViewSelector,
  PlatoFilterLine,
  PlatoFilterPanel,
  PlatoFilterComplex,
  PlatoInlineEdit,
  PlatoEdit,
  PlatoInlineEditLinked,
  PlatoList,
  Table,
  PlatoMarkSearch,
  PlatoNavMenu,
  PlatoPopper,
  PlatoTooltip,
  PlatoTags,
  PlatoTag,
  PlatoLoader,
  FieldSelector,
  GroupFieldSelector,

  Notification,
  Card,
  CardHeader,
  Breadcrumb,
  BreadcrumbItem,
  PageHeader,

  PlatoChip,
  PlatoChipGroup,
  PlatoButton,
  PlatoInput,
  PlatoSearch,
  PlatoButtonGroup,
  PlatoSlideColorPicker,
  Icon,
  ImageIcon,
  PlatoCheckbox,

  PlatoModal
};

const install = function(Vue, locale) {
  for (const i in Components) {
    Vue.component(i, Components[i]);
  }

  Vue.prototype.Modal = PlatoModalMethod;
  Vue.prototype.notification = NotificationMethod;
  Vue.prototype.Locale = locale || "ru";
  window.Locale = locale || "ru";
};

if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
}

Components.install = install;
export default Components;