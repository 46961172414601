import StateControllerBase from "@/store/StateControllerBase";
import { AuthenticationClient } from "@/PlatoAPI";
import { setLoadingState } from "@/store/decorators/setLoadingState";

const authenticationClient = new AuthenticationClient();

class Actions extends StateControllerBase {
  setInnerErrorCode({ commit }, value) {
    commit("setInnerErrorCode", value);
  }

  setIsMenuOpened({ commit }, value) {
    commit("setIsMenuOpened", value);
  }

  async SET_DATA_LOADING_ERROR(context, value) {
    context.commit("SET_DATA_LOADING_ERROR", value);
  }

  async SET_DATA_LOADING(context, value) {
    context.commit("SET_DATA_LOADING", value);
  }

  SET_CURRENT_USER(context, currentUser) {
    context.commit("SET_CURRENT_USER", currentUser);
  }

  resetState(context) {
    context.commit("resetState");
  }

  resetApp(context) {
    context.dispatch("resetState");
    context.dispatch("adminModule/resetState");
    context.dispatch("constructorModule/resetState");
    context.dispatch("navigator/resetState");
    context.dispatch("resetShellState");
    context.dispatch("resetAppState");
    context.dispatch("resetPageState");
  }

  @setLoadingState
  async getCurrentUser(context) {
    let user = await authenticationClient.getCurrentUser();
    context.commit("SET_CURRENT_USER", user);
  }

  async logout(context) {
    await authenticationClient.logout();
    context.dispatch("resetApp");
  }

  async login(_context, { username, password }) {
    await authenticationClient.login({ username, password });
  }

  async loginGuest(_context) {
    await authenticationClient.loginGuest();
  }
}

export default (new Actions).asPlainObject();