import _ from "lodash";

class StateControllerBase {
  asPlainObject() {
    let methods = Object.getOwnPropertyNames(Object.getPrototypeOf(this));

    return _.chain(methods)
      .without("constructor")
      .reduce((r, v) => _.set(r, v, this[v].bind(this)), {})
      .value();
  }
}

export default StateControllerBase;