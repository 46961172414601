import _ from "lodash";
import actions from "./index/pageActions";

const SORT_TYPES = {
  None: "none",
  Unset: "unset",
  Asc: "asc",
  Desc: "desc"
};

const getDefaultState = () => {
  return {
    pageId: null,
    navigationObject: null,
    exploreInstance: null,
    parentExploreInstance: null,
    parentCardExploreInstance: null,
    dataInstance: null,
    linkedProps: [],
    linkedPropsForTable: {},
    linkedPropertyFilterOptions: {},
    lastNewObjectInstanceId: null,
    dataSources: [],
    data: [],
    sectorId: null,
    startPageId: null,
    blockedDataSources: [],
    linkedPropertyAlias: null,
    linkedObjectId: null,
    linkedDataSourceId: null,
    pageLoading: false,
    ownCardInstance: null
  };
};

export default {
  state: getDefaultState,
  getters: {
    pageLoading: state => {
      return state.pageLoading;
    },

    blockedDataSources: state => {
      return state.blockedDataSources;
    },

    dataSources: state => {
      return state.dataSources;
    },

    linkedPropertyAlias: state => {
      return state.linkedPropertyAlias;
    },

    linkedObjectId: state => {
      return state.linkedObjectId;
    },

    linkedDataSourceId: state => {
      return state.linkedDataSourceId;
    },

    startPageId: state => {
      return state.startPageId;
    },

    sectorId: state => {
      return state.sectorId;
    },

    pageId: state => {
      return state.pageId;
    },

    ownCardInstance: state => {
      return state.ownCardInstance;
    },

    lastNewObjectInstanceId: state => {
      return state.lastNewObjectInstanceId;
    },

    LINKED_PROPS: state => {
      return state.linkedProps;
    },

    LINKED_PROPS_FOR_TABLE: state => alias => {
      return _.get(state.linkedPropsForTable, alias);
    },

    LINKED_PROPERTY_FILTER_OPTIONS: state => alias => {
      return state.linkedPropertyFilterOptions[alias] ?? [];
    },

    DATA_INSTANCE: state => {
      return state.dataInstance;
    },

    EXPLORE_INSTANCE: state => {
      return state.exploreInstance;
    },

    parentExploreInstance: state => {
      return state.parentExploreInstance;
    },

    parentCardExploreInstance: state => {
      return state.parentCardExploreInstance;
    },

    METADATA: state => dataSourceId => {
      return _.find(state.data, { applicationDataSourceId: dataSourceId }).metadata;
    },

    DATA: state => dataSourceId => {
      return _.find(state.data, { applicationDataSourceId: dataSourceId });
    },

    OBJECTS: state => dataSourceId => {
      let dataSource = _.find(state.data, { applicationDataSourceId: dataSourceId });

      if (dataSource && dataSource.serverData) {
        return dataSource.serverData.items;
      }

      return null;
    },

    changedFields: state => dataSourceId => {
      let dataSource = _.find(state.data, { applicationDataSourceId: dataSourceId });

      if (dataSource) {
        return dataSource.changedFields;
      }
    },

    currentObjectWithDataSource: state => {
      let data = _.find(state.data, data => !_.isEmpty(data.currentObject));

      if (data) {
        return {
          object: data.currentObject,
          dataSourceId: data.applicationDataSourceId
        };
      }
    },

    selectedObjectsIdGroups: state => {
      return _.reduce(state.data, (result, data) => {
        if (!_.isEmpty(data.selectedObjectsId)) {
          result[data.applicationDataSourceId] = data.selectedObjectsId;
        }
        return result;
      }, {});
    },

    selectedObjectsId: state => dataSourceId => {
      return _.chain(state.data).find({ applicationDataSourceId: dataSourceId }).get("selectedObjectsId").value();
    },

    CURRENT_OBJECT: state => dataSourceId => {
      return _.chain(state.data).find({ applicationDataSourceId: dataSourceId }).get("currentObject").value();
    },

    FILTERS: state => dataSourceId => {
      return _.chain(state.data).find({ applicationDataSourceId: dataSourceId }).get("filters").value();
    },

    ACTIVE_FILTERS: state => dataSourceId => {
      return _.chain(state.data).find({ applicationDataSourceId: dataSourceId }).get("filters").filter("active").value();
    },

    avalaibleFilters: (state, getters) => dataSourceId => {
      let metadata = getters.METADATA(dataSourceId);

      if (_.isEmpty(metadata)) {
        return [];
      }

      let ownWithFilters = _.reject(metadata.ownProperties, prop => _.isEmpty(prop.filters));
      let linkedWithFilters = _.filter(metadata.linkedProperties, prop => prop.isFilterable);

      return [
        ..._.map(ownWithFilters, own => ({
          alias: own.alias,
          name: own.name,
          type: own.filters[0].type
        })),
        ..._.map(linkedWithFilters, linked => ({
          alias: linked.alias,
          name: linked.name,
          type: "SelectionDynamic"
        }))
      ];
    },

    SEARCH_KEYWORD: state => dataSourceId => {
      return _.chain(state.data).find({ applicationDataSourceId: dataSourceId }).get("searchKeyword").value();
    },

    NEW_OBJECT: state => dataSourceId => {
      return _.chain(state.data).find({ applicationDataSourceId: dataSourceId }).get("newObject").value();
    },

    TOTAL_OBJECT_COUNT: state => dataSourceId => {
      let serverData = _.find(state.data, { applicationDataSourceId: dataSourceId }).serverData;
      return _.get(serverData, "totalCount", 0);
    },

    linkedPropertiesSortingData: state => dataSourceId => {
      let dataSource = _.find(state.data, { applicationDataSourceId: dataSourceId });

      if (_.isEmpty(dataSource?.metadata)) {
        return [];
      }

      //Из всех linkedProperties достаются все ownProperties у которых есть сортировки
      let allLinkedPropSorting = _.flatMap(dataSource.metadata.linkedProperties, linked =>
        _.map(linked.properties, prop => {
          if (prop.sortType != SORT_TYPES.None) {
            return {
              ..._.pick(prop, ["name", "alias", "sortType"]),
              parentAlias: linked.alias,
              parentName: linked.name,
              type: "linked"
            };
          }
        }));
      // фильтрация undefined-свойств у которых не была установлена сортировка
      return _.filter(allLinkedPropSorting);
    },

    ownPropertiesSortingData: state => dataSourceId => {
      let dataSource = _.find(state.data, { applicationDataSourceId: dataSourceId });

      if (_.isEmpty(dataSource?.metadata)) {
        return [];
      }

      return _.chain(dataSource.metadata?.ownProperties)
        .filter(prop => prop.sortType != SORT_TYPES.None)
        .map(prop => ({
          ..._.pick(prop, ["name", "alias", "sortType"]),
          type: "own",
          parentName: "",
          parentAlias: ""
        }))
        .value();
    },

    datasourceAppliedSorting: state => dataSourceId => {
      return _.find(state.data, { applicationDataSourceId: dataSourceId })?.appliedSorting;
    },

    annotationPropertyValue: state => (dataSourceId, alias, type) => {
      const metadata = _.find(state.data, { applicationDataSourceId: dataSourceId }).metadata;
      const property = _.find([...metadata.ownProperties, ...metadata.linkedProperties], { alias });
      if (!property) {
        return null;
      }

      const value = _.chain(property.annotationProperties).find(x => _.toLower(x.type) === _.toLower(type)).get("value").value();
      const parsed = _.attempt(JSON.parse, value);

      return _.isError(parsed) ? value : parsed;
    },

    NAVIGATION_OBJECT: state => {
      return state.navigationObject;
    },

    findedObjectById: (state, getters) => objectId => {
      let findedObject = null;
      _.each(getters.dataSources, dataSource => {
        let dataSourceObjects = getters.OBJECTS(dataSource.applicationDataSourceId);
        let objectFromDataSource = _.find(dataSourceObjects, object => object.id === objectId);
        if (objectFromDataSource != undefined) {
          findedObject = {
            id: objectFromDataSource.id,
            dataSourceId: dataSource.applicationDataSourceId,
            classId: objectFromDataSource.classId
          };
          return;
        }
      });
      return findedObject;
    },

    getNavigationObjectName: () => instance => {
      if (instance.dataProperties.length > 0) {
        let nameProp = _.find(instance.dataProperties, { id: "95b1ff3e-497c-462f-b260-c1b7166021b5" });
        if (nameProp) {
          return nameProp.value;
        }
      }
      return instance.iri;
    }
  },
  mutations: {
    setPageLoading: (state, value) => {
      state.pageLoading = value;
    },

    setInnerErrorCode: (state, value) => {
      state.innerErrorCode = value;
    },

    setLinkedPropertyAlias: (state, value) => {
      state.linkedPropertyAlias = value;
    },

    setLinkedObjectId: (state, value) => {
      state.linkedObjectId = value;
    },

    setLinkedDataSourceId: (state, value) => {
      state.linkedDataSourceId = value;
    },

    setBlockedDataSources: (state, value) => {
      state.blockedDataSources = value;
    },

    setStartPageId: (state, startPageId) => {
      state.startPageId = startPageId;
    },

    setSectorId: (state, sectorId) => {
      state.sectorId = sectorId;
    },

    setPageId: (state, pageId) => {
      state.pageId = pageId;
    },

    resetPageState: state => {
      Object.assign(state, getDefaultState());
    },

    setSelectedObjectsId: (state, data) => {
      _.find(state.data, { applicationDataSourceId: data.dataSourceId }).selectedObjectsId = data.selectedObjectsId;
    },

    removeSelectedObjectsId: (state, data) => {
      _.find(state.data, { applicationDataSourceId: data.dataSourceId }).selectedObjectsId = [];
    },

    setLastNewObjectInstanceId: (state, value) => {
      state.lastNewObjectInstanceId = value;
    },

    SET_METADATA: (state, data) => {
      _.find(state.data, { applicationDataSourceId: data.dataSourceId }).metadata = data.payload;
    },

    ADD_DATASOURCE: (state, dataSource) => {
      state.dataSources.push(dataSource);
      state.data.push({
        applicationDataSourceId: dataSource.applicationDataSourceId,
        serverData: null,
        metadata: null,
        currentObject: null,
        selectedObjectsId: [],
        newObject: null,
        changedFields: null,
        appliedSorting: [],
        actions: [],
        filters: [],
        searchKeyword: null,
        skip: 0,
        take: 10000
      });
    },

    SET_LINKED_PROPS: (state, value) => {
      state.linkedProps = value;
    },

    SET_LINKED_PROPS_FOR_TABLE: (state, { data, alias }) => {
      state.linkedPropsForTable[alias] = data;
    },

    SET_LINKED_PROPERTY_FILTER_OPTIONS: (state, { alias, options }) => {
      state.linkedPropertyFilterOptions = {
        ...state.linkedPropertyFilterOptions,
        [alias]: options
      };
    },

    SET_EXPLORE_INSTANCE: (state, value) => {
      state.exploreInstance = value;
    },

    setParentExploreInstance: (state, value) => {
      state.parentExploreInstance = value;
    },

    setOwnInstance: (state, value) => {
      state.ownCardInstance = value;
    },

    setParentCardExploreInstance: (state, value) => {
      state.parentCardExploreInstance = value;
    },

    SET_DATA_INSTANCE: (state, value) => {
      state.dataInstance = value;
    },

    SET_OBJECTS: (state, data) => {
      let stateData = _.find(state.data, { applicationDataSourceId: data.dataSourceId });
      if (stateData) {
        _.forEach(data.payload.items, (value, index) => value.index = index);
        stateData.serverData = data.payload;
      }
    },

    SET_CURRENT_OBJECT: (state, data) => {
      _.find(state.data, { applicationDataSourceId: data.dataSourceId }).currentObject = data.payload;
    },

    addChangedFields: (state, data) => {
      let dataSource = _.find(state.data, { applicationDataSourceId: data.dataSourceId });

      let field = null;

      if (data.type === "linked") {
        field = {
          alias: data.alias,
          instanceId: data.instanceId,
          added: data.value.added,
          removed: data.value.removed
        };
      }
      else {
        field = {
          alias: data.alias,
          value: data.value,
          instanceId: data.instanceId
        };
      }

      if (!dataSource.changedFields) {
        dataSource.changedFields = { [data.alias]: field };
      }
      else {
        _.set(dataSource.changedFields, data.alias, field);
      }
    },

    clearChangedFields: (state, dataSourceId) => {
      _.find(state.data, dataSource => dataSource.applicationDataSourceId === dataSourceId).changedFields = null;
    },

    addDatasourceSorting: (state, data) => {
      let dataSource = _.find(state.data, { applicationDataSourceId: data.dataSourceId });
      dataSource.appliedSorting.push(data.sortingField);
    },

    removeDatasourceSorting: (state, data) => {
      let dataSource = _.find(state.data, { applicationDataSourceId: data.dataSourceId });
      let sortIndex = _.findIndex(dataSource.appliedSorting, { alias: data.sortingField.alias });
      dataSource.appliedSorting.splice(sortIndex, 1);
    },

    setSortingType: (state, data) => {
      let dataSource = _.find(state.data, { applicationDataSourceId: data.dataSourceId });
      let dataSourceSorting = _.find(dataSource.appliedSorting, { alias: data.alias });

      dataSourceSorting.sortType = data.sortType;
    },

    clearAppliedDataSourceSorting: (state, data) => {
      let dataSource = _.find(state.data, { applicationDataSourceId: data.dataSourceId });
      if (dataSource && dataSource.appliedSorting) {
        dataSource.appliedSorting = [];
      }
    },

    ADD_NEW_OBJECT: (state, dataSourceId) => {
      let dataSource = _.find(state.data, dataSource => dataSource.applicationDataSourceId === dataSourceId);
      let ownProperties = _.reduce(dataSource.metadata.ownProperties, (result, prop) => {
        result.push({
          alias: prop.alias,
          value: prop.tableType == "Numeric" ? null : ""
        });
        return result;
      }, []);
      let linkedProperties = _.reduce(dataSource.metadata.linkedProperties, (result, prop) => {
        result.push({
          alias: prop.alias,
          value: ""
        });
        return result;
      }, []);
      dataSource.newObject = { ownProperties: ownProperties, linkedProperties: linkedProperties };
    },

    CHANGE_NEW_OBJECT_FIELD: (state, field) => {
      let dataSource = _.find(state.data, { applicationDataSourceId: field.dataSourceId });

      if (field.type == "linked") {
        let property = _.find(dataSource.newObject.linkedProperties, { alias: field.alias });
        let values = _.join(field.value.added, ",");
        _.set(property, "value", values);
        // TODO: Свойство для отображения
        ///значения linkedProperies, необходимые для отображения объектов в связи при создании нового объекта
        ///никак не задействовано при редактировании уже существующего объекта
        if (field.value.objects) {
          _.set(property, "objects", field.value.objects);
        }
      }
      else {
        let propLink = _.find(dataSource.newObject.ownProperties, { alias: field.alias });
        _.set(propLink, "value", field.value);
      }
    },

    CLEAR_DATA: (state, dataSourceId) => {
      let stateData = _.find(state.data, { applicationDataSourceId: dataSourceId });
      if (stateData) {
        stateData.serverData = null;
      }
    },

    SET_DATASOURCE_FILTER: (state, dataSourceFilters) => {
      let dataSource = _.find(state.data, { applicationDataSourceId: dataSourceFilters.dataSourceId });
      dataSource.filters = dataSourceFilters.filters;
    },

    REMOVE_ALL_DATASOURCE_FILTERS: (state, dataSourceId) => {
      let dataSource = _.find(state.data, { applicationDataSourceId: dataSourceId });
      _.set(dataSource, "filters", []);
    },

    SET_SEARCH_KEYWORD: (state, searchInfo) => {
      const dataSource = _.find(state.data, { applicationDataSourceId: searchInfo.dataSourceId });
      _.set(dataSource, "searchKeyword", searchInfo?.searchKeyword || null);
    },

    REMOVE_SEARCH_KEYWORD: (state, data) => {
      const dataSource = _.find(state.data, { applicationDataSourceId: data.dataSourceId });
      _.set(dataSource, "searchKeyword", null);
    },

    SET_NAVIGATION_OBJECT: (state, navigationObject) => {
      state.navigationObject = navigationObject;
    }
  },
  actions
};