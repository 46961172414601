<template>
  <li
    class="plato-menu-item-bottom"
  >
    <RouterLink
      :to="href"
      :active="isActive"
      class="plato-menu-item-bottom__body"
    >
      <div
        :title="title"
        class="plato-menu-item-bottom__body_icon"
      >
        <Icon :icon="iconClass" />
      </div>
      <span class="plato-menu-item-bottom__body_text">{{ title }}</span>
    </RouterLink>
  </li>
</template>

<script>
export default {
  name: "PlatoNavMenuItem",
  props: ["title", "iconClass", "isActive", "href"],
  data() {
    return {
      showSubitems: this.isOpened
    };
  }
};
</script>

<style lang="scss">
@import "../styles/colors.scss";
@import "../styles/variables.scss";

$icon-width: 40px;
$logo-width: 40px;

//Элемент меню
.plato-menu-item-bottom {
  margin: 0;

  a {
    text-decoration: none;
  }

  &__body {
    padding: 8px 15px;
    font-size: 15px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: $main-text-color;
    width: $menu-width - 30px;
    transition: background-color 0.08s linear;

    &[active] {
      color: $bottom-selected-text-color;
      background-color: transparent;
      i {
        color: $bottom-selected-text-color;
      }
    }

    &:hover:not([active=true]){
      color: $bottom-hovered-text-color;
      background-color: transparent;
      i {
        color: $bottom-hovered-text-color;
      }
    }

    &:active:not([active=true]){
      color: $bottom-active-text-color;
      background-color: transparent;
      i {
        color: $bottom-active-text-color;
      }
    }

    &_icon {
      color: $main-text-color;
      font-size: 20px;
      max-width: $collapsed-menu-width - 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 0 $collapsed-menu-width - 30px;

      .fa-bell {
        transform: rotate(45deg);
      }
    }

    &_text {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      padding-left: 19px;
      line-height: 20px;
    }
  }
}
</style>