import axios from "axios";

class CancelTokenSource {
  constructor() {
    const CancelToken = axios.CancelToken;
    this._source = CancelToken.source();
  }

  static isCancel(error) {
    return axios.isCancel(error);
  }

  get token() {
    return this._source.token;
  }

  cancel() {
    this._source.cancel();
  }
}

export default CancelTokenSource;