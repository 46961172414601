import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      changedObjectDataSourceId: "changedObject/changedObjectDataSourceId",
      pageCurrentObject: "currentObject/currentObject",
      changedFields: "card/changedFields",
      loading: "card/dataLoading",
      dataSourceCurrentObject: "card/currentObject"
    }),

    metadata() {
      return this.$store.getters["card/metadata"](this.dataSourceId);
    },

    ownProperties() {
      let ownProperties = _.reduce(
        this.metadata.ownProperties,
        (result, prop) => {
          let propValue = _.find(
            this.currentObject.ownProperties,
            valProp => valProp.alias === prop.alias
          );

          result.push({
            header: prop,
            value: _.get(propValue, "value", null),
            isEmpty: _.isEmpty(prop.value),
            type: "own"
          });

          return result;
        },
        []
      );

      let linkedProperties = [];

      if (!this.isNew) {
        linkedProperties = _.chain(this.metadata.linkedProperties).filter({ isAggregated: true }).map(prop => {
          let propValue = _.find(this.currentObject.linkedProperties, {
            alias: prop.alias
          });

          return {
            header: { ...prop, tableType: "Text" },
            value: _.get(propValue, "values[0].value") || null,
            isEmpty: _.isEmpty(_.get(propValue, "values")),
            readOnly: true,
            type: "own"
          };
        }).value();
      }

      return [...ownProperties, ...linkedProperties];
    },

    currentObject() {
      if (this.isNew) {
        return this.$store.getters["card/newObject"];
      }
      else if (this.dataSourceId) {
        return _.cloneDeep(this.$store.getters["card/editingCurrentObject"]);
      }

      return null;
    }
  },

  watch: {
    currentObject(newCurrentObject) {
      if (newCurrentObject) {
        this.setLinkedProperties();
      }
    },

    dataSourceCurrentObject() {
      this.setCurrentObject();
    },

    async changedObjectDataSourceId({ dataSourceId }) {
      if (dataSourceId && dataSourceId == this.dataSourceId) {
        await this.getFullCurrentObject(this.aliases);
      }
    },

    async pageCurrentObject(obj) {
      if (!this.isNew) {
        if (obj && obj.dataSourceId == this.dataSourceId) {
          await this.getFullCurrentObject(this.aliases);
        }
        else if (!obj) {
          this.removeFullCurrentObject();
        }
      }
    }
  },

  async created() {
    if (!this.isNew) {
      if (this.pageCurrentObject && this.pageCurrentObject.dataSourceId == this.dataSourceId) {
        await this.getFullCurrentObject(this.aliases);
      }
    }
    else {
      await this.$store.dispatch("card/getGeneratedData", this.dataSourceId);
    }

    this.setCurrentObject();
    this.setLinkedProperties();
  },

  methods: {
    ...mapActions({
      getFullCurrentObject: "card/getFullCurrentObject",
      removeFullCurrentObject: "card/removeFullCurrentObject"
    }),

    async saveField(type, prop, value) {
      if (this.isNew) {
        this.$store.dispatch("card/changeNewObjectField", {
          type: type,
          alias: prop.header.alias,
          value: value,
          dataSourceId: this.dataSourceId
        });

        this.setLinkedProperties();
      }
      else {
        let currentObject = this.currentObject;

        if (type === "linked") {
          let property = _.find(currentObject.linkedProperties, { alias: prop.header.alias });

          if (property) {
            property.values = value.objects;
          }
          else {
            currentObject.linkedProperties.push({
              alias: prop.header.alias,
              values: value.objects
            });
          }

          this.setLinkedProperties();
        }
        else {
          let property = _.find(currentObject.ownProperties, { alias: prop.header.alias });

          if (property) {
            property.value = value;
          }
          else {
            currentObject.ownProperties.push({
              alias: prop.header.alias,
              value: value
            });
          }
        }

        this.$store.dispatch("card/setEditingCurrentObject", currentObject);

        await this.$store.dispatch("card/addChangedFields", {
          type: type,
          dataSourceId: this.dataSourceId,
          alias: prop.header.alias,
          value: value,
          instanceId: this.currentObject.id
        });

        if (type === "linked") {
          await this.$store.dispatch("card/saveChangedFields", this.dataSourceId);
          this.notification.success({ title: "Успешное сохранение" });
        }
      }
    },

    setLinkedProperties() {
      this.linkedProperties = _.chain(this.metadata.linkedProperties).filter({ isAggregated: false }).map(prop => {
        let propValue = _.find(this.currentObject?.linkedProperties, {
          alias: prop.alias
        });

        let groupedProps = _.groupBy(_.get(propValue, this.isNew ? "objects" : "values"), "instanceId");

        return {
          header: prop,
          name: prop.name,
          values: _.map(groupedProps),
          isEmpty: _.isEmpty(groupedProps),
          type: "linked",
          isEditable: prop.isEditable
        };
      }).value();
    },

    setCurrentObject() {
      let dataSourceCurrentObject = _.cloneDeep(this.$store.getters["card/currentObject"]);
      if (!dataSourceCurrentObject) {
        this.$store.dispatch("card/setEditingCurrentObject", null);
        return;
      }

      if (!this.isNew && this.dataSourceId) {
        let currentObject = this.currentObject ? _.cloneDeep(this.currentObject) : dataSourceCurrentObject;

        let fields = this.changedFields(this.dataSourceId);
        if (currentObject) {
          currentObject.linkedProperties = dataSourceCurrentObject?.linkedProperties;

          fields.forEach(field => {
            let property = _.find(currentObject.ownProperties, { alias: field.alias });
            if (property) {
              property.value = field.value;
            }
          });
        }

        this.$store.dispatch("card/setEditingCurrentObject", currentObject);
      }
    }
  }
};