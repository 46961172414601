import _ from "lodash";
import FileObjectConverter from "@/PlatoAPI/FileObjectConverter.js";
import Vue from "vue";

const dateFormat = value => {
  if (!value) {
    return "";
  }

  let d = new Date(value);
  return [
    _.padStart(d.getDate(), 2, 0),
    _.padStart(d.getMonth() + 1, 2, 0),
    d.getFullYear()
  ].join(".");
};

const dateTimeFormat = value => {
  if (!value) {
    return "";
  }

  let d = new Date(value);
  return [
    _.padStart(d.getDate(), 2, 0),
    _.padStart(d.getMonth() + 1, 2, 0),
    d.getFullYear()
  ].join(".") + " " +
    [_.padStart(d.getHours(), 2, 0),
      _.padStart(d.getMinutes(), 2, 0),
      _.padStart(d.getSeconds(), 2, 0)
    ].join(":");
};

const parseBoolean = value => {
  if (_.isBoolean(value) || _.isNull(value)) {
    return value;
  }

  if (_.toLower(value) === "true") {
    return true;
  }

  if (_.toLower(value) === "false") {
    return false;
  }

  return null;
};

const booleanToDisplayString = value => {
  if (_.isBoolean(value)) {
    return value ? "Да" : "Нет";
  }

  return value;
};

/**
 * Форматирование даты
 */
Vue.filter("dateFormat", function(value) {
  return dateFormat(value);
});

/**
 * Форматирование свойства обьектв к выводу в зависимости от типа
 */
Vue.filter("propOutputFormat", function(value, type) {
  if (type == "SaveDate") {
    return dateTimeFormat(value);
  }

  if (type == "DateTimeUTC") {
    return dateFormat(value);
  }

  if (type == "Boolean") {
    return booleanToDisplayString(parseBoolean(value));
  }

  if (type == "RichText") {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = value;
    return tmp.textContent || tmp.innerText || "";
  }

  if (type == "File") {
    const _getFileName = file => {
      if (FileObjectConverter.checkFile(file)) {
        return _.get(FileObjectConverter.parseFile(file), "name");
      }

      return file;
    };

    return _.split(value, "|").map(_getFileName).join(", ");
  }

  else if (type == "Geometry" && value) {
    switch (value.type) {
    case "Point":
      return `${value.coordinates[0].toFixed(6)} ${value.coordinates[1].toFixed(6)}`;
    case "Polygon": {
      if (value.coordinates.length === 0) {
        return undefined;
      }
      return "Полигон";
    }
    case "LineString":
      return "Линия";
    default:
      return "Координаты";
    }
  }
  else {
    return _.unescape(value);
  }
});

Vue.filter("placeholder", function(value, placeholder) {
  if (!_.isEmpty(value) || _.isNumber(value) || _.isBoolean(value)) {
    return value;
  }

  return placeholder;
});


Vue.filter("parseBoolean", parseBoolean);
