import _ from "lodash";

class DataViewConverter {
  static parseDataView(dataView) {
    dataView.filters = _.map(dataView.filters, DataViewConverter.parseFilter);

    return dataView;
  }

  static parseFilter(filter) {
    if (!_.isNull(filter.value)) {
      filter.value = JSON.parse(filter.value);
    }

    return filter;
  }

  static encodeDataView(dataView) {
    let encoded = _.cloneDeep(dataView);
    encoded.filters = _.map(encoded.filters, DataViewConverter.encodeFilter);

    return encoded;
  }

  static encodeFilter(filter) {
    if (!_.isNull(filter.value)) {
      filter.value = JSON.stringify(filter.value);
    }

    return filter;
  }
}

export default DataViewConverter;