<template>
  <div
    class="filter-tab"
    :selected="selected"
    :active="active"
    @click="openFilter"
  >
    {{ tab.name }}
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    tab: {
      type: Object,
      required: true
    },
    activeTab: {
      type: String,
      required: false,
      default: ""
    },
    dataSourceId: {
      type: String,
      required: true
    }
  },
  computed: {
    active() {
      return this.tab.alias === this.activeTab;
    },
    selected() {
      let filter = _.find(this.filters, { alias: this.tab.alias, type: this.tab.type });
      if (!_.isEmpty(filter)) {
        if (filter.type === "Boolean" && filter.value !== null) {
          return true;
        }
        else {
          return !_.isEmpty(filter.value);
        }
      }
      return false;
    },

    filters() {
      return this.$store.getters["filters/filters"](this.dataSourceId);
    }
  },
  methods: {
    openFilter() {
      let filter = {
        dataSourceId: this.dataSourceId,
        filter: {
          alias: this.tab.alias,
          type: this.tab.type,
          value: null,
          name: this.tab.name
        }
      };
      let existFilter = _.find(this.filters, { alias: this.tab.alias, type: this.tab.type });
      if (!_.isEmpty(existFilter)) {
        filter.filter = existFilter;
      }

      if (this.selected && filter.filter.value != null) {
        this.$store.dispatch("filters/editFilter", { ...filter, refreshUrl: true });
      }

      this.$emit("openFilter", filter.filter);
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-tab{
  cursor: pointer;

  padding: 10px;
  max-width: 200px;
  @media screen and (max-width: 768px) {
    max-width: 150px;
    font-size: 14px;
    padding:10px 5px;
  }

  &[active] {
    background-color: #deebff;
  }

  &:hover:not([active=true]){
    background-color: hsla(201, 100%, 94%, 0.3);
  }

  &[selected] {
    color: #0066ff;
  }
}
</style>