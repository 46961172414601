import StateControllerBase from "@/store/StateControllerBase";
import { ApplicationClient, ShellClient } from "@/PlatoAPI";
import { setLoadingState } from "@/store/decorators/setLoadingState";

import AdminData from "@/PlatoAPI/AdminData";

const applicationClient = new ApplicationClient();
const shellClient = new ShellClient();

class Actions extends StateControllerBase {
  @setLoadingState
  async getShellPage(context, id) {
    let value = await shellClient.getShellPage(id);
    context.commit("SET_SHELL_PAGE", value);
  }

  @setLoadingState
  async getVersions(context) {
    let value = await shellClient.getVersions();
    context.commit("setVersions", value);
  }

  @setLoadingState
  async setApplicationsOrder(_context, applicationsIds) {
    await applicationClient.updateApplicationsOrder(applicationsIds);
  }

  @setLoadingState
  async setShellPagesOrder(_context, pagesIds) {
    await shellClient.updateShellPagesOrder(pagesIds);
  }

  resetShellState(context) {
    context.commit("resetShellState");
  }

  @setLoadingState
  async getShellManifest(context, setNavItems = true) {
    let manifest = await shellClient.getShellManifest();
    context.commit("SET_SHELL_MANIFEST", manifest);

    if (setNavItems) {
      context.commit("SET_NAV_LOGO");
      context.dispatch("SET_NAV_ITEMS");
    }
  }

  @setLoadingState
  async GET_APPLICATIONS_INFO({ commit }) {
    const data = await applicationClient.getAllApplicationsInfo();
    commit("SET_APPLICATIONS", data);
  }

  SET_NAV_ITEMS({ commit, getters, rootGetters }, items) {
    if (items) {
      commit("SET_NAV_ITEMS", items);
    }
    else if (rootGetters.CURRENT_USER
      && rootGetters.CURRENT_USER.id == AdminData.GUEST_USER_ID
      && getters.portalApplication) {
      return;
    }
    else {
      let shellNavItems = {
        main: [],
        bottom: []
      };

      if (getters.SHELL_MANIFEST?.pages) {
        getters.SHELL_MANIFEST.pages.forEach(page => {
          page.href = (page.static ? "/page/" : "/") + page.pageId;
          if (shellNavItems[page.menuType]) {
            shellNavItems[page.menuType].push(page);
          }
        });
      }

      commit("SET_NAV_ITEMS", shellNavItems);
    }
  }

  SET_MENU_CAN_COLLAPSE({ commit }, value) {
    commit("SET_MENU_CAN_COLLAPSE", value);
  }
}

export default (new Actions).asPlainObject();