import _ from "lodash";

const BASE_PATH = "/v1/FileApi";

class FileObjectConverter {
  static parseFile(file) {
    let [id, name, bucket] = _.split(file, "/");
    return { id, name, bucket };
  }

  static parseFiles(files) {
    return _.chain(files)
      .split("|")
      .filter()
      .map(this.parseFile)
      .value();
  }

  static encodeFile(file) {
    return `${file.id}/${file.name}/${file.bucket}`;
  }

  static encodeFiles(files) {
    return _.map(files, this.encodeFile).join("|");
  }

  static getUrl(file) {
    return `${BASE_PATH}/${file.bucket}/${file.id}`;
  }

  static checkFile(file) {
    return _.isString(file) && !file.startsWith("/") && _.split(file, "/").length === 3;
  }

  static getSplitedFiles(files) {
    return files.split("|");
  }
}

export default FileObjectConverter;