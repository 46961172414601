import httpClient from "./DefaultHttpClient";
import { rethrowPlatoApiError } from "./PlatoApiError";

const BASE_PATH = "/v1/MapApi";

class MapClient {
  @rethrowPlatoApiError
  async getMapParameters() {
    return httpClient.get(`${BASE_PATH}/map_parameters`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async saveMapParameters(mapParameters) {
    return httpClient.post(`${BASE_PATH}/map_parameters`, mapParameters)
      .then(response => response.data);
  }


  @rethrowPlatoApiError
  async getBaseLayers() {
    return httpClient.get(`${BASE_PATH}/base_layers`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getBaseLayer(id) {
    return httpClient.get(`${BASE_PATH}/base_layers/${id}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async saveBaseLayer(baseLayer) {
    return httpClient.post(`${BASE_PATH}/base_layers`, baseLayer)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async deleteBaseLayer(id) {
    return httpClient.delete(`${BASE_PATH}/base_layers/${id}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getMap() {
    return httpClient.get(`${BASE_PATH}/`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getMapMainParameters() {
    return httpClient.get(`${BASE_PATH}/mainParameters`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async saveMapMainParameters(parameters) {
    return httpClient.post(`${BASE_PATH}/mainParameters`, parameters)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getLayerGroups() {
    return httpClient.get(`${BASE_PATH}/layerGroups`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async saveLayerGroups(layerGroups) {
    return httpClient.post(`${BASE_PATH}/layerGroups/save`, layerGroups)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async saveLayerGroup(layerGroup) {
    return httpClient.post(`${BASE_PATH}/layerGroups`, layerGroup)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getLayerGroup(id) {
    return httpClient.get(`${BASE_PATH}/layerGroups/${id}`)
      .then(response => response.data);
  }

  async deleteLayerGroup(id) {
    return httpClient.delete(`${BASE_PATH}/layerGroups/${id}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getLayer({ layerGroupId, layerId }) {
    return httpClient.get(`${BASE_PATH}/layerGroups/${layerGroupId}/layer/${layerId}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async deleteLayer({ layerGroupId, layerId }) {
    return httpClient.delete(`${BASE_PATH}/layerGroups/${layerGroupId}/layer/${layerId}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async saveLayer({ layerGroupId, layer }) {
    return httpClient.post(`${BASE_PATH}/layerGroups/${layerGroupId}/layer`, layer)
      .then(response => response.data);
  }
}

export default MapClient;