export const LAYER_TYPES = {
  RasterXYZ: 0,
  RasterWMS: 1,
  DataSource: 2,
  RosreestrCadastre: 3,
  RosreestrZouit: 4
};

export const LAYER_TYPE_LABELS = {
  [LAYER_TYPES.RasterXYZ]: "Растровый (XYZ)",
  [LAYER_TYPES.RasterWMS]: "Растровый (WMS)",
  [LAYER_TYPES.DataSource]: "Источник данных",
  [LAYER_TYPES.RosreestrCadastre]: "Росреестр Кадастр",
  [LAYER_TYPES.RosreestrZouit]: "Росреестр Зоуит"
};
