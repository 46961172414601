import StateControllerBase from "@/store/StateControllerBase";
import { DataClient } from "@/PlatoAPI";
import { setLoadingState } from "@/store/decorators/setLoadingState";

import _ from "lodash";

const dataClient = new DataClient();

class Actions extends StateControllerBase {
  async SET_DATA_LOADING_ERROR(context, value) {
    context.commit("setDataLoadingError", value);
  }

  async SET_DATA_LOADING(context, value) {
    context.commit("setDataLoading", value);
  }

  addSorting({ commit, dispatch }, sorting) {
    commit("addSorting", sorting);
    dispatch("refreshUrl", { pushToHistory: false }, { root: true });
  }

  removeSorting({ commit, dispatch }, alias) {
    commit("removeSorting", alias);
    dispatch("refreshUrl", { pushToHistory: false }, { root: true });
  }

  changeSortingType({ commit, dispatch }, alias) {
    commit("setSortingType", alias);
    dispatch("refreshUrl", { pushToHistory: false }, { root: true });
  }

  unshiftSorting({ commit, dispatch }, sorting) {
    commit("unshiftSorting", sorting);
    dispatch("refreshUrl", { pushToHistory: false }, { root: true });
  }

  @setLoadingState
  async getLinkedProps({ getters, commit }, { id, alias, _aliases, searchKeyword }) {
    let sorting = getters.sorting;
    let sortingForRequest = _.map(sorting, sort => ({ propertyAlias: sort.alias, direction: sort.sortType }));

    const body = {
      skip: getters.skip,
      take: getters.take,
      // requestedPropertiesAliases: aliases,
      searchKeyword: searchKeyword,
      orderByProperties: sortingForRequest
    };

    let data = await dataClient.getDataByAlias(id, alias, body);
    commit("setLinkedProps", data);
  }

  increaseTake(context) {
    context.commit("increaseTake");
  }

  resetTake(context) {
    context.commit("resetTake");
  }

  resetState(context) {
    context.commit("resetState");
  }
}

export default (new Actions).asPlainObject();