<template>
  <div>
    <Draggable
      v-if="views.length > 0"
      :value="views"
      :disabled="loading || !writeAccess"
      class="data-view-sets"
      handle=".handle-item"
      @change="draggedDataView"
    >
      <DataViewSet
        v-for="view in views"
        :key="`view_${view.name}`"
        :view="view"
        :write-access="writeAccess"
        :data-source-id="dataSourceId"
      />
    </Draggable>
    <DataViewSet
      v-if="newView"
      :view="newView"
      :write-access="writeAccess"
      :is-new-view="!!newView"
      :data-source-id="dataSourceId"
    />
  </div>
</template>
<script>
import _ from "lodash";

import DataViewSet from "./DataViewSet.vue";
import Draggable from "vuedraggable";
import { mapActions, mapGetters } from "vuex";
import { scopes } from "@/store/Access";

export default {
  components: {
    DataViewSet,
    Draggable
  },

  props: {
    dataSourceId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      views: [],
      newView: null
    };
  },

  computed: {
    ...mapGetters("dataView", [
      "dataViewName"
    ]),
    dataViews() {
      return _.cloneDeep(this.$store.getters["dataSourceDataView"](this.dataSourceId));
    },

    writeAccess() {
      return this.$store.getters.writeAccess({ scope: scopes.APPLICATION, resource: this.applicationId });
    }
  },

  watch: {
    dataViews: {
      immediate: true,
      handler(value) {
        this.views = value;
      }
    }
  },

  mounted() {
    this.setNewView();
    if (!this.writeAccess) {
      this.setEditableName(false);
    }
  },

  methods: {
    ...mapActions("dataView", [
      "setEditableName"
    ]),

    async draggedDataView(value) {
      if (this.writeAccess) {
        this.views = this.arrayMove(this.views, value.moved.oldIndex, value.moved.newIndex);
        this.$emit("dragged-data-view", this.views);
      }
    },

    arrayMove(arr, oldIndex, newIndex) {
      let array = _.cloneDeep(arr);
      if (newIndex >= array.length) {
        let k = newIndex - array.length + 1;
        while (k--) {
          array.push(undefined);
        }
      }
      array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
      return array;
    },

    setNewView() {
      let sorts = _.cloneDeep(this.$store.getters["sorting/sorting"](this.dataSourceId));
      let filters = _.cloneDeep(this.$store.getters["filters/activeFilters"](this.dataSourceId));
      let grouping = _.cloneDeep(this.$store.getters["grouping/grouping"](this.dataSourceId));
      let hiddenColumns = _.cloneDeep(this.$store.getters["grouping/hiddenColumns"]);

      let isNewView = !_.some(this.views, view => _.isEqual(view.sorts, sorts)
      && _.isEqual(view.filters, filters)
      && _.isEqual(view.grouping, grouping)
      && _.isEqual(view.hiddenColumns, hiddenColumns));

      if (isNewView) {
        this.newView = {
          dataSourceId: this.dataSourceId,
          name: "Текущее отображение",
          sorts,
          filters,
          grouping,
          hiddenColumns
        };
        return;
      }

      this.newView = null;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/components/package/styles/colors.scss";
@import "@/components/package/styles/variables.scss";
.data-view-sets {
  display: flex;
  flex-wrap: no-wrap;
  flex-shrink: 0;
  flex-direction: column;
}
</style>