export default {
  namespaced: true,
  state: {
    navigationObject: null
  },
  getters: {
    navigationObject: state => {
      return state.navigationObject;
    }
  },
  mutations: {
    setNavigationObject: (state, newNavigationObject) => {
      state.navigationObject = newNavigationObject;
    }
  },
  actions: {
    changeNavigationObject({ commit }, navigationObject) {
      commit("setNavigationObject", navigationObject);
    },

    removeNavigationObject({ commit }) {
      commit("setNavigationObject", null);
    }
  }
};