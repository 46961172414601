<template>
  <button
    :class="[commonClass, activeClass, ...typeClass]"
    :disabled="disabled || loading"
    :title="title"
    :style="chipColorStyle"
    @click.prevent="e => $emit('click', e)"
  >
    <Icon
      v-if="loading"
      class="plato-btn__icon plato-btn__icon-loading"
      icon="spinner-third"
    />
    <template v-else>
      <Icon
        v-if="icon"
        class="plato-btn__icon"
        :icon="icon"
        :type="iconType"
      />
      <i
        v-if="icon && $slots['default']"
        class="plato-btn__icon_spacer"
      />
      <div
        v-if="$slots['default']"
        class="plato-btn__label"
      >
        <slot />
      </div>
      <div
        v-if="$slots['after']"
        class="plato-btn__icon"
      >
        <slot name="after" />
      </div>
    </template>
  </button>
</template>

<script>
import _ from "lodash";

import { colorSchema } from "@/components/ObjectColorSchema.js";

export default {
  name: "PlatoButton",
  props: {
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ""
    },
    componentStyle: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    iconType: {
      type: String,
      default: "fas"
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      commonClass: "plato-btn"
    };
  },
  computed: {
    activeClass() {
      return this.active ? "plato-btn--active" : "";
    },

    typeClass() {
      let types = _.chain(this.type).split(" ").map(_.trim).remove().value();
      return _.map(types, type => `plato-btn--${type}`);
    },

    chipColorStyle() {
      let color = colorSchema(this.componentStyle?.color, this.componentStyle?.outlineColor);

      return {
        "--backgound-chip-color": `${color.fill.main}`,
        "--backgound-hovered-chip-color": `${color.fill.hovered}`,
        "--backgound-clicked-chip-color": `${color.fill.clickHovered}`,
        "--text-chip-color": `${color.outline.main}`,
        "--text-hovered-chip-color": `${color.outline.hovered}`,
        "--text-clicked-chip-color": `${color.outline.clickHovered}`
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes plato-btn__icon-loading-keyframes {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.plato-btn {
  background: #f6f7f8;
  color: #505f79;
  height: 28px;
  min-width: 32px;
  font-size: 14px;
  align-items: center;
  border-radius: 4px;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  font-style: normal;
  margin: 0;
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  transition: background 0.1s ease-out 0s,
  box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  white-space: nowrap;
  outline: none;
  position: relative;
  //Было убрано и заработал PlatoPoppap в PlatoChip в ObjectCardNew
  //overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 0 9px;
  user-select: none;
  &__label {
    text-overflow: ellipsis;
    overflow: hidden;
    div {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  // &~& {
  //     margin-left: 8px;
  // }
  &:hover {
    background: #efeff2;
  }

  &:active {
    background-color: #b3d4ff99;
    color: #0052cc;
  }

  &[disabled], &:hover[disabled], &:active[disabled] {
    color: #505f793d;
    background: #fdfdfd;
    cursor: default;
  }

  &.plato-btn--compact {
    height: 24px;
    min-width: 24px;
  }

  &.plato-btn--tag {
    height: 18px;
    font-size: 11px;
    margin: 3px;

    background-color: rgba(0, 82, 204, 0.1);
    color: #0747a6;
    border: 1px solid rgba(0, 82, 204, 0.3019607843);

    padding: 0 5px;
  }

  &.plato-btn--small {
    height: auto;
    line-height: inherit;
    padding: 0;
    min-width: 24px;
    min-height: 26px;
  }

  &.plato-btn--primary {
    background: #0052cc;
    color: #ffffff;

    &:hover {
      background: #0065ff;
    }

    &:active {
      background: #0747a6;
    }

    &[disabled], &:hover[disabled], &:active[disabled] {
      color: #505f793d;
      background: #fdfdfd;
      cursor: default;
    }
  }

  &.plato-btn--subtle {
    background: none;
    color: #505f79;
    &:hover {
      background: #091e4214;
    }
    &:active {
      background: #b3d4ff99;
      color: #0052cc;
    }
    &[disabled], &:hover[disabled], &:active[disabled] {
      color: #505f793d;
      background: #fdfdfd;
      cursor: default;
    }
  }

  &.plato-btn--link {
    background: none;
    color: #0052cc;
    &:hover {
      color: #0065ff;
    }
    &:active {
      color: #0747a6;
      text-decoration: none;
    }
    &[disabled], &:hover[disabled], &:active[disabled] {
      color: #505f793d;
      background: #fdfdfd;
      cursor: default;
    }
  }

  &.plato-btn--link-grey {
    background: none;
    color: #505f79;
    &:hover {
      background-color: rgba(0, 82, 204, 0.1);
      color: #396eca;
    }
    &:active {
      color: #0747a6;
    }
    &[disabled], &:hover[disabled], &:active[disabled] {
      color: #505f793d;
      background: #fdfdfd;
      cursor: default;
    }

    &.plato-btn--active {
      background-color: rgba(0, 82, 204, 0.1);
      color: #505f79;
      &:hover {
        background: rgba(0, 82, 204, 0.1);
        color: #505f79;
      }
      &[disabled], &:hover[disabled], &:active[disabled] {
        background: rgba(0, 91, 228, 0.1);
        color: #505f79d0;
        cursor: default;
      }
    }
  }

  &.plato-btn--light {
    background: #deebff;
    color: #5585ce;
    &:hover {
      background: #cce0ff;
    }
    &:active {
      color: #0052cc;
      background: #6089c7;
    }
    &[disabled], &:hover[disabled], &:active[disabled] {
      color: #505f793d;
      background: #fdfdfd;
      cursor: default;
    }
  }

  &.plato-btn--light-blue {
    background: #deebff;
    color: #0052cc;
    &:hover {
      background: #b3d4ff99;
    }
    &:active {
      color: #0052cc;
      background: #caddf8;
    }
    &[disabled], &:hover[disabled], &:active[disabled] {
      color: #505f793d;
      background: #fdfdfd;
      cursor: default;
    }
  }

  &.plato-btn--custom {
    background: var(--backgound-chip-color);
    color: var(--text-chip-color);

    &:hover {
      background: var(--backgound-hovered-chip-color);
      color: var(--text-hovered-chip-color);
    }

    &:active {
      background: var(--backgound-clicked-chip-color);
      color: var(--text-clicked-chip-color);
    }
  }

  &.plato-btn--success {
    background-color: #008558;
    color: #fff;
    font-weight: 500;
    &:hover {
      background-color: #36b580;
      color: #fff;
    }
    &:active {
      background-color: #064;
      color: #fff;
    }
    &[disabled], &:hover[disabled], &:active[disabled] {
      color: #505f793d;
      background: #fdfdfd;
      cursor: default;
    }
  }

  &.plato-btn--warning {
    background: #ffab00;
    color: #172b4d;
    &:hover {
      background: #ffc400;
    }
    &:active {
      background: #ff991f;
      color: #172b4d;
    }
    &[disabled], &:hover[disabled], &:active[disabled] {
      color: #505f793d;
      background: #fdfdfd;
      cursor: default;
    }
  }

  &.plato-btn--danger {
    background-color: #e0350b;
    color: #fff;
    font-weight: 500;
    &:hover {
      background-color: #ff542e;
      color: #fff;
    }
    &:active {
      background-color: #bd2600;
      color: #fff;
    }
    &[disabled], &:hover[disabled], &:active[disabled] {
      color: #505f793d;
      background: #fdfdfd;
      cursor: default;
    }
  }

  &.plato-btn--danger-action {
    &:active {
      background-color: #ffa5a5;
      color: #bd2600;
    }
    &[disabled], &:hover[disabled], &:active[disabled] {
      color: #505f793d;
      background: #fdfdfd;
      cursor: default;
    }
  }

  &.plato-btn--close-action {
    background: none;
    &:hover {
      background: #efeff2;
    }
    &:active {
      background-color: #ffa5a5;
      color: #bd2600;
    }
    &[disabled], &:hover[disabled], &:active[disabled] {
      color: #505f793d;
      background: #fdfdfd;
      cursor: default;
    }
  }

  &.plato-btn--active {
    background-color: #42526e;
    color: #ffffff;
    &:hover {
      background: #42526e;
      color: #ffffff;
    }
    &[disabled], &:hover[disabled], &:active[disabled] {
      background: #7081a0;
      color: #ffffff;
      cursor: default;
    }
  }

  &.plato-btn--perspective-big {
    justify-content: space-between;
    padding: 10px 15px;
    box-shadow: 0 1px 1px 0 rgba(9, 30, 66, 0.25),
        0 0 1px 0 rgba(9, 30, 66, 0.31);
    background-color: #f5f6f8;
    color: #000;
    height: 34px;
    gap: 10px;
    &:hover {
        background-color: #eeeeee;
    }
    &:active {
        box-shadow: inset 1px 1px 1px #c1c1c1;
        background-color: #e7e9ee;
    }
    &--active {
        box-shadow: inset 1px 1px 1px #c1c1c1;
        background-color: #f0f1f5;
    }
  }

  &.plato-btn--perspective{
    box-shadow: 0 1px 1px 0 rgba(9, 30, 66, 0.25),
        0 0 1px 0 rgba(9, 30, 66, 0.31);
    background-color: #f5f6f8;
    color: #000;
    &:hover {
        background-color: #eeeeee;
    }
    &:active {
        box-shadow: inset 1px 1px 1px #c1c1c1;
        background-color: #e7e9ee;
    }
    &--active {
        box-shadow: inset 1px 1px 1px #c1c1c1;
        background-color: #f0f1f5;
    }
  }

  &__icon {
    font-size: 12px;
    width: 16px;

    &_spacer {
      display: inline-block;
      width: 5px;
    }

    &-loading {
      animation: plato-btn__icon-loading-keyframes 1s linear infinite;
    }
  }
}
</style>