import httpClient from "./DefaultHttpClient";
import { CancelTokenSource } from "../PlatoAPI";
import { rethrowPlatoApiError } from "./PlatoApiError";

const BASE_PATH = "/v1/GeofileApi";

class GeometryFileClient {
  constructor() {
    this._cancellationSource = null;
  }
  @rethrowPlatoApiError
  async convertGeoFileToGeojson(file, filename) {
    this._cancellationSource = new CancelTokenSource();
    return httpClient.post(`${BASE_PATH}/convertToGeojson`, file, {
      headers: {
        "Content-type": "application/octet-stream",
        "Content-Disposition": `attachment;filename=${filename}`
      },
      cancelToken: this._cancellationSource.token
    })
      .then(response => response.data)
      .catch(e => {
        if (!CancelTokenSource.isCancel(e)) {
          throw e;
        }
      });
  }

  async convertGeojsonToGeofile(geojson, format, fileName) {
    this._cancellationSource = new CancelTokenSource();
    return httpClient.post(`${BASE_PATH}/convertToGeofile`, geojson, {
      params: {
        format,
        fileName
      },
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json"
      },
      cancelToken: this._cancellationSource.token
    })
      .then(response => {
        let contentDisposition = response.headers["content-disposition"];
        let match = contentDisposition.match(/filename\s*=\s*"*(.+)"*/i);
        let filename = match[1];
        return {
          data: response.data, filename
        };
      })
      .catch(e => {
        if (!CancelTokenSource.isCancel(e)) {
          throw e;
        }
      });
  }
}

export default GeometryFileClient;
