import _ from "lodash";
import { DATASOURCE_TYPES } from "@/components/utils/dataSource/types.js";

import actions from "./actions";

function getDataProps(objectProperty) {
  let data = _.cloneDeep(objectProperty.dataProperties);

  data.forEach(dataProperty => {
    dataProperty.objectPropertyPath = objectProperty.path;
    dataProperty.parentAlias = objectProperty.alias;
    dataProperty.parentName = objectProperty.name;
  });

  _.forEach(objectProperty.objectProperties, op => {
    data.push(...getDataProps(op));
  });

  return data;
}

function getObjectProps(objectProperty) {
  let data = _.cloneDeep(objectProperty.objectProperties);

  data.forEach(objectProp => {
    objectProp.objectPropertyPath = objectProperty.path;
  });

  _.forEach(objectProperty.objectProperties, op => {
    data.push(...getObjectProps(op));
  });

  return data;
}

const getDefaultState = () => {
  return {
    DATA_LOADING: false,
    DATA_LOADING_ERROR: null,
    dataSourceName: "Новый источник данных",
    objectProperties: [],
    dataProperties: [],
    selectedObjectProperties: [],
    selectedDataProperties: [],
    annotationProperties: [],
    classes: [],
    classTree: [],
    clearClassTree: [],
    savedDataSource: null,
    dataSource: null,
    dataSourceEtag: "",
    dataSources: null,
    dataSourcesInfo: null,
    selectedClasses: [],
    markedParents: [],
    propTree: [],
    objectToEdit: null,
    styleToEdit: null,
    conditionToEdit: null,
    previewMode: false,
    filters: [],
    dataPreview: null,
    assembledDataSource: null,
    сurrentStep: 0,
    styles: [],
    defaultStyle: null,
    conditions: [],
    defaultCondition: null,
    dataSourceInstances: null,
    classInstances: [],
    dataSourceType: DATASOURCE_TYPES.internal
  };
};

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    DATA_LOADING: state => {
      return state.DATA_LOADING;
    },
    DATA_LOADING_ERROR: state => {
      return state.DATA_LOADING_ERROR;
    },
    selectedClasses: state => {
      return state.selectedClasses;
    },
    markedParents: state => {
      return state.markedParents;
    },
    fullClasses: state => {
      return state.fullClasses;
    },
    objectProperties: state => {
      return state.objectProperties;
    },
    dataProperties: state => {
      return state.dataProperties;
    },
    styles: state => {
      return state.styles;
    },
    conditions: state => {
      return state.conditions;
    },
    filters: state => {
      return state.filters;
    },
    styleToEdit: state => {
      return state.styleToEdit;
    },
    conditionToEdit: state => {
      return state.conditionToEdit;
    },
    currentStep: state => {
      return state.currentStep;
    },
    assembledDataSource: state => {
      return state.assembledDataSource;
    },
    annotationProperties: state => {
      return state.annotationProperties;
    },
    dataSourceName: state => {
      return state.dataSourceName;
    },
    classes: state => {
      return state.classes?.items;
    },
    dataPreview: state => {
      return state.dataPreview;
    },
    previewMode: state => {
      return state.previewMode;
    },
    classTree: state => {
      return state.classTree;
    },
    clearClassTree: state => {
      return state.clearClassTree;
    },
    objectToEdit: state => {
      return state.objectToEdit;
    },
    propertyByPath: state => path => {
      return _.get(state, path);
    },
    selectedObjectProperties: state => {
      return state.selectedObjectProperties;
    },
    selectedDataProperties: state => {
      return state.selectedDataProperties;
    },
    selectedDataPropertiesList: state => {
      let selectedDataPropertiesList = [...state.selectedDataProperties];
      _.forEach(state.selectedObjectProperties, op => {
        selectedDataPropertiesList.push(...getDataProps(op));
      });
      return selectedDataPropertiesList;
    },
    selectedObjectPropertiesList: state => {
      let selectedObjectPropertiesList = [...state.selectedObjectProperties];
      _.forEach(state.selectedObjectProperties, op => {
        selectedObjectPropertiesList.push(...getObjectProps(op));
      });
      return selectedObjectPropertiesList;
    },
    currentTopLevelClass: state => {
      return new Set(_.map(_.map(state.selectedClasses, "path"), p => isNaN(Number(p)) ? String(p[0]) : String(p))).size === 1;
    },
    treeItem: state => path => {
      return _.get(state.classTree, path);
    },
    mainClass: state => {
      let mainClass;
      if (state.selectedClasses.length == 0) {
        mainClass = null;
      }
      else if (state.selectedClasses.length == 1) {
        mainClass = _.cloneDeep(state.selectedClasses[0]);
      }
      else {
        let paths = _.map(state.selectedClasses, subCl => (String(subCl.path).split("")));
        let pathLengths = _.map(paths, p => (p.length));
        let minLengthPath = _.min(pathLengths);
        let minLengthCounter = 0;
        _.forEach(pathLengths, l => {
          if (l == minLengthPath) {
            minLengthCounter++;
          }
        });
        let minPathIndex = _.indexOf(pathLengths, minLengthPath);
        if (minLengthCounter == 1
          && !_.some(state.markedParents, parent => _.difference(String(parent.path).split(""), _.min(paths)).length == 1
            || _.difference(_.min(paths), String(parent.path).split("")).length == 1)) {
          mainClass = _.get(state.classTree, paths[minPathIndex].join(""));
        }
        else {
          let commonParentPath = [];
          for (let index = 0; index < paths[minPathIndex].length; index++) {
            let checkingPositions = _.map(paths, path => path[index]);
            let check = new Set(checkingPositions).size === 1;
            if (check) {
              if (index == paths[minPathIndex].length - 1) {
                mainClass = _.get(state.classTree, paths[minPathIndex].join(""));
              }
              commonParentPath.push(checkingPositions[0]);
            }
            else {
              break;
            }
          }
          if (!mainClass) {
            mainClass = _.get(state.classTree, commonParentPath.join("").slice(0, commonParentPath.length - 10));
          }
        }
      }
      state.objectToEdit = mainClass;
      state.conditions = [];
      state.dataSourceInstances = [];
      return mainClass;
    },
    savedDataSource: state => {
      return state.savedDataSource;
    },
    currentDatasource: state => {
      return state.dataSource;
    },
    dataSourceEtag: state => {
      return state.dataSourceEtag;
    },
    dataSources: state => {
      return state.dataSources;
    },
    dataSourcesInfo: state => {
      return state.dataSourcesInfo;
    },
    filterTemplates: state => {
      return state.filterTemplates;
    },
    defaultStyle: state => {
      return state.defaultStyle;
    },
    classInstances: state => {
      return state.classInstances;
    },
    instancesByClassId: state => classId => {
      return _.find(state.classInstances, { classId });
    },
    defaultCondition: state => {
      return state.defaultCondition;
    },
    dataSourceInstances: state => {
      return state.dataSourceInstances;
    },
    dataSourceStyles: state => {
      return [state.defaultStyle, ...state.styles];
    },
    dataSourceConditions: state => {
      return [state.defaultCondition, ...state.conditions];
    },
    availableLinkedDataSources: (state, getters) => linkedClassesIds => {
      if (state.dataSourcesInfo != null && linkedClassesIds.length > 0) {
        let allClasses = [];
        let getClassById = classId => {
          let currentClass = _.find(getters.classes, classItem => classItem.id === classId);
          return currentClass;
        };
        let getChildrenClassIds = parentClass => {
          if (parentClass.children.length > 0) {
            parentClass.children.forEach(childClassItem => {
              allClasses.push(childClassItem.id);
              let childClass = getClassById(childClassItem.id);
              getChildrenClassIds(childClass);
            });
          }
        };
        linkedClassesIds.forEach(parentClassId => {
          allClasses.push(parentClassId.id);
          let parentClass = getClassById(parentClassId.id);
          getChildrenClassIds(parentClass);
        });
        return _.filter(state.dataSourcesInfo, dataSourceInfo => {
          return _.find(allClasses, classId => classId === dataSourceInfo.classId);
        });
      }
      return [];
    },

    dataSourceType: state => {
      return state.dataSourceType;
    }
  },
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    resetClassTree(state) {
      state.classTree = _.cloneDeep(state.clearClassTree);
    },
    SET_DATA_LOADING: (state, value) => {
      state.DATA_LOADING = value;
    },
    SET_DATA_LOADING_ERROR: (state, value) => {
      state.DATA_LOADING_ERROR = value;
    },
    setSelectedObjectProperties: (state, data) => {
      state.selectedObjectProperties = data;
    },
    setCurrentStep: (state, value) => {
      state.сurrentStep = value;
    },
    setStyles: (state, styles) => {
      state.styles = styles;
    },
    setConditions: (state, conditions) => {
      state.conditions = conditions;
    },
    setStyleToEdit: (state, style) => {
      state.styleToEdit = style;
    },
    setConditionToEdit: (state, condition) => {
      state.conditionToEdit = condition;
    },
    updateStyleById: (state, style) => {
      state.styles.splice(_.findIndex(state.styles, { styleId: style.styleId }), 1, style);
    },
    updateConditionById: (state, condition) => {
      state.conditions.splice(_.findIndex(state.conditions, { conditionId: condition.conditionId }), 1, condition);
    },
    setDefaultStyle: (state, style) => {
      state.defaultStyle = style;
    },
    setDefaultCondition: (state, condition) => {
      state.defaultCondition = condition;
    },
    addStyle: (state, style) => {
      state.styles.push(style);
    },
    addCondition: (state, condition) => {
      state.conditions.push(condition);
    },
    deleteStyle: (state, styleId) => {
      state.styles = _.reject(state.styles, { styleId });
    },
    deleteCondition: (state, conditionId) => {
      state.conditions = _.reject(state.conditions, { conditionId });
    },
    setAssembledDataSource: (state, data) => {
      state.assembledDataSource = data;
    },
    setDataSourceName: (state, dataSourceName) => {
      state.dataSourceName = dataSourceName;
    },
    setFilters: (state, filters) => {
      state.filters = filters;
    },
    setSelectedDataProperties: (state, data) => {
      state.selectedDataProperties = data;
    },
    setPreviewMode: (state, check) => {
      state.previewMode = check;
    },
    updatePropertyByPath: (state, { path, data }) => {
      _.set(state, path, data);
    },
    setObjectProperties: (state, property) => {
      state.objectProperties = property;
    },
    setDataProperties: (state, property) => {
      state.dataProperties = property;
    },
    setAnnotationProperties: (state, property) => {
      state.annotationProperties = property;
    },
    setClasses: (state, classes) => {
      state.classes = classes;
    },
    setDataSourceClassTree: (state, classTree) => {
      state.classTree = _.cloneDeep(classTree);
    },
    setClearClassTree: (state, classTree) => {
      state.clearClassTree = classTree;
    },
    setCurrentDataSource: (state, value) => {
      state.dataSource = value;
    },
    setDataSourceEtag: (state, value) => {
      state.dataSourceEtag = value;
    },
    setSavedDataSource: (state, value) => {
      state.savedDataSource = value;
    },
    setDataPreview: (state, value) => {
      state.dataPreview = value;
    },
    setDataSourceInstances: (state, value) => {
      state.dataSourceInstances = value;
    },
    setDataSources: (state, dataSources) => {
      state.dataSources = dataSources;
    },
    setObjectToEdit: (state, objectToEdit) => {
      state.objectToEdit = objectToEdit;
    },
    setDataSourcesInfo: (state, dataSourcesInfo) => {
      state.dataSourcesInfo = dataSourcesInfo;
    },
    addClassInstances: (state, data) => {
      if (!_.find(state.classInstances, { classId: data.classId })) {
        state.classInstances.push(data);
      }
    },
    setClassInstances: (state, data) => {
      state.classInstances = data;
    },
    setFilterTemplates: (state, filterTemplates) => {
      state.filterTemplates = filterTemplates;
    },
    setFullClasses: (state, classes) => {
      state.fullClasses = classes;
    },
    setSelectedClasses(state, classes) {
      state.selectedClasses = classes;
    },
    setTreeItemCheck: (state, { path, check }) => {
      _.set(state.classTree, path + ".checked", check);
      if (check) {
        state.selectedClasses.push(_.get(state.classTree, path));
      }
      else if (!check) {
        state.selectedClasses = _.filter(state.selectedClasses, clas => clas.id != _.get(state.classTree, path).id);
      }
    },
    setTreeItemCheckk: (state, { path, check }) => {
      _.set(state.classTree, path + ".checked", check);
    },
    setTreeItemMark: (state, { path, mark }) => {
      _.set(state.classTree, path + ".marked", mark);
      if (mark && !_.some(state.markedParents, clas => clas.id == _.get(state.classTree, path).id)) {
        state.markedParents.push(_.get(state.classTree, path));
      }
      else if (!mark) {
        state.markedParents = _.filter(state.markedParents, clas => clas.id != _.get(state.classTree, path).id);
      }
    },
    setDataSourceType: (state, type) => {
      state.dataSourceType = type;
    }
  },
  actions
};