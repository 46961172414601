import _ from "lodash";
import Messages from "./Messages";

class PlatoApiError extends Error {
  /**
     * Ошибка Plato API
     * @param {String} message Сообщение ошибки
     * @param {Error} inner Внутренняя ошибка, которая привела к данной ошибке
     */
  constructor(message, inner) {
    super(message);

    this.details = (inner && inner.message) || "";
    this.inner = inner;
    this.name = this.constructor.name;
  }
}

class PlatoApiErrorDetailed extends PlatoApiError {
  /**
     * Ошибка Plato API с детальной информацией об ошибке
     * @param {String} message Сообщение ошибки
     * @param {String} details Дополнительное сообщение
     * @param {Error} inner Внутренняя ошибка, которая привела к данной ошибке
     */
  constructor(message, details, inner) {
    super(message);

    this.details = details || "";
    this.inner = inner;
    this.name = this.constructor.name;
  }
}

let rethrowPlatoApiError = (target, property, descriptor) => {
  let module = target.constructor.name;
  let method = property;
  let actucalFunction = descriptor.value;

  return {
    configurable: true,

    get() {
      return async function(...args) {
        try {
          return await actucalFunction.call(this, ...args);
        }
        catch (error) {
          if (_.get(error, "response.status") === 401) {
            throw new PlatoApiErrorDetailed("Неавторизованный пользователь", _.get(Messages, [module, method], Messages.default), error);
          }
          if (_.get(error, "response.status") === 412) {
            throw new PlatoApiErrorDetailed(_.get(Messages, [module, method], Messages.default), "Данные были изменены ранее. Перезагрузите страницу", error);
          }
          throw new PlatoApiError(_.get(Messages, [module, method], Messages.default), error);
        }
      };
    }
  };
};

export { PlatoApiError, PlatoApiErrorDetailed, rethrowPlatoApiError };
