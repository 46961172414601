import colorsys from "colorsys";

const getAppColors = function(mainColorHex) {
  let mainColor = colorsys.hexToHsl(mainColorHex);

  if (mainColor.l > 40 && mainColor.l < 70) {
    mainColor.l = (mainColor.l > 50) ? 70 : 40;
  }

  let luma = { light: [12, 14, 6], dark: [94, 76, 100] };
  let mode = mainColor.l > 50 ? "light" : "dark";

  return {
    // Цвет фона меню
    mainHsl: `hsla(${mainColor.h}, ${mainColor.s}%, ${mainColor.l}%, 1)`,

    // Цвет текста невыбранного и фона выбранного элемента основного меню
    contrastColorHsl: `hsla(${mainColor.h}, ${mainColor.s}%, ${luma[mode][0]}%, 1)`,

    // Цвет наведенного основного элемента меню
    hoveredColorHsl: `hsla(${mainColor.h}, ${mainColor.s}%, ${luma[mode][0]}%, 0.3)`,

    // Цвет нажатого основного элемента меню
    activeColorHsl: `hsla(${mainColor.h}, ${mainColor.s}%, ${luma[mode][0]}%, 0.6)`,

    // Цвет наведенного элемента нижнего меню
    bottomHoveredTextColorHsl: `hsla(${mainColor.h}, ${mainColor.s}%, ${luma[mode][1]}%, 0.8)`,

    // Цвет выделенного элемента нижнего меню
    bottomActiveTextColorHsl: `hsla(${mainColor.h}, ${mainColor.s}%, ${luma[mode][1]}%, 0.4)`,

    // Цвет выделенного элемента нижнего меню
    bottomSelectedTextColorHsl: `hsla(${mainColor.h}, ${mainColor.s}%, ${luma[mode][1]}%, 0.6)`,

    appCardBackgroundColorHsl: `hsla(${mainColor.h}, ${mainColor.s}%, 94%, 1)`,
    appCardTextColorHsl: `hsla(${mainColor.h}, ${mainColor.s}%, 6%, 1)`
  };
};

export { getAppColors };