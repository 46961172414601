<template>
  <div class="data-view-panel-wrapper">
    <PlatoAsideHeader
      header="Параметры отображения"
      @close="$emit('close')"
    >
      <PlatoButton
        v-if="writeAccess"
        :loading="loading"
        :disabled="!showSaveButton"
        type="primary"
        @click="saveChanges"
      >
        Сохранить
      </PlatoButton>
    </PlatoAsideHeader>

    <div class="plato-aside__body dataview-panel">
      <div
        class="dataview-panel_item"
      >
        <DataViewSets
          class="dataview-panel_sets"
          :data-source-id="dataSourceId"
          @dragged-data-view="(value) => views = value"
        />
      </div>

      <div class="dataview-panel_view">
        <div
          class="dataview-panel_item"
        >
          <CurrentDataView
            :data-source-id="dataSourceId"
          />
        </div>

        <div class="dataview-panel_item">
          <AppliedView
            :data-source-id="dataSourceId"
            :table-columns="tableColumns"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppliedView from "./DataViewComponents/AppliedView.vue";
import CurrentDataView from "./DataViewComponents/CurrentDataView.vue";
import DataViewSets from "./DataViewComponents/DataViewSets.vue";

import Converter from "@/components/package/Table/PlatoTableTableConverter";


import { mapActions, mapGetters } from "vuex";

import { scopes } from "@/store/Access";

import _ from "lodash";

export default {
  components: {
    DataViewSets,
    AppliedView,
    CurrentDataView
  },
  props: {
    dataSourceId: {
      type: String,
      required: true
    },

    metadata: {
      type: Object,
      default: () => {}
    },

    columnsParams: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      views: [],
      loading: false
    };
  },

  computed: {
    ...mapGetters("dataView", [
      "dataViewName",
      "editableName",
      "isEditDefaultView",
      "editDefaultViewOperation"
    ]),

    filters() {
      return this.$store.getters["filters/activeFilters"](this.dataSourceId);
    },

    sorts() {
      return this.$store.getters["sorting/sorting"](this.dataSourceId);
    },

    grouping() {
      return this.$store.getters["grouping/grouping"](this.dataSourceId);
    },

    hiddenColumns() {
      return this.$store.getters["grouping/hiddenColumns"];
    },

    dataViews() {
      return this.$store.getters["dataSourceDataView"](this.dataSourceId);
    },

    applicationId() {
      return this.$store.getters.MANIFEST_ID;
    },

    writeAccess() {
      return this.$store.getters.writeAccess({ scope: scopes.APPLICATION, resource: this.applicationId });
    },

    ownPropertiesSortings() {
      return this.$store.getters["sorting/ownPropertiesSortingData"](this.metadata);
    },

    linkedPropertiesSortings() {
      return this.$store.getters["sorting/linkedPropertiesSortingData"](this.metadata);
    },

    tableColumns() {
      return Converter.convertColumns(this.columnsParams, this.metadata, this.appliedSorting,
        this.ownPropertiesSortings, this.linkedPropertiesSortings);
    },

    isNewOrderViews() {
      return !_.isEqual(this.views, this.dataViews) && !_.isEmpty(this.views);
    },

    isNewView() {
      return (this.editableName && this.dataViewName !== "");
    },

    showSaveButton() {
      return this.isEditDefaultView || this.isNewOrderViews || this.isNewView;
    }
  },

  methods: {
    ...mapActions([
      "GET_MANIFEST",
      "addDataSourceDataView",
      "updateDataSourceDataView",
      "setDataSourceDefaultDataView",
      "deleteDataSourceDefaultDataView",
      "updateDataSourceDataViews"
    ]),

    async saveChanges() {
      this.loading = true;
      if (this.isNewOrderViews) {
        await this.updateDataSourceDataViews(this.views);
      }

      if (this.isNewView) {
        let dataView = {
          dataSourceId: this.dataSourceId,
          name: this.dataViewName,
          filters: this.filters,
          sorts: this.sorts,
          grouping: this.grouping,
          hiddenColumns: this.hiddenColumns
        };

        let existingDataViewName = _.find(this.dataViews, { name: this.dataViewName });
        if (existingDataViewName) {
          await this.updateDataSourceDataView(dataView);
        }
        else {
          await this.addDataSourceDataView(dataView);
        }
      }

      if (this.isEditDefaultView) {
        switch (this.editDefaultViewOperation) {
        case "setView":
          await this.setDataSourceDefaultDataView({ dataSourceId: this.dataSourceId, name: this.dataViewName });
          break;
        case "delete":
          await this.deleteDataSourceDefaultDataView(this.dataSourceId);
          break;
        default:
          break;
        }
      }

      await this.GET_MANIFEST(this.applicationId);
    }
  }
};
</script>
<style lang="scss" scoped>
.dataview-panel {
    display: flex;
    width: 100%;
    gap:10px;

    &_sets{
      display: flex;
      flex: 0 0 15%;
      flex-direction: column;
    }

    &_view{
      display: flex;
      flex: 0 1 85%;
      flex-direction: column;
    }
}
</style>