<script >
// https://popper.js.org/
import { createPopper } from "@popperjs/core";

export default {
  // functional: true,
  name: "PlatoPopper",
  props: {
    targetElement: {
      required: true
    },
    // https://popper.js.org/docs/v2/constructors/#placement
    placement: {
      type: String,
      default: "bottom-start"
    },
    // https://popper.js.org/docs/v2/modifiers/offset/
    offset: {
      type: Array,
      default: () => [0, 8]
    },
    // https://popper.js.org/docs/v2/constructors/#strategy
    strategy: {
      type: String,
      default: "fixed"
    },
    // Класс всплывающего окна
    popperClass: {
      type: String,
      default: "plato-popper"
    },
    // Используется изменения позиции при перемещении target
    targetPosition: {},
    // (Необязательно) Элемент ограничивающий viewport
    // https://popper.js.org/docs/v2/utils/detect-overflow/#boundary
    boundary: {
    },

    // Максимальная ширина поппера
    maxWidth: {
      type: Number,
      required: false
    }
  },
  watch: {
    targetPosition: function() {
      this.update();
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initPopper();
    });
  },
  beforeDestroy() {
    if (this.popper) {
      this.popper.destroy();
      this.popper = null;
    }
  },
  methods: {
    popperStyle() {
      if (this.maxWidth) {
        return { "max-width": `${this.maxWidth}px` };
      }

      return {};
    },

    initPopper() {
      this.popper = createPopper(
        this.targetElement,
        this.$refs["ref-plato-popper"],
        {
          placement: this.placement,
          strategy: this.strategy,
          modifiers: [
            {
              name: "offset",
              options: {
                offset: this.offset
              }
            },
            {
              name: "preventOverflow",
              options: {
                boundary: this.boundary
              }
            }
          ]
        }
      );
    },
    update() {
      if (this.popper) {
        this.popper.update();
      }
    }
  },
  render(createElement) {
    const defaultSlot = this.$slots.default;
    return createElement(
      "div",
      { class: this.popperClass, ref: "ref-plato-popper", style: this.popperStyle() },
      [defaultSlot]
    );
  }
};
</script>

<style lang="scss" scoped>
.plato-popper {
  z-index: 6000;
  background: #ffffff;
  color: #000;
  padding: 8px 10px;
  border-radius: 3px;
  box-shadow: 0 0 32px -8px rgba(9, 30, 66, 0.25),
    0 0 1px 0 rgba(9, 30, 66, 0.31);
  border: 1px solid #00000021;
  box-sizing: border-box;
  &[data-popper-reference-hidden] {
    pointer-events: none;
  }
  .chip_label {
    white-space: pre-wrap;
    overflow: hidden;
    max-width: 400px;
  }
  &__body {
    &_prop-alias {
      margin-right: 15px;
      font-size: 12px;
      font-weight: normal;
      color: #6b778c;
      text-align: left;
      line-height: 1.5;
    }
    &_prop-value {
      font-size: 14px;
      font-weight: normal;
    }
  }
}
</style>

