export function Download(file, filename) {
  let blob = new Blob([file]);
  let element = document.createElement("a");
  element.setAttribute("href", window.URL.createObjectURL(blob));
  element.setAttribute("download", filename);
  element.target = "_blank";

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
  element.remove();
}