import _ from "lodash";
import StateControllerBase from "@/store/StateControllerBase";
import { MapClient } from "@/PlatoAPI";
import { setLoadingState } from "@/store/decorators/setLoadingState";


const mapClient = new MapClient();

class Actions extends StateControllerBase {
  async SET_DATA_LOADING_ERROR(context, value) {
    context.commit("SET_DATA_LOADING_ERROR", value);
  }

  async SET_DATA_LOADING(context, value) {
    context.commit("SET_DATA_LOADING", value);
  }

  @setLoadingState
  async getMapMainParameters({ commit }) {
    let mapMainParameters = await mapClient.getMapMainParameters();
    commit("setMapMainParameters", mapMainParameters);
  }

  @setLoadingState
  async saveMapMainParameters({ dispatch }, mapMainParameters) {
    await mapClient.saveMapMainParameters(mapMainParameters);
    await dispatch("getMapMainParameters");
  }

  @setLoadingState
  async getLayerGroups({ commit }) {
    let layerGroups = await mapClient.getLayerGroups();
    commit("setLayerGroups", layerGroups);
  }

  @setLoadingState
  async getLayerGroup({ commit }, id) {
    let layerGroup = await mapClient.getLayerGroup(id);
    commit("setCurrentLayerGroup", layerGroup);
  }

  resetCurrentLayerGroup({ commit }) {
    commit("setCurrentLayerGroup", null);
  }

  @setLoadingState
  async saveLayerGroups({ dispatch, getters }, layerGroups) {
    let baseLayerGroup = _.find(getters.layerGroups, group => group.id === getters.mapMainParameters.baseLayersGroupId);

    await mapClient.saveLayerGroups([baseLayerGroup, ...layerGroups]);
    await dispatch("getLayerGroups");
  }

  @setLoadingState
  async saveLayerGroup({ dispatch }, layerGroup) {
    await mapClient.saveLayerGroup(layerGroup);
    await dispatch("getLayerGroups");
  }

  @setLoadingState
  async deleteLayerGroup({ dispatch }, id) {
    await mapClient.deleteLayerGroup(id);
    await dispatch("getLayerGroups");
  }

  @setLoadingState
  async getLayer({ commit }, { layerGroupId, layerId }) {
    let layer = await mapClient.getLayer({ layerGroupId, layerId });
    commit("setCurrentLayer", layer);
  }

  resetCurrentLayer({ commit }) {
    commit("setCurrentLayer", null);
  }

  @setLoadingState
  async saveLayer({ dispatch }, { layerGroupId, layer }) {
    await mapClient.saveLayer({ layerGroupId, layer });
    await dispatch("getLayerGroups");
  }

  @setLoadingState
  async deleteLayer({ dispatch }, { layerGroupId, layerId }) {
    await mapClient.deleteLayer({ layerGroupId, layerId });
    await dispatch("getLayerGroups");
  }
}

export default (new Actions).asPlainObject();