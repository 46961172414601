
export default {
  namespaced: true,
  state: {
    changedObjectDataSourceId: null
  },
  getters: {
    changedObjectDataSourceId: state => {
      return state.changedObjectDataSourceId;
    }
  },
  mutations: {
    setChangedObjectDataSourceId: (state, dataSourceId) => {
      state.changedObjectDataSourceId = { dataSourceId };
    }
  },
  actions: {
    async setChangedObjectDataSourceId({ commit }, dataSourceId) {
      commit("setChangedObjectDataSourceId", dataSourceId);
    }
  }
};