<template>
  <div class="plato-inline-edit-linked">
    <div
      v-if="!isTableView"
      class="plato-inline-edit-linked__header"
    >
      {{ _.get(property, "header.name", property.name) }}
    </div>
    <div class="plato-inline-edit-linked__body">
      <template v-if="!isTableView">
        <div
          v-if="isStringView"
          class="plato-inline-edit-linked__string-edit"
          :canEdit="canEditAdvansed"
          @click="stringViewClick()"
        >
          {{ getStringView(values) }}

          <Icon
            class="pencil-icon"
            icon="pen"
          />
        </div>

        <PlatoChipGroup v-else>
          <PlatoChip
            v-for="(item, index) in values"
            :key="`${_uid}-val-${index}`"
            :instance-id="item.instanceId"
            :disabled="!canClick"
            :label="item.value"
            :component-style="item.style"
            :active="isItemActive(item.instanceId)"
            type="custom"
            :show-more-info="showMoreInfo"
            @click="() => onLinkedPropClick(item)"
          />

          <PlatoButtonGroup v-if="showActions">
            <PlatoButton
              v-if="canAdd && createObjectActions.length == 1"
              :icon="'plus'"
              class="plato-context-menu"
              @click="onCreatePropClick(createObjectActions[0].actionId)"
            />
            <PlatoContextMenu
              v-if="canAdd && !_.isEmpty(createObjectActions) && createObjectActions.length > 1"
              icon="plus"
            >
              <div>
                <PlatoButtonGroup
                  vertical
                >
                  <PlatoButton
                    v-for="(action, index) in createObjectActions"
                    :key="`group-btn-${index}`"
                    class="plato-context-menu__btn"
                    type="subtle"
                    @click="onCreatePropClick(action.actionId)"
                  >
                    {{ action.name }}
                  </PlatoButton>
                </PlatoButtonGroup>
              </div>
            </PlatoContextMenu>

            <PlatoButton
              :disabled="!canEditAdvansed"
              icon="pen"
              class="edit-button"
              @click="onAddPropsClick"
            />
          </PlatoButtonGroup>
        </PlatoChipGroup>
      </template>

      <PlatoInlineEditLinkedTable
        v-if="isTableView"
        class="plato-inline-edit-linked-table"
        :property="property"
        :instance-id="_.get(navigationObject, 'instanceId', '')"
        :can-edit-element="canEditElement"
        :can-edit="canEditAdvansed"
        :can-add="canAdd && !_.isEmpty(createObjectActions)"
        :can-click="canClick"
        :data-source-id="dataSourceId"
        :group-by-class="groupByClass"
        :loading="loading"
        :show-more-info="showMoreInfo"
        @selectElement="onLinkedPropClick"
        @createButtonClick="onCreatePropClick"
        @addButtonClick="onAddPropsClick"
      />
    </div>

    <PlatoAside
      v-if="addAsideOpened"
      :ref="`addAside`"
      placement="right"
      width="60%"
      @closed="toogleAside"
    >
      <PlatoLinkedPropEdit
        slot-scope="inner"
        :linked-prop-edit-object="linkedPropEditObject"
        :metadata="metadata"
        @saveLinkedProps="saveLinkedProps"
        @cancelLinkedPropEdit="cancelLinkedPropEdit"
        @close="inner.close()"
      />
    </PlatoAside>
  </div>
</template>

<script>
import PlatoInlineEditLinkedTable from "./PlatoInlineEditLinkedTable.vue";
import PlatoLinkedPropEdit from "../InlineEdit/PlatoLinkedPropEdit/PlatoLinkedPropEdit.vue";
import { annotationProperties, getAnnotationPropertyValue } from "@/components/utils/annotationProperty/AnnotationProperty.js";

import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PlatoInlineEditLinked",

  components: {
    PlatoLinkedPropEdit,
    PlatoInlineEditLinkedTable
  },

  props: {
    property: {
      type: Object,
      default: () => {}
    },
    dataSourceId: {
      type: String,
      default: null
    },
    isTableView: {
      type: Boolean,
      default: false
    },
    showActions: {
      type: Boolean,
      default: true
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canEditElement: {
      type: Boolean,
      default: false
    },
    canClick: {
      type: Boolean,
      default: false
    },
    canAdd: {
      type: Boolean,
      default: false
    },
    metadata: {
      type: Object,
      default: () => {}
    },
    showMoreInfo: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      linkedPropEditObject: null,
      addAsideOpened: false,
      loading: true
    };
  },

  computed: {
    ...mapGetters({
      currentManifest: "MANIFEST",
      navigationObject: "navigationObject/navigationObject"
    }),

    isStringView() {
      return _.get(this.property.field, "stringView", false);
    },

    disableInlineEdit() {
      return _.get(this.property.field, "disableInlineEdit", false);
    },

    canEditAdvansed() {
      return this.canEdit && !this.disableInlineEdit;
    },

    groupByClass() {
      return this.property.field?.group;
    },

    createObjectActions() {
      return _.map(this.property.field?.createCardAction,
        actionId => _.find(this.currentManifest.actions, { actionId }));
    },

    values() {
      let values = _.map(this.property.values, value => {
        let dataSourceId = _.get(this.property, "header.linkedDataSourceId");
        if (this.property.field && Object.prototype.hasOwnProperty.call(this.property.field, "ownPropertyAlias")) {
          let ownPropertyValue = _.find(value, v => v.alias === this.property.field.ownPropertyAlias);
          if (ownPropertyValue) {
            if (dataSourceId) {
              ownPropertyValue.dataSourceId = dataSourceId;
              ownPropertyValue.navType = "dataSource";
            }

            return ownPropertyValue;
          }
        }
        else if (!this.property.field) {
          return value[0];
        }

        let newValue = value[0];
        newValue.value = "(нет данных)";
        return newValue;

        // let currentValue = value[0];

        // if (dataSourceId) {
        //   currentValue.dataSourceId = dataSourceId;
        //   currentValue.navType = "dataSource";
        // }

        // return currentValue;
      });
      return _.sortBy(values, "value");
    }
  },

  async created() {
    this.linkedPropEditObject = {
      prop: this.property,
      datasourceId: this.dataSourceId,
      metadata: this.metadata
    };

    this.loading = false;
  },

  methods: {
    ...mapActions({
      removeNavigationObject: "navigationObject/removeNavigationObject",
      changeNavigationObject: "navigationObject/changeNavigationObject"
    }),

    getEditCardActionId(classId) {
      return _.find(this.property.field?.editCardAction, { classId })?.actionId;
    },

    toogleAside() {
      if (this.addAsideOpened) {
        this.addAsideOpened = false;
      }
      else {
        this.addAsideOpened = true;
        this.$nextTick(() => this.$refs.addAside.open());
      }
    },

    cancelLinkedPropEdit() {
      this.$emit("setLinkedProperties");
      this.addAsideOpened = false;
    },

    onCreatePropClick(actionId) {
      this.$eventBus.$emit("component:action", {
        actionId: actionId,
        saveCallback: this.saveCallback,
        linkedPropertyAlias: this.property.header.alias,
        linkedObjectId: this.$store.getters["currentObject/currentObject"].id,
        linkedDataSourceId: this.dataSourceId
      });
    },

    async saveLinkedProps(saveData) {
      this.$emit("saveField", "linked", saveData.prop, saveData.value);
      this.cancelLinkedPropEdit();
    },

    async saveCallback(id) {
      let deleted = [];

      const cardinality = getAnnotationPropertyValue(this.metadata,
        this.property.header.alias,
        annotationProperties.PLATO_CARDINALITY);

      if (cardinality > 0 && this.property.values.length >= cardinality) {
        let deleteCount = (this.property.values.length - cardinality) + 1;
        deleted = _.chain(this.property.values).slice(0, deleteCount).map(x => x[0].instanceId).value();
      }

      const saveData = {
        value: { added: [id], removed: deleted, objects: [..._.flatMap(this.property.values)] },
        prop: this.property
      };

      await this.saveLinkedProps(saveData);
      this.$emit("saveChangedFields", this.dataSourceId);
    },

    isItemActive(instanceId) {
      return this.navigationObject && this.navigationObject.instanceId === instanceId;
    },

    onLinkedPropClick(item) {
      this.changeNavigationObject({ ...item, editCardAction: this.getEditCardActionId(item.classId) });

      this.$emit("linkedPropertySelected");
    },

    onAddPropsClick() {
      this.linkedPropEditObject = {
        prop: this.property,
        datasourceId: this.dataSourceId,
        metadata: this.metadata
      };

      this.toogleAside();
    },

    stringViewClick() {
      if (this.canEditAdvansed) {
        this.onAddPropsClick();
      }
    },

    getStringView(values) {
      if (_.isEmpty(values)) {
        return "(нет данных)";
      }

      return _.reduce(values, (res, prop) => {
        if (res === "") {
          return res = prop.value;
        }
        else {
          return res += ", " + prop.value;
        }
      }, "");
    }
  }
};
</script>

<style lang="scss" scoped>
.pencil-icon {
  align-items: center;
  margin-left: auto;
  padding: 0 5px;
  opacity: 0;
  font-size: initial;
  display: inline-flex;
  font-size: 11px;
  color: #505f79;
}

.plato-inline-edit-linked {
  &__header {
    font-size: 12px;
    color: #6b778c;
    padding-bottom: 4px;
    padding-left: 6px;
  }

  &__string-edit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 3px;
    padding: 7px;
    width: 100%;

    &:hover[canEdit] {
      background-color: #ebecf0;
      cursor: pointer;

      .pencil-icon {
        opacity: 1;
      }
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
  }

  &-table {
    overflow-x: auto;
    padding-right: 6px;
    margin-right: -6px;
  }

  .table-row-actions-group {
    justify-content: flex-end;

    &__header {
      margin-right: 8px;
    }

    &__body {
      margin-right: 15px;
    }
  }
}
</style>