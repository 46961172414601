<template>
  <div
    :id="_uid"
    class="plato-tags"
  >
    <PlatoTag
      v-for="(tag, index) in visibleValues"
      :key="`${_uid}-field-${index}`"
      :tag="tag"
      :with-close="withClose"
      :show-more-info="showMoreInfo"
      @click-close="$emit('click-close', tag)"
    />

    <PlatoButton
      v-if="visibleCount < tags.length"
      ref="target"
      :active="isOpenPopper"
      type="small"
      icon="ellipsis-h"
      class="plato-tags__more-btn"
      @mouseover.native="isOpenPopper = true"
      @mouseleave.native="isOpenPopper = false"
    >
      <PlatoPopper
        v-if="isOpenPopper"
        slot="after"
        :target-element="$refs.target.$el"
        :target-position="poperPosition"
        :offset="[0, 0]"
      >
        <div class="plato-tags__hidden">
          <div
            v-for="(group, index) in groupedHiddenValues"
            :key="index"
          >
            <div
              v-if="group"
              class="plato-tags__hidden_item_alias"
            >
              {{ index }}
            </div>

            <div class="plato-tags__hidden_item">
              <PlatoTag
                v-for="(tag, i) in group"
                :key="i"
                :tag="tag"
                :hide-popper="true"
                :with-close="withClose"
                :show-more-info="showMoreInfo"
                @click-close="$emit('click-close', tag)"
              />
            </div>
          </div>
        </div>
      </PlatoPopper>
    </PlatoButton>
  </div>
</template>

<script >
import _ from "lodash";

export default {
  name: "PlatoTags",
  props: {
    /**
     * Массив тэгов
     * [
     *  {
     *    value: "10м", // (Обязательный) значение которое будет выводиться в теге
     *    alias: "Расстояние", // Которое будет выводиться в popper слевой стороны
     *    color: "standart", // Окончание классового названия цвета тэга `plato-tag--${color}`
     *  },
     *  ...
     * ]
     */
    tags: {
      type: Array,
      required: true
    },
    /**
     * Создает тэги с кнопкой закрытия отправляющей emit "click-close" со всей инормацией о теге
     */
    withClose: {
      type: Boolean,
      default: false
    },
    /**
     * Ширина отведенная для контейнера с тэгами
     */
    width: {
      type: String,
      default: "100%"
    },
    visibleElementCount: {
      type: Number,
      default: 20
    },
    showMoreInfo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      /**
       * Изменяется при изменении размеров и передается в popper
       * скрытых теговб чтобы при передвижении спдитера перемещался popper
       */
      poperPosition: 0,
      // popper для тегов при наведении
      popperInstance: null,
      // Состояние popper скрытых тегов
      isOpenPopper: false,
      /**
       * Точки на которых заканчиваются теги,  индексы совпадают с индексами массива tags
       * [ 80, 160, 240, ... ]
       */
      breakPoints: [],
      //Количество видимых тегов
      // Ширина клавиши для показа всех тегов
      moreBtnWidth: 24,
      //ResizeObserver для отслеживания изменений контейнера
      // ro: null,
      visibleCount: 20
    };
  },
  computed: {
    // Теги которые отображаются
    visibleValues() {
      return this.tags.slice(0, this.visibleCount);
    },

    // Теги которые спрятаны в popper
    hiddenValues() {
      return this.tags.slice(this.visibleCount);
    },

    groupedHiddenValues() {
      return _.groupBy(this.hiddenValues, "alias");
    }
  },
  watch: {
    tags: function() {
      this.refreshBreakPoints();
    },
    width: function() {
      this.refreshBreakPoints();
    }
  },
  mounted() {
    this.visibleCount = this.visibleElementCount;
    let mainContainer = document.getElementById(this._uid);
    if (mainContainer) {
      //this.checkOverflow();
    }
  },
  methods: {
    // Устанавливает сколько элементов видны в значение visibleCount
    checkOverflow() {
      const overflowContainer = document.getElementById(this._uid);
      if (overflowContainer) {
        if (
          overflowContainer.clientWidth <
          this.breakPoints[this.visibleCount - 1] + this.moreBtnWidth
        ) {
          while (
            this.visibleCount >= 0 &&
            overflowContainer.clientWidth <
              this.breakPoints[this.visibleCount - 1] + this.moreBtnWidth
          ) {
            this.visibleCount -= 1;
          }
        }
        else {
          while (
            overflowContainer.clientWidth >
              this.breakPoints[this.visibleCount] + this.moreBtnWidth &&
            this.visibleCount < this.tags.length
          ) {
            this.visibleCount += 1;
          }
        }
      }
    },
    /**
     *  Обновление точек на которых заканчиваются тег
     *  заносит полученые значения в массив breakPoints
     *
     */
    refreshBreakPoints() {
      this.visibleCount = this.tags.length;
      if (!_.isEmpty(this.tags)) {
        this.$nextTick(() => {
          const tagContainer = document.getElementById(this._uid);
          if (tagContainer) {
            const tags = tagContainer.querySelectorAll(".plato-tag");
            _.each(tags, (tag, index) => {
              let prevSize = index ? this.breakPoints[index - 1] : 0;
              let style = window.getComputedStyle(tag);

              this.breakPoints[index] =
                prevSize +
                tag.scrollWidth +
                parseFloat(style.marginLeft) +
                parseFloat(style.marginRight);
            });
            //this.checkOverflow();
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.plato-tags {
  white-space: nowrap;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
  min-width: 24px;
  &__more-btn {
    &.plato-btn.plato-btn--small {
      height: 18px;
      min-height: auto;
    }
  }
  &__hidden {
    &_item {
      align-items: flex-start;
      font-weight: normal;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      & ~ & {
        text-align: left;
        margin-top: 10px;
      }
      &_alias {
        margin-right: 15px;
        font-size: 12px;
        font-weight: normal;
        color: #6b778c;
        white-space: break-spaces;
        text-align: left;
        line-height: 1.5;
      }
      .plato-tag {
        height: auto;
        font-weight: normal;
        &__label {
          white-space: break-spaces;
          text-align: left;
        }
      }
    }
  }
}
</style>

