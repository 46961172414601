<template>
  <div
    v-if="appliedView"
    class="applied-view"
  >
    <div
      v-if="activeFilters.length > 0"
      class="applied-view_block"
    >
      <div class="applied-view_block-name">
        Фильтры
      </div>

      <div class="applied-view_block-properties">
        <div
          v-for="filter in activeFilters"
          :key="`activeFilter_${filter.alias}`"
          class="applied-view_block-property"
        >
          <div class="applied-view_block-property-name">
            {{ filter.name }}
          </div>

          <div
            v-for="(value, index) in filter.value"
            :key="`value_${index}`"
            class="applied-view_block-property-value"
          >
            {{ value.display }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="appliedSorting.length > 0"
      class="applied-view_block"
    >
      <div class="applied-view_block-name">
        Сортировка
      </div>

      <div>
        <div
          v-for="sorting in appliedSorting"
          :key="`appliedSorting_${sorting.alias}`"
          class="applied-view_block-property-value applied-view_block-property-inline"
        >
          {{ fieldName(sorting) }}:
          {{ sorting.sortType === "asc" ? "По возрастанию" : "По убыванию" }}
        </div>
      </div>
    </div>

    <div
      v-if="showGrouping"
      class="applied-view_block"
    >
      <div class="applied-view_block-name">
        Группировка
      </div>

      <div
        class="applied-view_block-property-value applied-view_block-property-inline"
      >
        {{ grouping.label }}
      </div>
    </div>

    <div
      v-if="columns.length > 0 "
      class="applied-view_block"
    >
      <div class="applied-view_block-name">
        Столбцы
      </div>

      <div class="applied-view_block-properties">
        <div>
          <div class="applied-view_block-property-name">
            Видимые
          </div>

          <div
            v-for="column in visibleColumns"
            :key="`column_${column.property}`"
            class="applied-view_block-property-value"
          >
            {{ column.label }}
          </div>
        </div>


        <div
          v-if="hiddenColumns.length > 0"
        >
          <div class="applied-view_block-property-name">
            Скрытые
          </div>

          <div
            v-for="column in hiddenColumns"
            :key="`column_${column.property}`"
            class="applied-view_block-property-value"
          >
            {{ column.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <span v-else>
    Не заданы параметры фильтрации и сортировки
  </span>
</template>
<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  props: {
    dataSourceId: {
      type: String,
      required: true
    },

    tableColumns: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapGetters({
      hiddenColumns: "grouping/hiddenColumns"
    }),

    grouping() {
      return this.$store.getters["grouping/grouping"](this.dataSourceId);
    },

    showGrouping() {
      if (_.isEmpty(this.grouping) || _.isEmpty(this.grouping.propertyAlias)) {
        return false;
      }
      return true;
    },

    appliedSorting() {
      return this.$store.getters["sorting/sorting"](this.dataSourceId);
    },
    activeFilters() {
      return this.$store.getters["filters/activeFilters"](this.dataSourceId);
    },
    appliedView() {
      return !_.isEmpty(this.activeFilters) || !_.isEmpty(this.appliedSorting) || !_.isEmpty(this.tableColumns) || this.grouping.label !== "";
    },

    columns() {
      let columns = _.cloneDeep(this.tableColumns);
      columns.forEach(column => {
        if (_.some(this.hiddenColumns, hiddenColumn => column.property == hiddenColumn.property && column.ownProperty == hiddenColumn.ownProperty)) {
          column.hidden = !column.hidden;
        }
      });
      return columns;
    },

    visibleColumns() {
      return _.filter(this.columns, column => column.hidden !== true);
    }
  },

  methods: {
    fieldName(field) {
      return _.isEmpty(field.parentName) ? field.name : `${field.parentName}: ${field.name}`;
    }
  }
};
</script>
<style lang="scss" scoped>
.applied-view {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap:25px;

  &_block{
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap:5px;

    &-name{
      font-size: 14px;
      font-weight: 600;
      padding: 5px 7px;
      min-width: 140px;
    }

    &-properties{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 0 0 15px;
      gap: 20px;
    }

    &-property{
      flex: 1 0 40%;

      &-name{
        font-size: 12px;
        color: #6b778c;
        padding-left: 5px;
        margin-bottom: 4px;
      }

      &-value{
        font-size: 14px;
        padding: 5px;
        display: flex;
        align-items: center
      }

      &-inline{
        padding: 5px 5px 5px 20px;
      }
    }
  }
}
</style>