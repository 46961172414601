export default {
  AuthenticationClient: {
    refresh: "Не удалось восстановить сессию",
    getCurrentUser: "Не удалось получить информацию о текущем пользователе"
  },
  ApplicationClient: {
    getAllApplications: "Не удалось загрузить список приложений",
    getAplication: "Не удалось загрузить приложение",
    saveApplication: "Не удалось сохранить приложение",
    updateApplication: "Не удалось обновить приложение",
    deleteApplication: "Не удалось удалить приложение"
  },
  DataClient: {
    updateData: "Не удалось обновить данные",
    updateMultipleData: "Не удалось обновить данные",
    insertData: "Не удалось добавить данные",
    getDataByAlias: "Не удалось получить данные по свойству",
    getData: "Не удалось получить данные",
    getMetadata: "Не удалось получить метаданные",
    getHintData: "Не удалось получить данные для всплывающего окна",
    getInstance: "Не удалось получить объект",
    deleteInstance: "Не удалось удалить объект",
    deleteInstances: "Не удалось удалить объекты"
  },
  DataSourceClient: {
    saveDataSource: "Не удалось сохранить источник данных",
    deleteDataSource: "Не удалось удалить источник данных",
    getDataSource: "Не удалось получить источник данных",
    getAllDataSourcesInfo: "Не удалось получить информацию по источникам данных",
    getAllDataSources: "Не удалось получить список источников данных",
    saveFilterTemplate: "Не удалось сохранить шаблон фильтра",
    deleteFilterTemplate: "Не удалось удалить шаблон фильтра",
    getAllFilterTemplates: "Не удалось получить список шаблонов фильтров"
  },
  ExploreClient: {
    getAllAnnotationProperties: "Не удалось получить список annotation properties",
    getAllDataProperties: "Не удалось получить список data properties",
    getAllObjectProperties: "Не удалось получить список object properties",
    getAllClasses: "Не удалось получить список классов",
    getClass: "Не удалось получить класс",
    getInstanceById: "Не удалось получить instance",
    getInstances: "Не удалось получить список instances"
  },
  FileClient: {
    listFiles: "Не удалось получить список файлов",
    deleteFile: "Не удалось удалить файл",
    createFile: "Не удалось сохранить файл",
    upload: "Не удалось обновить файл"
  },
  ShellClient: {
    getShellManifest: "Не удалось получить настройки оболочки",
    getShellPage: "Не удалось получить страницу",
    saveShellPage: "Не удалось сохранить страницу",
    deleteShellPage: "Не удалось удалить страницу",
    getShellPages: "Не удалось получить страницы",
    getShellPagesInfo: "Не удалось получить страницы",
    getVersions: "Не удалось получить версию платформы"
  },
  default: "Ошибка при обработке запроса"
};