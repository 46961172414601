import actions from "./actions";

import _ from "lodash";

const getDefaultState = () => {
  return {
    linkedProps: [],
    dataLoading: null,
    dataLoadingError: null,
    skip: 0,
    take: 20,
    takePeriod: 20,
    sorting: []
  };
};

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    dataLoading: state => {
      return state.dataLoading;
    },

    dataLoadingError: state => {
      return state.dataLoadingError;
    },

    linkedProps: state => {
      return _.get(state.linkedProps, "items", []);
    },

    totalCount: state => {
      return _.get(state.linkedProps, "totalCount", 0);
    },

    skip: state => {
      return state.skip;
    },

    take: state => {
      return state.take;
    },

    sorting: state => {
      return state.sorting;
    }
  },

  mutations: {
    setDataLoading: (state, value) => {
      state.dataLoading = value;
    },

    setDataLoadingError: (state, value) => {
      state.dataLoadingError = value;
    },

    setLinkedProps: (state, value) => {
      state.linkedProps = value;
    },

    increaseTake: state => {
      state.take += state.takePeriod;
    },

    resetTake: state => {
      state.take = state.takePeriod;
    },

    unshiftSorting: (state, data) => {
      let sortIndex = _.findIndex(state.sorting, { alias: data.alias });
      if (sortIndex != -1) {
        state.sorting.splice(sortIndex, 1);
      }
      state.sorting.unshift(data);
    },

    addSorting: (state, sorting) => {
      state.sorting.push(sorting);
    },

    removeSorting: (state, alias) => {
      let sortIndex = _.findIndex(state.sorting, { alias });
      if (sortIndex != -1) {
        state.sorting.splice(sortIndex, 1);
      }
    },

    setSortingType: (state, data) => {
      let appliedSorting = _.find(state.sorting, { alias: data.alias });
      appliedSorting.sortType = data.sortType;
    },

    resetState: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions
};