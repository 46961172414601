import StateControllerBase from "@/store/StateControllerBase";

class Actions extends StateControllerBase {
  async SET_DATA_LOADING_ERROR(context, value) {
    context.commit("setDataLoadingError", value);
  }

  async SET_DATA_LOADING(context, value) {
    context.commit("setDataLoading", value);
  }

  async editSearchKeyword({ dispatch, commit }, { searchInfo, refreshUrl }) {
    if (searchInfo.searchKeyword) {
      commit("setSearchKeyword", searchInfo);
    }
    else {
      commit("removeSearchKeyword", searchInfo.dataSourceId);
    }

    if (refreshUrl) {
      dispatch("refreshUrl", { pushToHistory: false }, { root: true });
    }
  }

  setSearchKeywords({ commit }, searchKeywords) {
    commit("setSearchKeywords", searchKeywords);
  }

  resetState(context) {
    context.commit("resetState");
  }
}

export default (new Actions).asPlainObject();