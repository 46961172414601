<template>
  <div class="plato-component-header__left">
    <div class="plato-component-header__left_title">
      {{ title }}
    </div>
    <div
      v-if="subtitle"
      class="plato-component-header__left_subtitle"
    >
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: [String, Number],
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.plato-component-header {
  &__left {
    &_title {
      font-size: 20px;
      font-weight: 500;
    }
    &_subtitle {
      font-size: 12px;
      color: #6b778c;
    }
  }
}
</style>