import httpClient from "./DefaultHttpClient";
import { rethrowPlatoApiError } from "./PlatoApiError";

const BASE_PATH = "/v1/AuthenticationApi";

class AdminClient {
  @rethrowPlatoApiError
  async getUsers(body) {
    return httpClient.post(`${BASE_PATH}/users`, body)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async saveUser(user) {
    return httpClient.post(`${BASE_PATH}/user`, user)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getUser(userId) {
    return httpClient.get(`${BASE_PATH}/user/${userId}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async deleteUser(userId) {
    return httpClient.delete(`${BASE_PATH}/user/${userId}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getServices(body) {
    return httpClient.post(`${BASE_PATH}/services`, body)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async saveService(service) {
    return httpClient.post(`${BASE_PATH}/service`, service)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getService(serviceId) {
    return httpClient.get(`${BASE_PATH}/service/${serviceId}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async deleteService(serviceId) {
    return httpClient.delete(`${BASE_PATH}/service/${serviceId}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getRoles(body) {
    return httpClient.post(`${BASE_PATH}/roles`, body)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async saveRole(role) {
    return httpClient.post(`${BASE_PATH}/role`, role)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async deleteRole(roleId) {
    return httpClient.delete(`${BASE_PATH}/role/${roleId}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getPermissions() {
    return httpClient.get(`${BASE_PATH}/permissions`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getAccesses() {
    return httpClient.get(`${BASE_PATH}/accesses`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async savePermission(permission) {
    return httpClient.post(`${BASE_PATH}/permissions`, permission)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async deletePermission(permissionId) {
    return httpClient.delete(`${BASE_PATH}/permission/${permissionId}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async saveAccess(permission) {
    return httpClient.post(`${BASE_PATH}/access`, permission)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async deleteAccess(accessId) {
    return httpClient.delete(`${BASE_PATH}/access/${accessId}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getScopes() {
    return httpClient.get(`${BASE_PATH}/scopes`)
      .then(response => response.data);
  }
}

export default AdminClient;