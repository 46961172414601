import _ from "lodash";

export default {
  namespaced: true,
  state: () => ({
  }),
  getters: {
    all(state, getters, rootState, rootGetters) {
      let currentSummary = rootGetters["navigator/currentSummary"];
      return currentSummary?.annotationProperties ?? [];
    },
    current(state, getters) {
      return annotationPropertyId => _.find(getters["all"], x => x.id == annotationPropertyId);
    }
  },
  mutations: {
    setValue(state, { existingProperty, value }) {
      existingProperty.value = value;
    },
    addValue(state, { properties, newProperty }) {
      properties.push(newProperty);
    },
    removeEmpty(state, { properties }) {
      _.remove(properties, x => (!x.value || x.value.length === 0));
    }
  },
  actions: {
    setValue({ commit, getters }, { id, type, value }) {
      let existingProperty = getters["current"](id);
      if (existingProperty) {
        commit("setValue", { existingProperty, value });
      }
      else {
        let properties = getters["all"];
        let newProperty = { id, type, value };
        commit("addValue", { properties, newProperty });
      }
      commit("removeEmpty", getters["all"]);
    }
  }
};