export const SORT_TYPES = {
  None: "none",
  Unset: "unset",
  Asc: "asc",
  Desc: "desc"
};

export const SORT_TYPES_NUMS = {
  None: 0,
  Unset: 1,
  Asc: 2,
  Desc: 3
};

export const SORT_TYPES_LABELS = {
  [SORT_TYPES.None]: "Без сортировки",
  [SORT_TYPES.Unset]: "Сортировка по выбору пользователя",
  [SORT_TYPES.Asc]: "Сортировка по возрастанию",
  [SORT_TYPES.Desc]: "Сортировка по убыванию"
};

export const SORT_TYPES_LABELS_NUMS = {
  [SORT_TYPES_NUMS.None]: "Без сортировки",
  [SORT_TYPES_NUMS.Unset]: "Сортировка по выбору пользователя",
  [SORT_TYPES_NUMS.Asc]: "Сортировка по возрастанию",
  [SORT_TYPES_NUMS.Desc]: "Сортировка по убыванию"
};