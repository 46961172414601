<template>
  <div
    v-if="alive"
    ref="notification"
    :class="classObj"
    :style="styleObj"
  >
    <div
      class="notification-dialog"
      :style="{'width': width }"
    >
      <div class="notification-content">
        <div :class="`notification-content-inner-${type}`">
          <div
            :class="`notification-content-inner-${type}-left`"
          >
            <Icon :icon="`${notificationIconType}`" />
          </div>
          <div
            :class="`notification-content-inner-${type}-right`"
          >
            <div>
              <div
                :class="`notification-content-inner-${type}-right-title`"
              >
                {{ title }}
              </div>
              <div
                v-if="message"
                :class="`notification-content-inner-${type}-right-message`"
                v-html="message"
              />
            </div>
            <div
              :class="`notification-content-inner-${type}-right-close`"
            >
              <PlatoButton
                type="close-action"
                icon="times"
                @click="close"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import element from "../utils/element";

export default {
  name: "Notification",
  props: {
    title: {
      type: String,
      default: "",
      required: false
    },
    message: {
      type: String,
      default: "",
      required: false
    },
    show: {
      type: Boolean,
      default: false,
      required: false
    },
    width: {
      type: String,
      default: "440px",
      required: false
    },
    effect: {
      type: String,
      default: "fade-right",
      required: false
    },
    type: {
      type: String,
      default: "default",
      required: false
    },
    duration: {
      type: Number,
      default: 0,
      required: false
    }
  },
  data() {
    const show = this.show;
    return {
      isShow: show,
      alive: true
    };
  },
  computed: {
    classObj() {
      const { effect, type } = this;
      const classes = {};

      classes["notification"] = true;
      classes["notification-" + type] = true;
      classes["notification-" + effect] = true;

      return classes;
    },
    styleObj() {
      return {};
    },
    notificationIconType() {
      const { type } = this;

      switch (type) {
      case "default":
        return "home";
      case "warning":
        return "exclamation-triangle";
      case "danger":
        return "exclamation-triangle";
      case "success":
        return "thumbs-up";
      case "info":
        return "info-circle";
      }

      return "info-circle";
    }
  },
  watch: {
    isShow(val) {
      /**
       * Stackable logic
       */
      if (val) {
        const x = document.getElementsByClassName(
          "notification-in"
        );
        this.numberOfParentModals = x.length;
        const distanceToMove = 5;
        if (this.numberOfParentModals > 0) {
          for (let i = 0; i < this.numberOfParentModals; i++) {
            const currentMarginTop = x[i].style["bottom"];
            if (currentMarginTop && currentMarginTop !== "0px") {
              const m = Math.abs(currentMarginTop.slice(0, -2));
              const dist = parseInt(m + distanceToMove);
              x[i].style["bottom"] = "-" + dist + "px";
              x[i].style["right"] = "-" + dist + "px";
            }
            else {
              x[i].style["bottom"] = distanceToMove * -1 + "px";
              x[i].style["right"] = distanceToMove * -1 + "px";
            }
          }
        }
      }
      else {
        const x = document.getElementsByClassName(
          "notification-in"
        );
        this.numberOfParentModals = x.length;
        const distanceToMove = 5;
        if (this.numberOfParentModals > 0) {
          for (let i = 0; i < this.numberOfParentModals; i++) {
            const currentMarginTop = x[i].style["bottom"];
            if (currentMarginTop && currentMarginTop !== "0px") {
              const m = Math.abs(currentMarginTop.slice(0, -2));
              const dist = parseInt(m - distanceToMove);
              x[i].style["bottom"] = "-" + dist + "px";
              x[i].style["right"] = "-" + dist + "px";
            }
          }
        }
      }

      /**
       * Classes
       */
      const el = this.$el;
      const body = document.body;
      if (val) {
        this.$emit("show", { type: "show" });
        el.querySelector("." + "notification");
        el.style.display = "flex";
        // timeout required for opacity transition
        setTimeout(() => {
          element.addClass(el, "notification-in");
        }, 20);
        element.addClass(body, "notification-open");
      }
      else {
        this.$emit("hide", { type: "hide" });
        element.removeClass(el, "notification-in");
        element.addClass(el, "notification-out");
        setTimeout(() => {
          el.style.display = "none";
          element.removeClass(body, "notification-open");
          element.removeClass(el, "notification-out");
          this.$emit("closed", { type: "closed" });
        }, 300);
      }
    }
  },
  mounted() {
    if (this.duration) {
      setTimeout(() => {
        this.close();
      }, this.duration);
    }
  },
  methods: {
    close() {
      this.isShow = false;
      setTimeout(() => {
        this.alive = false;
      }, 500);
    },
    open() {
      this.isShow = true;
    },
    confirm() {
      this.$emit("confirm", { type: "confirm" });
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/colors.scss";

.notification {
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 3002;
  display: none;
  overflow: auto;
  outline: 0;
  transition: all 0.15s ease;

  &-dialog {
    position: relative;
    width: auto;
    margin: 25px;
  }

  &-content {
    position: relative;
    background-color: $N0;
    background-clip: padding-box;
    outline: 0;
    box-shadow: none;
    border: none;
  }

  /**
   * Expands to notification-fade notification-in
   */
  &-fade#{&}-in {
    opacity: 1 !important;
  }

  /**
   * fade-right
   */
  &-fade-right &-content {
    opacity: 0;
    transform: translateX(20px);
    transition: transform 0.15s, opacity 0.15s;
  }

  &-fade-right#{&}-in &-content {
    opacity: 1;
    transform: translateX(0);
    box-shadow: rgba(9, 30, 66, 0.08) 0px 1px 2px 0px,
      rgba(9, 30, 66, 0.08) 0px 2px 4px, rgba(9, 30, 66, 0.31) 0px 3px 20px -5px;
  }

  &-fade-right#{&}-out &-content {
    opacity: 0;
    transform: translateX(20px);
  }

  @mixin notification-content-inner-mixin($type, $borderColor, $iconColor) {
    .notification-content-inner-#{$type} {
      display: flex;
      flex-direction: row;
      &-left {
        flex-basis: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        text-align: center;
        i {
          color: $iconColor;
        }
      }
      &-right {
        flex: 1;
        padding: 20px;
        display: inline-flex;
        width: 100%;
        justify-content: space-between;

        &-title {
          font-weight: bold;

          &:last-child {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
          }
        }

        &-message {
          margin-top: 5px;
        }
      }
    }
  }

  @mixin notification-content-mixin(
    $type,
    $borderSize,
    $borderColor,
    $iconColor
  ) {
    .notification-content {
      border: $borderSize solid $borderColor;
      border-radius: 10px
    }
    @include notification-content-inner-mixin($type, $borderColor, $iconColor);
  }

  &-warning {
    @include notification-content-mixin('warning', 2px, $Y300, $Y400);
  }

  &-danger {
    @include notification-content-mixin('danger', 2px, $R300, $R400);
  }

  &-success {
    @include notification-content-mixin('success', 2px, $G300, $G400);
  }

  &-info {
    @include notification-content-mixin('info', 2px, $B50, $B200);
  }
}
</style>
