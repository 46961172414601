<template>
  <span
    ref="title"
    @mouseover="isShowPopper = true"
    @mouseout="isShowPopper = false"
  >
    <Icon
      v-if="iconClass"
      :icon="iconClass"
    />
    <span :class="innerSpanClass">
      {{ value }}
    </span>
    <PlatoPopper
      v-if="isShowPopper"
      :offset="[0, 8]"
      :target-element="$refs.title"
      :placement="popperPlacement"
    >
      <div class="plato-popper__body">
        <div
          v-if="alias"
          class="plato-popper__body_prop-alias"
        >
          {{ alias }}
        </div>
        <div class="plato-popper__body_prop-value">
          {{ value }}
        </div>
      </div>
    </PlatoPopper>
  </span>
</template>

<script>
export default {
  props: {
    /**
     * Значение поля
     */
    value: {
      type: String
    },
    /**
     * Значение поля
     */
    alias: {
      type: String
    },
    /**
     * Класс внутреннего span
     */
    innerSpanClass: {
      type: String
    },
    /**
     * Место расположения popper
     */
    popperPlacement: {
      type: String,
      default: "top"
    },
    /**
     * Класс иконки перед значением
     */
    iconClass: {
      type: String
    }
  },
  data() {
    return {
      isShowPopper: false
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
@import "../styles/variables.scss";
.plato-popper {
  max-width: 300px;
}
</style>

