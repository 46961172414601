import _ from "lodash";
import { CancelTokenSource } from "../PlatoAPI";

export default {
  state: () => ({
    requestQueue: []
  }),

  getters: {
    inQueue: state => dataSourceId => {
      return _.some(state.requestQueue, { dataSourceId });
    },

    tokenSource: state => dataSourceId => {
      return _.find(state.requestQueue, { dataSourceId })?.cancelTokenSource;
    }
  },

  mutations: {
    addToQueue(state, dataSourceToken) {
      state.requestQueue.push(dataSourceToken);
    },

    removeFromQueue(state, dataSourceId) {
      _.remove(state.requestQueue, { dataSourceId });
    }
  },

  actions: {
    cancelRequest({ getters, commit }, dataSourceId) {
      getters.tokenSource(dataSourceId)?.cancel();
      commit("removeFromQueue", dataSourceId);
    },

    async requestDataSourceData({ dispatch, commit, getters }, { dataSourceId, cancelRequest }) {
      console.debug("Запрошена загрузка источника данных", dataSourceId);

      if (cancelRequest) {
        if (getters.inQueue(dataSourceId)) {
          console.debug("Источник данных уже в очереди (отмена запроса)", dataSourceId);
          dispatch("cancelRequest", dataSourceId);
        }
      }
      else {
        if (_.get(getters["DATA"](dataSourceId), "serverData")) {
          console.debug("Источник данных уже был загружен", dataSourceId);
          return;
        }

        if (getters.inQueue(dataSourceId)) {
          console.debug("Источник данных уже в очереди", dataSourceId);
          return;
        }
      }

      console.debug("Начата загрузка источника данных", dataSourceId);

      const cancelTokenSource = new CancelTokenSource();
      const cancelToken = cancelTokenSource.token;

      commit("addToQueue", { dataSourceId, cancelTokenSource });

      try {
        await dispatch("GET_CURRENT_DATA", { dataSourceId, cancelToken });
      }
      catch (error) {
        console.debug(error, error.inner);

        if (!(CancelTokenSource.isCancel(error) || CancelTokenSource.isCancel(error.inner))) {
          throw error;
        }
      }
      finally {
        commit("removeFromQueue", dataSourceId);
      }
    }
  }
};