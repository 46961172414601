<template>
  <div class="filter-table">
    <div class="filter-table__header">
      <PlatoSearch
        v-model="search"
        @input="searchChange"
      />
    </div>

    <div class="filter-table__body">
      <Table
        :columns="tableColumns"
        :elements="filterOptions"
        :selected="selected"
        select-by="value"
        :checkboxes="true"
        :hide-head="true"
        :show-select-all="false"
        @change="onSelected"
      />
    </div>

    <div
      v-if="dataLoading || take < totalCountFilterOptions"
      class="filter-table__paginator"
    >
      <PlatoLoader
        v-if="dataLoading"
        :backdrop="0"
        mini
      />
      <PlatoButton
        v-else
        class="filter-table__paginator-button"
        type="subtle"
        @click="increaseTake"
      >
        Показать ещё
      </PlatoButton>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

import { mapGetters } from "vuex";

export default {
  props: {
    filter: {
      type: Object,
      required: true
    },
    dataSourceId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      search: "",
      selected: [],
      tableColumns: [{
        label: this.filter.name,
        property: "display" }]
    };
  },

  computed: {
    ...mapGetters({
      dataLoading: "filters/dataLoading"
    }),
    filterOptions() {
      let elements = this.$store.getters["filters/linkedPropertyFilterOptions"](this.filter.alias);
      return _.sortBy(elements, ["display"]);
    },
    isActiveFilter() {
      let filters = this.$store.getters["filters/filters"](this.dataSourceId);
      return _.some(filters, { alias: this.filter.alias });
    },

    take() {
      return this.$store.getters["filters/take"](this.filter?.alias);
    },

    totalCountFilterOptions() {
      return this.$store.getters["filters/totalCount"](this.filter?.alias);
    },

    searchOptions() {
      return this.search ? _.filter(this.filterOptions, opt => _.includes(_.toLower(opt.display), _.toLower(this.search))) : this.filterOptions;
    }
  },

  watch: {
    isActiveFilter(newValue) {
      if (!newValue) {
        this.selected = [];
      }
    }
  },

  async mounted() {
    this.selected = _.map(this.filter.value, "value");
    await this.resetTake();
    await this.getFilterDataSourceItems();
  },

  methods: {
    async getFilterDataSourceItems() {
      this.loading = true;
      await this.$store.dispatch("filters/getLinkedPropertyFilter", { dataSourceId: this.dataSourceId, alias: this.filter.alias, properties: this.filter.properties, searchKeyword: this.search });
      this.loading = false;
    },

    searchChange: _.debounce(function() {
      this.getFilterDataSourceItems();
    }, 750, { leading: false, trailing: true }),

    increaseTake() {
      this.$store.dispatch("filters/increaseTake", { dataSourceId: this.dataSourceId, alias: this.filter.alias, properties: this.filter.properties });
    },

    async resetTake() {
      await this.$store.dispatch("filters/resetTake", { alias: this.filter.alias });
    },

    async onSelected(selectedOptions) {
      let filters = [];
      selectedOptions.forEach(s => {
        let filter = _.find(this.filterOptions, { "value": s });
        filters.push(filter);
      });

      this.selected = selectedOptions;

      this.$emit("filterChange", {
        alias: this.filter.alias,
        name: this.filter.name,
        value: filters,
        operator: 0,
        type: "SelectionDynamic",
        active: true
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-table {
  &__name{
    padding: 10px 0;
    font-size: 16px;
  }

  &__header{
    position: sticky;
    top: 36px;
    background-color: #ffffff;
    z-index: 1;
  }

  &__body{
    width:100%
  }

  &__paginator {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 20px 0 0 0;
    position: relative;

    &-button {
      width: 100%;

    }
  }
}
</style>
