<template>
  <div
    :title="view.name"
    class="view-tab"
    :class="isExistView ? '' : 'view-tab-no-icon' "
    :active="isViewActive"
    @click="setDataView"
    @mouseover="showMoveIcon = true"
    @mouseleave="showMoveIcon = false"
  >
    <Icon
      v-if="isExistView"
      class="handle-item"
      icon="grip-dots-vertical"
    />
    <div
      class="view-tab-name"
    >
      {{ name }}
    </div>
  </div>
</template>
<script>
import dataViewMixin from "../dataViewMixin.js";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [dataViewMixin],

  data() {
    return {
      showMoveIcon: false
    };
  },

  computed: {
    ...mapGetters("dataView", [
      "dataViewName"
    ]),

    name() {
      if (this.isNewView && this.isViewActive && this.dataViewName !== "") {
        return this.dataViewName;
      }
      return this.view.name;
    },

    isExistView() {
      return !this.isNewView && this.writeAccess && this.showMoveIcon;
    }
  },

  mounted() {
    if (this.isViewActive) {
      this.setEditDefaultView(false);
      this.setEditOperation("");
    }
  },

  methods: {
    ...mapActions("dataView", [
      "setEditDefaultView",
      "setEditOperation"
    ])
  }
};
</script>
<style lang="scss" scoped>
@import "@/components/package/styles/colors.scss";
@import "@/components/package/styles/variables.scss";
.view-tab{
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap:10px;
  user-select:none;

  padding: 10px;
  width: 257px;

  &-name{
    max-width: 230px;
    max-height: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &[active] {
    background-color: #deebff;
  }

  &:hover:not([active=true]){
    background-color: hsla(201, 100%, 94%, 0.3);
  }

  &-no-icon{
    padding-left: 27px ;
    width: 240px;
  }
}
.handle-item{
  cursor: move
}
</style>