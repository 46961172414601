<template>
  <li
    class="plato-menu-item"
    :class="{
      'plato-menu-item--active': isActive,
    }"
  >
    <div
      v-if="items"
      class="plato-menu-item__body"
      @click.stop.prevent="showSubitems = !showSubitems"
    >
      <div
        :title="title"
        class="plato-menu-item__body_icon"
      >
        <Icon :icon="iconClass" />
      </div>
      <span class="plato-menu-item__body_text">{{ title }}</span>
      <span class="plato-menu-item__body_collapse">
        <Icon
          v-if="!showSubitems"
          icon="chevron-left"
        />
        <Icon
          v-else
          icon="chevron-down"
        />
      </span>
    </div>
    <RouterLink
      v-else
      :to="href"
      class="plato-menu-item__body"
      @click.native="$emit('link-click', href)"
    >
      <div
        :title="title"
        class="plato-menu-item__body_icon"
      >
        <Icon :icon="iconClass" />
      </div>
      <span class="plato-menu-item__body_text">{{ title }}</span>
    </RouterLink>
    <ul
      v-if="items && showSubitems"
      class="plato-menu-item__subitems"
    >
      <li
        v-for="(item, index) in items"
        :key="_uid + '-sub-' + index"
        class="plato-menu-item__subitem"
        :class="{
          'plato-menu-item__subitem--active': item.href == `${$route.path}`,
        }"
      >
        <RouterLink
          :to="item.href"
          class="plato-menu-item__subitem_body"
          @click.native="$emit('link-click', item.href)"
        >
          <div
            :title="item.title"
            class="plato-menu-item__subitem_body_icon"
          >
            <Icon :icon="item.icon" />
          </div>
          <span class="plato-menu-item__subitem_body_text">
            {{ item.title }}
          </span>
        </RouterLink>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  name: "PlatoNavMenuItem",
  props: ["title", "iconClass", "isActive", "href", "items", "isOpened"],
  data() {
    return {
      showSubitems: this.isOpened
    };
  },
  watch: {
    showSubitems() {
      if (this.showSubitems) {
        this.$el.scrollIntoView({ behavior: "smooth", alignToTop: false });
      }
    }
  }
};
</script>

<style lang="scss">
@import "../styles/colors.scss";
@import "../styles/variables.scss";

$icon-width: 40px;
$logo-width: 40px;

//Элемент меню
.plato-menu-item {
  a {
    text-decoration: none;
  }

  &__body {
    font-size: 15px;
    position: relative;
    padding: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: $main-text-color;
    width: $menu-width - 30px;
    transition: background-color 0.08s linear;

    &:has(.plato-menu-item__body_collapse) {
      .plato-menu-item__body_text {
        padding-right: 20px;
      }
    }

    &:hover {
      background-color: $hovered-menu-color;
      color: $main-text-color;
    }

    &:active {
      background-color: $active-menu-color;
      color: $main-text-color;
    }

    &_icon {
      color: $main-text-color;
      font-size: 20px;
      max-width: $collapsed-menu-width - 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 0 $collapsed-menu-width - 30px;

      &.plato-menu-item__body_icon--big {
        i {
          font-size: 38px;
        }
      }

      .fa-bell {
        transform: rotate(45deg);
      }
    }

    &_text {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      padding-left: 15px;
      line-height: 20px;
    }

    &_collapse {
      position: absolute;
      right: 0;
      top: 0;
      padding: 15px;
      color: $main-text-color;
    }
  }

  &__subitems {
    list-style: none;
    overflow: hidden;
    height: 0;
    outline: 0;
    margin: 0;
    padding: 0;
    margin-top: 0 !important;
    transition: height 0s ease-in 0.3s;
    .plato-menu:hover & {
      height: 100%;
    }
  }

  &__subitem {
    margin-top: 0;
    &_body {
      font-size: 15px;
      padding: 15px;
      display: flex;
      align-items: center;
      cursor: pointer;
      text-decoration: none;
      color: $main-text-color;
      // width: $menu-width - 15px;
      width: calc(100% - 15px);
      transition: background-color 0.08s linear;

      &:hover {
        background-color: $hovered-menu-color;
        color: $main-text-color;
        // i {
        //   color: $selected-menu-text-color;
        // }
      }

      &:active {
        background-color: $active-menu-color;
        color: $main-text-color;
      }

      &_icon {
        color: $main-text-color;
        font-size: 20px;
        max-width: $collapsed-menu-width - 20px;
        display: flex;
        //justify-content: flex-end;
        align-items: center;
        width: 20px;
        padding-left: 25px;
        //flex: 1 0 $collapsed-menu-width - 15px;
        &.plato-menu-item__body_icon--big {
          i {
            font-size: 38px;
          }
        }
        .fa-bell {
          transform: rotate(45deg);
        }
      }

      &_text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding-left: 20px;
      }
    }

    &.plato-menu-item__subitem--active {
      .plato-menu-item__subitem {
        &_body {
          background-color: $selected-menu-color;
          color: $selected-menu-text-color;
          &_icon {
            color: $selected-menu-text-color;
          }
        }
      }
    }
  }



  //Состояния
  &.plato-menu-item--active {
    .plato-menu-item {
      &__body {
        background-color: $selected-menu-color;
        color: $selected-menu-text-color;
        &_icon {
          color: $selected-menu-text-color;
        }
      }
      &__subitems {
        list-style: none;
        outline: 0;
        margin: 0;
        padding: 0;
      }
    }
  }
}
//////////////////////////////////////////////
</style>