<template>
  <Transition :name="placement === 'left' ? 'slideleft' : 'slideright'">
    <div
      v-show="show"
      :ref="`aside${_uid}`"
      :class="classObj"
      :style="{ width: isMobile ? '90%' : width }"
      class="plato-aside__inner"
    >
      <slot :close="close" />
    </div>
  </Transition>
</template>

<script>
import { isMobile } from "@/components/utils/mobile/Mobile.js";

export default {
  name: "Aside",
  props: {
    placement: {
      type: String,
      default: "left",
      required: false
    },
    title: {
      type: String,
      default: "",
      required: false
    },
    width: {
      type: String,
      default: "304px",
      required: false
    }
  },
  data() {
    return {
      show: false,
      reactOnOutside: false,
      isMobile: isMobile()
      // focusTrap: null
    };
  },
  computed: {
    classObj() {
      const { placement } = this;
      const classes = {};

      classes["plato-aside"] = true;
      classes["plato-aside--left"] = placement === "left";
      classes["plato-aside--right"] = placement === "right";

      return classes;
    }
  },
  watch: {
    show(val) {
      const backdrop = document.createElement("div");
      const body = document.body;
      backdrop.className = "plato-aside__backdrop";
      backdrop.id = `backdrop${this._uid}`;

      if (val) {
        body.appendChild(backdrop);
        // element.addClass(body, 'va-modal-open')

        // This timeout is included to allow for opacity transition.
        setTimeout(() => {
          backdrop.className += " " + "plato-aside--in";
          this._clickEvent = backdrop.addEventListener(
            "click",
            this.close,
            false
          );
          this.$emit("show");
        }, 20);

        // this.focusTrap.activate()
      }
      else {
        // this.focusTrap.deactivate()
        this.performClose();
      }
    }
  },
  created() {
    const escapeHandler = e => {
      if (e.key === "Escape" && this.show) {
        this.close();
      }
    };
    document.addEventListener("keydown", escapeHandler);
    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", escapeHandler);
    });
    this.isAlreadyInDOM = true;
  },
  mounted() {
    document.body.appendChild(this.$refs[`aside${this._uid}`]);
    this.$once("hook:beforeDestroy", () => {
      if (document.body == this.$refs[`aside${this._uid}`].parentNode) {
        document.body.removeChild(this.$refs[`aside${this._uid}`]);
      }
    });
    // this.focusTrap = createFocusTrap(this.$refs.aside, {
    //   clickOutsideDeactivates: true,
    //   returnFocusOnDeactivate: true,
    //   fallbackFocus: this.$refs.aside
    // })
  },
  beforeDestroy() {
    this.performClose();
  },
  methods: {
    open() {
      this.show = true;
      this.$nextTick(() => {
        // this.reactOnOutside = true;
      });
    },
    close() {
      this.show = false;

      this.$nextTick(() => {
        this.$emit("closed");
      });
    },
    performClose() {
      if (this._clickEvent) {
        this._clickEvent.remove();
      }

      const body = document.body;
      const backdrop = document.getElementById(`backdrop${this._uid}`);

      if (backdrop) {
        backdrop.className = "plato-aside__backdrop";
        setTimeout(() => {
          // element.removeClass(body, 'va-modal-open')
          if (body == backdrop.parentNode) {
            body.removeChild(backdrop);
          }
        }, 300);

        this.$emit("hide");
      }
    }
  }
};
</script>

<style lang="scss">
// TODO: Переменные для всех компонентов Plato
// @import "../variables";
$background-color: #fff;

.plato-aside-open {
  transition: transform 0.15s;
}

.plato-aside {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 3001;
  overflow-x: hidden;
  overflow-y: auto;
  background: $background-color;
  &--left {
    left: 0;
    right: auto;
  }
  &--right {
    right: 0;
    left: auto;
  }
  &:focus {
    outline: 0;
  }
  &__inner {
    padding: 0px 20px 20px 20px;
  }
  &__header {
    display: flex;
    align-items: center;
    position: relative;
    &_text {
      color: #172b4d;
      font-size: 20px;
      font-weight: 500;
    }
    .plato-aside &_back {
      color: #0646a3;
      margin-right: 5px;
      padding-left: 0;
      .fa-arrow-left {
        margin-left: 0;
        font-size: 16px;
      }
    }
    &_close {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3000;
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
    background: rgba(9, 30, 66, 0.54);
  }
  &__header {
    display: flex;
  }
  &--in {
    opacity: 1;
  }
}
</style>
