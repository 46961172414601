class Rosreestr {
  async getFeatures(featureType, query) {
    if (query.lat && query.lng) {
      query = [query.lat, query.lng].join(" ");
    }

    let response = await fetch(
      "https://pkk.rosreestr.ru/api/features/" + featureType.id + "?" + new URLSearchParams({ text: query, limit: 11, tolerance: 1, "_": Date.now() })
    );
    return response.json();
    //.then(response => response.body.features);
  }

  async getFeatureData(featureId, typeId) {
    let response = await fetch(
      "https://pkk.rosreestr.ru/api/features/" + typeId + "/" + featureId,
      { params: { "_": Date.now() } }
    );
    return response.json();
    //.then(response => response.body.feature);
  }

  getOverlayImage(id, featureType, pixelSize, bbox) {
    // let bounds = map.getPixelBounds(),
    //   ne = map.options.crs.project(map.unproject(bounds.getTopRight())),
    //   sw = map.options.crs.project(map.unproject(bounds.getBottomLeft())),
    //   size = [bounds.max.x - bounds.min.x, bounds.max.y - bounds.min.y],
    //   bbox = [sw.x, sw.y, ne.x, ne.y];

    let ids = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    let params = {
      size: pixelSize.join(","),
      bbox: bbox.join(","),
      layers: "show:" + ids.join(","),
      layerDefs: "{" + ids.map(function(nm) {
        return "\"" + nm + "\":\"ID = '" + id + "'\"";
      }).join(",") + "}",
      format: "png32",
      dpi: 96,
      transparent: "true",
      rand: Math.random(),
      imageSR: 102100,
      bboxSR: 102100
    };

    let imageUrl = "https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/";

    imageUrl += (featureType && featureType === Rosreestr.FeatureTypes.SpecialAreas.id ? "ZONESSelected" : "CadastreSelected") + "/MapServer/export?f=image";

    for (let key in params) {
      imageUrl += "&" + key + "=" + encodeURIComponent(params[key]);
    }
    return imageUrl;
    // return new L.ImageOverlay.CrossOrigin(imageUrl, map.getBounds(), {
    //   opacity: 0.5,
    //   id: id
    // });
  }

  resourceUrl() {
    return "https://pkk.rosreestr.ru/arcgis/rest/services/Hosted/caddivsion/VectorTileServer/resources";
  }

  objectCardHref(object) {
    return `https://pkk.rosreestr.ru/#/search?opened=${object.attrs.id}&text=${object.attrs.id}&type=${object.type}`;
  }

  async getCadastrVectorLayersStyle() {
    let response = await fetch("/map/rosreestrLayersStyles.json");
    return response.json();
  }

  //Единицы кадастрового деления
  createCadastrVectorMapboxSource() {
    return {
      type: "vector",
      tiles: ["https://pkk.rosreestr.ru/arcgis/rest/services/Hosted/caddivsion/VectorTileServer/tile/{z}/{y}/{x}.pbf"],
      tileSize: 512,
      maxzoom: 12
    };
  }

  //Земельные участки
  createCadastrRasterMapboxSource() {
    return {
      type: "raster",
      tiles: ["https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreObjects/MapServer/export?layers=show%3A27%2C24%2C23%2C22&dpi=96&format=PNG32&bbox={bbox-epsg-3857}&bboxSR=102100&imageSR=102100&size=1024%2C1024&transparent=true&f=image&_ts=false"],
      tileSize: 1024,
      attribution: "ПКК © <a href='https://pkk.rosreestr.ru/'>Росреестр</a> 2010-2015</div>"
    };
  }

  createCadastrRasterLayer(defaultEnabled) {
    return {
      id: Rosreestr.CadastrRasterSourceId + "-layer",
      type: "raster",
      source: Rosreestr.CadastrRasterSourceId,
      layout: {
        visibility: defaultEnabled ? "visible" : "none"
      }
    };
  }

  createZouitMapboxSource() {
    return {
      type: "raster",
      tiles: ["https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/ZONES/MapServer/export?format=PNG32&transparent=true&version=1.1.1&imageSR=102100&bboxSR=102100&size=1024%2C1024&f=image&bbox={bbox-epsg-3857}"],
      tileSize: 1024
    };
  }

  createZouitMapboxLayer(defaultEnabled) {
    return {
      id: Rosreestr.ZouitSourceId + "-layer",
      type: "raster",
      source: Rosreestr.ZouitSourceId,
      layout: {
        visibility: defaultEnabled ? "visible" : "none"
      }
    };
  }

  async getObjects(id) {
    try {
      let response = await fetch(
        `https://pkk.rosreestr.ru/api/typeahead/1?text=${id}&_=1652863154398`
      ).catch(error => {
        throw new Error(error);
      });
      return response.json();
    }
    catch (error) {
      throw new Error(error);
    }
  }

  async getObject(id) {
    let type = this.getRosreestrObjectType(id);

    let response = await fetch(
      `https://pkk.rosreestr.ru/api/features/${type}?_=1652865619740&text=${id}&limit=40&skip=0&tolerance=2`
    );
    return response.json();
  }

  getRosreestrObjectType(id) {
    let value = id.split("-")[0].trim();

    const areatRegex = new RegExp("(^[0-9]+[0-9]*$)");
    const districtRegex = new RegExp("(^[0-9]+:[0-9]+$)");
    const quarterRegex = new RegExp("(^[0-9]+:[0-9]+:[0-9]+$)");
    const landRegex = new RegExp("(^[0-9]+:[0-9]+:[0-9]+:[0-9]+$)");

    if (areatRegex.test(value)) {
      return 4;
    }

    if (districtRegex.test(value)) {
      return 3;
    }

    if (quarterRegex.test(value)) {
      return 2;
    }

    if (landRegex.test(value)) {
      return 1;
    }

    return 1;
  }
}

Rosreestr.CadastrVectorSourceId = "rosreestr-cadastr_vector";
Rosreestr.CadastrRasterSourceId = "rosreestr-cadastr_raster";
Rosreestr.ZouitSourceId = "rosreest-zouit";

Rosreestr.FeatureTypes = {
  LandPlots: { id: 1, name: "Земельные участки" },
  Quarters: { id: 2, name: "Кварталы" },
  Townships: { id: 3, name: "Районы" },
  Districts: { id: 4, name: "Округи" },
  CapitalConstructions: { id: 5, name: "ОКСы" },
  TerritorialZones: { id: 6, name: "Территориальные зоны" },
  Boundaries: { id: 7, name: "Границы" },
  LandmarkNets: { id: 9, name: "ОМС" },
  SpecialAreas: { id: 10, name: "ЗОУИТы" },
  Forestries: { id: 12, name: "Лесничества и лесопарки" },
  RedLines: { id: 13, name: "Красные линии" },
  LandPlotSchemas: { id: 15, name: "Схемы расположения ЗУ" },
  FreeEconomicZones: { id: 16, name: "Свободные экономические зоны" }
};

Rosreestr.ZoneTypes = {
  "218010010000": "Жилая зона",
  "218010020000": "Общественно-деловая зона",
  "218010030000": "Производственная зона, зона инженерной и транспортной инфраструктур",
  "218010040000": "Зона сельскохозяйственного использования",
  "218010050000": "Зона рекреационного назначения",
  "218010060000": "Зона особо охраняемых территорий",
  "218010070000": "Зона специального назначения",
  "218010080000": "Зона размещения военных объектов",
  "218010090000": "Иная зона",
  "218020000000": "Зоны с особыми условиями использования территории",
  "218020010000": "Зоны охраны природных объектов",
  "218020010001": "Территория особо охраняемого природного объекта",
  "218020010002": "Охранная зона особо охраняемого природного объекта",
  "218020010003": "Водоохранная зона",
  "218020010004": "Прибрежная защитная полоса",
  "218020010005": "Зона санитарной охраны источников водоснабжения и водопроводов питьевого назначения",
  "218020010006": "Особо ценные земли",
  "218020020000": "Зоны охраны искусственных объектов",
  "218020020002": "Зона охраны объекта культурного наследия",
  "218020020201": "Охранная зона",
  "218020020202": "Зона регулирования застройки и хозяйственной деятельности",
  "218020020203": "Зона охраняемого природного ландшафта",
  "218020020003": "Охранная зона геодезического пункта",
  "218020020004": "Охранная зона стационарного пункта наблюдений за состоянием окружающей природной среды",
  "218020020005": "Охранная зона транспорта",
  "218020020006": "Охранная зона инженерных коммуникаций",
  "218020020007": "Охранная зона линий и сооружений связи и линий и сооружений радиофикации",
  "218020020008": "Придорожная полоса",
  "218020030000": "Зоны защиты населения",
  "218020030001": "Запретная зона при военном складе",
  "218020030002": "Запретный район при военном складе",
  "218020030003": "Загрязненные земли",
  "218020030004": "Охранная зона загрязненных земель",
  "218020030005": "Санитарно-защитная зона предприятий, сооружений и иных объектов",
  "218020030006": "Санитарный разрыв (санитарная полоса отчуждения)",
  "218020030007": "Зона ограничения от передающего радиотехнического объекта",
  "218020030008": "Район падения отделяющихся частей ракет",
  "218020040000": "Прочие зоны с особыми условиями использования территории",
  "218020040001": "Зона публичного сервитута",
  "218020040002": "Зона резервирования земель",
  "218020040003": "Горный отвод",
  "218020040004": "Пригородная зона",
  "218020040005": "Зеленая зона",
  "218020040007": "Пограничная зона",
  "218020040008": "Территория традиционного природопользования",
  "218020050000": "Иные зоны с особыми условиями использования территории",
  "218020050001": "Иная зона с особыми условиями использования территории",
  "218030000000": "Территория объекта культурного наследия (памятников истории и культуры) народов Российской Федерации"
};

export default Rosreestr;
