<template>
  <div class="plato-eqrul-data-editor">
    <PlatoExternalDataEditor
      :loading="loading"
      :search-value="''"
      :elements="availableElements"
      :columns="columns"
      info="Введите ИНН или ОГРН в поле поиска"
      @search="search"
      @add="addInstanceToDataSource"
    />
  </div>
</template>
<script>
import Converter from "../Table/PlatoTableTableConverter.js";

import PlatoExternalDataEditor from "./PlatoExternalDataEditor.vue";

import { asyncScheduler, Subject } from "rxjs";
import { distinctUntilChanged, throttleTime } from "rxjs/operators";

import _ from "lodash";

export default {
  components: {
    PlatoExternalDataEditor
  },
  props: {
    dataSourceId: {
      type: String,
      required: true
    },

    metadata: {
      type: Object,
      required: true
    },

    tableParams: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      availableElements: [],
      columns: [],
      instances: [],
      columnsParams: [],
      searchSubject: new Subject()
    };
  },
  created() {
    this.createColumns();
    const searchObs = this.searchSubject.asObservable();
    searchObs
      .pipe(throttleTime(1000, asyncScheduler, { leading: false, trailing: true }))
      .pipe(distinctUntilChanged())
      .subscribe(async searchKeyword => {
        await this.searchEgrulData(searchKeyword);
      });
  },
  methods: {
    search(value) {
      this.searchSubject.next(value);
    },
    async searchEgrulData(value) {
      if (!_.isEmpty(value)) {
        this.loading = true;
        let response = await this.$store.dispatch("card/getExternalDataSourceData", { dataSourceId: this.dataSourceId, searchKeyword: value });
        this.instances = response.items;
        this.availableElements = Converter.convertElements(this.instances, null, null, this.columnsParams);
        this.loading = false;
      }
    },
    createColumnParams() {
      let columnsCount = 4;
      if (this.tableParams.length > 0) {
        let mappedColumns = this.mapCardParametersToColunms(columnsCount);
        if (mappedColumns.length === 0) {
          this.columnsParams = this.mapMetadataPropertiesToColumns(columnsCount);
          return;
        }
        this.columnsParams = mappedColumns;
        return;
      }
      this.columnsParams = this.mapMetadataPropertiesToColumns(columnsCount);
    },

    mapMetadataPropertiesToColumns(columnsCount) {
      let mappedColumns = [];
      this.metadata.ownProperties.forEach(ownProperty => {
        if (mappedColumns.length < columnsCount) {
          mappedColumns.push({
            displayName: ownProperty.name,
            propertyAlias: ownProperty.alias,
            tableType: ownProperty.tableType
          });
        }
      });
      return mappedColumns;
    },

    mapCardParametersToColunms(columnsCount) {
      let mappedColumns = [];
      this.tableParams.forEach(param => {
        if (param.groups.length > 0) {
          param.groups.forEach(group => {
            group.lines.forEach(line => {
              if (line.fields.length > 0) {
                line.fields.forEach(field => {
                  if (!_.isEmpty(field)) {
                    for (let [key, value] of Object.entries(field)) {
                      if (!_.isEmpty(field[key])) {
                        if (mappedColumns.length < columnsCount) {
                          let propertyMetadata = _.find(this.metadata.ownProperties, prop => prop.alias === value.propertyAlias);
                          if (!_.isEmpty(propertyMetadata)) {
                            mappedColumns.push({
                              displayName: propertyMetadata.name,
                              propertyAlias: propertyMetadata.alias,
                              tableType: propertyMetadata.tableType
                            });
                          }
                        }
                      }
                    }
                  }
                });
              }
            });
          });
        }
      });
      return mappedColumns;
    },

    createColumns() {
      this.createColumnParams();
      this.columns = Converter.convertColumns(this.columnsParams, this.metadata);
      this.columns.push({ property: "actions", label: "Действия", align: "right" });
    },
    addInstanceToDataSource(instanceToAdd) {
      this.$emit("add-instance-to-data-source", _.find(this.instances, instance => instance.id === instanceToAdd.instanceId));
    }
  }
};
</script>
<style scoped>

</style>