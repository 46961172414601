import _ from "lodash";
import Coordinates from "coordinate-parser";
import proj4 from "proj4";
import Vue from "vue";

export default {
  isCoordinate(element) {
    return _.isFinite(Number(element));
  },

  isCoordinatePair(element) {
    return Array.isArray(element) && this.isCoordinate(element[0]);
  },

  isRing(element) {
    return Array.isArray(element) && this.isCoordinatePair(element[0]);
  },

  isPolygon(element) {
    return Array.isArray(element) && this.isRing(element[0]);
  },

  isMultiPolygon(element) {
    return Array.isArray(element) && this.isPolygon(element[0]);
  },

  swapCoordinates(coordinates) {
    if (this.isCoordinatePair(coordinates)) {
      const lon = coordinates[0];
      const lat = coordinates[1];

      Vue.set(coordinates, 0, lat);
      Vue.set(coordinates, 1, lon);
      return;
    }

    for (const item of coordinates) {
      this.swapCoordinates(item);
    }
  },

  checkWgsCoordinates(coordinates) {
    if (!Array.isArray(coordinates) || coordinates.length == 0) {
      return false;
    }

    if (this.isCoordinatePair(coordinates)) {
      const lon = Number(coordinates[0]);
      const lat = Number(coordinates[1]);

      return lon > -180 && lon < 180 && lat > -90 && lat < 90;
    }

    for (const item of coordinates) {
      if (!this.checkWgsCoordinates(item)) {
        return false;
      }
    }

    return true;
  },

  checkCoordinatesIsValid(coordinatesNumbers) {
    for (let number of coordinatesNumbers) {
      if (typeof number === "string" && _.isEmpty(number) || !_.isFinite(Number(number))) {
        return false;
      }
    }

    return true;
  },

  convertCoordinatesToNumbers(coordinates) {
    if (this.isCoordinatePair(coordinates)) {
      coordinates.forEach((coordinate, index) => {
        Vue.set(coordinates, index, Number(coordinate));
      });
    }
    else {
      coordinates.forEach(geometryPart => {
        this.convertCoordinatesToNumbers(geometryPart);
      });
    }
  },

  checkAllLinearRingsLastCoordinates(coordinates) {
    if (this.isCoordinatePair(coordinates[0])) {
      if (!_.isEqual(coordinates[0], coordinates[coordinates.length - 1])) {
        coordinates.push(_.cloneDeep(coordinates[0]));
      }
    }
    else {
      coordinates.forEach(geometryPart => {
        this.checkAllLinearRingsLastCoordinates(geometryPart);
      });
    }
  },

  parseCoordinates(str) {
    let coordinatesString = str;
    let spaceAfterDelimeterRegexp = new RegExp(/,\s/);
    if (spaceAfterDelimeterRegexp.test(str)) {
      coordinatesString = _.replace(str, spaceAfterDelimeterRegexp, " ");
    }

    let minusAfterDelimeterRegexp = new RegExp(/,-/);
    if (minusAfterDelimeterRegexp.test(str)) {
      coordinatesString = _.replace(str, minusAfterDelimeterRegexp, " -");
    }
    let normalized = _.chain(coordinatesString).trim().replace(/,/g, ".");
    let pair = normalized.split(/[\s;]+/).map(Number).filter(_.isFinite).value();
    try {
      if (pair.length == 1) {
        return pair;
      }
      else if (pair.length == 2) {
        // Простая пара чисел в МСК-23 или EPSG:4326
        return [pair[1], pair[0]];
      }
      else {
        // Строка с градусами, минутами, секундами и прочее
        let coordinates = new Coordinates(normalized.value());
        return [coordinates.getLongitude(), coordinates.getLatitude()];
      }
    }
    catch (ex) {
      return null;
    }
  },

  createGeojson(items, type) {
    // Мультиполигон: массив массивов массивов коррдинат

    const coordinates = type == "Point" ? items[0] : items;

    return {
      type: "Feature",
      properties: {},
      geometry: {
        coordinates,
        type
      }
    };

    // if (Array.isArray(items) && Array.isArray(items[0]) && Array.isArray(items[0][0])) {
    //   geojson.geometry.coordinates = [items];
    //   geojson.geometry.type = "MultiPolygon";
    //   return geojson;
    // }

    // if (Array.isArray(items) && Array.isArray(items[0]) && !Array.isArray(items[0][0])) {
    //   geojson.geometry.coordinates = [items];
    //   geojson.geometry.type = "Polygon";
    //   return geojson;
    // }

    // if (Array.isArray(items) && items.length === 1) {
    //   geojson.geometry.coordinates = items[0];
    //   geojson.geometry.type = "Point";
    //   return geojson;
    // }

    // return null;
  },

  reprojectGeometry(coordinates, projections) {
    if (_.isFinite(coordinates[0])) {
      let projectedCoordinates = this.reprojectPoint(coordinates, projections);
      if (_.isArray(projectedCoordinates)) {
        coordinates.splice(0, 2, projectedCoordinates[0], projectedCoordinates[1]);
      }
    }
    else {
      coordinates.forEach(geometryPart => {
        this.reprojectGeometry(geometryPart, projections);
      });
    }

    // coordinates.forEach((coordinatesPair, index) => {
    //   if (_.isFinite(coordinatesPair[0])) {
    //     Vue.set(coordinates, index, this.reprojectPoint(coordinatesPair, projections));
    //   }
    //   else {
    //     this.reprojectGeometry(coordinatesPair, projections);
    //   }
    // });
  },

  reprojectPoint(coordinatesPair, projections) {
    let x = Math.abs(coordinatesPair[1]);
    let y = Math.abs(coordinatesPair[0]);

    if (_.isFinite(x) && _.isFinite(y)) {
      let zone = x.toString()[0];
      let srcProjection = "";

      if (x > 180) {
        for (let projection of projections) {
          let m = projection.value.match(/\+x_0=(\d)/);

          if (m && m[1] == zone) {
            srcProjection = projection.value;
            break;
          }
        }
        if (!_.isEmpty(srcProjection)) {
          return proj4(srcProjection, "EPSG:4326", [coordinatesPair[1], coordinatesPair[0]], Number);
        }
        else {
          Vue.prototype.notification.danger({ title: "Введенные координаты не принадлежат МСК" });
          return;
        }
      }
    }
    else {
      Vue.prototype.notification.danger({ title: "Введенные координаты не содержат пары чисел" });
      return;
    }
  }
};