<template>
  <div class="text-filter">
    <!-- <div class="text-filter__label">{{ property.name }}</div> -->
    <PlatoInlineEdit
      class=""
      :value="currentValue"
      :placeholder="'Фраза для фильтрации...'"
      type="Text"
      @change="(val) => currentValue = val"
    />
  </div>
</template>

<script>
import _ from "lodash";

export default {
  components: {},

  props: {
    dataSourceId: {
      type: String,
      default: ""
    },
    property: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    currentValue: {
      get: function() {
        let currentFilter = _.find(
          this.$store.getters["filters/filters"](this.dataSourceId),
          filter => filter.alias == this.property.alias && filter.type == this.property.type);
        return _.get(currentFilter, "value", null);
      },
      set: function(newValue) {
        this.$emit("filterChange", {
          alias: this.property.alias,
          name: this.property.name,
          value: newValue,
          operator: 10,
          type: this.property.type,
          active: true
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.text-filter__label {
  padding: 0 0 5px 0;
  font-weight: 600;
}
</style>