import axios from "axios";

import { default as httpClient, setJwtCache } from "./DefaultHttpClient";
import { rethrowPlatoApiError } from "./PlatoApiError";

const BASE_PATH = "/v1/AuthenticationApi";

const LogintHttpClient = axios.create({
  headers: {
    "Content-Type": "application/json"
  }
});

class AuthenticationClient {
  @rethrowPlatoApiError
  async login({ username, password }) {
    let response = await LogintHttpClient.post(`${BASE_PATH}/authenticate`,
      {
        User: {
          username: username,
          password: password
        }
      }
    );

    setJwtCache(response.data);
  }

  @rethrowPlatoApiError
  async logout() {
    await LogintHttpClient.post(`${BASE_PATH}/logout`);
    setJwtCache(null);
  }

  @rethrowPlatoApiError
  async refresh() {
    let response = await LogintHttpClient.post(`${BASE_PATH}/refresh`);
    setJwtCache(response.data);
  }

  @rethrowPlatoApiError
  async getCurrentUser() {
    return httpClient.get(`${BASE_PATH}/currentUser`, { redirect: false })
      .then(response => response.data);
  }

  rethrowPlatoApiError
  async loginGuest() {
    let response = await LogintHttpClient.post(`${BASE_PATH}/authenticateGuest`);

    setJwtCache(response.data);
  }
}

export default AuthenticationClient;