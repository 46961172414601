<template>
  <div
    class="sorting-options"
  >
    <div class="sorting-options__property">
      <PlatoInlineEdit
        type="Select"
        :context="{options: sortingByProperties}"
        :value="sorting.alias"
        @change="changeProperty"
      />
    </div>

    <div class="sorting-options__button">
      <PlatoButton
        :icon="iconSort"
        @click="changeSortType"
      />
    </div>

    <PlatoButton
      icon="xmark"
      @click="removeSorting"
    />
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    sortingByProperties: {
      type: Array,
      default: () =>[]
    },
    sorting: {
      type: Object,
      default: () => {
        return {
          alias: "",
          sortType: "asc"
        };
      }
    }
  },

  computed: {
    iconSort() {
      switch (this.sorting.sortType) {
      case "asc":
        return "arrow-up-wide-short";
      case "desc":
        return "arrow-down-wide-short";
      }

      return "";
    }
  },

  methods: {
    changeProperty(value) {
      if (value === null) {
        return;
      }
      let sorting = {
        oldSorting: this.sorting,
        newSorting: {
          alias: value,
          sortType: this.sorting.sortType
        }
      };
      this.$emit("changeProperty", sorting);
    },

    changeSortType() {
      if (this.sorting.alias === "") {
        return;
      }

      let sorting = _.cloneDeep(this.sorting);

      switch (this.sorting.sortType) {
      case "asc":
        sorting.sortType = "desc";
        break;
      case "desc":
        sorting.sortType = "asc";
        break;
      }
      this.$emit("changeSortType", sorting);
    },
    removeSorting() {
      this.$emit("removeSorting", this.sorting);
    }
  }

};
</script>

<style lang="scss" scoped>
.sorting-options{
  display: flex;
  align-items: center;
  height: 39.18px;

  &__property{
    width: 100%;
    max-width: 300px;
    margin-right: 5px;
  }

  &__button{
    margin-right: 5px;
  }
}
</style>