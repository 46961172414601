import _ from "lodash";
import actions from "./index/applicationActions";

const getDefaultState = () => {
  return {
    manifestFull: null,
    classes: [],
    etag: ""
  };
};

export default {
  state: getDefaultState,
  getters: {
    applicationId: state => {
      return _.get(state.manifestFull, "manifest.info.applicationId");
    },

    APP_MAIN_COLOR: state => {
      return _.get(state.manifestFull, "manifest.mainColor");
    },

    MANIFEST: state => {
      return _.get(state.manifestFull, "manifest");
    },

    classes: state => {
      return state.classes;
    },

    MANIFEST_ID: state => {
      return _.get(state.manifestFull, "id");
    },

    appEtag: state => {
      return state.etag;
    },

    dataSourceDataView: state => dataSourceId => {
      return _.filter(state.manifestFull.manifest.dataView, { dataSourceId });
    },

    dataSourceDefaultDataView: state => dataSourceId => {
      return _.find(state.manifestFull.manifest.defaultDataView, { dataSourceId });
    }
  },
  mutations: {
    setInnerErrorCode: (state, value) => {
      state.innerErrorCode = value;
    },

    resetAppState: state => {
      Object.assign(state, getDefaultState());
    },

    setClasses: (state, classes) => {
      state.classes = classes;
    },

    SET_MANIFEST: (state, payload) => {
      state.manifestFull = payload;
    },

    setEtag: (state, etag) =>{
      state.etag = etag;
    }
  },
  actions
};