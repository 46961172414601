import _ from "lodash";
import actions from "./actions";

const getDefaultState = () => {
  return {
    filters: [],
    urlFilters: [],
    linkedPropertyFilterOptions: [],
    skip: 0,
    takePeriod: 20,
    dataLoadingError: null,
    dataLoading: null,
    geometryFilterGeojson: null,
    mapFilteredDataSource: null
  };
};

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    dataLoadingError: state => {
      return state.dataLoadingError;
    },

    dataLoading: state => {
      return state.dataLoading;
    },

    filters: state => dataSourceId => {
      return _.chain(state.filters)
        .find({ dataSourceId })
        .get("filters", [])
        .value();
    },

    take: state => alias => {
      return _.chain(state.linkedPropertyFilterOptions)
        .find({ alias })
        .get("take", state.takePeriod)
        .value();
    },

    totalCount: state => alias => {
      return _.chain(state.linkedPropertyFilterOptions)
        .find({ alias })
        .get("totalCount", 0)
        .value();
    },

    urlFilters: state => dataSourceId => {
      return _.chain(state.urlFilters)
        .find({ dataSourceId })
        .get("filters", [])
        .value();
    },

    filtersForUrl: state => {
      const getFilters = filters => {
        return _.chain(filters)
          .filter(filter => filter.type != "Geojson")
          .map(filter => ({ alias: filter.alias, value: filter.value }))
          .filter(filter => !_.isEmpty(filter.value)
          || (filter.value === true || filter.value === false))
          .value();
      };

      return _.chain(state.filters)
        .map(DSFilter => ({ dataSourceId: DSFilter.dataSourceId,
          filters: getFilters(DSFilter.filters) }))
        .filter(filter => !_.isEmpty(filter.filters))
        .value();
    },

    activeFilters: state => dataSourceId => {
      return _.chain(state.filters)
        .find({ dataSourceId })
        .get("filters", [])
        .filter("active")
        .value();
    },

    linkedPropertyFilterOptions: state => alias => {
      let filter = _.find(state.linkedPropertyFilterOptions, { alias });
      return filter?.options ?? [];
    },

    availableFilters: _state => metadata => {
      if (!metadata) {
        return [];
      }

      let ownWithFilters = _.filter(metadata.ownProperties, prop => !_.isEmpty(prop.filters));
      let linkedWithFilters = _.filter(metadata.linkedProperties, prop => prop.isFilterable);

      return [
        ..._.map(ownWithFilters, own => ({
          alias: own.alias,
          name: own.name,
          type: own.filters[0].type
        })),
        ..._.map(linkedWithFilters, linked => ({
          alias: linked.alias,
          name: linked.name,
          type: "SelectionDynamic"
        }))
      ];
    },

    geometryFilterGeojson: state => {
      return state.geometryFilterGeojson;
    },

    mapFilteredDataSource: state => {
      return state.mapFilteredDataSource;
    }
  },
  mutations: {
    setDataLoadingError: (state, value) => {
      state.dataLoadingError = value;
    },

    setDataLoading: (state, value) => {
      state.dataLoading = value;
    },

    setInnerErrorCode: (state, value) => {
      state.innerErrorCode = value;
    },

    resetState: state => {
      Object.assign(state, getDefaultState());
    },

    setLinkedPropertyFilterOptions: (state, { alias, options, totalCount, take }) => {
      let filter = _.find(state.linkedPropertyFilterOptions, { alias });
      if (filter) {
        filter.options = options;
        filter.totalCount = totalCount;
      }
      else {
        let opt = {
          alias: alias,
          options: options,
          totalCount: totalCount,
          take: take
        };

        state.linkedPropertyFilterOptions.push(opt);
      }
    },

    increaseTake: (state, alias) => {
      let data = _.find(state.linkedPropertyFilterOptions, { alias });
      if (data.take < data.totalCount) {
        data.take += state.takePeriod;
      }
    },
    resetTake: (state, alias) => {
      let data = _.find(state.linkedPropertyFilterOptions, { alias });
      if (!_.isEmpty(data)) {
        data.take = state.takePeriod;
      }
    },

    setUrlFilters: (state, filters) => {
      state.urlFilters = filters;
    },

    setFilters: (state, data) => {
      let filters = _.find(state.filters, { dataSourceId: data.dataSourceId });
      if (filters) {
        filters.filters = data.filters;
      }
      else {
        state.filters.push({ dataSourceId: data.dataSourceId, filters: data.filters });
      }
    },

    removeFilters: (state, dataSourceId) => {
      state.filters = _.reject(state.filters, { dataSourceId });
    },

    setGeometryFilter: (state, geojsonString) => {
      state.geometryFilterGeojson = geojsonString;
    },

    setMapFilteredDataSource: (state, dataSourceId) => {
      state.mapFilteredDataSource = dataSourceId;
    }
  },
  actions
};