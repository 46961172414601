import _ from "lodash";
import CoordinatesUtils from "./CoordinatesUtils";
import Vue from "vue";

import { DataClient } from "@/PlatoAPI";

const mainGeometryPropertyId = "a7e55e81-a15a-4e88-9619-057a000fcf2f";

const dataClient = new DataClient();
const geometryCardModule = {
  namespaced: true,
  state() {
    return {
    //Тип геометрии в карточке
      type: "",

      //Координаты в карточке
      coordinates: [],

      //Текущие редактируемые координаты на карте
      mapEditingGeojson: null,

      /*Дефолтные координаты:
      -при клике на новый тип геометрии
      -для сравнения с текущими координатами для отключения кнопок отмены/сохранения
    */
      defaultCoordinates: {
        point: [0, 0],
        lineString: [[0, 0], [0, 0]],
        linearRing: [[0, 0], [0, 0], [0, 0], [0, 0]],
        polygon: [[[0, 0], [0, 0], [0, 0], [0, 0]]]
      },

      //Геометрия наведенной части геометрии
      hoveredGeojson: null,

      //Alias Свойство геометрии текущего объекта
      geojsonAlias: null,

      //Текущая геометрия объекта на карте (не сохраненная)
      mapCurrentObjectGeometry: null,
      //Map Aside Search
      cardType: "Map",

      editable: true,

      mapDrawMode: false,

      currentObjectSavedGeometry: null
    };
  },

  getters: {
    coordinates: state => {
      return state.coordinates;
    },

    type: state => {
      return state.type;
    },

    mapEditingGeojson: state => {
      return state.mapEditingGeojson;
    },

    defaultPoint: state => {
      return state.defaultCoordinates.point;
    },

    defaultLineString: state => {
      return state.defaultCoordinates.lineString;
    },

    defaultLinearRing: state => {
      return state.defaultCoordinates.linearRing;
    },

    defaultPolygon: state => {
      return state.defaultCoordinates.polygon;
    },

    //Сравнение координат с дефолтными
    defaultCoordinatesComparer: state => coordinates => {
      return (_.isEqual(coordinates, state.defaultCoordinates.point) && state.type === "Point") ||
      (_.isEqual(coordinates, state.defaultCoordinates.lineString) && state.type === "LineString") ||
      (_.isEqual(coordinates, state.defaultCoordinates.polygon) && state.type === "Polygon");
    },

    hoveredGeojson: state => {
      return state.hoveredGeojson;
    },

    geojsonAlias: state => {
      return state.geojsonAlias;
    },

    //Получение сохраненного значения свойства геометрии текущего объекта
    currentObjectSavedGeometry: state => {
      return state.currentObjectSavedGeometry;
    },

    currentObjectName: (state, getters, rootState, rootGetters) => {
      let currentObject = rootGetters["mapComponent/mapCurrentObject"];
      let dataSourceId = currentObject.dataSourceId;
      let layerSettings = rootGetters["mapComponent/dataSourceLayerSettings"](dataSourceId);
      let namePropertyAlias = layerSettings.parameters.dataSource.nameProperty;
      let nameProperty = _.find(currentObject.object.ownProperties, prop => prop.alias === namePropertyAlias);
      return nameProperty.value;
    },

    mapCurrentObjectGeometry: state => {
      return state.mapCurrentObjectGeometry;
    },

    /*Текущее состояние карточки
      -editingControlsMode = координаты изменены но не применены
      -saveControlsMode = координаты изменены, применены но не сохранены
      -deleteControlMode = координаты соотвествуют сохраненным координатам у текущего объекта и могут быть удалены
    */
    currentCardControlsMode: (state, getters) => {
      if (state.mapEditingGeojson !== null || state.mapCurrentObjectGeometry === null || !_.isEqual(state.mapCurrentObjectGeometry.coordinates, state.coordinates)) {
        return "editingControlsMode";
      }

      if (getters.currentObjectSavedGeometry === null || _.isEmpty(getters.currentObjectSavedGeometry) || !_.isEqual(getters.currentObjectSavedGeometry.coordinates, state.coordinates)) {
        return "saveControlsMode";
      }

      return "deleteControlMode";
    },

    isGeometryPartEditing: state => geometryPath => {
      return _.get(state.mapEditingGeojson, "properties") &&
      state.mapEditingGeojson.properties.path === geometryPath.path &&
      state.mapEditingGeojson.properties.itemIndex === geometryPath.itemIndex;
    },

    isGeometryPartNew: (state, getters) => geometryPath => {
      return getters.isGeometryPartEditing(geometryPath) && _.get(state.mapEditingGeojson.properties, "isNew");
    },

    cardType: state => {
      return state.cardType;
    },

    editable: state => {
      return state.editable;
    },

    mapDrawMode: state => {
      return state.mapDrawMode;
    },

    mainGeometryPropertyId: () => {
      return mainGeometryPropertyId;
    }
  },

  mutations: {
    setCoordinates: (state, coordinates) => {
      state.coordinates = coordinates;
    },

    setType: (state, type) => {
      state.type = type;
    },

    setCoordinatesByPath: (state, { path, coordinates, itemIndex }) => {
      switch (state.type) {
      case "Point":
        state.coordinates = coordinates;
        break;

      case "MultiPoint":
      case "LineString":
        Vue.set(state.coordinates, itemIndex, coordinates);
        break;

      case "MultiLineString":
      case "Polygon":
      case "MultiPolygon":
        Vue.set(_.get(state.coordinates, path), itemIndex, coordinates);
        break;
      }
    },

    setPastedCoordinates: (state, { path, coordinates, itemIndex, positionType }) => {
      switch (state.type) {
      case "Point":
        if (coordinates[0].length === 1) {
          Vue.set(state.coordinates, positionType === "lon" ? 0 : 1, coordinates[0][0]);
        }
        else {
          state.coordinates = coordinates[0];
        }
        break;

      case "MultiPoint":
        if (coordinates[0].length === 1) {
          let pair = _.get(state.coordinates, itemIndex);
          Vue.set(pair, positionType === "lon" ? 0 : 1, coordinates[0][0]);
        }
        else {
          Vue.set(state.coordinates, path, coordinates[0]);
        }
        break;

      case "LineString":
        if (coordinates[0].length === 1) {
          let pair = _.get(state.coordinates, itemIndex);
          Vue.set(pair, positionType === "lon" ? 0 : 1, coordinates[0][0]);
        }
        else {
          state.coordinates = coordinates;
        }
        break;

      case "MultiLineString":
        if (coordinates[0].length === 1) {
          let lineItem = _.get(state.coordinates, path);
          let pair = _.get(lineItem, itemIndex);
          Vue.set(pair, positionType === "lon" ? 0 : 1, coordinates[0][0]);
        }
        else {
          Vue.set(state.coordinates, path, coordinates);
        }
        break;

      case "Polygon":
        if (coordinates[0].length === 1) {
          let ringItem = _.get(state.coordinates, path);
          let pair = _.get(ringItem, itemIndex);
          Vue.set(pair, positionType === "lon" ? 0 : 1, coordinates[0][0]);
        }
        else {
          Vue.set(state.coordinates, path, coordinates);
        }
        break;

      case "MultiPolygon":
        if (coordinates[0].length === 1) {
          let parsedPath = path.split(".");
          let polygon = _.get(state.coordinates, parsedPath[0]);
          let ring = _.get(polygon, parsedPath[1]);
          let pair = _.get(ring, itemIndex);
          Vue.set(pair, positionType === "lon" ? 0 : 1, coordinates[0][0]);
        }
        else {
          let parsedPath = path.split(".");
          Vue.set(state.coordinates[parsedPath[0]], parsedPath[1], coordinates);
        }
        break;
      }
    },

    addPosition: (state, { path, positionIndex }) => {
      if (_.isEmpty(path)) {
        let coordinateForAdd = _.cloneDeep(_.get(state.coordinates, positionIndex));
        state.coordinates.splice(positionIndex, 0, coordinateForAdd);
      }
      else {
        let coordinates = _.get(state.coordinates, path);
        let coordinateForAdd = _.cloneDeep(_.get(coordinates, positionIndex));
        coordinates.splice(positionIndex, 0, coordinateForAdd);
      }
    },

    removePosition: (state, { path, positionIndex }) => {
      if (_.isEmpty(path)) {
        state.coordinates.splice(positionIndex, 1);
      }
      else {
        let coordinates = _.get(state.coordinates, path);
        coordinates.splice(positionIndex, 1);
      }
    },

    addMultipleGeometry: (state, { path, itemIndex, isLinearRing }) => {
      let currentCoordinates = state.coordinates;
      switch (state.type) {
      case "Point":
        state.coordinates = [currentCoordinates, _.cloneDeep(state.defaultCoordinates.point)];
        state.type = "MultiPoint";
        break;

      case "MultiPoint":
        state.coordinates.splice(itemIndex + 1, 0, _.cloneDeep(state.defaultCoordinates.point));
        break;

      case "LineString":
        state.coordinates = [currentCoordinates, _.cloneDeep(state.defaultCoordinates.lineString)];
        state.type = "MultiLineString";
        break;

      case "MultiLineString":
        state.coordinates.splice(itemIndex + 1, 0, _.cloneDeep(state.defaultCoordinates.lineString));
        break;

      case "Polygon":
        if (isLinearRing) {
          state.coordinates.splice(itemIndex + 1, 0, _.cloneDeep(state.defaultCoordinates.linearRing));
        }
        else {
          state.coordinates = [currentCoordinates, _.cloneDeep(state.defaultCoordinates.polygon)];
          state.type = "MultiPolygon";
        }
        break;

      case "MultiPolygon":
        if (isLinearRing) {
          //в path - index полигона; в itemIndex - index linearring
          state.coordinates[path].splice(itemIndex + 1, 0, _.cloneDeep(state.defaultCoordinates.linearRing));
        }
        else {
          state.coordinates.splice(itemIndex + 1, 0, _.cloneDeep(state.defaultCoordinates.polygon));
        }
        break;
      }
    },

    removeMultipleGeometry: (state, { path, itemIndex, isLinearRing }) => {
      switch (state.type) {
      case "MultiPoint":
        state.coordinates.splice(itemIndex, 1);
        if (state.coordinates.length === 1) {
          state.coordinates = state.coordinates[0];
          state.type = "Point";
        }
        break;

      case "MultiLineString":
        state.coordinates.splice(itemIndex, 1);
        if (state.coordinates.length === 1) {
          state.coordinates = state.coordinates[0];
          state.type = "LineString";
        }
        break;

      case "MultiPolygon":
        if (isLinearRing) {
          state.coordinates[path].splice(itemIndex, 1);
        }
        else {
          state.coordinates.splice(itemIndex, 1);
          if (state.coordinates.length === 1) {
            state.coordinates = state.coordinates[0];
            state.type = "Polygon";
          }
        }
        break;

      case "Polygon":
        state.coordinates.splice(itemIndex, 1);
        break;
      }
    },

    setMapEditingGeojson(state, { path, itemIndex, isNew }) {
      if (_.isEmpty(path)) {
        if (itemIndex === null) {
          state.mapEditingGeojson = {
            geometry: {
              coordinates: state.coordinates,
              type: state.type
            },
            properties: {
              path,
              itemIndex,
              isNew
            }
          };
          return;
        }

        if (state.type === "Polygon") {
          state.mapEditingGeojson = {
            geometry: {
              coordinates: [state.coordinates[itemIndex]],
              type: state.type
            },
            properties: {
              path,
              itemIndex,
              isNew
            }
          };
          return;
        }

        if (state.type === "MultiPolygon" && isNew) {
          state.mapEditingGeojson = {
            geometry: {
              coordinates: [state.coordinates[itemIndex][0]],
              type: state.type.substring(5)
            },
            properties: {
              path: `${itemIndex}`,
              itemIndex: 0,
              isNew
            }
          };
          return;
        }

        state.mapEditingGeojson = {
          geometry: {
            coordinates: state.coordinates[itemIndex],
            type: state.type.substring(5)
          },
          properties: {
            path,
            itemIndex,
            isNew
          }
        };
        return;
      }

      let currentCoordinates = _.get(state.coordinates, path);
      state.mapEditingGeojson = {
        geometry: {
          coordinates: [currentCoordinates[itemIndex]],
          type: state.type.substring(5)
        },
        properties: {
          path,
          itemIndex,
          isNew
        }
      };
    },

    setHoveredGeojson(state, { path, itemIndex }) {
      if (_.isEmpty(path)) {
        if (itemIndex === null || state.type === "Polygon") {
          state.hoveredGeojson = {
            geometry: {
              coordinates: state.coordinates,
              type: state.type
            },
            properties: {
              path,
              itemIndex
            }
          };
          return;
        }

        state.hoveredGeojson = {
          geometry: {
            coordinates: state.coordinates[itemIndex],
            type: state.type.substring(5)
          },
          properties: {
            path,
            itemIndex
          }
        };
        return;
      }

      let currentCoordinates = _.get(state.coordinates, path);
      state.hoveredGeojson = {
        geometry: {
          coordinates: [currentCoordinates[itemIndex]],
          type: state.type.substring(5)
        },
        properties: {
          path,
          itemIndex
        }
      };
    },

    removeHoveredGeojson(state) {
      state.hoveredGeojson = null;
    },

    setMapСurrentObjectCoordinates(state, { path, coordinates, itemIndex }) {
      if (state.mapCurrentObjectGeometry !== null) {
        state.mapCurrentObjectGeometry.type = state.type;
        let mapCoordinates = _.cloneDeep(state.coordinates);
        switch (state.type) {
        case "Point":
        case "LineString":
          mapCoordinates = coordinates;
          break;

        case "MultiPoint":
        case "MultiLineString":
          if (itemIndex === null) {
            mapCoordinates = coordinates;
          }
          else {
            Vue.set(mapCoordinates, itemIndex, coordinates);
          }
          break;
        case "Polygon":
          if (itemIndex === null) {
            mapCoordinates = coordinates;
          }
          else {
            Vue.set(mapCoordinates, itemIndex, coordinates[0]);
          }
          break;

        case "MultiPolygon":
          if (_.isEmpty(path)) {
            if (itemIndex === null) {
              mapCoordinates = coordinates;
            }
            else {
              Vue.set(mapCoordinates, itemIndex, coordinates);
            }
          }
          else {
            Vue.set(_.get(mapCoordinates, path), itemIndex, _.cloneDeep(coordinates[0]));
          }
          break;
        }
        state.mapCurrentObjectGeometry.coordinates = mapCoordinates;
      }
      else {
        state.mapCurrentObjectGeometry = {
          coordinates: coordinates,
          type: state.type
        };
      }
    },

    setMapEditingCoordinates(state, coordinates) {
      state.mapEditingGeojson.geometry.coordinates = coordinates;
    },

    resetMapEditingGeojson(state) {
      state.mapEditingGeojson = null;
    },

    setGeojsonAlias(state, alias) {
      state.geojsonAlias = alias;
    },

    setMapCurrentObjectGeometry(state, currentObjectGeometry) {
      state.mapCurrentObjectGeometry = currentObjectGeometry;
    },

    applyMapGeometryToCard(state) {
      state.coordinates = _.cloneDeep(state.mapCurrentObjectGeometry.coordinates);
      state.type = _.cloneDeep(state.mapCurrentObjectGeometry.type);
    },

    applyCardGeometryToMap(state) {
      state.mapCurrentObjectGeometry = {
        coordinates: _.cloneDeep(state.coordinates),
        type: _.cloneDeep(state.type)
      };
    },

    setCardType(state, cardType) {
      state.cardType = cardType;
    },

    setEditable(state, editable) {
      state.editable = editable;
    },

    setMapDrawMode(state, drawMode) {
      state.mapDrawMode = drawMode;
    },

    setCurrentObjectSavedGeometry(state, geometry) {
      state.currentObjectSavedGeometry = geometry;
    }
  },

  actions: {
    changeGeometry({ commit, getters }, geojson) {
      commit("resetMapEditingGeojson");
      if (geojson === null || geojson.geometry === null || _.isEmpty(geojson.geometry)) {
        commit("setType", "Point");
        commit("setCoordinates", _.cloneDeep(getters.defaultPoint));
        commit("setMapCurrentObjectGeometry", null);
        commit("setMapEditingGeojson", { path: "", itemIndex: null, isNew: true });
      }
      else {
        commit("setCoordinates", _.cloneDeep(geojson.geometry.coordinates));
        commit("setType", _.cloneDeep(geojson.geometry.type));

        commit("setMapCurrentObjectGeometry", { type: _.cloneDeep(geojson.geometry.type), coordinates: _.cloneDeep(geojson.geometry.coordinates) });
      }
    },

    changeCoordinates({ commit }, { path, coordinates, itemIndex }) {
      commit("setCoordinatesByPath", { path, coordinates, itemIndex });
    },

    addMultipleGeometry({ commit }, { path, itemIndex, isLinearRing }) {
      commit("addMultipleGeometry", { path, itemIndex, isLinearRing });
    },

    removeMultipleGeometry({ commit }, { path, itemIndex, isLinearRing }) {
      commit("removeMultipleGeometry", { path, itemIndex, isLinearRing });
      commit("resetMapEditingGeojson");
    },

    addPosition({ commit }, { path, positionIndex }) {
      commit("addPosition", { path, positionIndex });
    },

    removePosition({ commit }, { path, positionIndex }) {
      commit("removePosition", { path, positionIndex });
    },

    //Очистка состояния карточки
    clearGeometry({ commit }) {
      commit("setCoordinates", []);
      commit("setType", "");
      commit("setGeojsonAlias", null);
      commit("resetMapEditingGeojson");
      commit("setMapCurrentObjectGeometry", null);
      commit("removeHoveredGeojson");
    },

    changeType({ commit, getters }, type) {
      commit("setType", type);
      switch (type) {
      case "Point":
        commit("setCoordinates", _.cloneDeep(getters.defaultPoint));
        commit("setMapEditingGeojson", { path: "", itemIndex: null, isNew: true });
        break;

      case "LineString":
        commit("setCoordinates", _.cloneDeep(getters.defaultLineString));
        commit("setMapEditingGeojson", { path: "", itemIndex: null, isNew: true });
        break;

      case "Polygon":
        commit("setCoordinates", _.cloneDeep(getters.defaultPolygon));
        commit("setMapEditingGeojson", { path: "", itemIndex: 0, isNew: true });
        break;
      }
    },

    setMapEditingGeojson({ commit }, { path, itemIndex, isNew }) {
      commit("setMapEditingGeojson", { path, itemIndex, isNew });
    },

    setHoveredGeojson({ commit }, { path, itemIndex }) {
      commit("setHoveredGeojson", { path, itemIndex });
    },

    removeHoveredGeojson({ commit }) {
      commit("removeHoveredGeojson");
    },

    applyEditedCoordinates({ commit, getters }) {
      let path = getters.mapEditingGeojson.properties.path;
      let itemIndex = getters.mapEditingGeojson.properties.itemIndex;
      commit("setMapСurrentObjectCoordinates", { path, coordinates: getters.mapEditingGeojson.geometry.coordinates, itemIndex });
      commit("applyMapGeometryToCard");
      commit("resetMapEditingGeojson");
    },

    closeEditingMode({ commit }) {
      commit("applyCardGeometryToMap");
      commit("resetMapEditingGeojson");
    },

    resetMapEditingGeojson({ commit }) {
      commit("resetMapEditingGeojson");
    },

    applyCardGeometryToMap({ commit }) {
      commit("applyCardGeometryToMap");
      commit("resetMapEditingGeojson");
    },

    applyMapGeometryToCard({ commit }) {
      commit("applyMapGeometryToCard");
      commit("resetMapEditingGeojson");
    },

    updateMapEditingCoordinates({ commit }, coordinates) {
      commit("setMapEditingCoordinates", coordinates);
    },

    async saveGeometryProperty({ rootGetters, dispatch, getters }, { geojson, alias }) {
      let currentObject = rootGetters["currentObject/currentObject"];

      let saveGeometryString = "";

      saveGeometryString = JSON.stringify(geojson);

      saveGeometryString = saveGeometryString.replace(/"/g, "\\\"");

      let field = {
        dataSourceId: currentObject.dataSourceId,
        alias: alias,
        value: saveGeometryString,
        instanceId: currentObject.id
      };

      if (getters.cardType === "Map") {
        dispatch("mapComponent/changeMapLoadingState", true, { root: true });
        await dispatch("currentObject/saveCurrentObject", { fields: [field], dataSourceId: currentObject.dataSourceId }, { root: true });
        let promises = [];
        promises.push(dispatch("mapComponent/getMapCurrentObject", { dataSourceId: currentObject.dataSourceId, id: currentObject.id }, { root: true }));
        promises.push(dispatch("getCurrentObjectGeometry", { geojsonAlias: alias }));
        await Promise.all(promises);
        dispatch("changedObject/setChangedObjectDataSourceId", currentObject.dataSourceId, { root: true });
        dispatch("mapComponent/changeMapLoadingState", false, { root: true });
      }
      else if (getters.cardType === "Aside") {
        dispatch("card/addChangedFields", field, { root: true });
        await dispatch("card/saveChangedFields", currentObject.dataSourceId, { root: true });
        //await dispatch("card/getFullCurrentObject", { root: true });
      }
    },

    setGeojsonAlias({ commit }, alias) {
      commit("setGeojsonAlias", alias);
    },

    setPastedCoordinates({ commit }, { path, text, itemIndex, positionType }) {
      let lines = text.split(/\r?\n/);

      let parsedCoordinates = _.map(lines, CoordinatesUtils.parseCoordinates);
      _.remove(parsedCoordinates, _.isEmpty);

      if (parsedCoordinates.length > 0) {
        commit("setPastedCoordinates", { path, coordinates: parsedCoordinates, itemIndex, positionType });
      }
    },

    swapCoordinates({ commit, getters }) {
      let swapedCoordinates = _.cloneDeep(getters.coordinates);
      CoordinatesUtils.swapCoordinates(swapedCoordinates);
      commit("setCoordinates", swapedCoordinates);
    },

    setCardType({ commit }, cardType) {
      commit("setCardType", cardType);
    },

    setEditable({ commit }, editable) {
      commit("setEditable", editable);
    },

    setMapDrawMode({ commit }, drawMode) {
      commit("setMapDrawMode", drawMode);
    },

    setCoordinates({ commit }, coordinates) {
      commit("setCoordinates", coordinates);
    },

    async getCurrentObjectGeometry({ commit, rootGetters }, { geojsonAlias }) {
      let currentObject = rootGetters["currentObject/currentObject"];
      let body = {
        skip: 0,
        take: 1,
        filters: [],
        searchKeyword: "",
        instanceIds: [currentObject.id],
        requestedPropertiesAliases: [geojsonAlias]
      };
      let response = await dataClient.getData(currentObject.dataSourceId, body);
      let object = response.items[0];
      let geojsonProperty = _.find(object.ownProperties, property => property.alias === geojsonAlias);
      commit("setCurrentObjectSavedGeometry", geojsonProperty && !_.isEmpty(geojsonProperty.value) ? geojsonProperty.value : null);
    },

    getCurrentObjectGeometryForAsideCard({ commit }, geometry) {
      commit("setCurrentObjectSavedGeometry", !_.isEmpty(geometry) ? geometry : null);
    }
  }
};
export default geometryCardModule;