import _ from "lodash";

import { SORT_TYPES } from "@/components/utils/sorting/SortTypes";

export default {
  convertElements(data, currentObjectId, geojsonField, columnsParams, selectedObjectsId, metadata) {
    return _.map(data, instance => {
      let formatedInstance = {
        instanceId: instance.id,
        style: instance.style,
        active: !!(currentObjectId == instance.id),
        checked: _.some(selectedObjectsId, prop => prop == instance.id),
        classId: instance.classId
      };

      let ownProperties = _.chain(instance.ownProperties)
        .filter(property => _.find(columnsParams, { propertyAlias: property.alias })
          || property.alias == geojsonField)
        .groupBy("alias").merge().value();

      for (let property in ownProperties) {
        ownProperties[property] = ownProperties[property][0].value;
      }

      let linkedProperties = _.chain(instance.linkedProperties)
        .filter(property => _.find(columnsParams, { propertyAlias: property.alias }))
        .groupBy("alias").value();

      for (let property in linkedProperties) {
        linkedProperties[property] = linkedProperties[property][0].values;

        let metadataLinkedProperty = _.find(metadata.linkedProperties, { alias: property });
        if (metadataLinkedProperty && metadataLinkedProperty.linkedDataSourceId) {
          _.forEach(linkedProperties[property], value => {
            value.dataSourceId = metadataLinkedProperty.linkedDataSourceId;
          });
        }
      }

      return { ...formatedInstance, ...ownProperties, ...linkedProperties };
    });
  },

  convertColumns(columnsParams, metadata, appliedSorting, ownPropertiesSortings, linkedPropertiesSortings) {
    let columns = _.map(columnsParams, param => {
      let element = _.find(metadata.linkedProperties, { alias: param.propertyAlias })
        || _.find(metadata.ownProperties, { alias: param.propertyAlias });

      let appliedSort = "";

      if (param.ownPropertyAlias) {
        appliedSort = _.chain(appliedSorting).find(sorting => sorting.alias == param.ownPropertyAlias).get("sortType").value();
      }
      else {
        appliedSort = _.chain(appliedSorting).find(sorting => sorting.alias == param.propertyAlias
          || sorting.parentAlias == param.propertyAlias).get("sortType").value();
      }

      let ownPropertiesSort = _.chain(ownPropertiesSortings).find({ alias: param.propertyAlias }).get("sortType").value();
      let linkedPropertiesSort = _.chain(linkedPropertiesSortings)
        .find({ parentAlias: param.propertyAlias }).get("sortType").value();

      let sort = appliedSort || ownPropertiesSort || linkedPropertiesSort;

      if (_.isEmpty(appliedSort) && !_.isEmpty(sort)) {
        sort = SORT_TYPES.Unset;
      }

      return {
        label: param.displayName || element?.name,
        property: param.propertyAlias,
        ownProperty: param.ownPropertyAlias,
        width: param.width,
        showFooter: param.showFooter,
        stringView: param.stringView,
        tableType: element?.tableType,
        isAggregated: element?.isAggregated,
        sort
      };
    });

    _.remove(columns, { tableType: "RichText" });

    return columns;
  }
};
