<template>
  <PlatoInlineEdit
    :value="value"
    :label="label"
    type="Select"
    :context="{options: fieldsForSelect}"
    @change="value => $emit('change', value)"
  />
</template>

<script>
import _ from "lodash";

export default {
  props: {
    value: {
      type: String,
      default: ""
    },
    dsMetadata: {
      type: Object,
      default: () => {}
    },
    fields: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ""
    },
    removeRichText: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    fieldsForSelect() {
      let fields = [];
      if (!_.isEmpty(this.fields)) {
        fields = _.map(this.fields, field => ({
          value: field.alias,
          display: field.name,
          tableType: field.tableType,
          icon: this.getIcon(field.tableType),
          type: "own"
        }));
      }
      else {
        fields = this.allFieldsSelect;
      }

      if (this.removeRichText) {
        _.remove(fields, { tableType: "RichText" });
      }

      fields = _.sortBy(fields, "display");

      return fields;
    },

    allFieldsSelect() {
      if (!_.isEmpty(this.dsMetadata)) {
        let fields = [
          ..._.map(this.dsMetadata.ownProperties, field => ({
            value: field.alias,
            display: field.name,
            tableType: field.tableType,
            icon: this.getIcon(field.tableType),
            type: "own"
          })),
          ..._.map(this.dsMetadata.linkedProperties, field => ({
            value: field.alias,
            display: field.name,
            type: "linked",
            icon: this.getIcon("Link")
          }))
        ];

        return fields;
      }
      else {
        return [];
      }
    }
  },

  methods: {
    getIcon(type) {
      switch (type) {
      case "Link":
        return "link";
      case "File":
        return "file";
      case "Text":
        return "text";
      case "Geometry":
        return "book-atlas";
      case "DateTimeUTC":
        return "timer";
      case "Numeric":
        return "input-numeric";
      case "Boolean":
        return "power-off";
      case "Cadastre":
        return "clipboard-user";
      case "RichText":
        return "text-size";
      default:
        return "circle-question";
      }
    }
  }
};
</script>

<style>

</style>