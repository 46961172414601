import _ from "lodash";
import actions from "./actions";

import { DATASOURCE_TYPES } from "@/components/utils/dataSource/types.js";

const getDefaultState = () => {
  return {
    parentExploreInstance: null,
    lastNewObjectInstanceId: null,
    sectorId: null,
    startPageId: null,
    linkedPropertyAlias: null,
    linkedObjectId: null,
    linkedDataSourceId: null,
    currentObject: null,
    editingCurrentObject: null,
    metadata: [],
    changedFields: [],
    newObject: null,
    dataLoading: null,
    dataLoadingError: null,
    newObjectType: DATASOURCE_TYPES.internal
  };
};

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    dataLoading: state => {
      return state.dataLoading;
    },

    dataLoadingError: state => {
      return state.dataLoadingError;
    },

    startPageId: state => {
      return state.startPageId;
    },

    linkedPropertyAlias: state => {
      return state.linkedPropertyAlias;
    },

    linkedObjectId: state => {
      return state.linkedObjectId;
    },

    linkedDataSourceId: state => {
      return state.linkedDataSourceId;
    },

    sectorId: state => {
      return state.sectorId;
    },

    lastNewObjectInstanceId: state => {
      return state.lastNewObjectInstanceId;
    },

    parentExploreInstance: state => {
      return state.parentExploreInstance;
    },

    metadata: state => dataSourceId => {
      return _.chain(state.metadata)
        .find({ dataSourceId })
        .get("metadata", null)
        .value();
    },

    changedFields: state => dataSourceId => {
      return _.chain(state.changedFields)
        .find({ dataSourceId })
        .get("changedFields", [])
        .value();
    },

    currentObject: state => {
      return state.currentObject;
    },

    editingCurrentObject: state => {
      return state.editingCurrentObject;
    },

    newObject: state => {
      return state.newObject;
    },

    newObjectType: state => {
      return state.newObjectType;
    }
  },
  mutations: {
    setDataLoading: (state, value) => {
      state.dataLoading = value;
    },

    setDataLoadingError: (state, value) => {
      state.dataLoadingError = value;
    },

    deleteCurrentObject: state => {
      state.currentObject = null;
    },

    setLinkedDataSourceId: (state, value) => {
      state.linkedDataSourceId = value;
    },

    setLinkedPropertyAlias: (state, value) => {
      state.linkedPropertyAlias = value;
    },

    setLinkedObjectId: (state, value) => {
      state.linkedObjectId = value;
    },

    setSectorId: (state, sectorId) => {
      state.sectorId = sectorId;
    },

    resetState: state => {
      Object.assign(state, getDefaultState());
    },

    setStartPageId: (state, startPageId) => {
      state.startPageId = startPageId;
    },

    setLastNewObjectInstanceId: (state, value) => {
      state.lastNewObjectInstanceId = value;
    },

    addMetadata: (state, data) => {
      let metadata = _.find(state.metadata, { dataSourceId: data.dataSourceId });
      if (metadata) {
        metadata.metadata = data.metadata;
      }
      else {
        state.metadata.push({ dataSourceId: data.dataSourceId, metadata: data.metadata });
      }
    },

    setParentExploreInstance: (state, value) => {
      state.parentExploreInstance = value;
    },

    setCurrentObject: (state, currentObject) => {
      state.currentObject = currentObject;
    },

    setEditingCurrentObject: (state, editingCurrentObject) => {
      state.editingCurrentObject = editingCurrentObject;
    },

    addChangedFields: (state, data) => {
      let field = null;

      if (data.type === "linked") {
        field = {
          alias: data.alias,
          instanceId: data.instanceId,
          added: data.value.added,
          removed: data.value.removed
        };
      }
      else {
        field = {
          alias: data.alias,
          value: data.value,
          instanceId: data.instanceId
        };
      }

      let changedFields = _.find(state.changedFields, { dataSourceId: data.dataSourceId });
      if (changedFields) {
        changedFields.changedFields = _.reject(changedFields.changedFields, { alias: data.alias });
        changedFields.changedFields.push(field);
      }
      else {
        state.changedFields.push({ dataSourceId: data.dataSourceId, changedFields: [field] });
      }
    },

    clearChangedFields: (state, dataSourceId) => {
      state.changedFields = _.reject(state.changedFields, { dataSourceId });
    },

    addNewObject: (state, metadata) => {
      let ownProperties = _.reduce(metadata.ownProperties, (result, prop) => {
        result.push({
          alias: prop.alias,
          value: prop.tableType == "Numeric" ? null : ""
        });
        return result;
      }, []);
      let linkedProperties = _.reduce(metadata.linkedProperties, (result, prop) => {
        result.push({
          alias: prop.alias,
          value: ""
        });
        return result;
      }, []);

      state.newObject = { ownProperties: ownProperties, linkedProperties: linkedProperties };
    },

    clearNewObject: state => {
      state.newObject = null;
    },

    changeNewObjectField: (state, field) => {
      if (field.type == "linked") {
        let property = _.find(state.newObject.linkedProperties, { alias: field.alias });
        let values = _.join(field.value.added, ",");
        _.set(property, "value", values);
        // TODO: Свойство для отображения
        ///значения linkedProperies, необходимые для отображения объектов в связи при создании нового объекта
        ///никак не задействовано при редактировании уже существующего объекта
        if (field.value.objects) {
          _.set(property, "objects", field.value.objects);
        }
      }
      else {
        let propLink = _.find(state.newObject.ownProperties, { alias: field.alias });
        _.set(propLink, "value", field.value);
      }
    },

    setNewObjectType: (state, newObjectType) => {
      state.newObjectType = newObjectType;
    }
  },
  actions
};