const getDefaultState = () => {
  return {
    dataViewName: "",
    editableName: false,
    isEditDefaultView: false,
    editDefaultViewOperation: ""
  };
};

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    dataViewName: state => {
      return state.dataViewName;
    },

    editableName: state => {
      return state.editableName;
    },

    isEditDefaultView: state => {
      return state.isEditDefaultView;
    },

    editDefaultViewOperation: state => {
      return state.editDefaultViewOperation;
    }
  },
  mutations: {
    setDataViewName: (state, value) => {
      state.dataViewName = value;
    },

    setEditableName: (state, value) => {
      state.editableName = value;
    },

    setEditDefaultView: (state, value) => {
      state.isEditDefaultView = value;
    },

    setEditOperation: (state, value) => {
      state.editDefaultViewOperation = value;
    }
  },
  actions: {
    setDataViewName({ commit }, name) {
      commit("setDataViewName", name);
    },

    setEditableName({ commit }, editable) {
      commit("setEditableName", editable);
    },

    setEditDefaultView({ commit }, defaultEditView) {
      commit("setEditDefaultView", defaultEditView);
    },

    setEditOperation({ commit }, operation) {
      commit("setEditOperation", operation);
    }
  }
};