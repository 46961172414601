<template functional>
  <div class="plato-tooltip">
    <slot
      name="text"
      class="plato-tooltip__text"
    />
    <div class="plato-tooltip__body">
      <slot
        name="tooltip"
        class="plato-tooltip__body_content"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "PlatoTooltip"
};
</script>

<style lang="scss">
.plato-tooltip {
  position: relative;
}

.plato-tooltip__body {
  position: absolute;
  display: none;
  bottom: 100%;
  left: -50%;
  background-color: #fff;
  box-shadow: 0 20px 32px -8px rgba(9, 30, 66, 0.25),
    0 0 1px 0 rgba(9, 30, 66, 0.31);
  padding: 10px 15px ;
}
</style>

