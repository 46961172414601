export const ACTION_TYPES = {
  OpenPage: 0,
  NotifyUser: 1,
  OpenAside: 2,
  CreateReport: 3,
  ExportShapefile: 4,
  ExportExcel: 5,
  ExportKml: 6,
  ExportGeoJson: 7
};

export const ACTION_LABELS = {
  [ACTION_TYPES.OpenPage]: "Открыть страницу приложения",
  [ACTION_TYPES.NotifyUser]: "Уведомить пользователя",
  [ACTION_TYPES.OpenAside]: "Открыть боковую карточку",
  [ACTION_TYPES.CreateReport]: "Создать отчёт",
  [ACTION_TYPES.ExportShapefile]: "Экспорт в ESRI Shapefile",
  [ACTION_TYPES.ExportExcel]: "Экспорт в Excel",
  [ACTION_TYPES.ExportKml]: "Экспорт в KML",
  [ACTION_TYPES.ExportGeoJson]: "Экспорт в GeoJson"
};