<template>
  <div>
    <SortingField
      v-for="sorting in sortingFields"
      :key="`applied_${sorting.alias}`"
      :sorting-by-properties="sortingByProperties(sorting)"
      :sorting="sorting"
      @changeProperty="changeProperty"
      @changeSortType="changeSortType"
      @removeSorting="removeSorting"
    />
  </div>
</template>

<script>
import _ from "lodash";
import SortingField from "./SortingField.vue";
export default {
  components: { SortingField },
  props: {
    sortingFields: {
      type: Array,
      default: () =>[]
    },
    sortingOption: {
      type: Array,
      default: () =>[]
    },
    appliedSorting: {
      type: Array,
      default: () =>[]
    }
  },
  methods: {
    sortingByProperties(sorting) {
      return _.filter(this.sortingOption, prop => !_.some(this.appliedSorting, { alias: prop.value }) || sorting.alias == prop.value);
    },

    changeProperty(sorting) {
      this.$emit("changeProperty", sorting);
    },

    changeSortType(sorting) {
      this.$emit("changeSortType", sorting);
    },

    removeSorting(sorting) {
      this.$emit("removeSorting", sorting);
    }
  }
};
</script>
