<template>
  <img
    :height="size"
    :width="size"
    :src="url"
  >
</template>

<script>
import FileObjectConverter from "@/PlatoAPI/FileObjectConverter.js";

export default {
  props: {
    size: {
      type: Number,
      default: 40
    },
    icon: {
      type: String,
      default: ""
    }
  },

  computed: {
    url() {
      if (FileObjectConverter.checkFile(this.icon)) {
        return FileObjectConverter.getUrl(FileObjectConverter.parseFile(this.icon));
      }
      else {
        return this.icon || "/img/icons/default.png";
      }
    }
  }
};
</script>

<style scoped>
img {
  object-fit: cover;
}
</style>