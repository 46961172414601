import _ from "lodash";
import StateControllerBase from "@/store/StateControllerBase";
import { ExploreClient } from "@/PlatoAPI";
import { setLoadingState } from "@/store/decorators/setLoadingState";

const labelKey = "rdfs:label";
const realmKey = "plato:realm";
const colorKey = "plato:color";
const nameId = "95b1ff3e-497c-462f-b260-c1b7166021b5";

const exploreClient = new ExploreClient();

async function getInstance(id) {
  let data = await exploreClient.getInstanceById(id).catch(e => {
    throw new Error(`Ошибка при запросе инстанса ${id}:\n${e.status}`);
  });

  data.name = getName(data.dataProperties);
  return data;
}
/**
   * Находит в списке свойство по ключу и возвращает его значение
   */
function getAnnotationPropertyValue(
  annotationProperties,
  key,
  defaultValue = "Неизвестно"
) {
  let property = _.find(annotationProperties, x => x.type === key);
  if (!property) {
    return defaultValue;
  }

  return property.value;
}

function getName(dataProperties, defaultValue = "Неизвестно") {
  let property = _.find(dataProperties, x => x.id === nameId);
  if (!property) {
    return defaultValue;
  }

  return property.value;
}

class Actions extends StateControllerBase {
  resetState(context) {
    context.commit("resetState");
  }

  async SET_DATA_LOADING_ERROR(context, value) {
    context.commit("SET_DATA_LOADING_ERROR", value);
  }

  async SET_DATA_LOADING(context, value) {
    context.commit("SET_DATA_LOADING", value);
  }

  setEditable(context, value) {
    context.commit("setEditable", value);
  }

  changeInstanceGraphRootNodeId({ commit }, id) {
    commit("setInstanceGraphRootId", id);
  }

  changeCurrentItem({ commit, dispatch }, item) {
    if (item.clearHistory) {
      dispatch("changeHistory", [{
        id: "",
        type: "main",
        name: "Главная"
      }]);
    }

    commit("setCurrentItem", item);

    if (item.type == "main") {
      commit("setCurrentInstance");
      commit("setCurrentClassSummary");
      dispatch("changeGraphMode", "tree");
    }
  }

  changeCurrentList({ commit, dispatch }, item) {
    if (item.clearHistory) {
      dispatch("changeHistory", [{
        id: "",
        type: "main",
        name: "Главная"
      }]);

      dispatch("addHistoryItem", {
        type: item.type,
        subtype: "list",
        name: item.name
      });
    }

    commit("setCurrentList", item);
    commit("setCurrentItem", { id: 0, type: "" });

    if (item.type == "main") {
      commit("setCurrentInstance");
      commit("setCurrentClassSummary");
      dispatch("changeGraphMode", "tree");
    }
  }

  @setLoadingState
  async loadNavigatorData({ dispatch }) {
    dispatch("loadDataTypes");
    dispatch("loadAnnotationProperties");
    dispatch("loadDataProperties");
    dispatch("loadObjectProperties");
    dispatch("loadDomains");
  }

  /**
     * Запрашивает данные для графа
     */
  @setLoadingState
  async getFilteredData({ commit, dispatch }, filter) {
    commit("setFilter", filter);
    await dispatch("getGraphData");
    await dispatch("loadDataProperties");
    await dispatch("loadObjectProperties");
  }

  @setLoadingState
  async getGraphData({ commit, dispatch, getters }) {
    let filter = { domainsIdFilter: getters.filter };

    let data = await exploreClient.getAllClasses(filter).catch(e => {
      throw new Error(`Ошибка при запросе классов:\n${e.status}`);
    });

    let nodes = data.items.map(x => {
      return {
        id: x.id,
        name: getAnnotationPropertyValue(x.annotationProperties, labelKey),
        hasChildren: x.children && x.children.length,
        domains: x.classDomains,
        realm: {
          name: getAnnotationPropertyValue(x.annotationProperties, realmKey),
          color: getAnnotationPropertyValue(
            x.annotationProperties,
            colorKey,
            "#96b6d3"
          )
        }
      };
    });

    let links = [];
    data.items.forEach(x => {
      let source = x.id;
      x.children.forEach(child => {
        let target = child.id;
        let link = {
          source: source,
          target: target,
          name: "подкласс"
        };
        links.push(link);
      });
      x.objectProperties.forEach(op => {
        let label = op.name;
        op.linkedClasses.forEach(lc => {
          let target = lc.id;
          let link = {
            source: source,
            target: target,
            name: label
          };
          links.push(link);
        });
      });
    });
    commit("setGraphData", {
      nodes: nodes,
      links: links
    });
    if (_.isEmpty(filter.domainsIdFilter)) {
      let classes = data.items.map(x => {
        return {
          id: x.id,
          name: getAnnotationPropertyValue(x.annotationProperties, labelKey)
        };
      });
      commit("setClassesList", classes);
    }
    else {
      await dispatch("getClassesList");
    }
  }

  @setLoadingState
  async getClassesList({ commit }) {
    let data = await exploreClient.getAllClasses().catch(e => {
      throw new Error(`Ошибка при запросе классов:\n${e.status}`);
    });

    let classes = data.items.map(x => {
      return {
        id: x.id,
        name: getAnnotationPropertyValue(x.annotationProperties, labelKey)
      };
    });
    commit("setClassesList", classes);
  }

  @setLoadingState
  async getClassSummary({ commit }, id) {
    if (id === 0) {
      let data = {
        id: "",
        name: "Новый класс",
        instanceCount: 0,
        parents: [],
        children: [],
        annotationProperties: [],
        dataProperties: [{
          class: {
            id: "",
            name: "Новый класс"
          },
          items: []
        }],
        domainObjectProperties: [],
        rangeObjectProperties: []
      };
      commit("setCurrentClassSummary", data);
    }
    else {
      let data = await exploreClient.getClass(id).catch(e => {
        throw new Error(`Ошибка при запросе класса ${id}:\n${e.status}`);
      });

      data.name = getAnnotationPropertyValue(
        data.annotationProperties,
        labelKey
      );

      commit("setCurrentClassSummary", data);
    }
  }

  @setLoadingState
  async getClassInstances({ commit }, { classId, pageNumber }) {
    let data = await exploreClient
      .getInstances(classId, pageNumber)
      .catch(e => {
        throw new Error(
          `Ошибка при запросе элементов класса ${classId}:\n${e.status}`
        );
      });

    data.items.forEach(x => {
      x.name = getName(x.dataProperties);
    });

    commit("setClassInstances", data);
  }

  @setLoadingState
  async loadDataProperties({ getters, commit }) {
    let filter = { domainsIdFilter: getters.filter };
    const data = await exploreClient.getDataProperties(filter)
      .catch(e => {
        throw new Error(`Ошибка при запросе списка свойств: ${e.status}`);
      });

    commit("setDataProperties", data);
    if (_.isEmpty(filter.domainsIdFilter)) {
      let op = data.map(x => {
        return {
          id: x.id,
          name: getAnnotationPropertyValue(x.annotationProperties, labelKey),
          type: x.type.type
        };
      });
      commit("setDataPropertiesList", op);
    }
  }

  @setLoadingState
  async loadObjectProperties({ getters, commit }) {
    let filter = { domainsIdFilter: getters.filter };
    const data = await exploreClient.getObjectProperties(filter)
      .catch(e => {
        throw new Error(`Ошибка при запросе списка связей: ${e.status}`);
      });
    commit("setObjectProperties", data);
    if (_.isEmpty(filter.domainsIdFilter)) {
      let op = data.map(x => {
        return {
          id: x.id,
          name: getAnnotationPropertyValue(x.annotationProperties, labelKey)
        };
      });
      commit("setObjectPropertiesList", op);
    }
  }

  @setLoadingState
  async getObjectPropertySummary({ commit }, id) {
    if (id === 0) {
      let data = {
        id: "",
        annotationProperties: [],
        inverseObjectProperties: [],
        inverseOfObjectProperties: [],
        domains: [],
        ranges: [],
        classDomain: []
      };
      commit("setCurrentObjectProperty", data);
    }
    else {
      let data = await exploreClient.getObjectProperty(id).catch(e => {
        throw new Error(`Ошибка при запросе связи ${id}:\n${e.status}`);
      });

      commit("setCurrentObjectProperty", data);
    }
  }

  clearDataProperties({ commit }) {
    commit("setDataProperties", []);
  }

  @setLoadingState
  async getCurrentInstance({ commit, _dispatch, _getters }, id) {
    let data = await getInstance(id);
    commit("setCurrentInstance", data);

    // if (getters.graphMode == "instance") {
    //   dispatch("changeInstanceGraphRootNodeId", id);
    // }
  }

  @setLoadingState
  async getInstanceGraphFirstNode({ commit }, id) {
    let currentInstance = await getInstance(id);
    currentInstance.isOpened = true;
    commit("pushInstanceToInstanceGraphData", currentInstance);
  }

  @setLoadingState
  async getInstanceGraphChildNode({ commit, state, dispatch }, id) {
    let instance = _.find(state.currentInstanceGraphData, x => x.id === id);

    instance.objectProperties.forEach(op => {
      dispatch("addInstanceLinks", { parentId: id, child: op });
    });

    let children = await Promise.all(
      instance.objectProperties.map(async op => {
        return await getInstance(op.value.id);
      })
    );
    children.forEach(x => commit("pushInstanceToInstanceGraphData", x));
  }

  @setLoadingState
  async loadDataTypes({ commit }) {
    const dataTypes = await exploreClient.getDataTypes();
    commit("setDataTypes", dataTypes);
  }

  @setLoadingState
  async loadAnnotationProperties({ commit }) {
    const annotationProperties = await exploreClient.getAnnotationProperties();
    commit("setAnnotationProperties", annotationProperties);
  }

  resetInstanceGraphData({ commit }) {
    commit("clearInstanceGraphData");
  }

  resetInstanceGraphLinks({ commit }) {
    commit("clearInstanceGraphLinks");
  }

  addInstanceLinks({ commit }, { parentId, child }) {
    commit("pushInstanceLink", {
      source: parentId,
      target: child.value.id,
      name: child.name
    });
  }

  addHistoryItem({ commit }, item) {
    commit("pushHistoryItems", item);
  }

  updateHistoryItem({ commit }, item) {
    commit("updateHistoryItem", item);
  }

  changeHistory({ commit }, items) {
    commit("setHistoryItems", items);
  }

  changeGraphMode({ commit }, mode) {
    commit("setGraphMode", mode);
  }

  clearClassInstancesList({ commit }) {
    commit("resetClassInstancesList");
  }

  updateCurrentSummaryFields({ commit, getters }, data) {
    let currentSummary = getters.currentSummary;
    commit("updateCurrentSummaryFields", { currentSummary, data });
  }

  createDataProperty({ commit, dispatch }) {
    commit("addDataProperty");
    dispatch("changeCurrentItem", { id: "", type: "dataProperty", name: "Новое свойство" });
  }

  setCurrentDataProperty({ commit, getters }, id) {
    let dataProperty = _.find(getters.dataProperties, { id });
    commit("setCurrentDataProperty", dataProperty);
  }

  @setLoadingState
  async saveDataProperty({ dispatch }, { dataProperty }) {
    let body = {
      id: dataProperty.id,
      typeId: dataProperty.type.id,
      annotationProperties: dataProperty.annotationProperties.map(x => ({ id: x.id, value: x.value }))
    };
    let result = await exploreClient.saveDataProperty(body);
    dispatch("updateCurrentSummaryFields", { id: result });
    await dispatch("loadDataProperties");

    let historyItem = {
      id: result,
      type: "dataProperty",
      name: "Свойство " + _.find(dataProperty.annotationProperties, { type: "rdfs:label" })?.value ?? "Неизвестное свойство"
    };
    dispatch("updateHistoryItem", historyItem);
  }

  @setLoadingState
  async deleteDataProperty({ dispatch }, id) {
    if (id !== 0) {
      await exploreClient.deleteDataProperty(id);
      await dispatch("loadDataProperties");
    }
    dispatch("changeCurrentList", { id: "", type: "dataProperties", clearHistory: true, name: "Список свойств" });
  }

  @setLoadingState
  async saveInstance({ dispatch }, instance) {
    let body = {
      id: instance.id,
      classId: instance.class.id,
      annotationProperties: instance.annotationProperties.map(x => ({ id: x.id, value: x.value })),
      dataProperties: instance.dataProperties.map(x => ({ id: x.id, value: x.value }))
    };
    let result = await exploreClient.saveInstance(body);
    dispatch("updateCurrentSummaryFields", { id: result });
    await dispatch("getCurrentInstance", instance.id);

    let historyItem = {
      id: result,
      type: "instance",
      name: "Элемент " + getName(instance.dataProperties, "Неизвестно")
    };
    dispatch("updateHistoryItem", historyItem);
    await dispatch("getClassInstances", { classId: instance.class.id, pageNumber: 0 });
  }

  async deleteInstance({ commit, dispatch }, { classId, instanceId }) {
    if (instanceId !== 0) {
      await exploreClient.deleteInstance(instanceId);
      await dispatch("getClassInstances", { classId: classId, pageNumber: 0 });
    }
    dispatch("changeCurrentList", { id: classId, type: "instances", clearHistory: false });
    commit("deleteLastItemFromHistory");
  }

  createObjectProperty({ commit, dispatch }) {
    commit("addObjectProperty");
    dispatch("changeCurrentItem", { id: "", type: "objectProperty", name: "Новая связь" });
  }

  setCurrentObjectProperty({ commit, getters }, id) {
    let objectProperty = _.find(getters.objectProperties, { id });
    commit("setCurrentObjectProperty", objectProperty);
  }

  @setLoadingState
  async saveObjectProperty({ dispatch }, { objectProperty }) {
    let body = {
      id: objectProperty.id,
      annotationProperties: objectProperty.annotationProperties.map(x => ({ id: x.id, value: x.value })),
      domains: _.map(objectProperty.domains, x => x.id),
      ranges: _.map(objectProperty.ranges, x => x.id),
      inverseObjectProperties: _.map(objectProperty.inverseObjectProperties, x => x.id)
    };
    let result = await exploreClient.saveObjectProperty(body);
    // dispatch("updateCurrentSummaryFields", { id: result });
    await dispatch("getObjectPropertySummary", result);
    await dispatch("loadObjectProperties");

    let historyItem = {
      id: result,
      type: "objectProperty",
      name: "Связь " + _.find(objectProperty.annotationProperties, { type: "rdfs:label" })?.value ?? "Неизвестно"
    };
    dispatch("updateHistoryItem", historyItem);
    await dispatch("getGraphData");
  }

  @setLoadingState
  async deleteObjectProperty({ dispatch }, id) {
    if (id !== 0) {
      await exploreClient.deleteObjectProperty(id);
      await dispatch("loadObjectProperties");
    }
    dispatch("changeCurrentList", { id: "", type: "objectProperties", clearHistory: true, name: "Список связей" });
  }

  createClass({ commit, dispatch }) {
    commit("addClass");
    dispatch("changeCurrentItem", { id: "", type: "class", name: "Новый класс" });
  }

  createInstance({ commit, dispatch }, instanceClass) {
    commit("addInstance", instanceClass);
    dispatch("changeCurrentItem", { id: "", type: "instance", name: "Новый экземпляр" });
  }

  @setLoadingState
  async deleteClass({ dispatch }, id) {
    if (id !== 0) {
      await exploreClient.deleteClass(id);
    }
    await dispatch("getGraphData");
    dispatch("changeCurrentList", { id: "", type: "classes", clearHistory: true, name: "Список классов" });
  }

  @setLoadingState
  async deleteInstancesOfClass(_context, classId) {
    if (classId !== 0) {
      await exploreClient.deleteInstancesOfClass(classId);
    }
  }

  @setLoadingState
  async saveClass({ getters, dispatch }) {
    let currentClass = getters.currentSummary;
    let ownDataProperties = _.find(currentClass.dataProperties, x => x.class.id === currentClass.id)?.items ?? [];
    let body = {
      id: currentClass.id,
      parents: currentClass.parents.map(x => x.id),
      annotationProperties: currentClass.annotationProperties.map(x => ({ id: x.id, value: x.value })),
      dataProperties: ownDataProperties.map(x => x.id),
      domainsId: currentClass.classDomains.map(x => x.id)
    };
    let result = await exploreClient.saveClass(body);
    dispatch("updateCurrentSummaryFields", { id: result });
    await dispatch("getClassSummary", result);

    let historyItem = {
      id: result,
      type: "class",
      name: "Класс " + _.find(currentClass.annotationProperties, { type: "rdfs:label" })?.value ?? "Неизвестно"
    };
    dispatch("updateHistoryItem", historyItem);
    await dispatch("getGraphData");
  }

  @setLoadingState
  async loadDomains({ commit }) {
    const domains = await exploreClient.getDomains();
    commit("setDomains", domains);
  }

  setCurrentDomain({ commit, getters }, id) {
    let domain = _.find(getters.domains, { id });
    commit("setCurrentDomain", domain);
  }

  async addDomain({ dispatch }) {
    await dispatch("getDomain", 0);
    dispatch("changeCurrentItem", { id: "", type: "domain", name: "Новая предметная область" });
  }

  @setLoadingState
  async saveDomain({ dispatch }, domain) {
    let body = {
      id: domain.id,
      name: domain.name,
      color: domain.color,
      classesId: domain.classes.map(x => x.id)
    };
    let result = await exploreClient.saveDomain(body);
    dispatch("updateCurrentSummaryFields", { id: result });
    await dispatch("loadDomains");
    await dispatch("getDomain", (result));

    let historyItem = {
      id: result,
      type: "domain",
      name: "ПО " + domain.name
    };
    dispatch("updateHistoryItem", historyItem);
  }

  @setLoadingState
  async getDomain({ commit }, id) {
    if (id === 0) {
      let data = {
        id: "",
        name: "Новая предметная область",
        color: "#0052cc",
        classes: []
      };
      commit("setCurrentDomain", data);
    }
    else {
      let data = await exploreClient.getDomain(id).catch(e => {
        throw new Error(`Ошибка при запросе предметной области ${id}:\n${e.status}`);
      });

      commit("setCurrentDomain", data);
    }
  }

  async deleteDomain({ dispatch }, id) {
    if (id !== 0) {
      await exploreClient.deleteDomain(id);
    }
    dispatch("changeCurrentList", { id: "", type: "domains", clearHistory: true, name: "Список предметных областей" });
    dispatch("loadDomains");
  }
}

export default (new Actions).asPlainObject();