<template>
  <div
    v-if="shellManifest"
    :style="cssVars"
    class="plato-shell sc-default-theme"
  >
    <PlatoNavMenu
      v-if="currentUser"
      :logo-info="logoInfo"
      :collapsed="collapsedMenu"
      :can-collapse="menuCanCollapse"
      :show-back-button="showBackButton"
    />
    <div
      :class="{
        'plato-shell__body--menu-colapsed': collapsedMenu && menuCanCollapse,
        'plato-shell__body--menu-uncolapsable':
          !menuCanCollapse && currentUser,
        'openedMenu': isMenuOpened
      }"
      class="
        plato-shell__body"
    >
      <ErrorPage
        v-if="errorCode"
        :error-code="errorCode"
      />
      <RouterView
        v-else
        :collapsed-menu="collapsedMenu"
        :menu-can-collapse="menuCanCollapse"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

import ErrorPage from "@/components/ErrorPage.vue";
import { getAppColors } from "@/components/utils/appColors/Colors.js";

export default {
  components: {
    ErrorPage
  },

  data() {
    return {
      collapsedMenu: true,
      themStyles: ""
    };
  },

  computed: {
    ...mapGetters({
      shellManifest: "SHELL_MANIFEST",
      logoInfo: "NAV_LOGO",
      mainColor: "APP_MAIN_COLOR",
      menuCanCollapse: "MENU_CAN_COLLAPSE",
      currentUser: "CURRENT_USER",
      loading: "DATA_LOADING",
      errorCode: "innerErrorCode"
    }),

    showBackButton() {
      return this.menuCanCollapse;
    },

    isMenuOpened: {
      get() {
        return this.$store.getters.isMenuOpened;
      },
      set(value) {
        this.$store.dispatch("setIsMenuOpened", value);
      }
    },

    cssVars() {
      if (this.shellManifest.mainColor || this.mainColor) {
        let {
          mainHsl,
          contrastColorHsl,
          hoveredColorHsl,
          activeColorHsl,
          bottomHoveredTextColorHsl,
          bottomActiveTextColorHsl,
          bottomSelectedTextColorHsl
        } = getAppColors(this.mainColor || this.shellManifest.mainColor);

        let cssVars = [
          `--p-main-color: ${mainHsl}`,
          `--p-text-color: ${contrastColorHsl}`,
          `--p-selected-menu-color: ${contrastColorHsl}`,
          `--p-selected-menu-text-color: ${mainHsl}`,
          `--p-hovered-menu-color: ${hoveredColorHsl}`,
          `--p-active-menu-color: ${activeColorHsl}`,
          `--p-bottom-hovered-text-color: ${bottomHoveredTextColorHsl}`,
          `--p-bottom-active-text-color: ${bottomActiveTextColorHsl}`,
          `--p-bottom-selected-text-color: ${bottomSelectedTextColorHsl}`
        ];

        return _.join(cssVars, ";");
      }
      else {
        return "";
      }
    }
  },

  async mounted() {
    this.$store.dispatch("localStorage/getShowToursFromLocalStorage");

    await this.getVersions();
    document.title = this.shellManifest?.title || "Плато";
  },

  methods: {
    ...mapActions({
      getVersions: "getVersions"
    })
  }
};
</script>
<style src="shepherd.js/dist/css/shepherd.css"></style>
<style lang="scss">
@import "components/package/styles/colors.scss";
@import "components/package/styles/variables.scss";

.shepherd-element.tour-step {
  &[data-popper-placement="bottom"] {
    margin-top: 11px;
  }
  &[data-popper-placement="left"] {
    margin-left: -11px;
  }
  &[data-popper-placement="right"] {
    margin-left: 11px;
  }
  .shepherd-content {
      .shepherd-header {
          background-color: transparent;
      }
      .shepherd-footer {
        justify-content: space-between;
        padding: 1em;
        .shepherd-button {
          &.off-tour-btn {
            background: none;
            color: black;
            padding-left: 0;
            font-size: 12px;
            text-decoration: underline;
          }
        }
      }
  }
}
.plato-shell {
  height: 100vh;
  &__body {
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-left: $menu-width;
    //margin-left: $collapsed-menu-width;
    &>div {
      width: 100%;
      height: 100%;
    }
    &.plato-shell__body--menu-colapsed {
      margin-left: $collapsed-menu-width;
    }
    &.plato-shell__body--menu-uncolapsable {
      margin-left: $uncollapsible-menu-width;
    }
    button[selected],
    button[selected]:active {
      background-color: #0052cc;
      color: #ffffff;
      .badge {
        background-color: #deebff;
        color: #0052cc;
      }
    }
    button[selected]:hover {
      background-color: #0065ff;
      color: #ffffff;
      .badge {
        background-color: #deebff;
        color: #0052cc;
      }
    }
  }
}
</style>

