import _ from "lodash";
import actions from "./index/actions";
import AdminModule from "./AdminModule";
import ApplicationModule from "./ApplicationModule";
import CardModule from "./card/module";
import CardTable from "./cardTable/module";
import ChangedObject from "./changedObject/module";
import ConstructorModule from "./ConstructorModule";
import CurrentObjectModule from "./CurrentObjectModule";
import DataLoaderModule from "./DataLoaderModule";
import DataSourceConstructorModule from "./dataSourceConstructor/module";
import DataViewModule from "./dataView/module";
import DiagramModule from "./diagram/module";
import FiltersModule from "./filters/module";
import FlyToObjectModule from "./FlyToObjectModule";
import GeometryCardModule from "./GeometryCardModule";
import GroupingModule from "./grouping/module";
import LinkedPropEdit from "./linkedPropEdit/module";
import ListModule from "./list/module";
import LocalStorageModule from "./LocalStorageModule";
import MapComponentModule from "./MapComponentModule";
import MapParametersModule from "./MapParametersModule";
import NavigatorModule from "./NavigatorModule";
import onboardingModule from "./onboarding/module";
import PageModule from "./PageModule";
import ReportModule from "./report/module";
import RouteControllerModule from "./RouteControllerModule";
import SearchKeywordsModule from "./searchKeywords/module";
import SelectedObjectsModule from "./selectedObjects/module";
import ShellModule from "./ShellModule";
import SortingModule from "./sorting/module";
import TableModule from "./table/module";
import Vue from "vue";
import Vuex from "vuex";

import NavigationObjectModule from "./NavigationObjectModule";
import { getPermissions, getPermissionsForScopes } from "./Access";

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    dataLoading: false,
    dataLoadingError: null,
    innerErrorCode: null,
    currentUser: null,
    isMenuOpened: false
  };
};

export default new Vuex.Store({
  modules: {
    "constructorModule": ConstructorModule,
    "dataSourceConstructor": DataSourceConstructorModule,
    "onboarding": onboardingModule,
    "dataLoader": DataLoaderModule,
    "navigator": NavigatorModule,
    "adminModule": AdminModule,
    "localStorage": LocalStorageModule,
    "selectedObjects": SelectedObjectsModule,
    "table": TableModule,
    "list": ListModule,
    "report": ReportModule,
    "filters": FiltersModule,
    "sorting": SortingModule,
    "diagram": DiagramModule,
    "card": CardModule,
    "cardTable": CardTable,
    "linkedPropEdit": LinkedPropEdit,
    "changedObject": ChangedObject,
    "searchKeywords": SearchKeywordsModule,
    "routeController": RouteControllerModule,
    "shell": ShellModule,
    "app": ApplicationModule,
    "page": PageModule,
    "grouping": GroupingModule,
    "mapParameters": MapParametersModule,
    "mapComponent": MapComponentModule,
    "currentObject": CurrentObjectModule,
    "navigationObject": NavigationObjectModule,
    "flyToObject": FlyToObjectModule,
    "dataView": DataViewModule,
    "nested": {
    }
  },

  state: getDefaultState,

  getters: {
    innerErrorCode: state => {
      return state.innerErrorCode;
    },

    isMenuOpened: state => {
      return state.isMenuOpened;
    },

    readAccess: state => parameters => {
      let permissions = state.currentUser.permissions;
      return getPermissions(permissions, parameters.scope, parameters.resource).includes("r");
    },

    writeAccess: state => parameters => {
      let permissions = state.currentUser.permissions;
      return getPermissions(permissions, parameters.scope, parameters.resource).includes("w");
    },

    readOrWriteAccess: state => parameters => {
      let permissions = state.currentUser.permissions;
      return !_.isEmpty(getPermissions(permissions, parameters.scope, parameters.resource));
    },

    scopeAccess: state => scope => {
      let permissions = state.currentUser.permissions;
      return !_.isEmpty(getPermissionsForScopes(permissions, scope));
    },

    DATA_LOADING_ERROR: state => {
      return state.dataLoadingError;
    },

    DATA_LOADING: state => {
      return state.dataLoading;
    },

    CURRENT_USER: state => {
      return state.currentUser;
    }
  },
  mutations: {
    setInnerErrorCode: (state, value) => {
      state.innerErrorCode = value;
    },

    setIsMenuOpened: (state, value) => {
      state.isMenuOpened = value;
    },

    resetState: state => {
      Object.assign(state, getDefaultState());
    },

    SET_DATA_LOADING_ERROR: (state, value) => {
      state.DATA_LOADING_ERROR = value;
    },

    SET_DATA_LOADING: (state, value) => {
      state.DATA_LOADING = value;
    },

    SET_CURRENT_USER: (state, currentUser) => {
      state.currentUser = currentUser;
    }
  },
  actions: {
    ...actions,

    registerModule(_context, name) {
      this.registerModule(["nested", name], GeometryCardModule);
    }
  }
});