<template>
  <Skeleton
    class="skeleton"
    :height="28"
    width="100%"
    secondary-color="#e5edfa"
    preserveAspectRatio="none"
    :primary-opacity="1"
  />
</template>

<script>
import Skeleton from "./skeleton";

export default {
  components: {
    Skeleton
  }
};
</script>

<style scoped>
.skeleton {
  margin-bottom: -2px;
}
</style>