<template>
  <div class="plato-card__body_content_col">
    <PlatoInlineEdit
      v-for="prop in properties"
      :key="`info_header_${prop.header.alias}`"
      class="plato-card__body_content_col_field"
      :is-generated="prop.header.isGenerated"
      :label="prop.header.name"
      :value="prop.value"
      :editable="!readOnly && !loading && !prop.readOnly"
      :type="prop.header.tableType"
      :xsd-type="prop.header.xsdType"
      :is-photo="getIsPhoto(prop)"
      :context="{
        dataSourceId: dataSourceId,
        alias: prop.header.alias,
        propName: prop.header.name
      }"
      @change="(val, callback) => $emit('saveField','own', prop, val, callback)"
    />
  </div>
</template>

<script>
import { annotationProperties, getAnnotationPropertyValue } from "@/components/utils/annotationProperty/AnnotationProperty.js";

import { mapGetters } from "vuex";

export default {
  props: {
    properties: {
      type: Array,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: true
    },
    dataSourceId: {
      type: String,
      default: ""
    }
  },

  computed: {
    ...mapGetters({
      loading: "card/dataLoading"
    }),

    metadata() {
      return this.$store.getters["card/metadata"](this.dataSourceId);
    }
  },

  methods: {
    getIsPhoto(property) {
      return getAnnotationPropertyValue(this.metadata, property.header.alias, annotationProperties.PLATO_IS_PHOTO);
    }
  }
};
</script>

<style>

</style>