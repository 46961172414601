<template>
  <PlatoLoader v-if="loading" />

  <DataTable
    v-else
    :elements="tableProperties"
    :columns="tableColumns"
    :can-edit-element="canEditElement"
    :can-edit="canEdit"
    :can-add="canAdd"
    :can-click="canClick"
    :open-object-actions="openObjectActions"
    :create-object-actions="createObjectActions"
    :create-report-actions="createReportActions"
    :show-actions="!!property.header"
    :grouping="grouping"
    :count="count"
    :show-more-info="showMoreInfo"
    @selectElement="element => $emit('selectElement', element)"
    @createButtonClick="actionId => $emit('createButtonClick', actionId)"
    @addButtonClick="$emit('addButtonClick')"
    @columnClick="columnClick"
    @group="group"
  />
</template>

<script>
import _ from "lodash";

import Converter from "./PlatoInlineEditLinkedTableConverter";
import DataTable from "@/components/package/Table/DataTable";

import { SORT_TYPES } from "@/components/utils/sorting/SortTypes";

import { mapGetters } from "vuex";

export default {
  components: {
    DataTable
  },

  props: {
    property: {
      type: Object,
      required: true
    },
    instanceId: {
      type: String,
      required: true
    },
    canEditElement: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canAdd: {
      type: Boolean,
      default: false
    },
    canClick: {
      type: Boolean,
      default: false
    },
    dataSourceId: {
      type: String,
      default: null
    },
    groupByClass: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    showMoreInfo: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      sortedColumn: null,
      grouping: {
        propertyAlias: "",
        ownPropertyAlias: ""
      }
    };
  },

  computed: {
    ...mapGetters({
      currentManifest: "MANIFEST",
      classes: "classes"
    }),

    tableColumns() {
      return Converter.convertColumns(this.sortedColumn, this.property);
    },

    tableProperties() {
      return Converter.convertElements(this.property, this.instanceId, this.classes, this.sortedColumn);
    },

    sort() {
      return this.property.field?.sort;
    },

    count() {
      return this.property.field?.count;
    },

    createObjectActions() {
      return _.map(this.property.field?.createCardAction,
        actionId => _.find(this.currentManifest.actions, { actionId }));
    },

    openObjectActions() {
      return _.map(this.property.field?.editCardAction,
        action => ({ classId: action.classId, action: _.find(this.currentManifest.actions, { actionId: action.actionId }) }));
    },

    createReportActions() {
      return _.map(this.property.field?.createReportAction,
        action => ({ classId: action.classId, action: _.find(this.currentManifest.actions, { actionId: action.actionId }) }));
    }
  },

  async created() {
    if (this.groupByClass) {
      this.grouping.propertyAlias = "prop_class_name";
    }

    if (this.sort) {
      this.columnClick(_.first(this.tableColumns));
    }
  },

  methods: {
    columnClick(column) {
      this.sortedColumn = _.cloneDeep(column);

      switch (this.sortedColumn.sort) {
      case SORT_TYPES.Unset:
        this.sortedColumn.sort = SORT_TYPES.Asc;
        break;
      case SORT_TYPES.Asc:
        this.sortedColumn.sort = SORT_TYPES.Desc;
        break;
      case SORT_TYPES.Desc:
        this.sortedColumn.sort = SORT_TYPES.Unset;
        break;
      }
    },

    group(column) {
      if (column.property == this.grouping.propertyAlias && column.ownProperty == this.grouping.ownPropertyAlias) {
        this.grouping.propertyAlias = "";
        this.grouping.ownPropertyAlias = "";
      }
      else {
        this.grouping.propertyAlias = column.property;
        this.grouping.ownPropertyAlias = column.ownProperty;
      }
    }
  }
};
</script>

<style>

</style>