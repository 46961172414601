export function convertFilter(filter) {
  let requestFilter = {
    alias: filter.alias
  };

  if (filter.value || (filter.type == "Boolean" && filter.value !== null)) {
    switch (filter.type) {
    case "Text":
      requestFilter.method = filter.operator;
      requestFilter.value = `%${filter.value}%`;
      return requestFilter;

    case "Range": {
      let rangeFilters = [];
      if (filter.value.gteValue != "") {
        let gteFilter = {
          alias: filter.alias,
          propertyId: filter.id,
          method: filter.operator.gteOp,
          value: filter.value.gteValue
        };
        rangeFilters.push(gteFilter);
      }

      if (filter.value.lteValue != "") {
        let lteFilter = {
          alias: filter.alias,
          propertyId: filter.id,
          method: filter.operator.lteOp,
          value: filter.value.lteValue
        };
        rangeFilters.push(lteFilter);
      }
      return rangeFilters;
    }
    case "Boolean":
      requestFilter.method = filter.operator;
      requestFilter.value = filter.value;
      return requestFilter;

    case "SelectionStatic":
      requestFilter.method = filter.operator,
      requestFilter.value = filter.value,
      requestFilter.filterById = true;
      return requestFilter;

    case "SelectionDynamic":
      requestFilter.method = filter.operator,
      requestFilter.value = filter.value.map(x => x.value),
      requestFilter.filterById = true;
      return requestFilter;

    case "Geojson":
      requestFilter.method = filter.operator;
      requestFilter.value = filter.value;
      return requestFilter;

    default:
      break;
    }
  }
}