import _ from "lodash";
import Vue from "vue";
import VueRouter from "vue-router";

import store from "./store/index.js";

import { AdminData } from "@/PlatoAPI";

const ConstructorMain = () => import("@/components/constructor/Main.vue");
const ConstructorElements = () => import("@/components/constructor/Elements.vue");
const ConstructorNewApp = () => import("@/components/constructor/manifest/NewApp.vue");
const ConstructorAppWrap = () => import("@/components/constructor/manifest/AppWrap.vue");
const ConstructorDataSourcesWrap = () => import("@/components/dataSourceConstructor/DataSourcesWrap.vue");
const ConstructorApp = () => import("@/components/constructor/manifest/App.vue");
const ConstructorAction = () => import("@/components/constructor/manifest/Action.vue");
const ConstructorPage = () => import("@/components/constructor/manifest/Page.vue");
const ConstructorPageItem = () => import("@/components/constructor/manifest/PageItem.vue");
const Menu = () => import("@/components/constructor/manifest/menu/Menu.vue");
const Navigator = () => import("@/components/navigatorComponents/Navigator.vue");
const DataSources = () => import("@/components/dataSourceConstructor/DataSources.vue");
const ConstructorDataSourcePageNew = () => import("@/components/dataSourceConstructor/DataSourcePage.vue");
const AdminSecurity = () => import("@/components/admin/AdminSecurity.vue");
const AdminPages = () => import("@/components/admin/AdminPages.vue");
const AdminConfigurations = () => import("@/components/admin/AdminConfigurations.vue");
const AdminShell = () => import("@/components/admin/AdminShell.vue");
const AdminMap = () => import("@/components/admin/AdminMap.vue");
const AdminDatabase = () => import("@/components/admin/AdminDatabase.vue");
const AdminWrap = () => import("@/components/admin/AdminWrap.vue");
const LoginWindow = () => import("@/components/LoginWindow.vue");
const LogoutWindow = () => import("@/components/LogoutWindow.vue");
const AppWrap = () => import("@/components/AppWrap.vue");
const AppList = () => import("@/components/AppList.vue");
const StaticPage = () => import("@/components/StaticPage.vue");
const PageWrap = () => import("@/components/PageWrap.vue");
const AboutPlatform = () => import("@/components/AboutPlatform.vue");
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location, onComplete, onAbort) {
  const result = originalPush.call(
    this,
    location,
    onComplete,
    onAbort
  );
  if (result) {
    return result.catch(err => {
      if (err.name !== "NavigationDuplicated") {
        throw err;
      }
    });
  }
  return result;
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location, onComplete, onAbort) {
  const result = originalReplace.call(
    this,
    location,
    onComplete,
    onAbort
  );
  if (result) {
    return result.catch(err => {
      if (err.name !== "NavigationDuplicated") {
        throw err;
      }
    });
  }
  return result;
};

const routes = [
  // Shell
  {
    path: "/",
    component: AppList
  },

  {
    path: "/page/:pageId",
    component: StaticPage,
    props: true
  },

  // AboutPlatform
  {
    path: "/about-platform",
    component: AboutPlatform
  },

  // Login
  {
    path: "/login",
    component: LoginWindow
  },

  // Logout
  {
    path: "/logout",
    component: LogoutWindow
  },

  // App Constructor
  {
    path: "/constructor",
    component: ConstructorMain
  },

  {
    path: "/admin",
    component: AdminWrap,
    children: [
      {
        path: "security",
        component: AdminSecurity,
        name: "AdminSecurity"
      },
      {
        path: "pages",
        component: AdminPages,
        name: "AdminPages"
      },
      {
        path: "shell",
        component: AdminShell,
        name: "AdminShell"
      },
      {
        path: "configurations",
        component: AdminConfigurations,
        name: "AdminConfigurations"
      },
      {
        path: "map",
        component: AdminMap,
        name: "AdminMap"
      },
      {
        path: "database",
        component: AdminDatabase,
        name: "AdminDatabase"
      }
    ]
  },

  {
    path: "/constructor/elements",
    component: ConstructorElements
  },

  //Создание нового приложения
  {
    path: "/constructor/app",
    component: ConstructorNewApp
  },

  // // Работа с уже созданным
  // {
  //   path: "/constructor/app/:appId",
  //   component: ConstructorAppWrap,
  // },

  //Главная
  {
    path: "/constructor/app/:appId",
    component: ConstructorAppWrap,
    children: [
      // Страница создания действия
      {
        path: "",
        component: ConstructorApp,
        name: "ConstructorApp"
      },
      // Страница создания действия
      {
        path: "action",
        component: ConstructorAction
      },
      // Страница действия
      {
        path: "action/:actionId",
        component: ConstructorAction
      },
      // Страница со страницами приложения
      // {
      //     path: 'pages',
      //     component: ConstructorPages
      // },
      // Страница создания новой страницы
      {
        path: "page",
        component: ConstructorPage
      },
      //  Редактирование страницы
      {
        path: "page/:pageId",
        component: ConstructorPage
      },
      // Создания нового компонента
      {
        path: "page/:pageId/page-item",
        component: ConstructorPageItem
      },
      // Редактирование компонента
      {
        path: "page/:pageId/page-item/:pageItemId",
        component: ConstructorPageItem
      },
      //Редактор меню
      {
        path: "menu",
        component: Menu
      }
    ]
  },

  // Datasource Constructor
  {
    path: "/dataSources",
    component: DataSources
  },
  {
    path: "/dataSources",
    component: ConstructorDataSourcesWrap,
    children: [
      {
        path: "new",
        component: ConstructorDataSourcePageNew
      },
      {
        path: ":dataSourceId",
        component: ConstructorDataSourcePageNew
      }
    ]
  },

  // Navigator
  {
    path: "/navigator",
    name: "navigator",
    component: Navigator
  },

  {
    path: "/:appId",
    component: AppWrap,
    props: true,
    children: [
      {
        path: ":pageId",
        component: PageWrap,
        name: "PageWrap",
        props: true
      }
    ]
  }
];

class VueRouterEx extends VueRouter {
  constructor(options) {
    super(options);
    const { addRoutes } = this.matcher;
    const { routes } = options;

    this.routes = routes;

    this.matcher.addRoutes = newRoutes => {
      this.routes.push(...newRoutes);
      addRoutes(newRoutes);
    };
  }
}

Vue.use(VueRouterEx);
const router = new VueRouterEx({
  mode: "history",
  // base: process.env.BASE_URL,
  routes: routes
});

router.beforeEach(
  async (to, from, next) => {
    if (store.getters.CURRENT_USER === null && to.path !== "/logout") {
      try {
        await store.dispatch("getCurrentUser");
        await store.dispatch("getShellManifest", false);
      }
      catch (error) {
        console.error(error);
        if (_.get(error, "inner.response.status") == 401) {
          if (to.path !== "/login") {
            try {
              await store.dispatch("loginGuest");

              await store.dispatch("getCurrentUser");
              await store.dispatch("getShellManifest");
            }
            catch (error) {
              const reason = _.get(error, "inner.response.data.reason", -1);
              next({ path: "/login", query: { reason: reason } });
            }
          }
        }
        else {
          Vue.prototype.$showError({
            message: "Ошибка при подключении к серверу",
            details: "Для продолжения работы перезагрузите страницу"
          });
        }
      }
    }

    if (store.getters.CURRENT_USER &&
      store.getters.CURRENT_USER.id == AdminData.GUEST_USER_ID &&
      store.getters.portalApplication !== "" &&
      !_.includes(to.path, "/" + store.getters.portalApplication) &&
      to.path !== "/login" &&
      to.path !== "/logout") {
      next({ path: "/" + store.getters.portalApplication });
    }

    let mainItems = store.getters.NAV_ITEMS.main;
    let item = _.find(mainItems, { href: to.path });

    if (item) {
      if (!_.isEmpty(item.scopes) && !_.some(item.scopes, scope => store.getters.scopeAccess(scope))) {
        store.dispatch("setInnerErrorCode", 403);
        return;
      }
    }

    next();
  }
);

export default router;
