<template>
  <PlatoButton
    type="perspective"
    class="item"
    :class="{'plato-btn--perspective--active' : isViewActive}"
    @click="setDataView"
  >
    {{ view.name }}
  </PlatoButton>
</template>

<script>
import dataViewMixin from "../dataViewMixin.js";

export default {
  mixins: [dataViewMixin]
};
</script>

<style scoped>
.item {
  margin: 0 8px 8px 0 !important;
}
</style>