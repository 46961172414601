import _ from "lodash";
import { DataClient } from "@/PlatoAPI";
const dataClient = new DataClient();
export default {
  namespaced: true,
  state: {
    currentObject: null,
    eTagCard: ""
  },
  getters: {
    currentObject: state => {
      return state.currentObject;
    },
    eTagCard: state => {
      return state.eTagCard;
    }
  },
  mutations: {
    setCurrentObject: (state, newCurrentObject) => {
      state.currentObject = newCurrentObject;
    },
    setETag: (state, eTag) => {
      state.eTagCard = eTag;
    }
  },
  actions: {
    removeCurrentObject({ commit, dispatch }, refreshUrl = true) {
      commit("setCurrentObject", null);
      if (refreshUrl) {
        dispatch("refreshUrl", { pushToHistory: false }, { root: true });
      }
    },
    changeCurrentObject({ commit, getters, dispatch, rootGetters }, { id, dataSourceId, forceRefresh, refreshUrl = true }) {
      if (forceRefresh || !_.includes(rootGetters.blockedDataSources, dataSourceId) || !getters.currentObject) {
        commit("setCurrentObject", {
          id,
          dataSourceId
        });
        dispatch("navigationObject/removeNavigationObject", {}, { root: true });
      }
      else {
        console.log("Смена currentObject не произошла");
      }

      if (refreshUrl) {
        dispatch("refreshUrl", { pushToHistory: false }, { root: true });
      }
    },
    async getFullCurrentObject({ getters }) {
      let currentObject = getters.currentObject;
      let body = {
        skip: 0,
        take: 1,
        filters: [],
        searchKeyword: "",
        instanceIds: [currentObject.id]
      };
      let response = await dataClient.getDataWithHeaders(currentObject.dataSourceId, body);
      return response.data;
    },
    async getCurrentObjectProperty({ getters }, _alias) {
      let currentObject = getters.currentObject;
      let body = {
        skip: 0,
        take: 1,
        filters: [],
        searchKeyword: "",
        instanceIds: [currentObject.id]
      };
      let response = await dataClient.getDataWithHeaders(currentObject.dataSourceId, body);
      return response.data;
    },
    setETag({ commit }, eTag) {
      commit("setETag", eTag);
    },
    async saveCurrentObject({ getters }, { fields, dataSourceId }) {
      await dataClient.updateMultipleData(dataSourceId, JSON.stringify({ data: fields }), getters.eTagCard);
    }
  }
};