import StateControllerBase from "@/store/StateControllerBase";

class Actions extends StateControllerBase {
  setAllSelectedObjects(context, selectedObjects) {
    context.commit("setAllSelectedObjects", selectedObjects);
  }

  setSelectedObjects(context, { dataSourceId, selectedObjects, refreshUrl = true }) {
    context.commit("setSelectedObjects", { dataSourceId, selectedObjects });

    if (refreshUrl) {
      context.dispatch("refreshUrl", { pushToHistory: false }, { root: true });
    }
  }

  removeSelectedObjects(context, { dataSourceId, refreshUrl = true }) {
    context.commit("removeSelectedObjects", dataSourceId);

    if (refreshUrl) {
      context.dispatch("refreshUrl", { pushToHistory: false }, { root: true });
    }
  }

  resetState(context) {
    context.commit("resetState");
  }
}

export default (new Actions).asPlainObject();