import _ from "lodash";
import axios from "axios";
import router from "../routes";

import AdminData from "./AdminData";
import AuthenticationClient from "./AuthenticationClient";

let jwtCache;

const LoginRedirectHttpClient = axios.create({
  headers: {
    "Content-Type": "application/json"
  }
});

const DefaultHttpClient = axios.create({
  redirect: true,
  headers: {
    "Content-Type": "application/json"
  }
});

const authentication = new AuthenticationClient();

DefaultHttpClient.interceptors.request.use(request => requestInterceptor(request));
DefaultHttpClient.interceptors.response.use(response => response, error => responseInterceptor(error));

LoginRedirectHttpClient.interceptors.request.use(request => requestInterceptor(request));
LoginRedirectHttpClient.interceptors.response.use(response => response, error => redirectInterceptor(error));

const setJwtCache = newCache => jwtCache = _.cloneDeep(newCache);

let isRefreshing = false;

const requestInterceptor = async request => {
  request.headers.Authorization = `Bearer ${jwtCache?.token}`;
  return request;
};

const responseInterceptor = async error => {
  if (_.get(error, "response.status") === 401) {
    if (isRefreshing) {
      while (isRefreshing) {
        await new Promise(resolve => setTimeout(resolve, 250));
      }

      return LoginRedirectHttpClient.request(error.response.config);
    }

    isRefreshing = true;

    try {
      await authentication.refresh();
      return LoginRedirectHttpClient.request(error.response.config);
    }
    catch (_error) {
      if (error.response.config.redirect) {
        const reason = _.get(_error,
          "inner.response.data.reason",
          AdminData.AUTHENTICATION_RESULT_REASON_TYPES.INVALID_REFRESH_TOKEN);

        router.push({ path: "/login", query: { reason: reason } }).catch(() => {});
      }
    }
    finally {
      isRefreshing = false;
    }
  }

  throw error;
};

const redirectInterceptor = async error => {
  if (_.get(error, "response.status") === 401) {
    const reason = _.get(error,
      "inner.response.data.reason",
      AdminData.AUTHENTICATION_RESULT_REASON_TYPES.INVALID_REFRESH_TOKEN);

    router.push({ path: "/login", query: { reason: reason } }).catch(() => {});
  }

  throw error;
};

export { DefaultHttpClient as default, setJwtCache };