import httpClient from "./DefaultHttpClient";
import { CancelTokenSource } from "../PlatoAPI";
import { rethrowPlatoApiError } from "./PlatoApiError";

const BASE_PATH = "/v1/FileApi";

class FileClient {
  constructor(bucket) {
    this._cancellationSource = null;
    this.bucket = bucket;
  }

  @rethrowPlatoApiError
  async listFiles() {
    return httpClient.get(`${BASE_PATH}/${this.bucket}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async deleteFile(id) {
    return httpClient.delete(`${BASE_PATH}/${this.bucket}/${id}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async createFile(name, type) {
    return httpClient.post(`${BASE_PATH}/${this.bucket}`, {}, {
      headers: {
        "Plato-Filename": encodeURI(name),
        "Plato-Filetype": type
      }
    })
      .then(response => response.data);
  }

  cancelLoading() {
    this._cancellationSource?.cancel();
  }

  @rethrowPlatoApiError
  async upload(id, file, onprogress) {
    this._cancellationSource = new CancelTokenSource();

    return httpClient.put(`${BASE_PATH}/${this.bucket}/${id}`, file, {
      headers: {
        "Content-type": "application/octet-stream"
      },
      cancelToken: this._cancellationSource.token,
      onUploadProgress: e => onprogress(e)
    })
      .then(responce => responce.data)
      .catch(e => {
        if (!CancelTokenSource.isCancel(e)) {
          throw e;
        }
      });
  }

  getBucket() {
    return this.bucket;
  }
}

export default FileClient;
