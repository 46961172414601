import httpClient from "./DefaultHttpClient";
import { rethrowPlatoApiError } from "./PlatoApiError";

const BASE_PATH = "/v1/ExportApi";

class ExportClient {
  @rethrowPlatoApiError
  async exportData(body, dataSourceId, cancelToken = null) {
    let response = await httpClient.post(`${BASE_PATH}/data/${dataSourceId}`, body, {
      cancelToken,
      responseType: "arraybuffer",
      headers: {
        "Accept": "application/octet-stream"
      }
    });
    return response;
  }
}

export default ExportClient;