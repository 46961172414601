<template>
  <div :class="classObj">
    <div
      v-if="hasHeaderData"
      :class="headerClassObj"
      :style="headerStyleObj"
    >
      <div class="card-header-inner">
        <div class="card-header-inner-left">
          <slot name="topLeft" />
        </div>
        <div class="card-header-inner-right">
          <slot name="topRight" />
        </div>
      </div>
    </div>
    <div
      :style="bodyStyleObj"
      class="card-body"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    elevation: {
      type: [Number, String],
      default: 0,
      validator(v) {
        v = v.toString();
        return ["0", "1", "2", "3", "4", "5"].includes(v);
      }
    },
    padding: {
      type: [Number, String],
      default: "10px"
    }
  },
  computed: {
    classObj() {
      const { elevation } = this;
      const classes = {};

      classes["card"] = true;
      classes["card-elevation-" + elevation] = true;

      return classes;
    },
    bodyStyleObj() {
      const { padding } = this;
      const style = {};

      style["padding"] = padding;

      return style;
    },
    headerClassObj() {
      const classes = {};

      classes["card-header"] = true;

      return classes;
    },
    headerStyleObj() {
      const { padding } = this;
      const style = {};

      style["padding-left"] = padding;
      style["padding-right"] = padding;

      return style;
    },
    hasHeaderData() {
      return this.$slots.topLeft || this.$slots.topRight;
    }
  }
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

@mixin card-theme-mixin(
  $background,
  $headerBackground,
  $headerBorderBottom,
  $elevation0BoxShadow,
  $elevation0Border,
  $elevation1BoxShadow,
  $elevation1Border,
  $elevation2BoxShadow,
  $elevation2Border,
  $elevation3BoxShadow,
  $elevation3Border,
  $elevation4BoxShadow,
  $elevation4Border,
  $elevation5BoxShadow,
  $elevation5Border
) {
  background: $background;

  &-header {
    background: $headerBackground;
    border-bottom: $headerBorderBottom;
  }

  &-elevation-0 {
    box-shadow: $elevation0BoxShadow;
    border: $elevation0Border;
  }

  &-elevation-1 {
    box-shadow: $elevation1BoxShadow;
    border: $elevation1Border;
  }

  &-elevation-2 {
    box-shadow: $elevation2BoxShadow;
    border: $elevation2Border;
  }

  &-elevation-3 {
    box-shadow: $elevation3BoxShadow;
    border: $elevation3Border;
  }

  &-elevation-4 {
    box-shadow: $elevation4BoxShadow;
    border: $elevation4Border;
  }

  &-elevation-5 {
    box-shadow: $elevation5BoxShadow;
    border: $elevation5Border;
  }
}

.card,
.-theme-light .card {
  @include card-theme-mixin(
    $background: $N0,
    $headerBackground: $N0,
    $headerBorderBottom: 1px solid $N40,
    $elevation0BoxShadow: none,
    $elevation0Border: none,
    $elevation1BoxShadow: none,
    $elevation1Border: 1px solid $N40,
    $elevation2BoxShadow: (
      0 2px 6px -2px rgba(9, 30, 66, 0.31),
      0 0 1px rgba(9, 30, 66, 0.31)
    ),
    $elevation2Border: none,
    $elevation3BoxShadow: (
      0 4px 8px -2px rgba(9, 30, 66, 0.31),
      0 0 1px rgba(9, 30, 66, 0.31)
    ),
    $elevation3Border: none,
    $elevation4BoxShadow: (
      0 6px 10px -2px rgba(9, 30, 66, 0.31),
      0 0 1px rgba(9, 30, 66, 0.31)
    ),
    $elevation4Border: none,
    $elevation5BoxShadow: (
      0 8px 12px -2px rgba(9, 30, 66, 0.31),
      0 0 1px rgba(9, 30, 66, 0.31)
    ),
    $elevation5Border: none
  );
}

.card {
  border-radius: 3px;

  &-header {
    display: flex;
    flex-direction: column;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    height: 44px;
    justify-content: center;

    &-inner {
      align-content: center;
      flex-direction: row;
      display: flex;
      justify-content: space-between;

      &-left {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        overflow-wrap: anywhere;
        align-items: center;

        & > * {
          margin: 0;
          padding: 0;
        }
      }

      &-right {
        display: flex;
        align-content: center;
        justify-content: flex-end;
        flex-wrap: wrap;

        & > * {
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  &-body {
    p:first-child {
      margin-top: 0;
    }
  }
}
</style>
