<template>
  <PlatoSearch
    :value="value"
    :options="searchOptions"
    show-options-panel
    search-on-click
    @input="search"
    @change="updateCurrentQuery"
    @select="select"
    @click="click"
  />
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    searchInit: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      value: this.searchInit,
      showOptions: false,
      currentQuery: ""
    };
  },

  computed: {
    ...mapGetters({
      searchKeywords: "localStorage/searchKeywords"
    }),

    searchOptions() {
      return _.uniq(_.filter(this.searchKeywords, word => word.includes(this.currentQuery)));
    }
  },

  watch: {
    searchInit(value) {
      this.value = value;
    }
  },

  created() {
    this.getSearchKeywords();
  },

  methods: {
    ...mapActions({
      getSearchKeywords: "localStorage/getSearchKeywords",
      addSearchKeyword: "localStorage/addSearchKeyword"
    }),

    blur() {
      setTimeout(() => {
        this.showOptions = false;
      }, 150);
    },

    updateCurrentQuery(value) {
      this.currentQuery = _.isEmpty(value) ? "" : value;
    },

    click(value) {
      this.$emit("search-change", value);
      this.addSearchKeyword(value);
      this.getSearchKeywords();
      this.value = value;
    },

    select(value) {
      this.$emit("search-change", value);
      this.value = value;
    },

    search: _.debounce(function(value) {
      this.$emit("search-change", value);
    }, 750, { leading: false, trailing: true })
  }
};
</script>

<style>

</style>