import httpClient from "./DefaultHttpClient";
import { rethrowPlatoApiError } from "./PlatoApiError";

const BASE_PATH = "/v1/ShellApi";

class ShellClient {
  @rethrowPlatoApiError
  async getShellManifest() {
    return httpClient.get(`${BASE_PATH}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getVersions() {
    return httpClient.get(`${BASE_PATH}/version`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async saveShellManifest(manifest) {
    return httpClient.post(`${BASE_PATH}`, manifest)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getShellManifestInfo() {
    return httpClient.get(`${BASE_PATH}/info`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async saveShellManifestInfo(manifest) {
    return httpClient.post(`${BASE_PATH}/info`, manifest)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getShellPage(id) {
    return httpClient.get(`${BASE_PATH}/pages/${id}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async saveShellPage(page) {
    return httpClient.post(`${BASE_PATH}/pages`, page)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async deleteShellPage(id) {
    return httpClient.delete(`${BASE_PATH}/pages/${id}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async updateShellPagesOrder(pagesIds) {
    return httpClient.post(`${BASE_PATH}/reorder_pages`, pagesIds)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getShellPages() {
    return httpClient.get(`${BASE_PATH}/pages`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getShellPagesInfo() {
    return httpClient.get(`${BASE_PATH}/pages/info`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getMapProjections() {
    return httpClient.get(`${BASE_PATH}/map_projections`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getMapProjection(id) {
    return httpClient.get(`${BASE_PATH}/map_projections/${id}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async saveMapProjection(mapProjection) {
    return httpClient.post(`${BASE_PATH}/map_projections`, mapProjection)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async deleteMapProjection(id) {
    return httpClient.delete(`${BASE_PATH}/map_projections/${id}`)
      .then(response => response.data);
  }
}

export default ShellClient;