<template>
  <div v-if="!loading && currentObject">
    <div class="plato-card__body_content">
      <PlatoCardBodyOwnProperties
        :properties="filteredOwnProperties"
        :data-source-id="dataSourceId"
        :read-only="readOnly"
        @saveField="saveField"
        @fly-to="$emit('flyTo')"
      />

      <PlatoCardBodyLinkedProperties
        :properties="filteredLinkedProperties"
        :data-source-id="dataSourceId"
        :read-only="readOnly"
        :is-new="isNew"
        :show-more-info="showMoreInfo"
        @saveField="saveField"
        @setLinkedProperties="setLinkedProperties"
      />
    </div>

    <div
      v-if="showNoContent"
      class="plato-card__body_no-content_sector"
    >
      Нет данных
    </div>
  </div>
  <PlatoLoader
    v-else-if="loading"
  />
</template>

<script>
import _ from "lodash";

import platoCardBodyDataMixin from "./platoCardBodyDataMixin.js";
import PlatoCardBodyLinkedProperties from "./PlatoCardBodyLinkedProperties.vue";
import PlatoCardBodyOwnProperties from "./PlatoCardBodyOwnProperties.vue";

export default {
  components: {
    PlatoCardBodyOwnProperties,
    PlatoCardBodyLinkedProperties
  },

  mixins: [platoCardBodyDataMixin],

  props: {
    readOnly: {
      type: Boolean,
      required: true
    },
    isNew: {
      type: Boolean,
      required: true
    },
    dataSourceId: {
      type: String,
      required: true
    },
    showEmpty: {
      type: Boolean,
      required: true
    },
    showMoreInfo: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      linkedProperties: null,
      aliases: []
    };
  },

  computed: {
    showNoContent() {
      return this.ownProperties.length == 0 && this.linkedProperties.length == 0;
    },

    filteredLinkedProperties() {
      return _.filter(
        this.linkedProperties,
        prop => this.showEmpty || !_.isEmpty(prop.values)
      );
    },

    filteredOwnProperties() {
      return _.filter(this.ownProperties, prop => {
        return (
          (this.showEmpty ||
            (prop.value !== "" &&
              prop.value !== null &&
                (prop.header.tableType != "Geometry" ||
                  (prop.header.tableType === "Geometry" &&
                    prop.value.coordinates.length != 0)))) &&
          //поля которые не показываются при создание новой записи
          (prop.header.tableType != "Geometry" || !this.isNew)
        );
      });
    }
  }
};
</script>

<style>

</style>