<template>
  <DataTable
    checkboxes
    :selected="selected"
    :elements="tableProperties"
    :columns="columns"
    :show-actions="false"
    :show-select-all="showSelectAll"
    :loading="dataLoading"
    :show-pagination="showPagination"
    :grouping="grouping"
    :show-more-info="true"
    @checkElements="value => $emit('changeSelected', value)"
    @columnClick="columnClick"
    @group="group"
    @increaseTake="$emit('increaseTake')"
  />
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

import Converter from "./PlatoLinkedPropEditTableConverter";
import DataTable from "@/components/package/Table/DataTable";
import { SORT_TYPES } from "@/components/utils/sorting/SortTypes";

export default {
  components: {
    DataTable
  },
  props: {
    selected: {
      type: Array,
      required: true
    },
    linkedProperty: {
      type: Object,
      required: true
    },
    showSelectAll: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: false
    },
    showPagination: {
      type: Boolean,
      default: false
    },
    aliases: {
      type: Array,
      default: () => []
    },
    searchKeyword: {
      type: String,
      default: ""
    },
    sort: {
      type: String,
      default: "asc"
    },
    dataSourceId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      grouping: {
        propertyAlias: "",
        ownPropertyAlias: ""
      }
    };
  },

  computed: {
    ...mapGetters({
      linkedProps: "linkedPropEdit/linkedProps",
      sorting: "linkedPropEdit/sorting",
      classes: "classes",
      dataLoading: "linkedPropEdit/dataLoading"
    }),

    columns() {
      return Converter.convertColumns(this.linkedProperty, this.sorting);
    },

    tableProperties() {
      return Converter.convertElements(this.linkedProps, this.selected, this.classes);
    }
  },

  async mounted() {
    await this.getLinkedProps();
    if (this.columns) {
      this.addSorting({
        alias: this.columns[0].ownProperty || this.columns[0].property,
        sortType: "asc"
      });
    }
  },

  methods: {
    ...mapActions({
      addSorting: "linkedPropEdit/addSorting",
      changeSortingType: "linkedPropEdit/changeSortingType",
      removeSorting: "linkedPropEdit/removeSorting",
      unshiftSorting: "linkedPropEdit/unshiftSorting"
    }),

    async getLinkedProps() {
      await this.$store.dispatch("linkedPropEdit/getLinkedProps", {
        id: this.dataSourceId,
        alias: this.linkedProperty.header.alias,
        aliases: this.aliases,
        searchKeyword: this.searchKeyword,
        sort: this.sort
      });
    },

    group(col) {
      let column = _.cloneDeep(col);

      if (column.sort) {
        this.removeSorting(this.grouping.ownPropertyAlias || this.grouping.propertyAlias);

        if (column.property !== "prop_class_name") {
          switch (column.sort) {
          case SORT_TYPES.Asc:
          case SORT_TYPES.Desc:
            column.sort = SORT_TYPES.Asc;
            this.unshiftSorting({
              alias: column.ownProperty || column.property,
              sortType: column.sort
            });
            this.grouping.propertyAlias = column.property;
            this.grouping.ownPropertyAlias = column.ownProperty;
            break;
          case SORT_TYPES.Unset:
            this.grouping.propertyAlias = "";
            this.grouping.ownPropertyAlias = "";
            break;
          }
        }
      }
    },

    columnClick(col) {
      let column = _.cloneDeep(col);

      if (column.sort) {
        switch (column.sort) {
        case SORT_TYPES.Unset:
          column.sort = SORT_TYPES.Asc;
          this.addSorting({
            alias: column.ownProperty || column.property,
            sortType: column.sort
          });
          break;
        case SORT_TYPES.Asc:
          column.sort = SORT_TYPES.Desc;
          this.changeSortingType({
            alias: column.ownProperty || column.property,
            sortType: column.sort
          });
          break;
        case SORT_TYPES.Desc:
          this.removeSorting(column.ownProperty || column.property);
          if (column.property == this.grouping.propertyAlias && column.ownProperty == this.grouping.ownPropertyAlias) {
            this.grouping.propertyAlias = "";
            this.grouping.ownPropertyAlias = "";
          }
          break;
        }
      }
    }
  }
};
</script>

<style>

</style>