import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    dataSourceId: {
      type: String,
      required: true
    },

    view: {
      type: Object,
      required: true
    },

    isNewView: {
      type: Boolean
    },

    writeAccess: {
      type: Boolean
    }
  },

  computed: {
    ...mapGetters({
      hiddenColumns: "grouping/hiddenColumns"
    }),

    grouping() {
      return this.$store.getters["grouping/grouping"](this.dataSourceId);
    },

    appliedSorting() {
      return this.$store.getters["sorting/sorting"](this.dataSourceId);
    },

    activeFilters() {
      return this.$store.getters["filters/activeFilters"](this.dataSourceId);
    },

    isViewActive() {
      return _.isEqual(this.activeFilters, this.view.filters)
      && _.isEqual(this.appliedSorting, this.view.sorts)
      && (_.isEqual(this.grouping, this.view.grouping) || this.isGroupingEmpty)
      && _.isEqual(this.hiddenColumns, this.view.hiddenColumns);
    },

    isGroupingEmpty() {
      return _.isEmpty(this.grouping.propertyAlias) && _.isEmpty(this.view.grouping?.propertyAlias);
    }
  },

  mounted() {
    if (this.isViewActive) {
      this.setViewName();
    }
  },

  methods: {
    ...mapActions({
      setFilters: "filters/setFilters",
      clearSorting: "sorting/clearSorting",
      addSorting: "sorting/addSorting",
      setGrouping: "grouping/setGrouping",
      setHiddenColumns: "grouping/setHiddenColumns",
      setDataViewName: "dataView/setDataViewName",
      setEditableName: "dataView/setEditableName"
    }),

    setDataView() {
      if (!this.isViewActive) {
        this.setSorting(_.cloneDeep(this.view.sorts));

        this.setFilters({ dataSourceId: this.dataSourceId, filters: _.cloneDeep(this.view.filters), refreshUrl: true });

        this.setGrouping({ dataSourceId: this.dataSourceId, property: _.cloneDeep(this.view.grouping) });
        this.setHiddenColumns(_.cloneDeep(this.view.hiddenColumns));
        this.setViewName();
      }
    },

    setViewName() {
      if (this.isNewView) {
        if (this.writeAccess) {
          this.setDataViewName("");
          this.setEditableName(true);
          return;
        }
        this.setDataViewName("Текущее отображение");
        this.setEditableName(false);
        return;
      }

      this.setDataViewName(this.view.name);
      this.setEditableName(false);
    },

    setSorting(sorting) {
      this.clearSorting(this.dataSourceId);
      sorting.forEach(sort => this.addSorting({ dataSourceId: this.dataSourceId, sorting: sort }));
    }
  }
};