<template>
  <div class="plato-card">
    <PlatoCardHeader
      v-if="metadata"
      :show-empty="showEmpty"
      :read-only="readOnly || !writeAccess"
      :page-item-settings="pageItemSettings"
      :is-new="isNew"
      :data-source-id="dataSourceId"
      :current-object="currentObject"
      :context="context"
      :write-access="writeAccess"
      :hierarchical-property="hierarchicalProperty"
      :name="name"
      @changeShowEmpty="changeShowEmpty"
    />
    <div class="plato-card__body">
      <PlatoCardBody
        v-if="metadata"
        :show-empty="showEmpty"
        :read-only="readOnly || !writeAccess"
        :page-item-settings="pageItemSettings"
        :is-new="isNew"
        :data-source-id="dataSourceId"
      />
      <PlatoLoader v-else />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import PlatoCardBody from "./PlatoCardBody";
import PlatoCardHeader from "./PlatoCardHeader";
import { scopes } from "@/store/Access";

import { annotationProperties, getAnnotationPropertyValue } from "@/components/utils/annotationProperty/AnnotationProperty.js";
import { mapGetters } from "vuex";

export default {
  name: "PlatoCard",
  components: { PlatoCardBody, PlatoCardHeader },
  props: {
    pageItemSettings: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    dataSourceId: {
      type: String,
      required: true
    },
    isNew: {
      type: Boolean,
      default: false
    },
    context: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      showEmpty: this.isNew
    };
  },

  computed: {
    ...mapGetters({
      loading: "card/dataLoading"
    }),

    metadata() {
      return this.$store.getters["card/metadata"](this.dataSourceId);
    },

    /** Настройки полей из манофеста */
    manifestShowEmpty() {
      return _.chain(this.pageItemSettings)
        .find(["pageItemSettingId", "ShowEmpty"])
        .get("value")
        .value()?.value;
    },

    hierarchicalProperty() {
      if (!this.currentObject) {
        return null;
      }

      if (!this.metadata) {
        return null;
      }

      let merged = _.cloneDeep(this.currentObject.linkedProperties);

      merged.forEach(element => {
        element.parentAlias = _.find(this.metadata.linkedProperties, { alias: element.alias })?.parentAlias;
      });

      return _.find(merged, property => getAnnotationPropertyValue(this.metadata, property.alias, annotationProperties.PLATO_TO_PARENT) && !property.parentAlias);
    },

    currentObject() {
      if (this.isNew) {
        return this.$store.getters["card/newObject"];
      }
      else {
        return this.$store.getters["card/currentObject"];
      }
    },

    writeAccess() {
      return this.$store.getters.writeAccess({ scope: scopes.APPLICATION_DATA, resource: this.dataSourceId });
    }
  },

  watch: {
    hierarchicalProperty: {
      immediate: true,
      async handler(value, oldValue) {
        if (value && oldValue && value.alias == oldValue.alias) {
          return;
        }

        if (value && !_.isEmpty(value.values) && value.values[0]) {
          await this.$store.dispatch("card/getParentExploreInstance", value.values[0].instanceId);
        }
        else {
          await this.$store.dispatch("card/removeParentExploreInstance");
        }
      }
    }
  },

  async mounted() {
    if (this.manifestShowEmpty) {
      this.showEmpty = true;
    }

    await this.$store.dispatch("card/getMetadata", this.dataSourceId);
  },

  beforeDestroy() {
    if (this.currentObject?.id == this.$store.getters["card/currentObject"]?.id) {
      this.$store.dispatch("card/deleteCurrentObject");
    }
  },

  methods: {
    changeShowEmpty(value) {
      this.showEmpty = value;
    }
  }
};
</script>

<style lang="scss">
@import "../styles/colors.scss";
@import "../styles/variables.scss";

.plato-card {
  color: $text-color;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>