<template>
  <div
    ref="colorPicker"
    class="color-picker"
    :style="{ 'background-color': colorValue }"
    @click="isOpenPopper = true"
  >
    <PlatoPopper
      v-if="isOpenPopper"
      v-click-outside="
        () => {
          isOpenPopper = false;
          $forceUpdate();
        }
      "
      :offset="[0, 8]"
      :target-element="$refs.colorPicker"
      placement="top"
    >
      <Slider
        :swatches="[]"
        :value="color"
        :disable-alpha="true"
        @input="updateValue"
      />
    </PlatoPopper>
  </div>
</template>
<script>
import { Slider } from "vue-color";

export default {
  name: "PlatoSlideColorPicker",
  components: {
    Slider
  },
  props: ["color"],
  data() {
    return {
      isOpenPopper: false,
      colorValue: this.color
    };
  },
  methods: {
    updateValue(value) {
      this.colorValue = value.hex;
      this.$emit("change", value);
    }
  }
};
</script>

<style lang="scss" scoped>
.vc-sketch {
  box-shadow: none;
}
.vc-slider-swatches{
    display: none !important;
}

.color-picker {
  cursor: pointer;
  border-radius: 50%;
  display: inline-block;
  width: 14px;
  height: 14px;
  margin: 0 2px;
  border: solid 1px #dfe1e6;
}

.color-picker .plato-popper {
  cursor: auto;
}
</style>