import _ from "lodash";

import { SORT_TYPES } from "@/components/utils/sorting/SortTypes";

export default {
  setReducedValue(linkedProperties, reducedValue, instanceId) {
    linkedProperties.forEach(linkedProperty => {
      _.flatMapDeep(linkedProperty.values).forEach(linkedPropertyValue => {
        if (linkedPropertyValue.linkedInstanceId == instanceId
          && !_.some(reducedValue[linkedProperty.alias], { alias: linkedPropertyValue.alias, instanceId: linkedPropertyValue.instanceId })) {
          let obj = reducedValue[linkedProperty.alias] || [];
          _.set(reducedValue, linkedProperty.alias, [linkedPropertyValue, ...obj]);
          this.setReducedValue(linkedProperties, reducedValue, linkedPropertyValue.instanceId);
        }
      });
    });
  },

  convertElements(linkedPropsItems, selectedObjectsId, classes) {
    if (_.isEmpty(linkedPropsItems)) {
      return [];
    }

    let items = linkedPropsItems.map(value => {
      let reducedValue = _.reduce(value.ownProperties, (acc, instance) => {
        return _.set(acc, instance.alias || "name", instance.value);
      }, { instanceId: value.id });

      if (value.linkedProperties) {
        this.setReducedValue(value.linkedProperties, reducedValue, reducedValue.instanceId);
      }

      reducedValue.selected = _.some(selectedObjectsId, prop => prop == reducedValue.instanceId);
      reducedValue.style = value.style;

      let itemclass = _.find(classes, { id: value.classId });
      if (itemclass) {
        let label = _.find(itemclass.annotationProperties, { type: "rdfs:label" }).value;
        reducedValue.prop_class_name = label;
      }

      return reducedValue;
    });

    return items;
  },

  convertColumns(property, sorting) {
    let linkedProperty = property.header;

    let columns = _.chain(linkedProperty.properties)
      .filter(prop => prop.tableType != "Geometry" && prop.tableType != "RichText")
      .map(prop => ({
        property: prop.alias,
        tableType: prop.tableType,
        label: prop.name,
        sort: SORT_TYPES.Unset
      })).value();

    if (linkedProperty.linkedProperties) {
      linkedProperty.linkedProperties.forEach(linkedProperty => {
        let linkedColumns = _.chain(linkedProperty.header.properties)
          .filter(prop => prop.tableType != "Geometry" && prop.tableType != "RichText")
          .map(prop => ({
            property: linkedProperty.header.alias,
            ownProperty: prop.alias,
            tableType: prop.tableType,
            stringView: prop.stringView,
            sort: SORT_TYPES.Unset,
            label: linkedProperty.header.properties.length == 1 ? linkedProperty.header.name
              : linkedProperty.header.name + ` (${prop.name})`
          })).value();

        columns.push(...linkedColumns);
      });
    }

    if (!_.isEmpty(property.field?.columns)) {
      let newColumns = [];

      property.field.columns.forEach(fieldColumn => {
        let foundColumn = _.find(columns, column => {
          if (column.property == fieldColumn.propertyAlias) {
            if (fieldColumn.ownPropertyAlias) {
              if (column.ownProperty !== fieldColumn.ownPropertyAlias) {
                return false;
              }
            }

            return true;
          }
        });
        if (foundColumn) {
          foundColumn.stringView = fieldColumn.stringView;
          newColumns.push(foundColumn);
        }
      });

      columns = newColumns;
    }

    columns.push({
      label: "Класс",
      property: "prop_class_name",
      sort: SORT_TYPES.Unset,
      tableType: "Text",
      hidden: true
    });

    columns.forEach(column => {
      let sort = _.find(sorting, sort => sort.alias == column.ownProperty || sort.alias == column.property);
      if (sort) {
        column.sort = sort.sortType;
      }
    });

    return columns;
  }
};
