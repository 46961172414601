import httpClient from "./DefaultHttpClient";
import { CancelTokenSource } from "../PlatoAPI";
import { rethrowPlatoApiError } from "./PlatoApiError";

const BASE_PATH = "/v1/ApplicationApi";

class ApplicationClient {
  @rethrowPlatoApiError
  async getAllApplications() {
    return httpClient.get(`${BASE_PATH}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getAllApplicationsInfo() {
    return httpClient.get(`${BASE_PATH}/info`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async updateApplicationsOrder(applicationIds) {
    return httpClient.put(`${BASE_PATH}`, applicationIds)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getAplication(id) {
    return httpClient.get(`${BASE_PATH}/${id}`)
      .then(response => response);
  }

  @rethrowPlatoApiError
  async exportApplication(id) {
    return httpClient.get(`${BASE_PATH}/${id}/export`, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/octet-stream"
      }
    })
      .then(response => response.data)
      .catch(e => {
        if (!CancelTokenSource.isCancel(e)) {
          throw e;
        }
      });
  }

  @rethrowPlatoApiError
  async importApplication(application, replace) {
    return httpClient.post(`${BASE_PATH}/import`, application, {
      headers: {
        "Content-Type": "application/octet-stream"
      },
      params: { replace }
    })
      .then(response => response.data)
      .catch(e => {
        if (!CancelTokenSource.isCancel(e)) {
          throw e;
        }
      });
  }

  @rethrowPlatoApiError
  async getApplicationImportInfo(application) {
    return httpClient.post(`${BASE_PATH}/info`, application, {
      headers: {
        "Content-Type": "application/octet-stream"
      }
    })
      .then(response => response.data)
      .catch(e => {
        if (!CancelTokenSource.isCancel(e)) {
          throw e;
        }
      });
  }

  @rethrowPlatoApiError
  async saveApplication(manifest) {
    return httpClient.post(`${BASE_PATH}`, manifest)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async copyApplication(id) {
    return httpClient({
      method: "copy",
      url: `${BASE_PATH}/${id}`
    })
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async updateApplication(id, manifest, etag) {
    return httpClient.post(`${BASE_PATH}/${id}`, manifest, {
      headers: {
        "If-Match": etag
      }
    })
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async deleteApplication(id) {
    return httpClient.delete(`${BASE_PATH}/${id}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async addDataView(appId, dataView, etag) {
    return httpClient.post(`${BASE_PATH}/${appId}/dataview`, dataView, {
      headers: {
        "If-Match": etag
      }
    })
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async updateDataView(appId, dataView, etag) {
    return httpClient.put(`${BASE_PATH}/${appId}/dataview`, dataView, {
      headers: {
        "If-Match": etag
      }
    })
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async updateDataViews(appId, dataViews, etag) {
    return httpClient.put(`${BASE_PATH}/${appId}/dataviews`, dataViews, {
      headers: {
        "If-Match": etag
      }
    })
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async deleteDataView(appId, dataViewForDelete, etag) {
    return httpClient.delete(`${BASE_PATH}/${appId}/dataview/${dataViewForDelete.dataSourceId}/${dataViewForDelete.name}`, {
      headers: {
        "If-Match": etag
      }
    })
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async setDefaultDataView(appId, defaultDataView, etag) {
    return httpClient.post(`${BASE_PATH}/${appId}/defaultDataView`, defaultDataView, {
      headers: {
        "If-Match": etag
      }
    })
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async deleteDefaultDataView(appId, dataSourceId, etag) {
    return httpClient.delete(`${BASE_PATH}/${appId}/defaultDataView/${dataSourceId}`, {
      headers: {
        "If-Match": etag
      }
    })
      .then(response => response.data);
  }
}

export default ApplicationClient;