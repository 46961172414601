<template>
  <PlatoLoader v-if="loading" />
  <div
    v-else
    class="plato-linkedprop-edit"
  >
    <PlatoAsideHeader
      header="Связи"
      @close="closeWithoutSave"
    >
      <div slot="header_text">
        <p
          class="plato-linkedprop-edit__header_left-group_title"
        >
          {{ linkedPropEditObject.prop.header.name }}
        </p>
        <div class="plato-linkedprop-edit__header_left-group_count">
          Выбрано {{ checkedIds.length }}<span v-if="getCardinality(linkedPropMetadata) > 0"> из {{ getCardinality(linkedPropMetadata) }}</span>
        </div>
      </div>

      <PlatoButton
        type="primary"
        :disabled="added.length == 0 && removed.length == 0"
        @click="$emit('cancelLinkedPropEdit')"
      >
        Сохранить
      </PlatoButton>
    </PlatoAsideHeader>

    <div class="plato-linkedprop-edit__body">
      <div v-if="linkedDataSource != null && linkedDataSource.metadata.dataSourceType === dataSourceTypes.egrul">
        <PlatoTabs>
          <PlatoTab
            name="Связанные объекты"
          >
            <PlatoSearch
              @change="value => searchInputVаlueChange(value)"
            />
            <PlatoLinkedPropEditTable
              :selected="checkedIds"
              :linked-property="linkedPropEditObject.prop"
              :show-select-all="!getCardinality(linkedPropMetadata)"
              :loading="dataLoading"
              :show-pagination="take < totalCount"
              :aliases="aliases"
              :search-keyword="searchKeyword"
              :sort="sort"
              :data-source-id="linkedPropEditObject.datasourceId"
              class="plato-linkedprop-edit__body_values-table"
              @changeSelected="changeSelected"
              @increaseTake="increaseTake"
            />
          </PlatoTab>
          <PlatoTab
            name="Получение объектов из внешнего источника данных"
          >
            <EgrulDataEditor
              :data-source-id="linkedDataSource.dataSourceId"
              :metadata="linkedDataSource.metadata"
              @add-instance-to-data-source="addNewInstanceToExternalDataSource"
            />
          </PlatoTab>
        </PlatoTabs>
      </div>
      <div v-else>
        <PlatoSearch
          @change="value => searchInputVаlueChange(value)"
        />
        <PlatoLinkedPropEditTable
          :selected="checkedIds"
          :linked-property="linkedPropEditObject.prop"
          :show-select-all="!getCardinality(linkedPropMetadata)"
          :loading="dataLoading"
          :show-pagination="take < totalCount"
          :aliases="aliases"
          :search-keyword="searchKeyword"
          :sort="sort"
          :data-source-id="linkedPropEditObject.datasourceId"
          class="plato-linkedprop-edit__body_values-table"
          @changeSelected="changeSelected"
          @increaseTake="increaseTake"
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { asyncScheduler, Subject } from "rxjs";
import { distinctUntilChanged, throttleTime } from "rxjs/operators";
import { mapActions, mapGetters } from "vuex";

import PlatoLinkedPropEditTable from "./PlatoLinkedPropEditTable";
import { annotationProperties, getAnnotationPropertyValue } from "@/components/utils/annotationProperty/AnnotationProperty.js";

import { DATASOURCE_TYPES } from "@/components/utils/dataSource/types.js";

import PlatoTab from "@/components/package/Tabs/PlatoTab.vue";
import PlatoTabs from "@/components/package/Tabs/PlatoTabs.vue";

import EgrulDataEditor from "@/components/package/ExternalDataEditor/EgrulDataEditor.vue";

export default {
  components: {
    PlatoLinkedPropEditTable,
    PlatoTabs,
    PlatoTab,
    EgrulDataEditor
  },

  props: {
    linkedPropEditObject: {
      type: Object,
      required: true
    },
    metadata: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      currentCheckedIds: [],
      checkedIds: [],
      loading: true,
      searchSubject: new Subject(),
      searchKeyword: "",
      sort: null,
      linkedDataSource: null,
      dataSourceTypes: DATASOURCE_TYPES,
      saveOnClose: true
    };
  },

  computed: {
    ...mapGetters({
      linkedProps: "linkedPropEdit/linkedProps",
      dataLoading: "linkedPropEdit/dataLoading",
      take: "linkedPropEdit/take",
      skip: "linkedPropEdit/skip",
      totalCount: "linkedPropEdit/totalCount",
      sorting: "linkedPropEdit/sorting"
    }),

    linkedPropMetadata() {
      return this.linkedPropEditObject.metadata ? _.find(
        this.linkedPropEditObject.metadata.linkedProperties,
        prop => prop.alias === this.linkedPropEditObject.prop.header.alias
      ) : [];
    },

    removed() {
      return _.difference(this.currentCheckedIds, this.checkedIds);
    },

    added() {
      return _.difference(this.checkedIds, this.currentCheckedIds);
    },

    aliases() {
      return _.chain(this.linkedPropEditObject)
        .get("prop.field.columns", [])
        .flatMap(column => column.ownPropertyAlias ? column.ownPropertyAlias : column.propertyAlias)
        .value();
    }
  },

  watch: {
    async take() {
      await this.getLinkedProps();
    },

    sorting: {
      deep: true,
      async handler() {
        this.resetTake();
        await this.getLinkedProps();
      }
    }
  },

  async mounted() {
    await this.getLinkedDataSource();
    //await this.getLinkedProps();
    this.getSelected();

    const searchObs = this.searchSubject.asObservable();
    searchObs
      .pipe(throttleTime(500, asyncScheduler, { leading: false, trailing: true }))
      .pipe(distinctUntilChanged())
      .subscribe(async v => {
        await this.searchChange(v);
      });

    this.loading = false;
  },

  beforeDestroy() {
    if (this.saveOnClose) {
      this.onSaveButtonClick();
    }
    this.$store.dispatch("linkedPropEdit/resetState");
  },

  methods: {
    ...mapActions({
      increaseTake: "linkedPropEdit/increaseTake"
    }),

    closeWithoutSave() {
      this.saveOnClose = false;
      this.$emit("cancelLinkedPropEdit");
    },

    searchInputVаlueChange(value) {
      this.searchSubject.next(value);
    },

    async searchChange(value) {
      this.searchKeyword = value;
      await this.getLinkedProps();
      this.resetTake();
    },

    resetTake() {
      this.$store.dispatch("linkedPropEdit/resetTake", this.dataSourceId);
    },

    getSelected() {
      if (this.linkedPropEditObject.prop.values != null) {
        this.currentCheckedIds = this.linkedPropEditObject.prop.values.map(x => x[0].instanceId);
      }

      this.checkedIds = _.cloneDeep(this.currentCheckedIds);
    },

    changeSelected(selected) {
      let cardinality = this.getCardinality(this.linkedPropMetadata);

      if (cardinality > 0 && selected.length > cardinality) {
        let deleteCount = selected.length - cardinality;
        selected.splice(0, deleteCount);
      }

      this.checkedIds = selected;
    },

    getCardinality(property) {
      return getAnnotationPropertyValue(this.metadata,
        property.alias,
        annotationProperties.PLATO_CARDINALITY);
    },

    async getLinkedProps() {
      await this.$store.dispatch("linkedPropEdit/getLinkedProps", {
        id: this.linkedPropEditObject.datasourceId,
        alias: this.linkedPropEditObject.prop.header.alias,
        aliases: this.aliases,
        searchKeyword: this.searchKeyword,
        sort: this.sort
      });
    },

    async onSaveButtonClick() {
      if (this.removed.length != 0 || this.added.length != 0) {
        let newObjectLinkedPropValue = _.filter(this.linkedProps, object => _.includes(this.checkedIds, object.id));

        let newObjects = _.flatMap(newObjectLinkedPropValue, object => {
          let props = [];

          object.ownProperties.forEach(prop => {
            prop.instanceId = object.id;
            prop.classId = object.classId;

            if (prop.alias === this.linkedPropEditObject.prop.header.properties[0].alias) {
              props.unshift(prop);
            }
            else {
              props.push(prop);
            }
          });

          return props;
        });

        this.$emit("saveLinkedProps", {
          value: { added: this.added, removed: this.removed, objects: newObjects },
          prop: this.linkedPropEditObject.prop
        });
      }
    },

    checkAllItems(items) {
      let checked = _.uniq(items.map(x => x.checked));
      return checked.length === 1 && checked[0];
    },

    async getLinkedDataSource() {
      if (!_.isEmpty(this.linkedPropEditObject.prop.header.linkedDataSourceId)) {
        let linkedDataSourceMetadata = await this.$store.dispatch("card/getLinkedDataSourceMetadata", this.linkedPropEditObject.prop.header.linkedDataSourceId);
        this.linkedDataSource = {
          dataSourceId: this.linkedPropEditObject.prop.header.linkedDataSourceId,
          metadata: linkedDataSourceMetadata
        };
      }
    },

    async addNewInstanceToExternalDataSource(instance) {
      try {
        await this.$store.dispatch("card/saveExternalInstance", { dataSourceId: this.linkedDataSource.dataSourceId, instance });
        this.notification.success({
          title: `Объект добавлен в источник данных "${this.linkedDataSource.metadata.class.name}"`
        });
        await this.getLinkedProps();
      }
      catch (error) {
        console.error(error);
        this.notification.danger({ title: "Ошибка добавления объекта из внешнего источника данных" });
      }
    }
  }
};
</script>

<style lang="scss">
.plato-linkedprop-edit {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &_left-group {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      &_title {
        margin: 0;
      }
      &_count {
        font-size: 12px;
        color: #6b778c;
      }
    }

    &_func-buttons {
      display: flex;
      flex-direction: row;
      margin-left: 5px;
    }
  }
  &__body {
    flex: 1 1 auto;
    overflow: auto;

    &_values-table {
      margin-top: 10px;
    }

    &_group-name {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      color: #505f79;
      margin-bottom: 5px;
    }
    &_table {
      border-collapse: collapse;
      width: 100%;
      margin-bottom: 30px;
      thead {
        th {
          padding-top: 8px;
          padding-bottom: 7px;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          color: #6b778c;
          text-align: left;
        }
      }
      tr {
        border-bottom: 1px solid #dfe1e6;
      }
      tbody {
        td {
          padding-top: 22px;
          padding-bottom: 9px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          color: #172b4d;
        }
      }
      &_checkbox {
        width: 24px;
        height: 24px;
        label {
          margin-left: 5px;
        }
      }
    }
  }
}

.aside-search {
  margin-top: 10px;
}
</style>