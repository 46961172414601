<template>
  <i :class="`${type} fa-${icon}`" />
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "fas"
    },
    icon: {
      type: String,
      required: true
    }
  }
};
</script>
