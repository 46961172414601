<template>
  <div class="plato-tabs">
    <Draggable
      v-if="!(hideIfOne && tabs.length == 1)"
      :value="tabs"
      class="plato-tabs__header"
      v-bind="dragOptions"
      @change="dragged"
    >
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="plato-tabs__header_button"
        :class="{'plato-tabs__header_button--active': tab.isActive, 'plato-tabs__header_button--disabled': tab.isDisabled}"
        @click="selectTab(tab, index)"
      >
        {{ tab.name }}
      </div>
    </Draggable>

    <div
      :hideTabs="hideIfOne && tabs.length == 1"
      class="plato-tabs__body"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import Draggable from "vuedraggable";

export default {
  name: "PlatoTabs",

  components: {
    Draggable
  },

  props: {
    firstSectorIndex: {
      type: [Number, String],
      default: 0
    },
    draggable: {
      type: Boolean,
      default: false
    },
    hideIfOne: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      tabs: [],
      domChildren: []
    };
  },

  computed: {
    dragOptions() {
      return {
        group: "tag-header",
        disabled: !this.draggable
      };
    }
  },

  watch: {
    "firstSectorIndex": function() {
      this.updateTabIndex();
    },

    "domChildren": function() {
      let oldTabs = _.clone(this.tabs);

      this.tabs = _.filter(this.$children, child => child.name !== undefined);

      let newTab = this.tabs[this.tabs.length - 1];

      if (oldTabs < this.tabs) {
        this.selectTab(newTab);
      }
      else if (!_.some(this.tabs, "isActive") && !_.isEmpty(newTab)) {
        this.selectTab(newTab);
      }
    }
  },

  mounted() {
    this.tabs = _.filter(this.$children, child => child.name !== undefined);
    this.domChildren = this.$children;

    if (this.tabs.length) {
      this.updateTabIndex();
    }
  },

  methods: {
    updateTabIndex() {
      let index = this.firstSectorIndex && this.firstSectorIndex < this.tabs.length ? this.firstSectorIndex : 0;
      this.selectTab(this.tabs[index], index, true);
    },

    selectTab(currentTab, index, mounted = false) {
      this.tabs.forEach(tab => tab.isActive = false);
      currentTab.isActive = true;

      if (!mounted) {
        this.$emit("changeTab", index);
      }
    },

    dragged(value) {
      this.$emit("dragged", value);

      let oldTab = this.tabs[value.moved.oldIndex];
      let currentTab = _.find(this.tabs, "isActive");
      let index = this.tabs.indexOf(currentTab);

      let mode = 0;

      if (index <= value.moved.newIndex && index > value.moved.oldIndex) {
        mode = -1;
      }
      else if (index >= value.moved.newIndex && index < value.moved.oldIndex) {
        mode = 1;
      }

      this.selectTab(oldTab == currentTab ? this.tabs[value.moved.newIndex] : this.tabs[index + mode]);
    }
  }
};
</script>
<style lang="scss" scoped>
.plato-tabs {
    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        border-bottom: 2px solid #e0e2e6;

        &_button {
            padding: 5px;
            cursor: pointer;
            width: 100%;
            text-align: center;
            background: none;
            color: #505f79;
            &--active {
                box-shadow: 0px 2px 0px 0px #1f67d3;
                color: #1f67d3;
            }
            &--disabled {
                color: #505f799e;
                pointer-events: none;
            }
            &:hover {
                color: #0065ff;
            }
            &:active {
                color: #0747a6;
            }
        }
    }
    &__body {
      &:not([hideTabs=true]) {
        margin-top: 10px;
      }
    }
}
</style>