<template>
  <div
    class="filter-tab-view"
  >
    <div class="filter-tab-view-label">
      {{ filter.name }}
    </div>
    <TextFilter
      v-if="filter.type === `Text`"
      :id="filter.alias"
      :property="filter"
      :data-source-id="dataSourceId"
      @filterChange="filterChange"
    />
    <NumberFilter
      v-else-if="filter.type === `Range`"
      :id="filter.alias"
      :property="filter"
      :data-source-id="dataSourceId"
      @filterChange="filterChange"
    />
    <BooleanFilter
      v-if="filter.type === `Boolean`"
      :id="filter.alias"
      :property="filter"
      :data-source-id="dataSourceId"
      @filterChange="filterChange"
    />
    <FilterTable
      v-if="filter.type === 'SelectionDynamic'"
      :filter="filter"
      :data-source-id="dataSourceId"
      @filterChange="selectfilterChange"
    />
  </div>
</template>

<script>
import _ from "lodash";
import BooleanFilter from "../Filters/BooleanFilter.vue";
import NumberFilter from "../Filters/NumberFilter.vue";
import TextFilter from "../Filters/TextFilter.vue";

import FilterTable from "./FilterTable.vue";

export default {
  components: {
    NumberFilter,
    TextFilter,
    BooleanFilter,
    FilterTable
  },
  props: {
    filter: {
      type: Object,
      required: true
    },
    dataSourceId: {
      type: String,
      required: true
    }
  },
  methods: {
    removeFilter(filter) {
      if (filter.type == "Geojson") {
        this.$store.dispatch("filters/setGeometryFilter", "");
      }
      else {
        this.$store.dispatch("filters/editFilter", {
          dataSourceId: this.dataSourceId,
          filter: {
            alias: filter.alias,
            type: filter.type,
            value: null
          }
        });
      }
    },

    filterChange: _.debounce(function(value) {
      this.$store.dispatch("filters/editFilter", {
        dataSourceId: this.dataSourceId,
        filter: value
      });
    }, 750, { leading: false, trailing: true }),

    selectfilterChange(e) {
      this.$store.dispatch("filters/editFilter", {
        dataSourceId: this.dataSourceId,
        filter: e
      });
    }
  }

};
</script>
<style lang="scss" scoped>
.filter-tab-view{
  padding: 0 0 15px 15px;
  position: relative;
  width: 90%;
  &-label{
    padding: 10px 0;
    background-color: #ffffff;
    font-size: 16px;
    position: sticky;
    top:0;
    z-index: 1
  }
}
</style>
