<template>
  <div class="plato-inline-edit">
    <div class="plato-inline-edit__header">
      {{ label }}
      <div
        v-if="isGenerated"
        title="Автогенерируемое поле"
        class="plato-inline-edit__badge plato-badge plato-badge--light-blue "
      >
        А
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    isGenerated: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.plato-inline-edit {
  // margin-bottom: 5px;
  width: 100%;
  &__header {
    font-size: 12px;
    color: #6b778c;
    padding-left: 6px;
    margin-bottom: 4px;

    display: flex;
  }

  &__badge {
    height: 14px;
    padding: 0 2px 0 3px;
    border-radius: 4px;
    cursor: default;
  }

  &__body {
    &_value {
      margin-left: 7px;
    }
  }
}
</style>