import httpClient from "./DefaultHttpClient";
import { rethrowPlatoApiError } from "./PlatoApiError";

const BASE_PATH = "/v1/DataApi";

class DataClient {
  @rethrowPlatoApiError
  async updateData(id, data) {
    let response = await httpClient.post(`${BASE_PATH}/${id}/update`, data);
    return response.data;
  }

  @rethrowPlatoApiError
  async getDataPreview(body, cancelToken = null) {
    let response = await httpClient.post(`${BASE_PATH}/data_preview`, body, { cancelToken });
    return response.data;
  }

  @rethrowPlatoApiError
  async updateMultipleData(id, data, eTag) {
    let response = await httpClient.post(`${BASE_PATH}/${id}/multipleUpdate`, data, {
      headers: {
        "If-Match": eTag
      }
    });
    return response.data;
  }

  @rethrowPlatoApiError
  async insertData(id, data) {
    return httpClient.post(`${BASE_PATH}/${id}/insert`, data)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getDataByAlias(id, alias, body) {
    return httpClient.post(`${BASE_PATH}/${id}/data/${alias}`, body)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getDataByInstanceAndAlias(id, instanceId, alias, body) {
    return httpClient.post(`${BASE_PATH}/${id}/data/${instanceId}/${alias}`, body)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getData(id, body, cancelToken = null) {
    let response = await httpClient.post(`${BASE_PATH}/${id}/data/`, body, { cancelToken });
    return response.data;
  }

  @rethrowPlatoApiError
  async getDataWithHeaders(id, body, cancelToken = null) {
    return await httpClient.post(`${BASE_PATH}/${id}/data/`, body, { cancelToken });
  }

  @rethrowPlatoApiError
  async getGeneratedData(id) {
    let response = await httpClient.get(`${BASE_PATH}/${id}/generatedData`);
    return response.data;
  }

  @rethrowPlatoApiError
  async getLargeData(id, body, cancelToken = null) {
    let response = await httpClient.post(`${BASE_PATH}/${id}/stream_data/`, body, { cancelToken });
    return { items: response.data, totalCount: response.headers.totalCount };
  }

  @rethrowPlatoApiError
  async getMetadata(id) {
    let response = await httpClient.get(`${BASE_PATH}/${id}/metadata`);
    return response.data;
  }

  @rethrowPlatoApiError
  async getHintData(id) {
    return httpClient.get(`${BASE_PATH}/${id}/instance`,
      {
        params: {
          id: id,
          mode: "hint"
        }
      })
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getInstance(id) {
    return httpClient.get(`${BASE_PATH}/instance`,
      {
        params: {
          id: id
        }
      })
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async deleteInstances(dsId, ids) {
    let response = await httpClient.post(`${BASE_PATH}/${dsId}/delete`, JSON.stringify({ ids: ids }));

    return response.data;
  }

  @rethrowPlatoApiError
  async deleteInstance(dsId, id) {
    let response = await httpClient.post(`${BASE_PATH}/${dsId}/delete`, JSON.stringify({ ids: [id] }));

    return response.data;
  }

  @rethrowPlatoApiError
  async getExternalDataSourceData(dsId, searchKeyword, cancelToken = null) {
    let response = await httpClient.post(`${BASE_PATH}/${dsId}/externalData/`, { dataSourceId: dsId, searchKeyword: searchKeyword }, { cancelToken });
    return response.data;
  }

  @rethrowPlatoApiError
  async updateExternalDataSourceInstance(requestBody, cancelToken = null) {
    let response = await httpClient.post(`${BASE_PATH}/${requestBody.request.dataSourceId}/updateExternalData/`, requestBody, { cancelToken });
    return response.data;
  }
}

export default DataClient;