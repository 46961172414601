<template>
  <div class="plato-filters-line__wrapp">
    <PlatoFilterLine
      :data-source-id="dataSourceId"
      :show-checkbox="showCheckbox"
      :show-filters="showFilters"
      :show-choosen-filters="showChoosenFilters"
      :show-search="showSearch"
      :search-init="searchInit"
      :filter-visible="filterVisible"
      @search-change="(e) => $emit('search-change', e)"
      @filters-panel-open="changeFilterVisibility"
    />
    <div
      v-if="filterVisible"
      class="plato-filters-line__filters-panel"
    >
      <div class="plato-filters-line__filters-panel_body">
        <PlatoFilterPanel :data-source-id="dataSourceId" />
      </div>
      <div
        class="plato-filters-line__filters-panel_close-button"
        @click="filterVisible = false"
      >
        <span class="material-icons material-icons__small"> close </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PlatoFilterComplex",
  components: {},
  props: {
    dataSourceId: {
      type: String
    },
    showCheckbox: {
      default: true
    },
    showFilters: {
      default: true
    },
    showChoosenFilters: {
      default: true
    },
    showSearch: {
      default: true
    },
    searchInit: {
      type: String
    }
  },
  data() {
    return {
      filterVisible: false
    };
  },
  computed: {
    ...mapGetters({
      searchKeywords: "localStorage/searchKeywords"
    })
  },
  mounted() {
    this.$store.dispatch("localStorage/getSearchKeywords");
  },
  methods: {
    changeFilterVisibility() {
      if (this.filterVisible) {
        this.filterVisible = false;
        this.$emit("filters-panel-close");
      }
      else {
        this.filterVisible = true;
        this.$emit("filters-panel-open");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
@import "../styles/variables.scss";

.plato-filters-line {
  display: flex;
  flex-direction: column;
  // padding: 0px 15px 10px;
  &__body {
    display: flex;
    justify-content: space-between;
    align-content: center;
    &_left {
      display: flex;
      align-items: center;
      &_filter-btns {
        display: flex;
        align-items: center;
        .multiselect {
          min-height: auto;
        }
      }
    }
  }
  &_right {
    display: flex;
    flex: 1 1 auto;
    align-self: flex-end;
  }
  // Панель фильтров
  &__filters-panel {
    position: absolute;
    background: #fff;
    z-index: 3000;
    top: 120px;
    left: 15px;
    height: 80vh;
    box-shadow: 0 20px 32px -8px rgba(9, 30, 66, 0.25),
      0 0 1px 0 rgba(9, 30, 66, 0.31);
    &:hover {
      .plato-filters-line__filters-panel_close-button {
        display: block;
      }
    }
    &_body {
      overflow: auto;
      height: 100%;
    }
    &_close-button {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      border-bottom-right-radius: 100%;
      padding: 6px 0 0 6px;
      background-color: #ffffff;
      border-bottom: 1px solid $border-color;
      border-right: 1px solid $border-color;
      width: 35px;
      height: 36px;
      cursor: pointer;
      user-select: none;
    }
  }
}

// Строка поиска
.plato-search {
  position: relative;
  flex: 0 1 230px;
  margin-left: auto;
  input {
    margin-right: 25px;
  }
  &__icon {
    position: absolute;
    right: 6px;
    top: 6px;
  }
  // Предсказания для строки поиска
  &__predict {
    position: absolute;
    width: 100%;
    z-index: 989;
    cursor: default;
    background: #fff;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0 0 3px 3px;
    box-shadow: 0 4px 6px 0 rgba(32, 33, 36, 0.28);
    // padding-bottom: 5px;
    overflow: hidden;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
    &_advice {
      display: flex;
      margin: 0;
      padding: 5px 10px;
      overflow: hidden;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:hover {
        background-color: $btn-hov-bg-color;
        color: $btn-hov-color;
      }
      &_delete {
        display: flex;
        flex: 0 1 auto;
        cursor: pointer;
        padding: 0 10px;
        height: 100%;
        &:hover {
          color: #c4c4ff;
        }
      }
      &_text {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        min-width: 0;
        max-height: none;
        padding: 6px 0;
      }
    }
  }
}
</style>