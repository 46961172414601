<template>
  <PlatoLoader
    v-if="loading"
  />
  <div v-else>
    <div
      v-if="!_.isEmpty(images)"
      class="plato-card__body_carusel"
    >
      <div class="plato-card__body_carusel_body">
        <div
          v-for="(image, index) in images"
          :key="_uid + image.id"
          class="plato-card__body_carusel_body_item"
          @click="imgIndex = index"
        >
          <img
            :src="FileObjectConverter.getUrl(image)"
            :alt="image.name"
            class="image"
          >
          <div class="mask" />
        </div>
      </div>
      <div
        class="plato-card__body_carusel_next"
        @click="imgIndex = 0"
      >
        <Icon icon="images" />
      </div>
      <VueGallerySlideshow
        :images="images.map((image) => FileObjectConverter.getUrl(image))"
        :index="imgIndex"
        @close="imgIndex = null"
      />
    </div>
    <EgrulDataEditor
      v-if="newObjectType === dataSourceTypes.egrul"
      :data-source-id="dataSourceId"
      :metadata="metadata"
      :table-params="manifestFieldParams"
      @add-instance-to-data-source="addNewInstanceToExternalDataSource"
    />
    <div
      v-else
      class="plato-card__body_instance-properties"
    >
      <PlatoCardBodyProperties
        v-if="_.isEmpty(manifestFieldParams)"
        :data-source-id="dataSourceId"
        :read-only="readOnly"
        :is-new="isNew"
        :show-empty="showEmpty"
        :show-more-info="showMoreInfo"
        @fly-to="flyTo"
      />

      <template v-else>
        <PlatoTabs
          :first-sector-index="sectorId"
          hide-if-one
          @changeTab="changeTab"
        >
          <PlatoTab
            v-for="(sector, index) in manifestFieldParams"
            :key="index"
            :name="sector.name"
          >
            <template v-if="sectorId == index || (!sectorId && index == 0) || isNew">
              <PlatoCardBodyLinkedProperty
                v-if="getIsSingle(sector)"
                :sector="sector"
                :read-only="readOnly"
                :is-new="isNew"
                :data-source-id="dataSourceId"
                :show-empty="showEmpty"
                :name-setting="manifestNameParams"
                :show-more-info="showMoreInfo"
              />

              <PlatoCardBodySector
                v-else
                :sector="sector"
                :read-only="readOnly"
                :is-new="isNew"
                :data-source-id="dataSourceId"
                :show-empty="showEmpty"
                :name-setting="manifestNameParams"
                :show-more-info="showMoreInfo"
                @fly-to="flyTo"
              />
            </template>
          </PlatoTab>
        </PlatoTabs>
      </template>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import VueGallerySlideshow from "vue-gallery-slideshow";
import { mapGetters } from "vuex";

import FileObjectConverter from "@/PlatoAPI/FileObjectConverter.js";
import PlatoCardBodyLinkedProperty from "./PlatoCardBodyLinkedProperty/PlatoCardBodyLinkedProperty.vue";
import PlatoCardBodyProperties from "./PlatoCardBodyProperties.vue";
import PlatoCardBodySector from "./PlatoCardBodySector.vue";
import PlatoTab from "@/components/package/Tabs/PlatoTab.vue";
import PlatoTabs from "@/components/package/Tabs/PlatoTabs.vue";
import { annotationProperties, getAnnotationPropertyValue } from "@/components/utils/annotationProperty/AnnotationProperty.js";

import { DATASOURCE_TYPES } from "@/components/utils/dataSource/types.js";

import EgrulDataEditor from "@/components/package/ExternalDataEditor/EgrulDataEditor.vue";

export default {
  name: "PlatoCardBody",
  components: {
    PlatoTabs,
    PlatoTab,
    PlatoCardBodySector,
    PlatoCardBodyLinkedProperty,
    PlatoCardBodyProperties,
    VueGallerySlideshow,
    EgrulDataEditor
  },

  props: {
    showEmpty: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    pageItemSettings: {
      type: Array,
      required: true
    },
    dataSourceId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      linkedProperties: null,
      FileObjectConverter: FileObjectConverter,
      imgIndex: null,
      loading: true,
      dataSourceTypes: DATASOURCE_TYPES
    };
  },

  computed: {
    ...mapGetters({
      sectorId: "card/sectorId",
      newObjectType: "card/newObjectType"
    }),

    showMoreInfo() {
      let param = _.find(this.pageItemSettings, ["pageItemSettingId", "ShowMoreInfo"]);
      return _.get(param, "value.value");
    },

    metadata() {
      return this.$store.getters["card/metadata"](this.dataSourceId);
    },

    dataSourceType() {
      return this.metadata.dataSourceType;
    },

    manifestFieldParams() {
      return _.chain(this.pageItemSettings)
        .find(["pageItemSettingId", "Fields"])
        .get("value")
        .value();
    },

    manifestNameParams() {
      return _.chain(this.pageItemSettings)
        .find(["pageItemSettingId", "Name"])
        .get("value", {})
        .value();
    },

    ownProperties() {
      if (this.currentObject) {
        return _.map(this.metadata.ownProperties, prop => {
          let propValue = _.find(
            this.currentObject.ownProperties,
            valProp => valProp.alias === prop.alias
          );

          return {
            header: prop,
            value: _.get(propValue, "value", null),
            isEmpty: _.isEmpty(prop.value),
            type: "own"
          };
        });
      }
      return [];
    },

    images() {
      let photoProps = _.filter(this.ownProperties, property => property.header.tableType == "File"
        && getAnnotationPropertyValue(this.metadata, property.header.alias, annotationProperties.PLATO_IS_PHOTO)
        && property.value);

      let photos = [];

      if (!_.isEmpty(photoProps)) {
        photoProps.forEach(prop => {
          photos = [...photos, ...FileObjectConverter.parseFiles(prop.value)];
        });
      }

      return photos;
    },

    currentObject() {
      if (this.isNew) {
        return this.$store.getters["card/newObject"];
      }
      else if (this.dataSourceId) {
        return _.cloneDeep(this.$store.getters["card/editingCurrentObject"]);
      }

      return null;
    }
  },

  created() {
    this.$eventBus.$on("component:cancelEditing", this.cancel);
    if (this.isNew && this.dataSourceType != this.dataSourceTypes.internal) {
      this.$store.dispatch("card/setNewObjectType", this.dataSourceType);
    }
    this.loading = false;
  },

  beforeDestroy() {
    this.$eventBus.$off("component:cancelEditing", this.cancel);
  },

  methods: {
    getIsSingle(sector) {
      let fields = _.chain(sector.groups).flatMapDeep("lines").flatMapDeep("fields").filter().value();

      if (fields.length == 1 && !_.isEmpty(fields[0]["field-0"]) && _.isEmpty(fields[0]["field-1"])) {
        if (_.some(this.metadata.linkedProperties, { alias: fields[0]["field-0"].propertyAlias })) {
          return true;
        }
      }

      return false;
    },

    flyTo() {
      this.$store.dispatch("flyToObject/changeFlytoState", {
        dataSourceId: this.dataSourceId,
        objectId: this.currentObject.id
      });
    },

    isEmptyGroup(group) {
      return !_.some(group.lines, line => _.some(line.properties, prop => prop !== null));
    },

    cancel() {
      this.setCurrentObject();
      this.$nextTick(() => this.$store.dispatch("card/clearChangedFields", this.dataSourceId));
    },

    setCurrentObject() {
      if (!this.isNew && this.dataSourceId) {
        let currentObject = _.cloneDeep(this.$store.getters["card/currentObject"]);

        this.$store.dispatch("card/setEditingCurrentObject", currentObject);
      }
    },

    changeTab(index) {
      this.$store.dispatch("card/setSectorId", { sectorId: index });
    },

    addNewInstanceToExternalDataSource(instance) {
      instance.ownProperties.forEach(property => {
        if (!_.isEmpty(property.value)) {
          this.$store.dispatch("card/changeNewObjectField", {
            type: "own",
            alias: property.alias,
            value: property.value,
            dataSourceId: this.dataSourceId
          });
        }
      });
      this.$store.dispatch("card/setNewObjectType", this.dataSourceTypes.internal);
    }
  }
};
</script>

<style lang="scss">
@import "../styles/colors.scss";
@import "../styles/variables.scss";

.plato-card__body {
  margin-top: 10px;
  flex: 1 1 auto;
  padding: 0px 15px 15px 15px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  &_carusel {
    margin-bottom: 10px;
    height: 96px;
    flex: 0 0 96px;
    position: relative;
    overflow: hidden;
    &_body {
      display: flex;
      height: 100%;
      &_item {
        height: 100px;
        width: 150px;
        overflow: hidden;
        position: relative;
        flex: 0 0 auto;
        cursor: pointer;
        & ~ & {
          margin-left: 12px;
        }
        &:hover {
          .mask {
            opacity: 0;

            transform: translate(265px, 145px) rotate(45deg);
          }
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          // width: calc(100% - 10px);
          // height: 100%;
          user-select: none;
          pointer-events: none;
          transition: all 0.2s ease-in;
        }
        .mask {
          background-color: rgba(12, 19, 27, 0.3);
          width: 300px;
          padding: 105px;
          height: 200px;
          opacity: 1;
          transform: translate(-80px, -125px) rotate(45deg);
          transition: all 0.4s ease-in-out;
        }
      }
    }
    &_next {
      z-index: 1;
      position: absolute;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      outline: none;
      right: 0;
      background: linear-gradient(90deg, transparent, #ffffff 80%);
      padding: 0 5px 0 25px;
      .fas {
        font-weight: 300;
        border-radius: 50%;
        font-size: 14px;
        cursor: pointer;
        color: #172b4d;
        border: solid 1px #dfe1e6;
        background: #f5f6f8;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background: #eff0f2;
        }
      }
    }
    .vgs {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      &__container {
        position: unset;
        margin: 0 0 15px;
        background-color: transparent;
        min-width: 50vw;
        border-radius: 0;
        flex: 1 1 auto;
      }
      &__gallery {
        position: unset;
        margin: 0;
        margin-bottom: 20px;
      }
      &__close {
        @media screen and (max-width: 768px){
          top: 60px;
        }
      }
    }
  }
  &_no-content {
    &_sector {
      //margin-top: -7px;
      color: #6b778c;
    }

    &_table {
      margin: 14px 0 14px 14px;
      color: #6b778c;
    }
  }
  &_content {
    display: flex;
    padding-bottom: 20px;

    &_col {
      display: flex;
      flex-direction: column;
      min-width: 50%;
      & ~ & {
        margin-left: 10px;
      }
      &_field {
        width: 100%;
        // margin-bottom: 5px;
        & ~ & {
          margin-top: 5px;
        }
      }
    }
  }
}
</style>