<template>
  <div class="sorting-menu">
    <div class="sorting-menu__header">
      Сортировка
    </div>

    <div
      class="sorting-menu__options"
    >
      <SortingFields
        :sorting-fields="sortingFields"
        :sorting-option="sortingOptions"
        :applied-sorting="appliedSorting"
        @changeProperty="changeProperty"
        @changeSortType="changeSortingTypeValue"
        @removeSorting="removeSortingField"
      />
    </div>
    <div
      v-if="sortingFields.length < allPropertiesSorting.length"
      class="sorting-menu__buttons"
    >
      <PlatoButton
        @click="addField"
      >
        Добавить
      </PlatoButton>
      <PlatoButton
        @click="onDefaultSortingClick"
      >
        По умолчанию
      </PlatoButton>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import SortingFields from "./SortingFields.vue";
import { mapActions } from "vuex";

export default {
  components: { SortingFields },

  props: {
    dataSourceId: {
      type: String,
      required: true
    },
    metadata: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      sortingFields: []
    };
  },

  computed: {
    ownPropertiesSortings() {
      return this.$store.getters["sorting/ownPropertiesSortingData"](this.metadata);
    },

    mappedOwnPropertiesSortings() {
      return _.map(this.ownPropertiesSortings, prop => ({
        display: prop.name,
        value: prop.alias
      }));
    },

    linkedPropertiesSortings() {
      return this.$store.getters["sorting/linkedPropertiesSortingData"](this.metadata);
    },

    mappedLinkedPropertiesSortings() {
      return _.map(this.linkedPropertiesSortings, prop => ({
        display: prop.parentName + ` (${prop.name})`,
        value: prop.alias
      }));
    },

    allPropertiesSorting() {
      return [...this.ownPropertiesSortings, ...this.linkedPropertiesSortings];
    },

    sortingOptions() {
      return [...this.mappedOwnPropertiesSortings, ...this.mappedLinkedPropertiesSortings];
    },

    appliedSorting() {
      return this.$store.getters["sorting/sorting"](this.dataSourceId);
    }
  },

  watch: {
    appliedSorting() {
      this.setSortingFields();
    }
  },

  mounted() {
    this.setSortingFields();
  },

  methods: {
    ...mapActions({
      removeSorting: "sorting/removeSorting",
      addSorting: "sorting/addSorting",
      changeSortingAlias: "sorting/changeSortingAlias",
      changeSortingType: "sorting/changeSortingType",
      applyDefaultSorting: "sorting/applyDefaultSorting"
    }),

    setSortingFields() {
      if (_.isEmpty(this.appliedSorting)) {
        this.sortingFields = [{ alias: "", sortType: "asc" }];
      }
      else {
        this.sortingFields = _.cloneDeep(this.appliedSorting);
      }
    },

    addField() {
      if (this.sortingFields[this.sortingFields.length - 1].alias === "") {
        return;
      }
      this.sortingFields.push({
        alias: "",
        sortType: "asc"
      });
    },

    changeProperty(sorting) {
      if (_.some(this.appliedSorting, { alias: sorting.newSorting.alias })) {
        return;
      }

      let newSorting = _.find(this.allPropertiesSorting, { alias: sorting.newSorting.alias });
      newSorting.sortType = sorting.newSorting.sortType;

      if (_.some(this.appliedSorting, { alias: sorting.oldSorting.alias })) {
        this.changeSortingAlias({ dataSourceId: this.dataSourceId, oldSortingAlias: sorting.oldSorting.alias, newSorting: newSorting });
      }
      else {
        this.addSorting({ dataSourceId: this.dataSourceId, sorting: newSorting });
      }
    },

    changeSortingTypeValue(sorting) {
      this.changeSortingType({
        dataSourceId: this.dataSourceId,
        sorting: sorting
      });
    },

    removeSortingField(sorting) {
      if (sorting.alias === "") {
        this.sortingFields.pop();
      }
      else {
        this.removeSorting({ dataSourceId: this.dataSourceId, sorting });
      }
    },

    onDefaultSortingClick() {
      this.applyDefaultSorting({ dataSourceId: this.dataSourceId, metadata: this.metadata });
    }
  }
};
</script>

<style lang="scss" scoped>
.sorting-menu{

    &__header{
      margin:10px;
      font-weight: 600;
      color:#6b778c;
    }

    &__options{
      padding: 5px 10px;
      width: 380px;
    }

    &__buttons{
      height: 39.18px;
      display: flex;
      justify-content:space-between;
      padding: 0 10px;
    }
  }
</style>