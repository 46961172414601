<template>
  <div
    ref="wrap"
    class="plato-app-card-wrap"
  >
    <!--TODO клик не работает с router link, переделать - переход по ссылке, логику срабатывающую по клику -->
    <RouterLink
      :to="href"
      :class="{ 'plato-app-card_with-image': image.length }"
      :style="{
        '--pac-left-line-color': appMainColor,
        '--pac-card-color': appCardBackgroundColor,
        '--pac-font-color': appCardTextColor
      }"
      class="plato-app-card"
      @click.native="onCardClick"
    >
      <div
        v-if="image.length"
        class="plato-app-card__image"
      >
        <img :src="image">
      </div>
      <div
        ref="target"
        class="plato-app-card__body"
      >
        <div class="plato-app-card__body-icon">
          <ImageIcon
            :size="60"
            :icon="icon"
          />
        </div>
        <div class="plato-app-card__body-title">
          {{ name }}
        </div>

        <div
          ref="infoButton"
          class="plato-app-card__body_info-button"
          :class="{'plato-app-card__body_info-button--active':infoButtonClicked}"
          @click.prevent="onInfoButtonClick"
        >
          <Icon icon="info-circle" />
        </div>
      </div>
    </RouterLink>
    <PlatoPopper
      v-if="infoButtonClicked"
      :id="_uid"
      v-click-outside="hide"
      class="plato-app-card__info"
      :target-element="$refs.target"
      :boundary="$parent.$refs.apps"
    >
      <div>
        <p
          v-if="description"
          class="plato-app-card__info_text"
        >
          {{ description }}
        </p>
        <p
          v-if="version"
          class="plato-app-card__info_version"
        >
          Версия: {{ version }}
        </p>
      </div>
      <div
        id="arrow"
        data-popper-arrow
      />
    </PlatoPopper>
  </div>
</template>

<script>
import PlatoPopper from "../Popper/PlatoPopper.vue";
import { getAppColors } from "@/components/utils/appColors/Colors.js";


export default {
  name: "PlatoAppCard",
  components: { PlatoPopper },
  props: {
    name: {
      type: String,
      required: true
    },
    version: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    href: {
      type: String,
      default: "#",
      required: false
    },
    image: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    mainColor: {
      type: String,
      default: "#e2f1f9"
    }
  },

  data() {
    return {
      infoButtonClicked: false
    };
  },

  computed: {
    appCardTextColor() {
      return getAppColors(this.mainColor).appCardTextColorHsl;
    },

    appMainColor() {
      return getAppColors(this.mainColor).mainHsl;
    },

    appCardBackgroundColor() {
      return getAppColors(this.mainColor).appCardBackgroundColorHsl;
    }
  },

  methods: {
    onInfoButtonClick() {
      this.infoButtonClicked = !this.infoButtonClicked;
    },
    hide() {
      this.infoButtonClicked = false;
    },
    ///Обработка события клика по приложению
    onCardClick(event) {
      if (event.composedPath().indexOf(this.$refs.infoButton) < 0) {
        ///Если нажато приложение (не кнопки "Избранное" или "Информация") срабатывает событие перехода на приложение
        this.$emit("click");
      }
      else {
        ///Если нажата кнопка "Информация" событие клика не передается дальше до события перехода на приложение
        ///и вызывается "Информация о приложении"
        event.preventDefault();
        event.stopPropagation();
        ///Если при открытой информации одного приложения
        ///кликнуть по кнопке "Информация" другого приложения не срабатывает v-click-outside
        ///Вызывается событие клика по внешнему div карточки приложения для сброса информации о предыдущем приложении
        this.$refs.wrap.click();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@use "sass:color";
@import "../styles/colors.scss";

$line-color: var(--pac-left-line-color);
$card-color: var(--pac-card-color);
$font-color: var(--pac-font-color);

.plato-app-card-wrap{
  margin: 10px 12px;
}

.plato-app-card {
  position: relative;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 288px;
  height: 148px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 20px 32px -8px rgba(9, 30, 66, 0.25),
    0 0 1px 0 rgba(9, 30, 66, 0.31);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translate3d(0,0,0);
  outline: none;
  &::after{
    box-shadow: 0 20px 32px -8px rgba(9, 30, 66, 0.25),
    0 0 1px 0 rgba(9, 30, 66, 0.31);
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  &:hover{
    transform: scale(1.05, 1.05);
  }
  &:hover::after{
    opacity: 1;
  }
  //Стиль если есть картинка
  &.plato-app-card_with-image {
    height: 215px;
    .plato-app-card__body-title {
      color: #ffffff;
    }
    .plato-app-card__body {
      flex-direction: row;
      height: auto;
      justify-content: flex-start;
      align-items: center;
      background: $card-color;
      padding: 18px 20px;
      &::before {
        display: none;
      }
    }
    .plato-app-card__body-icon {
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
  }
  &__body-icon {
    height: 60px;
    width: 60px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  &__body-title {
    color: $font-color;
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    display: flex;
    flex: 1 1 auto;
    align-items: flex-end;
  }
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background: $card-color;
    padding: 20px 20px 20px 25px;
    &::before {
      content: "";
      background: $line-color;
      height: 100%;
      width: 8px;
      top: 0;
      left: 0;
      position: absolute;
      transition: width 0.5s ease-out;
    }
    &_info-button{
      position:absolute;
      bottom:12px;
      right:12px;
      width: 16px;
      height: 16px;
      color: #505f79;
      line-height: 1;
      i{
        opacity: 0.5;
        font-size: 16px;
      }
      &:hover{
        i{
          opacity: 1;
        }
      }
      &--active{
        i{
          opacity: 1;
        }
      }
    }
  }
  &__info{
    width: 500px;
    border-radius: 8px;
    padding: 24px;
    .wrapper {
      display: flex;
      justify-content: space-between;
    }
    &_title{
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      color: #505f79;
    }
    &_version{
      font-size: 16px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      color: rgba(#505f79,0.8);
    }
    &_text{
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      color: #000000;
    }
  }
}
#arrow,
#arrow::before {
  position: absolute;
  width: 15px;
  height: 15px;
  z-index: -1;
}

#arrow::before {
  content: '';
  transform: rotate(45deg);
  background: #fff;
}
.plato-app-card__info[data-popper-placement^='top'] > #arrow {
  bottom: -4px;
}

.plato-app-card__info[data-popper-placement^='bottom'] > #arrow {
  top: -4px;
  left:123px !important;
}

.plato-app-card__info[data-popper-placement^='left'] > #arrow {
  right: -4px;
}

.plato-app-card__info[data-popper-placement^='right'] > #arrow {
  left: -4px;
}
</style>>
