<template>
  <div :class="menuClasses">
    <nav
      class="plato-menu__body"
      :class="{ openedMenu: isMenuOpened }"
    >
      <RouterLink
        v-if="logoInfo.icon"
        :to="logoInfo.href || ''"
        class="plato-menu__logo"
        :class="{ openedMenu: isMenuOpened }"
        @click.native="logoClick(logoInfo.href || '')"
      >
        <ImageIcon
          class="plato-menu__logo_image"
          :icon="logoInfo.icon"
        />

        <span class="plato-menu__logo_text">
          {{ logoInfo.name }}
        </span>
      </RouterLink>

      <ul
        class="plato-menu__items-group plato-menu__items-group--main"
        :class="{ openedMenu: isMenuOpened }"
      >
        <PlatoNavMenuItem
          v-for="(menuItem, index) in filteredMainNavItems"
          :key="`nav_item_${index}_${menuItem.href}`"
          :title="menuItem.title"
          :is-active="$route.path == `${menuItem.href}`"
          :href="menuItem.href"
          :icon-class="menuItem.icon"
          :is-opened="menuItem.isOpened"
          :items="menuItem.items"
          @link-click="changePage"
        />
      </ul>
      <ul
        v-if="showProfile"
        class="plato-menu__items-group plato-menu__items-group--bottom"
        :class="{ openedMenu: isMenuOpened, 'plato-menu-item--divider': showBackButton }"
      >
        <PlatoNavMenuItemBottom
          v-for="(menuItem, index) in filteredBottomNavItems"
          :key="`nav_item_bottom_${index}_${menuItem.href}`"
          :title="menuItem.title"
          :is-active="$route.path == `${menuItem.href}`"
          :href="menuItem.href"
          :icon-class="menuItem.icon"
        />

        <PlatoNavMenuItemBottom
          v-if="shellManifest.onboardingToggler"
          :title="localShowTours ? 'Отключить обучение' : 'Включить обучение'"
          :is-active="false"
          icon-class="graduation-cap"
          href="#"
          @click.native="switchLocalShowTours"
        />

        <PlatoNavMenuItemBottom
          v-if="currentUser && currentUser.username !== 'guest'"
          :title="currentUser.userInfo.name"
          :is-active="false"
          href="#"
          icon-class="user"
        />

        <PlatoNavMenuItemBottom
          v-if="currentUser"
          :title="currentUser.username == 'guest' ? 'Вход' : 'Выход'"
          :is-active="false"
          icon-class="sign-out-alt"
          href="/logout"
        />

        <PlatoNavMenuItemBottom
          v-if="!currentUser"
          title="Вход"
          :is-active="false"
          href="/login"
          icon-class="user"
        />

        <PlatoNavMenuItem
          v-else-if="showBackButton && currentUser"
          class="plato-menu-item--divider"
          href="/"
          icon-class="grip-horizontal"
          title="Вернуться на портал"
          @click.native="closeMenu()"
        />
      </ul>
    </nav>
    <input
      id="menu__toggle"
      v-model="isMenuOpened"
      type="checkbox"
    >
    <label
      class="menu__btn"
      for="menu__toggle"
    >
      <span :class="{ inApp: $store.getters.applicationId }" />
    </label>
  </div>
</template>

<script>
import _ from "lodash";
// import AdminData from "@/PlatoAPI/AdminData";
import PlatoNavMenuItem from "./PlatoNavMenuItem";
import PlatoNavMenuItemBottom from "./PlatoNavMenuItemBottom";
import { isMobile } from "@/components/utils/mobile/Mobile.js";
import { mapGetters } from "vuex";


export default {
  name: "PlatoNavMenu",
  components: { PlatoNavMenuItem, PlatoNavMenuItemBottom },
  props: {
    /**
     * Описание логотипа
     */
    logoInfo: {
      type: Object,
      required: true
    },
    /**
     * Можно ли свернуть меню
     */
    canCollapse: {
      type: Boolean,
      default: false
    },
    /**
     * Показывать кнопку назад
     */
    showBackButton: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isMobile: isMobile()
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "CURRENT_USER",
      navItems: "NAV_ITEMS",
      shellManifest: "SHELL_MANIFEST",
      localShowTours: "localStorage/localShowTours"
    }),

    isMenuOpened: {
      get() {
        return this.$store.getters.isMenuOpened;
      },
      set(value) {
        this.$store.dispatch("setIsMenuOpened", value);
      }
    },

    menuClasses() {
      return {
        "plato-menu": true,
        "plato-menu--uncollapsible": !this.canCollapse
      };
    },

    filteredMainNavItems() {
      return _.orderBy(_.filter(this.navItems.main,
        item => (_.isEmpty(item.scopes) || _.some(item.scopes, scope => this.$store.getters.scopeAccess(scope))) &&
        item.visible !== false), "order");
    },

    filteredBottomNavItems() {
      return _.orderBy(_.filter(this.navItems.bottom, { visible: true }), "order");
    },

    showProfile() {
      return this.shellManifest.showProfile;
    },

    appId() {
      return this.$store.getters.applicationId;
    }
  },

  watch: {
    appId(val) {
      if (val) {
        this.$store.dispatch("setIsMenuOpened", true);
      }
    }
  },

  methods: {
    changePage(href) {
      this.$store.dispatch("setInnerErrorCode", "");

      this.closeMenu();

      if (this.$store.getters.applicationId) {
        this.$store.dispatch("openPage", href.split("/")[2]);
      }
    },

    switchLocalShowTours() {
      this.$store.dispatch("localStorage/setShowToursToLocalStorage", !this.localShowTours);
      this.$store.dispatch("localStorage/getShowToursFromLocalStorage");
    },

    logoClick(href) {
      this.$store.dispatch("setInnerErrorCode", "");


      if (this.$store.getters.applicationId) {
        if (this.isMobile) {
          if (this.isMenuOpened) {
            this.closeMenu();
          }
          else {
            this.isMenuOpened = true;
          }
        }
        else {
          this.$store.dispatch("openPage", href.split("/")[2]);
        }
      }
    },

    closeMenu() {
      this.$store.dispatch("setIsMenuOpened", false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
@import "../styles/variables.scss";

$icon-width: 40px;
$logo-width: 40px;

#menu__toggle {
  opacity: 0;
  display: none;
  visibility: none;

  &:checked ~ .menu__btn > span {
    transform: rotate(45deg);
  }

  &:checked ~ .menu__btn > span::before {
    top: 0;
    transform: rotate(0);
  }

  &:checked ~ .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
  }
}

.menu__btn {
  display: none;
  align-items: center;
  position: fixed;
  top: 23px;
  right: 25px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 1;
  @media screen and (max-width: 768px) {
    display: flex;
  }
  span,
  span::before,
  span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $main-text-color;
    transition-duration: 0.25s;
  }
  span::before {
    content: "";
    top: -8px;
  }
  span::after {
    content: "";
    top: 8px;
  }
}

.plato-menu {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: $collapsed-menu-width;
  user-select: none;
  transform: translateZ(0) scale(1, 1);
  font-weight: 500;
  z-index: 3000;
  box-shadow: 5px 0 20px #00000000;
  transition: width 0.05s ease-in 0.3s, box-shadow 0.05s linear 0.3s;

  &:hover {
    width: $menu-width;
    box-shadow: 5px 0 20px #00000040;
  }

  a {
    outline: none;
    outline-offset: 0;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  //Логотип
  &__logo {
    width: $menu-width - 15px;
    font-size: 18px;
    color: $main-text-color;
    text-decoration: none;
    font-weight: bold;
    padding: 0 0 11px 12px;
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    margin: 17px 0 14px;
    &_image {
      width: 40px;
      height: 40px;
      margin-right: 14px;
      flex: 0 0 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &_text {
      font-size: 17px;
      font-weight: 500;
      color: $main-text-color;
      transition: color 0.1s linear;
      line-height: initial;
    }
    @media screen and (max-width: 768px) {
        margin: 22px 15px;
        &.openedMenu {
          margin-top: 8px;
        }
      }
  }
  //////////////////////////////
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $main-color;
    height: 100%;
    overflow: hidden;

    &.openedMenu {
      height: 100vh;
      flex-direction: column;
    }
  }
  //////////////////////////////

  &__items-group {
    list-style: none;
    outline: 0;
    margin: 0;
    padding: 0;
    flex: 1 1 auto;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    &.openedMenu {
      display: flex;
    }

    hr {
      width: calc(100% - 30px);
      align-self: center;
    }

    &:hover {
      overflow-y: auto;
    }

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
      &-thumb {
        background: rgba(9, 30, 66, 0.32);
        border-radius: 5px;
      }
      &-track {
        border-radius: 10px;
      }
    }

    &.plato-menu__items-group--bottom {
      flex: 0 0 auto;
      margin: 0 0 10px;
      border-radius: 0;

      @media (max-width: 768px){
        margin-bottom: 90px;
      }
    }

  }
  //Состояния
  &.plato-menu--uncollapsible {
    width: $uncollapsible-menu-width;

    &:hover {
      box-shadow: none;
    }
  }
}

.plato-menu-item--divider {
  border-top: 1px solid var(--p-text-color);
  margin-top: 17px;
  padding-top: 8px;

  a {
    margin: 0 -12px;
  }
}
</style>