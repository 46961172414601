import AnnotationPropertiesUtils from "@/components/utils/metadata/AnnotationProperties.js";
import { annotationProperties } from "@/components/utils/annotationProperty/AnnotationProperty.js";

import anyAscii from "any-ascii";

import _ from "lodash";

export function ConvertFilename(filename) {
  let asciiFilename = anyAscii(filename);
  let replaceInvalidCharRegex = new RegExp(/[^A-Za-z0-9_. ]*/, "g");
  let validFilename = asciiFilename.replace(replaceInvalidCharRegex, "");
  let replaceWhitespaceRegex = new RegExp(/[ ]/, "g");
  return validFilename.replace(replaceWhitespaceRegex, "_");
}

export function ConvertObjectProperties(ownProperties, linkedProperties, metadata) {
  let geojsonProperties = {};
  _.each(ownProperties, property => {
    let ownPropMetadata = _.find(metadata.ownProperties, prop => prop.alias === property.alias);
    if (checkPropertyType(ownPropMetadata)) {
      geojsonProperties[anyAscii(ownPropMetadata.name)] = property.value;
    }
  });

  _.each(linkedProperties, property => {
    let linkedPropMetadata = _.find(metadata.linkedProperties, prop => prop.alias === property.alias);
    if (AnnotationPropertiesUtils.getAnnotationPropertyValue(linkedPropMetadata, annotationProperties.PLATO_CARDINALITY) === 1) {
      geojsonProperties[anyAscii(linkedPropMetadata.name)] = property.values[0]?.value;
    }
  });
  return geojsonProperties;
}

export function GetRequestedAliases(metadata) {
  let aliases = [];
  for (let ownProperty of metadata.ownProperties) {
    if (checkPropertyType(ownProperty)) {
      aliases.push(ownProperty.alias);
    }
  }

  for (let linkedProperty of metadata.linkedProperties) {
    if (AnnotationPropertiesUtils.getAnnotationPropertyValue(linkedProperty, annotationProperties.PLATO_CARDINALITY) === 1) {
      for (let ownLinkedProperty of linkedProperty.properties) {
        aliases.push(ownLinkedProperty.alias);
      }
    }
  }
  return aliases;
}

function checkPropertyType(property) {
  return property.tableType === "Text" || property.tableType === "Numeric" || property.tableType === "DateTimeUTC";
}