import _ from "lodash";

function filterOperator(type) {
  switch (type) {
  case "Text":
    return 10;
  case "Range":
    return { gteOp: 4, lteOp: 5 };
  case "Boolean":
    return 0;
  case "SelectionDynamic":
    return 0;
  }
}

export function parseFilter(metadata, filter) {
  let ownProperty = _.find(metadata.ownProperties, { alias: filter.alias });
  if (ownProperty) {
    return {
      active: true,
      alias: filter.alias,
      name: ownProperty.name,
      operator: filterOperator(ownProperty.filters[0].type),
      type: ownProperty.filters[0].type,
      value: filter.value
    };
  }

  let linkedProperty = _.find(metadata.linkedProperties, { alias: filter.alias });
  if (linkedProperty) {
    return {
      active: true,
      alias: filter.alias,
      name: linkedProperty.name,
      operator: 0,
      type: "SelectionDynamic",
      value: filter.value
    };
  }
}

export function parseSorting(metadata, sorting) {
  let ownProperty = _.find(metadata.ownProperties, { alias: sorting.alias });
  if (ownProperty) {
    return {
      alias: sorting.alias,
      name: ownProperty.name,
      parentAlias: "",
      parentName: "",
      sortType: sorting.sortType,
      type: "own"
    };
  }

  let linkedProperty = _.find(metadata.linkedProperties, prop => _.find(prop.properties, { alias: sorting.alias }));
  if (linkedProperty) {
    return {
      alias: sorting.alias,
      name: _.find(linkedProperty.properties, { alias: sorting.alias }).name,
      parentAlias: linkedProperty.alias,
      parentName: linkedProperty.name,
      sortType: sorting.sortType,
      type: "linked"
    };
  }
}
