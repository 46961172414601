import _ from "lodash";
import StateControllerBase from "@/store/StateControllerBase";
import { DataClient } from "@/PlatoAPI";
import { setLoadingState } from "@/store/decorators/setLoadingState";

const dataClient = new DataClient();

function getName(ownProperties, defaultValue = "Неизвестно") {
  let property = _.chain(ownProperties).filter(x => !x.alias.startsWith("prop_class_name")).head().value();
  return property ? property.value : defaultValue;
}

class Actions extends StateControllerBase {
  async SET_DATA_LOADING_ERROR(context, value) {
    context.commit("setDataLoadingError", value);
  }

  async SET_DATA_LOADING(context, value) {
    context.commit("setDataLoading", value);
  }

  async editFilter({ dispatch }, dsFilter) {
    let isEmpty = true;
    let filter = dsFilter.filter;

    if (filter.type === "Range" && filter.value) {
      isEmpty = _.isEmpty(filter.value.gteValue) && _.isEmpty(filter.value.lteValue);
    }
    else if (filter.type === "Boolean" && filter.value !== null) {
      isEmpty = false;
    }
    else {
      isEmpty = _.isEmpty(filter.value);
    }

    if (isEmpty) {
      await dispatch("removeFilter", { ...dsFilter, refreshUrl: true });
    }
    else {
      await dispatch("createFilter", { ...dsFilter, refreshUrl: true });
    }
  }

  async createFilter({ commit, dispatch, getters }, { dataSourceId, filter, refreshUrl }) {
    let dataSourceFilterList = getters.filters(dataSourceId);

    let index = _.findIndex(dataSourceFilterList, { alias: filter.alias, type: filter.type });

    if (index != -1) {
      dataSourceFilterList.splice(index, 1, filter);
    }
    else {
      dataSourceFilterList.push(filter);
    }

    let currentDataSourceFilterList = {
      dataSourceId: dataSourceId,
      filters: dataSourceFilterList
    };

    commit("setFilters", currentDataSourceFilterList);

    if (refreshUrl) {
      dispatch("refreshUrl", { pushToHistory: false }, { root: true });
    }
  }

  setFilters({ commit, dispatch }, { dataSourceId, filters, refreshUrl }) {
    commit("setFilters", { dataSourceId, filters });

    if (refreshUrl) {
      dispatch("refreshUrl", { pushToHistory: false }, { root: true });
    }
  }

  setUrlFilters({ commit }, filters) {
    commit("setUrlFilters", filters);
  }

  async removeFilter({ commit, getters, dispatch }, dataSourceFilter) {
    let dataSourceId = dataSourceFilter.dataSourceId;
    let filter = dataSourceFilter.filter;

    let filters = getters.filters(dataSourceId);
    let currentDataSourceFilters = _.reject(filters, { alias: filter.alias, type: filter.type });

    let currentDataSourceFilterList = {
      dataSourceId: dataSourceId,
      filters: currentDataSourceFilters
    };

    if (_.isEmpty(currentDataSourceFilters)) {
      commit("removeFilters", dataSourceId);
    }
    else {
      commit("setFilters", currentDataSourceFilterList);
    }

    dispatch("refreshUrl", { pushToHistory: false }, { root: true });
  }

  async removeFilters({ dispatch, commit }, { dataSourceId, refreshUrl }) {
    commit("removeFilters", dataSourceId);

    if (refreshUrl) {
      dispatch("refreshUrl", { pushToHistory: false }, { root: true });
    }
  }

  @setLoadingState
  async getLinkedPropertyFilter({ getters, commit }, { dataSourceId, alias, properties, searchKeyword }) {
    //id наименования
    let sortingByProperty = _.find(properties, { id: "95b1ff3e-497c-462f-b260-c1b7166021b5" });
    if (_.isEmpty(sortingByProperty)) {
      sortingByProperty = properties[0];
    }

    let take = getters.take(alias);
    let sortingForRequest = [{
      propertyAlias: sortingByProperty.alias,
      direction: "asc"
    }];
    const body = {
      take: take,
      orderByProperties: sortingForRequest,
      searchKeyword: searchKeyword
    };
    let data = await dataClient.getDataByAlias(dataSourceId, alias, body);
    commit("setLinkedPropertyFilterOptions",
      {
        alias,
        options: data.items.map(item => ({
          value: item.id,
          classId: item.classId,
          display: getName(item.ownProperties ?? [])
        })),
        totalCount: data.totalCount,
        take
      });
  }

  async increaseTake({ commit, dispatch }, { dataSourceId, alias, properties }) {
    await commit("increaseTake", alias);
    dispatch("getLinkedPropertyFilter", { dataSourceId: dataSourceId, alias: alias, properties: properties });
  }

  resetTake({ commit }, { alias }) {
    commit("resetTake", alias);
  }

  resetState(context) {
    context.commit("resetState");
  }

  setGeometryFilter({ commit }, geojson) {
    commit("setGeometryFilter", geojson);
  }


  setMapFilteredDataSource({ commit }, dataSourceId) {
    commit("setMapFilteredDataSource", dataSourceId);
  }
}

export default (new Actions).asPlainObject();