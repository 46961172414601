const setLoadingState = (_target, _property, descriptor) => {
  let actucalFunction = descriptor.value;

  descriptor.value = async (context, ...args) => {
    context.dispatch("SET_DATA_LOADING_ERROR", null);
    context.dispatch("SET_DATA_LOADING", true);

    try {
      return await actucalFunction.call(this, context, ...args);
    }
    catch (error) {
      context.dispatch("SET_DATA_LOADING_ERROR", error);
      throw error;
    }
    finally {
      context.dispatch("SET_DATA_LOADING", false);
    }
  };
};

const setPageLoadingState = (_target, _property, descriptor) => {
  let actucalFunction = descriptor.value;

  descriptor.value = async (context, ...args) => {
    context.dispatch("setPageLoading", true);

    try {
      return await actucalFunction.call(this, context, ...args);
    }
    finally {
      context.dispatch("setPageLoading", false);
    }
  };
};

export { setLoadingState, setPageLoadingState };
