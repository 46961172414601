<template>
  <div class="plato-filter-panel-wrapper">
    <PlatoAsideHeader
      v-if="showHeader"
      header="Фильтры"
      @close="$emit('close')"
    >
      <PlatoButton
        :disabled="filters.length === 0"
        @click="applyFiltersToMapDatasource"
      >
        Применить фильтрацию на карте
      </PlatoButton>
      <PlatoButton
        type="danger-action"
        @click="clear()"
      >
        Очистить
      </PlatoButton>
    </PlatoAsideHeader>



    <div class="plato-aside__body plato-filter-panel">
      <FilterTabs
        :metadata="metadata"
        :data-source-id="dataSourceId"
      />
    </div>
  </div>
</template>

<script>
import FilterTabs from "./FilterPanelComponents/FilterTabs.vue";

export default {
  name: "PlatoFilterPanel",

  components: {
    FilterTabs
  },

  props: {
    showHeader: {
      type: Boolean,
      default: true
    },
    dataSourceId: {
      type: String,
      required: true
    },
    metadata: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    filters: function() {
      return this.$store.getters["filters/filters"](this.dataSourceId);
    }
  },

  methods: {
    /**
     * Обработка нажатия на сброс всех фильтров
     */
    clear() {
      if (this.filters && this.filters.length) {
        this.$store.dispatch("filters/removeFilters", { dataSourceId: this.dataSourceId, refreshUrl: true });
      }

      this.$store.dispatch("filters/setGeometryFilter", "");
    },

    applyFiltersToMapDatasource() {
      this.$store.dispatch("filters/setMapFilteredDataSource", null);
      this.$nextTick(() => {
        this.$store.dispatch("filters/setMapFilteredDataSource", this.dataSourceId);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
@import "../styles/variables.scss";
.plato-filter-panel {
  display: flex;
  align-items: flex-start;
  &_item {
    padding: 5px ;
    flex-direction: column;
  }
}
</style>