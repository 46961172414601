import chroma from "chroma-js";
import tinycolor from "tinycolor2";

const convertColorToRGBA = (color, alpha) => {
  return tinycolor(color).setAlpha(alpha).toRgbString();
};

export const defaultColor = "#deebff";

export const mapLayerColorSchema = colorString => {
  let color = tinycolor(colorString || defaultColor).toRgb();
  let scale = chroma
    .bezier(["white", tinycolor(color).toHex(), "black"])
    .scale()
    .colors(6);

  return {
    fill: {
      main: convertColorToRGBA(color, color.a),
      clicked: convertColorToRGBA(color, color.a),
      hovered: convertColorToRGBA(scale[2], color.a),
      clickHovered: convertColorToRGBA(scale[3], color.a),
      blocked: convertColorToRGBA(scale[4], color.a)
    },
    outline: {
      main: convertColorToRGBA(color, 1),
      clicked: convertColorToRGBA("#000000", 1),
      hovered: convertColorToRGBA(scale[2], 1),
      clickHovered: convertColorToRGBA(scale[3], 1),
      blocked: convertColorToRGBA(scale[4], 1)
    }
  };
};