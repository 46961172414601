<template>
  <div class="plato-external-data-editor-container">
    <div class="plato-external-data_info">
      {{ info }}
    </div>
    <PlatoSearch
      :value="''"
      @input="search"
    />
    <PlatoLoader
      v-if="loading"
      :delay="0"
    />
    <Table
      v-else
      :elements="elements"
      :columns="columns"
    >
      <template #actions="{ element }">
        <PlatoButton
          icon="plus"
          title="Добавить объект"
          @click="add(element)"
        />
      </template>
    </Table>
  </div>
</template>
<script>
export default {
  props: {
    elements: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    info: {
      type: String,
      default: ""
    }
  },
  methods: {
    add(element) {
      this.$emit("add", element);
    },
    search(value) {
      this.$emit("search", value);
    }
  }
};
</script>
<style scoped>
.plato-external-data_info {
  margin-bottom: 10px;
}
</style>