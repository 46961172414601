<template>
  <div class="plato-input">
    <input
      ref="plato-input"
      :spellcheck="false"
      :value="value"
      :placeholder="placeholder"
      :type="type"
      :disabled="disabled"
      :readonly="readOnly"
      @input="v => $emit('input', v.target.value)"
      @focus="event => $emit('focus', event)"
      @blur="event => $emit('blur', event)"
      @paste="event=> $emit('paste', event)"
      @keyup.enter="$emit('enter')"
      @keydown.up="event => event.preventDefault()"
    >
    <div
      v-if="$slots.default"
      class="plato-input__icons"
      @click="focus"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    focus() {
      this.$refs["plato-input"].focus();

      let position = this.$refs["plato-input"].value.length;
      this.$refs["plato-input"].setSelectionRange(position, position);
    },

    blur() {
      this.$refs["plato-input"].blur();
    }
  }
};
</script>

<style lang="scss" scoped>
.plato-input {
    display: flex;
    flex: 1 0 auto;
    position: relative;
    justify-content: space-between;
    align-items: baseline;
    box-sizing: border-box;
    overflow: hidden;
    word-wrap: break-word;
    max-width: 100%;
    padding-right: 1px;

    &[search-input] {
      input {
        padding-right: 34px;
      }
    }

    &[show-search-icon] {
      input {
        padding-right: 44px;
      }
    }

    input {
        border: solid 1px #dfe1e6;
        border-radius: 3px;
        color: #091E42;
        transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
        background-color: #fafbfc;
        height: 26px;
        width: 100%;
        padding: 0 6px;

        @media screen and ( max-width: 768px ){
          border: solid 1px #56575a;
        }

        &:focus {
            border-color: #4c9aff;
        }
    }
    &__icons {
        margin-right: 8px;
        margin-left: -21px;
        font-size: 13px;
        color: #505F79;
        display: flex;
        gap: 10px;
    }
}
</style>