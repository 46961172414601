import _ from "lodash";
import actions from "./actions";

const SORT_TYPES = {
  None: "none",
  Unset: "unset",
  Asc: "asc",
  Desc: "desc"
};

const getDefaultState = () => {
  return {
    sorting: [],
    urlSorting: [],
    dataLoadingError: null,
    dataLoading: null
  };
};

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    dataLoadingError: state => {
      return state.dataLoadingError;
    },

    dataLoading: state => {
      return state.dataLoading;
    },

    linkedPropertiesSortingData: _state => metadata => {
      if (!metadata) {
        return [];
      }

      //Из всех linkedProperties достаются все ownProperties у которых есть сортировки
      let allLinkedPropSorting = _.flatMap(metadata.linkedProperties, linked =>
        _.map(linked.properties, prop => {
          if (prop.sortType != SORT_TYPES.None && prop.isSortable) {
            return {
              name: prop.name,
              alias: prop.alias,
              sortType: prop.sortType,
              parentAlias: linked.alias,
              parentName: linked.name,
              type: "linked"
            };
          }
        }));
      // фильтрация undefined-свойств у которых не была установлена сортировка
      return _.filter(allLinkedPropSorting);
    },

    ownPropertiesSortingData: _state => metadata => {
      if (!metadata) {
        return [];
      }

      return _.chain(metadata.ownProperties)
        .filter(prop => prop.sortType != SORT_TYPES.None)
        .map(prop => ({
          name: prop.name,
          alias: prop.alias,
          sortType: prop.sortType,
          type: "own",
          parentName: "",
          parentAlias: ""
        }))
        .value();
    },

    allSorting: state => {
      return state.sorting;
    },

    sorting: state => dataSourceId => {
      return _.chain(state.sorting)
        .find({ dataSourceId })
        .get("sorting", [])
        .value();
    },

    urlSorting: state => dataSourceId => {
      return _.chain(state.urlSorting)
        .find({ dataSourceId })
        .get("sorting", [])
        .value();
    },

    sortingForUrl: state => {
      return _.chain(state.sorting)
        .filter(sorting => !_.isEmpty(sorting.sorting))
        .map(DSSorting => ({
          dataSourceId: DSSorting.dataSourceId,
          sorting: _.map(DSSorting.sorting, sorting => ({ alias: sorting.alias, sortType: sorting.sortType }))
        }))
        .value();
    }
  },
  mutations: {
    setDataLoadingError: (state, value) => {
      state.dataLoadingError = value;
    },

    setDataLoading: (state, value) => {
      state.dataLoading = value;
    },

    resetState: state => {
      Object.assign(state, getDefaultState());
    },

    setUrlSorting: (state, sorting) => {
      state.urlSorting = sorting;
    },

    setSorting: (state, data) => {
      let sorting = _.find(state.sorting, { dataSourceId: data.dataSourceId });
      if (sorting) {
        sorting.sorting = data.sorting;
      }
      else {
        state.sorting.push({ dataSourceId: data.dataSourceId, sorting: data.sorting });
      }
    },

    unshiftSorting: (state, data) => {
      let sorting = _.find(state.sorting, { dataSourceId: data.dataSourceId });
      if (sorting) {
        let sortIndex = _.findIndex(sorting.sorting, { alias: data.sorting.alias });
        sorting.sorting.splice(sortIndex, 1);
        sorting.sorting.unshift(data.sorting);
      }
      else {
        state.sorting.push({ dataSourceId: data.dataSourceId, sorting: [data.sorting] });
      }
    },

    addSorting: (state, data) => {
      let sorting = _.find(state.sorting, { dataSourceId: data.dataSourceId });
      if (sorting) {
        sorting.sorting.push(data.sorting);
      }
      else {
        state.sorting.push({ dataSourceId: data.dataSourceId, sorting: [data.sorting] });
      }
    },

    removeSorting: (state, data) => {
      let sorting = _.find(state.sorting, { dataSourceId: data.dataSourceId });
      let sortIndex = _.findIndex(sorting.sorting, { alias: data.sorting.alias });
      sorting.sorting.splice(sortIndex, 1);

      if (_.isEmpty(sorting.sorting)) {
        state.sorting = _.reject(state.sorting, { dataSourceId: data.dataSourceId });
      }
    },

    clearSorting: (state, dataSourceId) => {
      state.sorting = _.reject(state.sorting, { dataSourceId });
    },

    setSortingAlias: (state, data)=>{
      let sorting = _.find(state.sorting, { dataSourceId: data.dataSourceId });
      let sortIndex = _.findIndex(sorting.sorting, { alias: data.oldSortingAlias });
      sorting.sorting.splice(sortIndex, 1, data.newSorting);
    },

    setSortingType: (state, data) => {
      let sorting = _.find(state.sorting, { dataSourceId: data.dataSourceId });
      let sortIndex = _.findIndex(sorting.sorting, { alias: data.sorting.alias });
      sorting.sorting.splice(sortIndex, 1, data.sorting);
    }
  },
  actions
};