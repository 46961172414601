import _ from "lodash";
import StateControllerBase from "@/store/StateControllerBase";

const sortTypes = {
  None: "none",
  Unset: "unset",
  Asc: "asc",
  Desc: "desc"
};

class Actions extends StateControllerBase {
  async SET_DATA_LOADING_ERROR(context, value) {
    context.commit("setDataLoadingError", value);
  }

  async SET_DATA_LOADING(context, value) {
    context.commit("setDataLoading", value);
  }

  setUrlSorting({ commit }, sorting) {
    commit("setUrlSorting", sorting);
  }

  setSorting({ commit, dispatch }, sorting) {
    commit("setSorting", sorting);
    dispatch("refreshUrl", { pushToHistory: false }, { root: true });
  }

  addSorting({ commit, dispatch }, { dataSourceId, sorting }) {
    commit("addSorting", { dataSourceId, sorting });
    dispatch("refreshUrl", { pushToHistory: false }, { root: true });
  }

  removeSorting({ commit, dispatch }, { dataSourceId, sorting }) {
    commit("removeSorting", { dataSourceId, sorting });
    dispatch("refreshUrl", { pushToHistory: false }, { root: true });
  }

  changeSortingAlias({ commit, dispatch }, { dataSourceId, oldSortingAlias, newSorting }) {
    commit("setSortingAlias", { dataSourceId, oldSortingAlias, newSorting });
    dispatch("refreshUrl", { pushToHistory: false }, { root: true });
  }

  changeSortingType({ commit, dispatch }, { dataSourceId, sorting }) {
    commit("setSortingType", { dataSourceId, sorting });
    dispatch("refreshUrl", { pushToHistory: false }, { root: true });
  }

  clearSorting({ commit, dispatch, getters }, dataSourceId) {
    if (!_.isEmpty(getters.allSorting)) {
      commit("clearSorting", dataSourceId);
      dispatch("refreshUrl", { pushToHistory: false }, { root: true });
    }
  }

  unshiftSorting({ commit, dispatch }, { dataSourceId, sorting }) {
    commit("unshiftSorting", { dataSourceId, sorting });
    dispatch("refreshUrl", { pushToHistory: false }, { root: true });
  }

  applyDefaultSorting({ getters, dispatch }, { dataSourceId, metadata }) {
    dispatch("clearSorting", dataSourceId);
    let sortData = _.concat(
      getters.ownPropertiesSortingData(metadata),
      getters.linkedPropertiesSortingData(metadata)
    );
    if (sortData.length > 0) {
      _.forEach(sortData, sorting => {
        if (sorting.sortType === sortTypes.Asc || sorting.sortType === sortTypes.Desc) {
          dispatch("addSorting", { dataSourceId, sorting: _.clone(sorting) });
        }
      });
    }

    dispatch("refreshUrl", { pushToHistory: false }, { root: true });
  }

  resetState(context) {
    context.commit("resetState");
  }
}

export default (new Actions).asPlainObject();