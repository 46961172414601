import _ from "lodash";
import StateControllerBase from "@/store/StateControllerBase";
import { ACTION_TYPES } from "@/components/utils/actions/ActionTypes";
import { convertFilter } from "../FilterConverter.js";
import { DataClient, ExploreClient, ExportClient } from "@/PlatoAPI";
import { Download } from "@/components/utils/Files/FileDownloader.js";
import { setLoadingState, setPageLoadingState } from "@/store/decorators/setLoadingState";

const dataClient = new DataClient();
const exploreClient = new ExploreClient();
const exportClient = new ExportClient();

function getName(ownProperties, defaultValue = "Неизвестно") {
  let property = _.chain(ownProperties).filter(x => !x.alias.startsWith("prop_class_name")).head().value();
  return property ? property.value : defaultValue;
}

const SORT_TYPES = {
  None: "none",
  Unset: "unset",
  Asc: "asc",
  Desc: "desc"
};

/// TODO: Неправильно работает сравнение массивов фильтров
const filterSetsDiffer = (set1, set2) => {
  let isFilterApplicable = filter => filter.active;
  return !_.isEqual(_.filter(set1, isFilterApplicable), _.filter(set2, isFilterApplicable));
};

class Actions extends StateControllerBase {
  setPageLoading({ commit }, value) {
    commit("setPageLoading", value);
  }

  updateBlockedDataSources({ dispatch, getters }) {
    let currentManifest = getters.MANIFEST;
    let pageId = getters.pageId;
    let page = _.find(currentManifest.pages, page => page.pageId === pageId);
    dispatch("setBlockedDataSources", page.pageItems);
  }

  setBlockedDataSources({ commit }, items) {
    let blockedDataSources = [];

    items.forEach(item => {
      let isBlocking = _.find(item.pageItemSettings, { pageItemSettingId: "BlockDataSource" })?.value?.value;
      if (isBlocking) {
        blockedDataSources.push(item.dataSourceId);
      }
    });

    commit("setBlockedDataSources", blockedDataSources);
  }

  setLinkedPropertyAlias({ commit, dispatch }, sectorId) {
    commit("setLinkedPropertyAlias", sectorId);

    dispatch("refreshUrl", { pushToHistory: false });
  }

  setLinkedObjectId({ commit, dispatch }, pageId) {
    commit("setLinkedObjectId", pageId);

    dispatch("refreshUrl", { pushToHistory: false });
  }

  setSectorId({ commit, dispatch }, sectorId) {
    commit("setSectorId", sectorId);

    dispatch("refreshUrl", { pushToHistory: false });
  }

  setPageId({ commit, dispatch }, { pageId, refreshUrl = true }) {
    commit("setPageId", pageId);

    if (refreshUrl) {
      dispatch("refreshUrl", { pushToHistory: false });
    }
  }

  //Вспомогательная
  changePage({ dispatch }, { pageId, startPageId }) {
    dispatch("setPageId", { pageId, refreshUrl: false });

    dispatch("clearStates");

    dispatch("card/setStartPageId", { startPageId, refreshUrl: false });
  }

  clearStates({ dispatch }) {
    dispatch("selectedObjects/resetState");
    dispatch("currentObject/removeCurrentObject", false);
    dispatch("grouping/resetState");
    dispatch("grouping/clearHiddenColumns");

    dispatch("searchKeywords/resetState");
    dispatch("sorting/resetState");
    dispatch("filters/resetState");
    dispatch("card/resetState");
    dispatch("report/resetState");
    dispatch("diagram/resetState");
  }

  //При переходе через навигационное меню
  openPage({ dispatch }, pageId) {
    dispatch("changePage", { pageId });
    dispatch("refreshUrl", { pushToHistory: false });
  }

  @setPageLoadingState
  //При переходе через событие
  async openCard({ dispatch, getters }, { pageId, dataSourceId, currentObjectId, linkedPropertyAlias, linkedObjectId, linkedDataSourceId }) {
    dispatch("changePage", { pageId, startPageId: getters.startPageId || getters.pageId });

    dispatch("card/setLinkedPropertyAlias", { linkedPropertyAlias, refreshUrl: false });
    dispatch("card/setLinkedObjectId", { linkedObjectId, refreshUrl: false });
    dispatch("card/setLinkedDataSourceId", { linkedDataSourceId, refreshUrl: false });

    if (currentObjectId) {
      dispatch("currentObject/changeCurrentObject", {
        id: currentObjectId,
        dataSourceId: dataSourceId,
        refreshUrl: false
      });
    }

    dispatch("refreshUrl", { pushToHistory: true });
  }

  @setPageLoadingState
  async openReport({ dispatch, getters }, { pageId, dataSourceId, currentObjectId }) {
    let filters = getters["filters/filters"](dataSourceId);
    let sorting = getters["sorting/sorting"](dataSourceId);
    let selectedObjects = getters["selectedObjects/selectedObjects"](dataSourceId);

    dispatch("changePage", { pageId });

    if (currentObjectId) {
      dispatch("currentObject/changeCurrentObject", {
        id: currentObjectId,
        dataSourceId: dataSourceId,
        refreshUrl: false
      });
    }

    if (filters) {
      dispatch("filters/setFilters", { dataSourceId, filters });
    }

    if (sorting) {
      dispatch("sorting/setSorting", { dataSourceId, sorting });
    }

    if (selectedObjects) {
      dispatch("selectedObjects/setSelectedObjects", { dataSourceId, selectedObjects, refreshUrl: false });
    }

    dispatch("refreshUrl", { pushToHistory: true });
  }

  tryApplayDefaultDataView({ dispatch, getters }, { dataSourceId, metadata }) {
    let defaultDataView = _.find(getters.MANIFEST.defaultDataView, { dataSourceId });

    if (defaultDataView) {
      dispatch("applyDefaultDataView", { defaultDataView });
    }
    else {
      dispatch("sorting/applyDefaultSorting", { dataSourceId, metadata });
    }
  }

  clearDataSources({ getters, commit }) {
    getters.dataSources.forEach(dataSource => {
      commit("CLEAR_DATA", dataSource.applicationDataSourceId);

      commit("removeSelectedObjectsId", { dataSourceId: dataSource.applicationDataSourceId });
      commit("SET_CURRENT_OBJECT", { id: null, dataSourceId: dataSource.applicationDataSourceId });

      commit("REMOVE_SEARCH_KEYWORD", { dataSourceId: dataSource.applicationDataSourceId });
      commit("clearAppliedDataSourceSorting", { dataSourceId: dataSource.applicationDataSourceId });
      commit("REMOVE_ALL_DATASOURCE_FILTERS", dataSource.applicationDataSourceId);
    });
  }

  @setLoadingState
  async deleteInstance(_context, { dataSourceId, id }) {
    await dataClient.deleteInstance(dataSourceId, id);
  }

  @setLoadingState
  async deleteInstances(_context, { dataSourceId, ids }) {
    await dataClient.deleteInstances(dataSourceId, ids);
  }

  clearExploreInstance({ commit }) {
    commit("SET_EXPLORE_INSTANCE", null);
  }

  @setLoadingState
  async GET_EXPLORE_INSTANCE(context, id) {
    let instance = await exploreClient.getInstanceById(id);
    let metadata = await exploreClient.getClass(instance.class.id);
    _.each(instance.dataProperties, instanceProp => {
      _.each(metadata.dataProperties, metadataProp => {
        let instancePropertyMetadata = _.find(metadataProp.items, ({ id: instanceProp.id }));
        if (instancePropertyMetadata) {
          instanceProp.tableType = instancePropertyMetadata.type;
        }
      });
    });
    context.commit("SET_EXPLORE_INSTANCE", instance);
  }

  @setLoadingState
  async getParentExploreInstance(context, id) {
    let instance = await exploreClient.getInstanceById(id);
    context.commit("setParentExploreInstance", instance);
  }

  @setLoadingState
  async getOwnInstance(context, { dataSourceId, body, propertyId }) {
    let instances = await dataClient.getData(dataSourceId, body);
    let ownInstance = instances.items[0];
    ownInstance.linkedProperties[0] = {
      alias: ownInstance.linkedProperties[0].alias,
      id: propertyId,
      header: {},
      values: [ownInstance.linkedProperties[0].values]
    };
    context.commit("setOwnInstance", ownInstance);
  }

  async removeParentExploreInstance(context) {
    context.commit("setParentExploreInstance", "");
  }

  @setLoadingState
  async getParentCardExploreInstance(context, id) {
    let instance = await exploreClient.getInstanceById(id);
    context.commit("setParentCardExploreInstance", instance);
  }

  async removeParentCardExploreInstance(context) {
    context.commit("setParentCardExploreInstance", "");
  }

  setSelectedObjectsId(context, data) {
    context.commit("setSelectedObjectsId", { dataSourceId: data.dataSourceId, selectedObjectsId: data.selectedObjectsId });

    context.dispatch("refreshUrl", { pushToHistory: false });
  }

  CHANGE_NAVIGATION_OBJECT(context, navigationObject) {
    context.commit("SET_NAVIGATION_OBJECT", navigationObject);
  }

  REMOVE_NAVIGATION_OBJECT(context) {
    context.commit("SET_NAVIGATION_OBJECT", null);
  }

  async editFilter({ dispatch }, dsFilter) {
    let isEmpty = true;
    let filter = dsFilter.filter;

    if (filter.type === "Range" && filter.value) {
      isEmpty = _.isEmpty(filter.value.gteValue) && _.isEmpty(filter.value.lteValue);
    }
    else if (filter.type === "Boolean" && filter.value !== null) {
      isEmpty = false;
    }
    else {
      isEmpty = _.isEmpty(filter.value);
    }

    if (isEmpty) {
      await dispatch("REMOVE_FILTER", { ...dsFilter, refreshUrl: true });
    }
    else {
      await dispatch("EDIT_DATASOURCE_FILTER", { ...dsFilter, refreshUrl: true });
    }
  }

  async EDIT_DATASOURCE_FILTER({ state, commit, dispatch }, { dataSourceId, filter, refreshUrl }) {
    let dataSource = _.find(state.data, { applicationDataSourceId: dataSourceId });
    let dataSourceFilterList = _.cloneDeep(dataSource.filters);

    let currentFilter = _.find(dataSource.filters, { alias: filter.alias, type: filter.type });

    if (currentFilter) {
      _.remove(dataSourceFilterList, { alias: filter.alias, type: filter.type });
    }

    dataSourceFilterList.push(filter);

    let currentDataSourceFilterList = {
      dataSourceId: dataSourceId,
      filters: dataSourceFilterList
    };

    let reloadDataSource = filterSetsDiffer(dataSource.filters, dataSourceFilterList);
    commit("SET_DATASOURCE_FILTER", currentDataSourceFilterList);

    if (reloadDataSource) {
      await dispatch("requestDataSourceData", { dataSourceId, cancelRequest: true });
    }

    if (refreshUrl) {
      dispatch("refreshUrl", { pushToHistory: false });
    }
  }

  async SET_DATASOURCE_FILTERS({ state, commit, dispatch }, { dataSourceId, filters, refreshUrl }) {
    let dataSource = _.find(state.data, { applicationDataSourceId: dataSourceId });

    let reloadDataSource = filterSetsDiffer(dataSource.filters, filters);
    commit("SET_DATASOURCE_FILTER", { dataSourceId, filters });

    if (reloadDataSource) {
      await dispatch("requestDataSourceData", { dataSourceId: dataSourceId, cancelRequest: true });
    }

    if (refreshUrl) {
      dispatch("refreshUrl", { pushToHistory: false });
    }
  }

  async EDIT_SEARCH_KEYWORD({ dispatch, commit }, { searchInfo, refreshUrl }) {
    commit("SET_SEARCH_KEYWORD", searchInfo);
    await dispatch("requestDataSourceData", { dataSourceId: searchInfo.dataSourceId, cancelRequest: true });

    if (refreshUrl) {
      dispatch("refreshUrl", { pushToHistory: false });
    }
  }

  async REMOVE_FILTER({ commit, state, dispatch }, dataSourceFilter) {
    let dataSourceId = dataSourceFilter.dataSourceId;
    let filter = dataSourceFilter.filter;

    let dataSource = _.find(state.data, { applicationDataSourceId: dataSourceId });
    let currentDataSourceFilters = _.reject(dataSource.filters, { alias: filter.alias, type: filter.type });

    let currentDataSourceFilterList = {
      dataSourceId: dataSourceId,
      filters: currentDataSourceFilters
    };

    let reloadDataSource = filterSetsDiffer(dataSource.filters, currentDataSourceFilters);
    commit("SET_DATASOURCE_FILTER", currentDataSourceFilterList);

    if (reloadDataSource) {
      await dispatch("requestDataSourceData", { dataSourceId, cancelRequest: true });
    }

    dispatch("refreshUrl", { pushToHistory: false });
  }

  async CLEAR_DATASOURCE_FILTERS({ state, dispatch, commit }, { dataSourceId, refreshUrl }) {
    let dataSource = _.find(state.data, { applicationDataSourceId: dataSourceId });
    let reloadDataSource = !_.isEmpty(dataSource.filters);

    commit("REMOVE_ALL_DATASOURCE_FILTERS", dataSourceId);

    if (reloadDataSource) {
      await dispatch("requestDataSourceData", { dataSourceId, cancelRequest: true });
    }

    if (refreshUrl) {
      dispatch("refreshUrl", { pushToHistory: false });
    }
  }

  async setCurrentObject({ dispatch, getters }, { currentObjectId, dataSourceId, refreshUrl = true }) {
    await dispatch("GET_INSTANCE", { id: currentObjectId, dataSourceId });
    let instance = getters.DATA_INSTANCE;
    dispatch("CHANGE_CURRENT_OBJECT", { id: currentObjectId, dataSourceId, instance, forceRefresh: true, refreshUrl });
  }

  CHANGE_CURRENT_OBJECT(context, { id, dataSourceId, forceRefresh, instance, refreshUrl = true }) {
    let currentObject = context.getters.CURRENT_OBJECT(dataSourceId);
    if (forceRefresh || !_.includes(context.state.blockedDataSources, dataSourceId) || !currentObject) {
      let currentObjectDataSource = _.find(context.state.data, { applicationDataSourceId: dataSourceId });
      if (currentObjectDataSource && currentObjectDataSource.serverData) {
        context.commit("SET_CURRENT_OBJECT", {
          payload: _.find(currentObjectDataSource.serverData.items,
            { id }), dataSourceId: dataSourceId
        });
        context.dispatch("REMOVE_NAVIGATION_OBJECT");
      }
      else if (instance) {
        context.commit("SET_CURRENT_OBJECT", { payload: instance, dataSourceId: dataSourceId });
      }
    }
    else {
      console.log("Смена currentObject не произошла");
    }

    if (refreshUrl) {
      context.dispatch("refreshUrl", { pushToHistory: false });
    }
  }

  ADD_NEW_OBJECT(context, dataSourceId) {
    context.commit("ADD_NEW_OBJECT", dataSourceId);
  }

  CHANGE_NEW_OBJECT_FIELD(context, field) {
    context.commit("CHANGE_NEW_OBJECT_FIELD", field);
  }

  /**
           * Cохранение поля объекта
           * @param {Object} context
           * @param {Object} field
           * {
           *      type - тип поля (возможное значение "linked")
           *      dataSourceId
           *      alias
           *      value
           *      instanceId
           * }
           */
  @setLoadingState
  async CHANGE_CURRENT_OBJECT_FIELD(context, field) {
    let body;
    if (field.type === "linked") {
      body = JSON.stringify({
        alias: field.alias,
        instanceId: field.instanceId,
        added: field.value.added,
        removed: field.value.removed
      });
    }
    else {
      body = JSON.stringify({
        alias: field.alias,
        value: field.value,
        instanceId: field.instanceId
      });
    }

    await dataClient.updateData(field.dataSourceId, body);
    await context.dispatch("saveChangedFields", field.dataSourceId);
  }

  async saveChangedFields(context, dataSourceId) {
    let fields = _.values(context.getters.changedFields(dataSourceId));

    await dataClient.updateMultipleData(dataSourceId, JSON.stringify({ data: fields }));
    await context.dispatch("GET_CURRENT_DATA", { dataSourceId });
    context.commit("clearChangedFields", dataSourceId);
  }

  addChangedFields(context, field) {
    context.commit("addChangedFields", field);
  }

  clearChangedFields(context, dataSourceId) {
    context.commit("clearChangedFields", dataSourceId);
  }

  /**
           * Сохранение нового объекта
           * @param {Object} context
           * @param {String} dataSourceId Источник данных обьект которого нужно сохранить
           */
  @setLoadingState
  async SAVE_NEW(context, dataSourceId) {
    let dataSource = _.find(context.state.data, dataSource => dataSource.applicationDataSourceId === dataSourceId);
    let ownProperties = _.reduce(dataSource.newObject.ownProperties, (result, prop) => {
      if (prop.value != null && prop.value != "") {
        result.push({ alias: prop.alias, value: "" + prop.value });
      }
      return result;
    }, []);
    let linkedProperties = _.reduce(dataSource.newObject.linkedProperties, (result, prop) => {
      if (prop.value != null && prop.value != "") {
        result.push({ alias: prop.alias, value: prop.value });
      }
      return result;
    }, []);
    let properties = _.concat(ownProperties, linkedProperties);
    if (!_.isEmpty(properties)) {
      const property = JSON.stringify({
        properties: properties
      });

      let instance = await dataClient.insertData(dataSourceId, property);

      context.commit("setLastNewObjectInstanceId", instance.instanceId);

      context.dispatch("GET_CURRENT_DATA", { dataSourceId: dataSourceId });
    }
  }

  @setLoadingState
  async GET_INSTANCE(context, data) {
    const body = {
      instanceIds: [data.id]
    };

    let instanceInArray = await dataClient.getData(data.dataSourceId, body);

    context.commit("SET_DATA_INSTANCE", instanceInArray.items[0]);
  }

  @setLoadingState
  async GET_DATA_BY_ALIAS(context, { id, alias, body }) {
    let data = await dataClient.getDataByAlias(id, alias, body);
    context.commit("SET_LINKED_PROPS", data);
  }

  @setLoadingState
  async GET_DATA_FOR_TABLE_BY_ALIAS(context, { id, alias, body }) {
    let data = await dataClient.getDataByAlias(id, alias, body);
    context.commit("SET_LINKED_PROPS_FOR_TABLE", { data, alias });
  }

  @setLoadingState
  async exportData({ rootGetters }, { dataSourceId, type, aliases }) {
    let searchKeyword = rootGetters["searchKeywords/searchKeywords"](dataSourceId);
    let activeFilters = rootGetters["filters/activeFilters"](dataSourceId);
    let sorting = rootGetters["sorting/sorting"](dataSourceId);

    let filtersForRequest = _.flatMap(activeFilters, filter => convertFilter(filter));
    let sortingForRequest = _.map(sorting, sort => ({ propertyAlias: sort.alias, direction: sort.sortType }));

    let format = 1;
    switch (type) {
    case ACTION_TYPES.ExportShapefile:
      format = 1;
      break;
    case ACTION_TYPES.ExportExcel:
      format = 0;
      break;
    case ACTION_TYPES.ExportKml:
      format = 3;
      break;
    case ACTION_TYPES.ExportGeojson:
      format = 2;
      break;
    }

    const body = {
      request: {
        filters: filtersForRequest,
        searchKeyword: searchKeyword,
        requestedPropertiesAliases: _.filter(aliases),
        orderByProperties: sortingForRequest
      },
      format: format
    };

    let response = await exportClient.exportData(body, dataSourceId);

    let contentDisposition = response.headers["content-disposition"];
    let filename = contentDisposition.match(/(filename=)("?)([^,;]+)("?)(;?)/)[3];

    Download(response.data, filename);
  }

  @setLoadingState
  async GET_LINKED_PROPERTY_FILTER(context, { dataSourceId, alias }) {
    let data = await dataClient.getDataByAlias(dataSourceId, alias, {});
    context.commit("SET_LINKED_PROPERTY_FILTER_OPTIONS",
      {
        alias,
        options: data.items.map(item => ({
          value: item.id,
          display: getName(item.ownProperties ?? [])
        }))
      });
  }

  @setLoadingState
  async GET_METADATA({ commit, state, dispatch }, dataSourceId) {
    const data = await dataClient.getMetadata(dataSourceId);

    for (let property in data.properties) {
      let currentProperty = data.properties[property];

      for (let filter in currentProperty.availableFilters) {
        let currentFilter = currentProperty.availableFilters[filter];
        if (currentFilter.filterType === "selection_dynamic") {
          let currentFilterDataSource = _.find(state.dataSources,
            { applicationDataSourceId: currentFilter.dataSourceId });
          if (currentFilterDataSource) {
            currentFilter.metadata = await dataClient.getMetadata(currentFilterDataSource.applicationDataSourceId);
            const body = { skip: 0, take: 20, filters: [] };
            currentFilter.items = await dataClient.getData(currentFilterDataSource.applicationDataSourceId, body);
          }
        }
      }
    }

    commit("SET_METADATA", { payload: data, dataSourceId: dataSourceId });
    dispatch("ADD_NEW_OBJECT", dataSourceId);
  }

  ADD_DATASOURCE(context, dataSource) {
    context.commit("ADD_DATASOURCE", dataSource);
  }

  clearCurrentObjects(context, { refreshUrl = true }) {
    let dataSources = _.chain(context.state.data).filter(x => x.currentObject).map("applicationDataSourceId").value();

    dataSources.forEach(dsId => {
      context.dispatch("CHANGE_CURRENT_OBJECT", { id: null, dataSourceId: dsId });
    });

    if (refreshUrl) {
      context.dispatch("refreshUrl", { pushToHistory: false });
    }
  }

  @setLoadingState
  async GET_CURRENT_DATA({ getters, commit, dispatch }, { dataSourceId, cancelToken }) {
    commit("CLEAR_DATA", dataSourceId);

    let currentDataSourceData = getters.DATA(dataSourceId);
    let activeFilters = getters.ACTIVE_FILTERS(dataSourceId);

    let filtersForRequest = _.flatMap(activeFilters, filter => convertFilter(filter));

    const body = {
      skip: currentDataSourceData.skip,
      take: currentDataSourceData.take,
      filters: filtersForRequest,
      searchKeyword: currentDataSourceData.searchKeyword
    };

    let response = await dataClient.getData(dataSourceId, body, cancelToken);
    if (response.items == null) {
      response.items = [];
    }

    commit("SET_OBJECTS", { payload: response, dataSourceId: dataSourceId });

    let currentObjId = _.get(getters.CURRENT_OBJECT(dataSourceId), "id", null);

    if (currentObjId) {
      dispatch("CHANGE_CURRENT_OBJECT", {
        id: currentObjId,
        dataSourceId: dataSourceId,
        forceRefresh: true
      });
    }

    dispatch("applySortingToData", dataSourceId);
  }
  applyDefaultDataView({ dispatch, rootGetters }, { defaultDataView, refreshUrl }) {
    let currentDataView = rootGetters.dataSourceDataView(defaultDataView.dataSourceId);
    let currentView = _.find(currentDataView, { name: defaultDataView.name });
    if (currentView) {
      dispatch("filters/removeFilters", { dataSourceId: currentView.dataSourceId });
      if (currentView.filters.length > 0) {
        currentView.filters.forEach(filter => {
          dispatch("filters/createFilter", { dataSourceId: currentView.dataSourceId, filter, refreshUrl: refreshUrl });
        });
      }

      dispatch("sorting/clearSorting", currentView.dataSourceId);
      if (currentView.sorts.length > 0) {
        currentView.sorts.forEach(sorting => {
          dispatch("sorting/addSorting", { dataSourceId: currentView.dataSourceId, sorting });
        });
      }

      dispatch("grouping/clearGrouping", currentView.dataSourceId);
      if (currentView.grouping) {
        dispatch("grouping/setGrouping", { dataSourceId: currentView.dataSourceId, property: _.cloneDeep(currentView.grouping) });
      }

      dispatch("grouping/clearHiddenColumns");
      if (currentView.hiddenColumns) {
        dispatch("grouping/setHiddenColumns", _.cloneDeep(currentView.hiddenColumns));
      }
      dispatch("dataView/setDataViewName", defaultDataView.name);
    }
    else {
      dispatch("deleteDataSourceDefaultDataView", defaultDataView.dataSourceId);
      dispatch("GET_MANIFEST", rootGetters.MANIFEST_ID);
    }

    if (refreshUrl) {
      dispatch("refreshUrl", { pushToHistory: false });
    }
  }

  addDatasourceSorting({ commit }, { dataSourceId, sortingField }) {
    commit("addDatasourceSorting", { dataSourceId, sortingField });
  }

  removeDatasourceSorting({ commit }, { dataSourceId, sortingField }) {
    commit("removeDatasourceSorting", { dataSourceId, sortingField });
  }

  changeSortingType({ commit, dispatch }, { dataSourceId, alias, sortType }) {
    commit("setSortingType", { dataSourceId, alias, sortType });
    dispatch("applySortingToData", dataSourceId);
  }

  clearAppliedDataSourceSorting({ commit }, dataSourceId) {
    commit("clearAppliedDataSourceSorting", { dataSourceId });
  }

  applyDefaultSortingFields({ rootGetters, dispatch }, dataSourceId) {
    dispatch("clearAppliedDataSourceSorting", dataSourceId);
    let sortData = _.concat(
      rootGetters.ownPropertiesSortingData(dataSourceId),
      rootGetters.linkedPropertiesSortingData(dataSourceId)
    );
    if (sortData.length > 0) {
      _.forEach(sortData, sortingField => {
        if (sortingField.sortType === SORT_TYPES.Asc || sortingField.sortType === SORT_TYPES.Desc) {
          dispatch("addDatasourceSorting", { dataSourceId, sortingField: _.clone(sortingField) });
        }
      });
    }
  }

  applySortingToData({ getters, commit, dispatch }, dataSourceId) {
    let data = getters.OBJECTS(dataSourceId);
    let totalCount = getters.TOTAL_OBJECT_COUNT(dataSourceId);
    let appliedSorting = getters.datasourceAppliedSorting(dataSourceId);
    let predicates = [];
    let sortTypes = [];


    let getSortingPropertyType = sorting => {
      let metadata = getters.METADATA(dataSourceId);
      if (sorting.type === "own") {
        let property = _.find(metadata.ownProperties, prop => prop.alias === sorting.alias);
        return property.tableType;
      }
      else {
        let parentProperty = _.find(metadata.linkedProperties, prop => prop.alias === sorting.parentAlias);
        let property = _.find(parentProperty.properties, prop => prop.alias === sorting.alias);
        return property.tableType;
      }
    };

    let convertPropertyValues = (propertyType, value) => {
      if (propertyType === "Numeric") {
        return Number(value);
      }
      return value;
    };

    if (appliedSorting?.length > 0) {
      _.forEach(appliedSorting, sorting => {
        let sortingPropertyType = getSortingPropertyType(sorting);
        if (!_.isEmpty(sorting.parentAlias)) {
          predicates.push(object => {
            let linkedProp = _.find(object.linkedProperties, linkedProp => linkedProp.alias === sorting.parentAlias);
            if (linkedProp && linkedProp.values.length > 0) {
              let linkedPropSortingValue = _.find(linkedProp.values, ownProp => ownProp.alias === sorting.alias);
              return convertPropertyValues(sortingPropertyType, linkedPropSortingValue?.value);
            }
          });
          sortTypes.push(sorting.sortType);
        }
        else {
          predicates.push(object => {
            let property = _.find(object.ownProperties, prop => prop.alias === sorting.alias);
            if (property) {
              return convertPropertyValues(sortingPropertyType, property?.value);
            }
          });
          sortTypes.push(sorting.sortType);
        }
      });
    }
    else {
      predicates.push("id");
      sortTypes.push("asc");
    }
    let orderedData = _.orderBy(data, predicates, sortTypes);
    commit("SET_OBJECTS", { dataSourceId, payload: { items: orderedData, totalCount } });

    let selectedObjectsId = [];

    getters.selectedObjectsId(dataSourceId).forEach(id => {
      if (_.find(orderedData, { id })) {
        selectedObjectsId.push(id);
      }
    });

    if (getters.selectedObjectsId(dataSourceId) == selectedObjectsId) {
      commit("setSelectedObjectsId", { dataSourceId, selectedObjectsId });
    }

    dispatch("refreshUrl", { pushToHistory: false });
  }

  @setLoadingState
  async SAVE_DATA(context, data) {
    context.commit("SET_OBJECTS", { payload: data.items, dataSourceId: data.dataSourceId });
  }

  resetPageState(context) {
    context.commit("resetPageState");
  }
}

export default (new Actions).asPlainObject();