<template>
  <div class="filters">
    <div
      class="filters-picked-block"
    >
      <PlatoChipGroup>
        <PickedFilter
          v-for="(filter,index) in filtersWithValue"
          :key="index"
          :filter="filter"
          @remove="removeFilter"
          @open="setFilter"
        />
      </PlatoChipGroup>
    </div>

    <div class="filters-tabs-block">
      <FilterTab
        v-for="tab in tabs"
        :key="tab.alias"
        class="tabs-block-item"
        :tab="tab"
        :active-tab="activeTab"
        :data-source-id="dataSourceId"
        @openFilter="setFilter"
        @click.native="nextStep"
      />
    </div>

    <div class="filters-tab-view-block">
      <FilterTabView
        v-if="activeFilter !== null "
        :filter="activeFilter"
        :data-source-id="dataSourceId"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import FilterTab from "./FilterTab.vue";
import FilterTabView from "./FilterTabView.vue";
import PickedFilter from "./PickedFilter.vue";
import PlatoChipGroup from "../../Buttons/PlatoChipGroup.vue";

export default {
  components: {
    FilterTab,
    FilterTabView,
    PlatoChipGroup,
    PickedFilter
  },
  props: {
    dataSourceId: {
      type: String,
      required: true
    },
    metadata: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      activeFilter: null,
      activeTab: null

    };
  },
  computed: {
    tabs() {
      return _.orderBy(this.$store.getters["filters/availableFilters"](this.metadata), x => x.name, "asc");
    },
    filters() {
      return this.$store.getters["filters/filters"](this.dataSourceId);
    },
    filtersWithValue() {
      return _.filter(this.filters, function(f) {
        if (f.value !== null) {
          return f;
        }
      });
    },
    currentFilter() {
      if (this.activeFilter !== null) {
        return _.find(this.filters, { alias: this.activeFilter.alias, type: this.activeFilter.type });
      }
      return null;
    },

    currentFilterValue() {
      if (!_.isEmpty(this.currentFilter)) {
        return this.currentFilter.value;
      }
      return null;
    }
  },
  watch: {
    currentFilterValue(newValue) {
      if (this.activeFilter !== null) {
        this.activeFilter.value = newValue;
      }
    }
  },
  async mounted() {
    let filter = {
      dataSourceId: this.dataSourceId,
      filter: {
        alias: this.tabs[0].alias,
        type: this.tabs[0].type,
        value: null,
        name: this.tabs[0].name
      }
    };
    let existFilter = _.find(this.filters, { alias: this.tabs[0].alias });
    if (!_.isEmpty(existFilter)) {
      filter.filter = existFilter;
    }

    this.setFilter(filter.filter);
  },
  methods: {
    nextStep() {
      this.$store.dispatch("onboarding/nextTourStep");
    },

    setFilter(filter) {
      this.activeFilter = null;
      this.activeTab = filter.alias;
      let linkedProperty = _.find(this.metadata.linkedProperties, { alias: filter.alias });

      this.$nextTick(() =>{
        this.activeFilter = filter;
        this.activeFilter.properties = linkedProperty?.properties ?? [];
      });
    },
    async removeFilter(filter) {
      let dataSourceFilter = {
        dataSourceId: this.dataSourceId,
        filter: filter
      };
      await this.$store.dispatch("filters/removeFilter", dataSourceFilter);
    }
  }

};
</script>
<style lang="scss" scoped>
.filters{
  width:100%;
  height: 90vh;
  overflow: hidden;
  display:grid;
  grid-template-areas:"picked picked" "tabs view" ;
  grid-template-columns: 220px 1fr;
  grid-template-rows:auto 1fr;

  &-picked-block{
    grid-area:picked;
    padding: 0 0 15px 0;
    background-color: white;
  }

  &-tabs-block{
    grid-area:tabs;
    max-height: 90vh;
    overflow-y: auto;
  }

  &-tab-view-block{
    grid-area:view;
    max-height: 90vh;
    overflow-y: scroll;
  }
}
</style>
