<template>
  <div :class="`plato-btn-group${vertical ? '-vertical' : ''}`">
    <slot />
  </div>
</template>

<script>
export default {
  name: "PlatoButtonGroup",
  props: {
    vertical: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.plato-btn-group {
  display: flex;
  margin-left: 5px;
  align-items: center;
  &[no-border-radius] {
    .plato-btn {
      border-radius: 0px;
    }
  }
  .plato-btn {
    margin: 0px;
  }

  .plato-btn:not(:first-child).plato-btn:not(:last-child) {
    border-radius: 0;
  }

  .plato-btn:not(:first-child):last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .plato-btn:not(:last-child):first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &[table-context-menu] {
    max-height: 400px;
    overflow-y: auto;
  }
}

.plato-btn-group-vertical {
  &:not([have-subgroup]) {
    .plato-btn:not(:first-child).plato-btn:not(:last-child) {
      border-radius: 0;
    }

    .plato-btn:not(:first-child):last-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .plato-btn:not(:last-child):first-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &[table-context-menu] {
    max-height: 400px;
    overflow-y: auto;
  }

  &[have-subgroup] {
    .plato-btn-group {
      margin-left: 0;
      .plato-context-menu__btn {
        width: 100%;
        justify-content: flex-start;
      }
    }

    .plato-btn-group:not(:first-child).plato-btn-group:not(:last-child) {
      .plato-btn {
        border-radius: 0;
      }
    }

    .plato-btn-group:not(:first-child):last-child {
      .plato-btn {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    .plato-btn-group:not(:last-child):first-child {
      .plato-btn {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
</style>