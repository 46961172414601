import _ from "lodash";

export function getAnnotationPropertyValue(metadata, alias, type) {
  if (!metadata) {
    return null;
  }

  const property = _.find([...metadata.ownProperties, ...metadata.linkedProperties], { alias });
  if (!property) {
    return null;
  }

  const value = _.chain(property.annotationProperties).find(x => _.toLower(x.type) === _.toLower(type)).get("value").value();
  const parsed = _.attempt(JSON.parse, value);

  return _.isError(parsed) ? value : parsed;
}

export const annotationProperties = {
  PLATO_IS_HINT: "plato:ishint",
  PLATO_IS_PHOTO: "plato:isphoto",
  PLATO_CARDINALITY: "plato:cardinality",
  PLATO_TO_PARENT: "plato:toparent",
  PLATO_TO_CHILD: "plato:tochild"
};