<template>
  <section
    v-show="isActive"
  >
    <slot />
  </section>
</template>
<script>
export default {
  name: "PlatoTab",
  props: {
    name: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false
    };
  }
};
</script>
<style lang="scss" scoped>

</style>