import _ from "lodash";
import StateControllerBase from "@/store/StateControllerBase";
import { ApplicationClient, DataViewConverter, ExploreClient } from "@/PlatoAPI";
import { setLoadingState } from "@/store/decorators/setLoadingState";

const applicationClient = new ApplicationClient();
const exploreClient = new ExploreClient();

class Actions extends StateControllerBase {
  @setLoadingState
  async GET_MANIFEST(context, manifestId) {
    const response = await applicationClient.getAplication(manifestId);
    const application = response.data;
    application.manifest.dataView = _.map(application.manifest.dataView, DataViewConverter.parseDataView);
    context.commit("SET_MANIFEST", application);
    context.commit("setEtag", response.headers.etag);
  }

  REMOVE_APP_MANIFEST({ commit }) {
    commit("SET_MANIFEST", null);
  }

  resetAppState(context) {
    context.commit("resetAppState");
  }

  setApplicationId({ commit }, id) {
    commit("setApplicationId", id);
  }


  @setLoadingState
  async getClasses({ commit, getters }) {
    if (_.isEmpty(getters.classes)) {
      const data = await exploreClient.getAllClasses();
      commit("setClasses", data?.items);
    }
  }

  @setLoadingState
  async deleteDataSourceDataView({ dispatch, rootGetters }, dataViewForDelete) {
    await applicationClient.deleteDataView(rootGetters.MANIFEST_ID, dataViewForDelete, rootGetters.appEtag);
    await dispatch("GET_MANIFEST", rootGetters.MANIFEST_ID);
  }

  @setLoadingState
  async addDataSourceDataView({ rootGetters }, dataView) {
    await applicationClient.addDataView(rootGetters.MANIFEST_ID, DataViewConverter.encodeDataView(dataView), rootGetters.appEtag);
  }

  @setLoadingState
  async updateDataSourceDataView({ rootGetters }, dataView) {
    await applicationClient.updateDataView(rootGetters.MANIFEST_ID, DataViewConverter.encodeDataView(dataView), rootGetters.appEtag);
  }

  @setLoadingState
  async updateDataSourceDataViews({ rootGetters }, dataViews) {
    await applicationClient.updateDataViews(rootGetters.MANIFEST_ID, _.map(dataViews, dataView => DataViewConverter.encodeDataView(dataView)), rootGetters.appEtag);
  }

  @setLoadingState
  async setDataSourceDefaultDataView({ rootGetters }, defaultDataView) {
    await applicationClient.setDefaultDataView(rootGetters.MANIFEST_ID, defaultDataView, rootGetters.appEtag);
  }

  @setLoadingState
  async deleteDataSourceDefaultDataView({ rootGetters }, dataSourceId) {
    await applicationClient.deleteDefaultDataView(rootGetters.MANIFEST_ID, dataSourceId, rootGetters.appEtag);
  }
}

export default (new Actions).asPlainObject();