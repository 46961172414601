import httpClient from "./DefaultHttpClient";
import { rethrowPlatoApiError } from "./PlatoApiError";

const BASE_PATH = "/v1/ConfigurationApi";

class ConfigurationClient {
  @rethrowPlatoApiError
  async getServiceConfigurations() {
    return httpClient.get(`${BASE_PATH}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async saveServiceConfiguration(configuration) {
    return httpClient.post(`${BASE_PATH}`, configuration)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async deleteServiceConfiguration(key) {
    return httpClient.delete(`${BASE_PATH}/${key}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async resetServiceConfigurations() {
    return httpClient.post(`${BASE_PATH}/reset`)
      .then(response => response.data);
  }
}

export default ConfigurationClient;