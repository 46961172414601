<template>
  <div
    :class="{
      'plato-list-card--selected': isSelected,
      'plato-list-card--checked': isChecked,
    }"
    class="plato-list-card"
    @click="$event.defaultPrevented || click()"
  >
    <div class="plato-list-card__inner">
      <div
        :style="{ flexBasis: leftColumnWidth }"
        class="plato-list-card__inner__left"
      >
        <div
          class="plato-list-card__inner__left_index-check"
          @mouseover="hovered = true"
          @mouseleave="hovered = null"
        >
          <span
            v-if="(!hovered || !checkboxes) && !isChecked"
            :class="{'index-align': index < 10}"
          >
            {{ index }}
          </span>

          <PlatoCheckbox
            v-if="checkboxes && hovered || isChecked"
            :value="isChecked"
            @change="$emit('check-click')"
          />
        </div>

        <Icon
          v-if="icon"
          :style="colorStyle"
          :icon="icon"
          class="plato-list-card__inner__left_icon plato-list-card__icon"
        />
        <div
          v-else-if="outlineColor"
          :style="colorStyle"
          class="plato-list-card__inner__left_icon plato-list-card__color"
        />

        <PlatoListField
          v-if="idField"
          :value="idField.value"
          :alias="idField.alias"
          class="plato-list-card__inner__left_first"
        />
        <div class="plato-list-card__inner__left_second">
          <div class="plato-list-card__inner__left_second_title">
            <PlatoListField
              v-if="objectFields[0] && objectFields[0][0]"
              :value="objectFields[0][0].value"
              :alias="objectFields[0][0].alias"
              :style="{ cursor: actionOpenObject ? 'pointer' : 'default' }"
              class="plato-list-card__inner__left_second_title_text"
              @click.prevent="
                actionOpenObject
                  ? triggerAction(actionOpenObject.actionId)
                  : false
              "
            />
            <PlatoListField
              v-if="statusField"
              :value="statusField.value"
              :alias="statusField.alias"
              inner-span-class="plato-tag__label"
              class="plato-status-field"
            />
          </div>

          <PlatoListField
            v-if="objectFields[1] && objectFields[1][0]"
            :value="objectFields[1][0].value"
            :alias="objectFields[1][0].alias"
            :show-more-info="showMoreInfo"
            class="plato-list-card__inner__left_second_subtitle"
          />
        </div>
      </div>
      <PlatoListField
        v-if="numField"
        :value="numField.value"
        :alias="numField.alias"
        :icon-class="numIconClass"
        class="plato-list-card__inner__right_num-param plato-status-field"
      />
      <PlatoButton
        v-if="actionOpenObject"
        type="link-grey small"
        :icon="writeAccess ? 'pen' : 'eye'"
        :class="{ 'display-none' : isMobile }"
        @click="clickActionOpenObject"
      />
      <PlatoContextMenu
        v-if="isDeletable || (!_.isEmpty(actionOpenObject) && !_.isEmpty(actionCreateReport))"
        type="link-grey small"
        :class="{ 'display-none' : isMobile }"
      >
        <PlatoButton
          v-if="!_.isEmpty(actionCreateReport)"
          icon="file-chart-line"
          class="plato-context-menu__btn"
          @click="triggerAction(actionCreateReport.actionId)"
        >
          {{ actionCreateReport.name }}
        </PlatoButton>
        <PlatoButton
          v-if="isDeletable && writeAccess"
          icon="trash"
          type="danger-action"
          class="plato-context-menu__btn"
          @click="confirmDeleteInstance"
        >
          Удалить
        </PlatoButton>
      </PlatoContextMenu>
    </div>
    <div
      v-if="!_.isEmpty(otherObjectFields)"
      class="plato-list-card__tags"
    >
      <PlatoTags
        :show-more-info="showMoreInfo"
        :tags="sortedOtherObjectFields"
      />
    </div>
  </div>
</template>

<script>
import PlatoListField from "./PlatoListField.vue";

import _ from "lodash";
import { colorSchema } from "@/components/ObjectColorSchema.js";
import { scopes } from "@/store/Access";

import { isMobile } from "@/components/utils/mobile/Mobile.js";

export default {
  name: "PlatoListCard",
  components: {
    PlatoListField
  },
  props: {
    dataSourceId: {
      type: String,
      default: ""
    },
    geojeson: {
      type: Object,
      default: null
    },
    icon: {
      type: String,
      default: ""
    },
    outlineColor: {
      type: String,
      default: ""
    },
    idField: {
      type: Object,
      default: null
    },
    isChecked: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    leftColumnWidth: {
      type: String,
      default: ""
    },
    numField: {
      type: Object,
      default: null
    },
    numIconClass: {
      type: String,
      default: ""
    },
    object: {
      type: Object,
      default: null
    },
    objectFields: {
      type: Array,
      default: () => []
    },
    otherObjectFields: {
      type: Array,
      default: () => []
    },
    statusField: {
      type: Object,
      default: null
    },
    isDeletable: {
      type: Boolean,
      default: true
    },
    checkboxes: {
      type: Boolean,
      default: true
    },
    actionOpenObject: {
      type: Object,
      default: () => {}
    },
    actionCreateReport: {
      type: Object,
      default: () => {}
    },
    aliases: {
      type: Array,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    showMoreInfo: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isShowTitlePopper: false,
      isShowSubtitlePopper: false,
      isShowNumPopper: false,
      isMobile: isMobile(),
      hovered: false
    };
  },

  computed: {
    writeAccess() {
      return this.$store.getters.writeAccess({ scope: scopes.APPLICATION_DATA, resource: this.dataSourceId });
    },

    sortedOtherObjectFields() {
      if (this.otherObjectFields) {
        let fields = [];

        let groups = _.groupBy(this.otherObjectFields, "propAlias");

        for (const groupName in groups) {
          fields.push(..._.sortBy(groups[groupName], "value"));
        }

        return fields;
      }
      return null;
    },

    colorStyle() {
      let color = colorSchema(null, this.outlineColor);

      return {
        "--color": color.outline.main
      };
    }
  },

  methods: {
    click() {
      this.$emit("click");
      if (this.isMobile) {
        this.clickActionOpenObject();
      }
    },

    confirmDeleteInstance() {
      this.Modal.confirm({
        title: "Удаление элемента",
        message: "Вы уверены, что хотите удалить элемент?",
        type: "danger",
        onConfirm: this.deleteInstance
      });
    },

    async deleteInstance() {
      await this.$store.dispatch("list/deleteInstances", { dataSourceId: this.dataSourceId, ids: [this.object.id] });
      this.notification.success({ title: "Объект удалён" });

      await this.$store.dispatch("list/getDataSourceData", { dataSourceId: this.dataSourceId, aliases: this.aliases });

      if (this.isSelected) {
        this.$store.dispatch("currentObject/removeCurrentObject", { refreshUrl: false });
      }
    },

    getColor(key) {
      let colors = [
        "standard",
        "green",
        "blue",
        "red",
        "purple",
        "grey",
        "teal",
        "yellow",
        "green-light",
        "blue-light",
        "red-light",
        "purple-light",
        "grey-light",
        "teal-light",
        "yellow-light"
      ];
      return colors[parseInt(key % colors.length)];
    },
    triggerAction(actionId) {
      this.$eventBus.$emit("component:action", {
        actionId: actionId,
        id: this.object.id
      });
      this.show = false;
    },
    clickActionOpenObject() {
      this.triggerAction(this.actionOpenObject.actionId);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
@import "../styles/variables.scss";

.plato-status-field {
  padding: 4px 6px;
  border-radius: 10px;
  background: #eaecf1;
  font-size: 12px;
  color: #505f79;
}

.plato-list-card {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  user-select: none;
  flex-direction: column;
  border-bottom: 1px solid #dfe1e6;

  &__icon {
    color: var(--color);
  }

  &__color {
    background: var(--color);
    border-radius: 8px;
    max-width: 34px;
    height: 19px;
  }

  &:hover {
    background-color: #f3f9ff;
  }
  &.plato-list-card--checked {
    background-color: #f5f6f8;
  }
  &.plato-list-card--selected {
    background-color: #deebff;
  }
  .menu-button-wrap {
    position: unset;
  }
  &__tags {
    margin: 5px 0 -3px 34px;
  }
  &__inner {
    display: flex;
    align-items: center;

    &__left {
      display: flex;
      flex: 1 0;
      align-items: center;
      margin-left: 5px;
      &_index-check {
        min-width: 26px;
        justify-content: center;
        .index-align{
          margin-left: 3px;
        }
      }
      &_icon {
        flex: 0 0 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
      }
      &_first {
        white-space: nowrap;
        border: 1px solid #ffa367;
        padding: 2px 4px;
        border-radius: 3px;
        background: #fff7e0;
        font-size: 13px;
        margin: 0 8px;

        span {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      &_second {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 8px;

        &_title, &_subtitle {
          margin: 1px 0;
        }

        &_title {
          display: flex;
          align-items: baseline;

          &_text {
            font-size: 14px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-right: 5px;
            padding: 2px 0;
          }
        }
        &_subtitle {
          font-size: 12px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          padding: 2px 0;
        }
      }
    }
    &__right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1 1 auto;
      overflow: hidden;
      .multiselect {
        align-items: center;
      }
      &_num-param {
        display: flex;
        align-items: center;
        margin: 0 8px;
        ::v-deep {
          .fas {
            margin-right: 7px;
          }
        }
      }
      &_all-params {
        display: flex;
        flex: 1 1 auto;
        flex-wrap: wrap;
        overflow: hidden;
        ::v-deep {
          .plato-tag__label {
            text-transform: uppercase;
          }
        }
      }
      ::v-deep {
        > .plato-btn {
          margin-left: 4px;
        }
      }
    }
  }
}

.plato-context-menu {
  margin-left: 4px;
}

button.plato-context-menu__btn {
  width: 100%;
  justify-content: flex-start;
}

div.display-none, button.display-none {
  display: none;
}
</style>

