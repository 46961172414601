<template>
  <table class="plato-table">
    <thead v-if="!hideHead">
      <tr>
        <th
          v-if="checkboxes || indexes"
        >
          <div
            v-if="showSelectAll && checkboxes"
            class="plato-table__checkbox"
          >
            <PlatoCheckbox
              v-model="selectAll"
            />
          </div>
          <div
            v-if="indexes && !checkboxes"
            class="plato-table__number"
          >
            №
          </div>
        </th>

        <th v-if="colorOrIcon">
          <div />
        </th>

        <th
          v-for="(column, index) in visibleColumns"
          :key="`table-th-${column.property}-${column.ownProperty}-${index}`"
        >
          <div
            :style="{'min-width': column.width + 'px'}"
            :class="{'align-right': column.align == 'right',
                     'sortable': column.sort,
                     'sortable-desc': column.sort == 'desc'}"
            @click="$emit('columnClick', column)"
          >
            <slot
              :name="`header_${column.property}`"
              :index="index"
              :column="column"
            >
              <div class="title-wrapper">
                <div
                  v-if="column.sort"
                  class="plato-table__sort-icon"
                >
                  <Icon
                    v-if="column.sort == 'asc'"
                    icon="chevron-up"
                  />
                  <div
                    v-else-if="column.sort == 'desc'"
                    class="plato-table__sort-icon-desc"
                  >
                    <Icon
                      icon="chevron-down"
                    />
                  </div>
                  <Icon
                    v-else
                    icon=""
                  />
                </div>

                {{ column.label }}
              </div>
            </slot>
          </div>
        </th>
      </tr>
    </thead>
    <Draggable
      tag="tbody"
      class="tbody"
      v-bind="dragOptions"
      :value="[{}, ...elements]"
      handle=".handle-layer"
      @change="dragged"
    >
      <slot name="body">
        <template
          v-for="(grouped, groupedIndex) in groupedElements"
        >
          <tr
            v-if="groupedElements.length >= 1 && _.keys(groupedElements[0])[0] !== 'element'"
            :key="`table-group-${groupedIndex}`"
            class="group-row"
          >
            <th
              :colspan="columns.length + 1"
              class="group-header"
            >
              <div class="group-header_group">
                <PlatoCheckbox
                  v-if="checkboxes && showSelectAll"
                  class="group-header_checkbox"
                  :value="_.every(_.values(grouped)[0], element => selected.indexOf(element[selectBy]) >= 0)"
                  @change="value => changeSelections(_.values(grouped)[0], value)"
                />

                <div class="group-header_collapser">
                  <PlatoButton
                    type="subtle"
                    :icon="grouped.collapsed ? 'chevron-right' : 'chevron-down'"
                    @click="changeCollapse(_.keys(grouped)[0])"
                  />
                </div>

                <div class="group-header_title">
                  {{ _.keys(grouped)[0] }}
                </div>

                <div class="group-header_count">
                  ({{ _.values(grouped)[0].length }})
                </div>
              </div>
            </th>
          </tr>

          <template v-if="!grouped.collapsed">
            <tr
              v-for="(element, tableIndex) in _.values(grouped)[0]"
              :key="`table-tr-${groupedIndex}-${tableIndex}`"
              :active="element.active"
              :checked="element.checked"
              @click="$event.defaultPrevented || $emit('click', element)"
            >
              <td
                v-if="checkboxes || indexes"
                class="plato-table__check"
                @mouseover="hoveredItem = element"
                @mouseleave="hoveredItem = null"
              >
                <PlatoCheckbox
                  v-if="checkboxes"
                  v-show="!indexes || (indexes && hoveredItem == element)
                    || (indexes && !!(selected.indexOf(element[selectBy]) >= 0))"
                  :value="selected.indexOf(element[selectBy]) >= 0"
                  @change="value => changeSelection(element, value)"
                />

                <div
                  v-if="indexes"
                  v-show="!checkboxes || (checkboxes && hoveredItem !== element && (selected.indexOf(element[selectBy]) < 0))"
                  class="plato-table__index"
                >
                  {{ getTableIndex(tableIndex, groupedIndex) }}
                </div>
              </td>

              <td v-if="colorOrIcon">
                <Icon
                  v-if="element.style.icon"
                  :style="getColorStyle(element.style)"
                  :icon="element.style.icon"
                  class="plato-table__icon"
                />
                <div
                  v-else-if="element.style.outlineColor"
                  :style="getColorStyle(element.style)"
                  class="plato-table__color"
                />
              </td>

              <td
                v-for="(column, index) in visibleColumns"
                :key="`table-td-${column.property}-${column.ownProperty}-${index}`"
                :class="{'drag-column' : (draggable && index == 0)}"
              >
                <Icon
                  v-if="draggable && index == 0"
                  class="handle-layer"
                  icon="grip-vertical"
                />
                <div :class="{'align-right': column.align == 'right'}">
                  <slot
                    :name="column.ownProperty || column.property"
                    :index="tableIndex"
                    :element="element"
                  >
                    {{ _.get(element, column.property) | propOutputFormat(column.tableType) }}
                  </slot>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </slot>
    </Draggable>
    <tfoot>
      <tr v-if="count">
        <td
          v-for="(column, index) in formatedColumns"
          :key="`table-td-${index}`"
        >
          <div
            v-if="(column.tableType == 'Numeric' || column.isAggregated)
              && (column.showFooter == true || column.showFooter == null)"
          >
            {{ getSum(column.property, elements) }}
          </div>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import _ from "lodash";
import Draggable from "vuedraggable";

import Icon from "../Icons/Icon.vue";
import { colorSchema } from "@/components/ObjectColorSchema.js";

export default {
  components: {
    Draggable,
    Icon
  },

  props: {
    elements: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    selectBy: {
      type: String,
      default: "id"
    },
    selected: {
      type: Array,
      default: () => []
    },
    checkboxes: {
      type: Boolean,
      default: false
    },
    showSelectAll: {
      type: Boolean,
      default: true
    },
    sort: {
      type: Boolean,
      default: true
    },
    count: {
      type: Boolean,
      default: false
    },
    indexes: {
      type: Boolean,
      default: false
    },
    groupingProperty: {
      type: String,
      default: ""
    },
    groupingOwnProperty: {
      type: String,
      default: ""
    },
    draggable: {
      type: Boolean,
      default: false
    },
    hideHead: {
      type: Boolean,
      default: false
    },
    layerTableIndex: {
      type: Number,
      default: 0
    },
    showIcons: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      hoveredItem: null,
      collapsedGroups: []
    };
  },

  computed: {
    dragOptions() {
      return {
        group: "table-element",
        disabled: !this.draggable
      };
    },

    selectAll: {
      get: function() {
        return _.chain(this.elements)
          .map(this.selectBy)
          .intersection(this.selected)
          .size()
          .eq(this.elements.length)
          .value() && this.elements.length !== 0;
      },

      set: function(value) {
        let selected = _.cloneDeep(this.selected);

        let keys = _.map(this.elements, this.selectBy);

        if (value) {
          _.each(keys, key => selected.push(key));
        }
        else {
          _.remove(selected, selectedElement => _.find(keys, key => key == selectedElement));
        }

        this.$emit("change", _.uniq(selected));
      }
    },

    formatedColumns() {
      let columns = _.cloneDeep(this.visibleColumns);

      if (this.indexes || this.checkboxes) {
        columns.unshift({});
      }

      if (this.colorOrIcon) {
        columns.unshift({});
      }

      return columns;
    },

    colorOrIcon() {
      return this.showIcons && _.some(this.elements, element => element.style);
    },

    visibleColumns() {
      return _.filter(this.columns, column => column.hidden != true);
    },

    groupedElements() {
      if (_.isEmpty(this.groupingProperty)) {
        return [{ element: this.elements }];
      }

      let obj = {};

      let column = _.find(this.columns, { property: this.groupingProperty });

      if (column && column.ownProperty) {
        obj = _.chain(this.elements).orderBy(element => _.find(element[this.groupingProperty], { alias: this.groupingOwnProperty })?.value, [column.sort])
          .groupBy(element => _.find(element[this.groupingProperty], { alias: this.groupingOwnProperty })?.value).value();
      }
      else {
        obj = _.chain(this.elements).orderBy(element => element[this.groupingProperty] == this.groupingProperty, [column.sort]).groupBy(this.groupingProperty).value();
      }

      let arrOfGroups = [];

      for (const [key, value] of Object.entries(obj)) {
        let newKey = key == "undefined" ? "Нет данных"
          : this.$options.filters.propOutputFormat(key, column ? column.tableType : "");

        let group = { [newKey]: value };

        if (_.some(this.collapsedGroups, name => name == newKey)) {
          group.collapsed = true;
        }

        arrOfGroups.push(group);
      }

      return arrOfGroups;
    }
  },

  methods: {
    dragged(value) {
      value.layerTableIndex = this.layerTableIndex;
      this.$emit("dragged", value);
    },

    getColorStyle(style) {
      let color = colorSchema(null, style?.outlineColor);

      return {
        "--color": color.outline.main
      };
    },

    //Метод для удалёния лишних дробей при сложении
    getSum(property, elements) {
      //Получение числовых значений
      let filteredNumbers = _.chain(elements)
        .map(property)
        .filter()
        .value();

      //Преобразование числовых значений в связях
      if (_.some(filteredNumbers, number => _.isArray(number))) {
        filteredNumbers = _.chain(filteredNumbers)
          .flatMap()
          .map(x => x.value)
          .value();
      }

      //Получение длины максимальной дроби
      let maxFraction = _.chain(filteredNumbers)
        .map(prop => _.split(prop, ".")[1]?.length)
        .max()
        .value();

      //Сложение и округление
      return parseFloat(_.chain(filteredNumbers)
        .sumBy(prop => parseFloat(prop))
        .value()
        .toFixed(maxFraction));
    },

    changeCollapse(group) {
      if (_.some(this.collapsedGroups, name => name == group)) {
        this.collapsedGroups = _.filter(this.collapsedGroups, name => name !== group);
      }
      else {
        this.collapsedGroups.push(group);
      }
    },

    getTableIndex(tableindex, groupedIndex) {
      let groups = _.slice(this.groupedElements, 0, groupedIndex);

      let count = 1;

      groups.forEach(group => {
        count += _.values(group)[0].length;
      });

      return tableindex + count;
    },

    changeSelections(elements, value) {
      let selected = _.cloneDeep(this.selected);

      _.map(elements, this.selectBy).forEach(key => {
        if (value) {
          if (selected.indexOf(key) < 0) {
            selected.push(key);
          }
        }
        else {
          let index = selected.indexOf(key);
          selected.splice(index, 1);
        }
      });

      this.$emit("change", selected);
    },

    changeSelection(element, value) {
      let selected = _.cloneDeep(this.selected);
      let key = element[this.selectBy];

      if (value) {
        if (selected.indexOf(key) < 0) {
          selected.push(key);
        }
      }
      else {
        let index = selected.indexOf(key);
        selected.splice(index, 1);
      }

      this.$emit("change", selected);
    }
  }
};
</script>

<style lang="scss" scoped>

.plato-table[hoverable] {
  tbody > :not(.group-row):not(.plato-card__body_no-content_table) {
    &:hover {
      background-color: #f3f9ff;
    }

    &[checked]:not([active]) {
      background-color: #f5f6f8;
    }

    &[active] {
      background-color: #deebff;
    }
  }
}

.plato-table {
  border-collapse: collapse;
  width: 100%;
  &[sticky-with-header] {
    thead{
      top: 80px;
    }
  }

  thead {
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    z-index: 2;
    background-color: white;

    tr {
      box-shadow: inset 0 -1px 0 #dfe1e6
    }

    th {
      vertical-align: middle;
      height: 42px;

      > div {
        display: flex;
        padding: 6px 0 6px 8px;
        line-height: 1.2;
        color: #6b778c;
        text-align: left;
        align-items: center;

        &.plato-table__checkbox {
          min-width: 36px;
          justify-content: center;
        }


        &.align-right {
          justify-content: flex-end;
          margin-right: -6px;
        }

        &.sortable {
          cursor: pointer;
        }
      }
    }
  }
  .title-wrapper {
    position: relative;

    @media screen and (max-width: 768px) {
      position: relative;
      max-height: 35px;
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;
    }

  }
  tfoot {
    tr {
      border-top: 2px solid #dfe1e6;
      font-weight: bold;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #dfe1e6;
      position: relative;
    }

    .group {
      &-header {
        padding: 7px 0 7px 7px;

        &_group {
          display: flex;
          align-items: center;
          font-weight: bold;
          color: #6b778c;
          text-align: left;
        }

        &_collapser {
          padding-right: 5px;
        }

        &_checkbox {
          padding-right: 2px;
          padding-left: 8px
        }

        &_count {
          padding-left: 10px;
        }
      }

      &-footer__count {
        font-weight: bold;
      }
    }
  }

  tbody, tfoot {
    td {
      vertical-align: middle;
      //height: 42px;
      padding-right: 8px;

      > div:not(.group-table-wrapper) {
        display: flex;
        padding: 8px 0 8px 8px;
        font-size: 14px;
        line-height: 1.2;
        color: #172b4d;
        width: 100%;

        &.plato-table__index {
          padding: 8px 0 8px 11px;
        }

        &.align-right {
          justify-content: flex-end;
        }
      }
    }
  }

  &__sort-icon {
    margin-bottom: -2px;
    text-align: center;
    font-size: 10px;
    position: absolute;
    top: -9px;
    right: calc(50% - 8px);
    &-desc {
      margin-bottom: -9px;
    }
  }

  &__check {
    padding-left: 7px;
    width: 30px;
  }

  &__checkbox {
    width: 24px;
    height: 24px;
    label {
      margin-left: 5px;
    }
  }

  .group-table:last-child {
    tr:last-child {
      border-bottom: 0;
    }
  }
}
.handle-layer {
  cursor: move;
  color: #bbbbbb;
  margin-right: 2px;
}
.drag-column {
  display: flex;
  align-items: center;
  padding-top: 1px;
  min-height: 42px;
}
.plato-table__number {
  min-width: 36px;
  justify-content: center;
}
.plato-table__color {
  max-width: 2px;
  justify-content: center;
  background: var(--color);
  border-radius: 4px;
  margin-left: 4px;
  margin-right: 3px;
}
.plato-table__icon {
  color: var(--color);
  padding: 0 5px;
}
</style>