import _ from "lodash";
import LocalStorageController from "./localStorageController";

const localStorageController = new LocalStorageController();

export default {
  namespaced: true,
  state: () => ({
    searchKeywords: [],
    localPaneSizes: null,
    localLayersState: null,
    localBaseLayerState: null,
    localLayerGroupsState: null,
    localShowTours: null
  }),
  getters: {
    searchKeywords: state => {
      return state.searchKeywords;
    },
    localPaneSizes: state => {
      return state.localPaneSizes;
    },
    localShowTours: state => {
      return state.localShowTours;
    },
    localLayerState: state => layerId => {
      if (state.localLayersState != null) {
        let localLayerState = _.find(state.localLayersState, layerState => layerState.id === layerId);
        return localLayerState ? localLayerState : null;
      }
      return null;
    },
    localBaseLayerState: state => {
      return state.localBaseLayerState;
    },
    localLayerGroupState: state => groupId => {
      if (state.localLayerGroupsState != null) {
        let localGroupState = _.find(state.localLayerGroupsState, groupState => groupState.groupId === groupId);
        return localGroupState ? localGroupState : null;
      }
      return null;
    }
  },
  mutations: {
    setSearchKeywords: (state, localSearchKeywords) => {
      if (localSearchKeywords) {
        state.searchKeywords = localSearchKeywords;
      }
      else {
        state.searchKeywords = [];
      }
    },
    setPaneSizes: (state, paneSizes) => {
      if (paneSizes) {
        state.localPaneSizes = paneSizes;
      }
      else {
        state.localPaneSizes = null;
      }
    },

    setShowTours: (state, showTours) => {
      state.localShowTours = showTours;
    },

    setLayersState: (state, layersState) => {
      if (layersState) {
        state.localLayersState = layersState;
      }
      else {
        state.localLayersState = null;
      }
    },

    setBaseLayerState: (state, baseLayerState) => {
      if (baseLayerState) {
        state.localBaseLayerState = baseLayerState;
      }
      else {
        state.localBaseLayerState = null;
      }
    },

    setLayerGroupsState: (state, layerGroupsState) => {
      if (layerGroupsState) {
        state.localLayerGroupsState = layerGroupsState;
      }
      else {
        state.localLayerGroupsState = null;
      }
    }
  },
  actions: {
    getSearchKeywords({ rootGetters, commit }) {
      let searchKeywords = localStorageController.getData("_searchKeywords", rootGetters.MANIFEST.info.applicationId);
      commit("setSearchKeywords", searchKeywords);
    },
    addSearchKeyword({ dispatch, state }, searchValue) {
      if (_.isEmpty(searchValue)) {
        return;
      }

      let changedKeywords = _.cloneDeep(state.searchKeywords);
      changedKeywords.unshift(searchValue);
      _.remove(changedKeywords, function(n, num) {
        return num > 9;
      });
      dispatch("setToLocalStorageSearchKeywords", changedKeywords);
    },
    removeSearchKeyword({ dispatch, state }, removedKeyword) {
      let changedSearchKeywords = _.remove(state.searchKeywords, function(n) {
        return n !== removedKeyword;
      });
      if (changedSearchKeywords.length > 0) {
        dispatch("setToLocalStorageSearchKeywords", changedSearchKeywords);
      }
      else {
        dispatch("removeLocalStorageSearchKeywords");
      }
    },
    setToLocalStorageSearchKeywords({ rootGetters }, element) {
      localStorageController.setData("_searchKeywords", rootGetters.MANIFEST.info.applicationId, element);
    },
    removeLocalStorageSearchKeywords({ rootGetters }) {
      localStorageController.removeSearchKeywords(rootGetters.MANIFEST.info.applicationId);
    },

    setPaneSizesToLocalStorage({ rootGetters }, { pageId, paneSizes }) {
      let source = `${rootGetters.MANIFEST.info.applicationId}_${pageId}`;
      localStorageController.setData("_paneSizes", source, paneSizes);
    },

    getPaneSizesFromLocalStorage({ commit, rootGetters }, pageId) {
      if (!rootGetters.MANIFEST) {
        return;
      }

      let source = `${rootGetters.MANIFEST.info.applicationId}_${pageId}`;
      let paneSizes = localStorageController.getData("_paneSizes", source);
      commit("setPaneSizes", paneSizes);
    },

    setShowToursToLocalStorage(_context, showTours) {
      localStorageController.setData("showTours", "", showTours);
    },

    getShowToursFromLocalStorage({ commit, dispatch }) {
      let showTours = localStorageController.getData("showTours", "");
      if (!_.isUndefined(showTours)) {
        commit("setShowTours", showTours);
      }
      else {
        dispatch("setShowToursToLocalStorage", false);
        commit("setShowTours", false);
      }
    },

    setLocalLayerState({ rootGetters }, layerState) {
      let source = `${rootGetters.MANIFEST.info.applicationId}_${rootGetters.pageId}`;
      let layersState = _.cloneDeep(localStorageController.getData("_layersState", source));
      if (layersState === undefined) {
        layersState = [];
      }
      let oldLayerStateIndex = _.findIndex(layersState, localLayerState => localLayerState.id === layerState.id);
      if (oldLayerStateIndex != -1) {
        layersState.splice(oldLayerStateIndex, 1);
      }
      layersState.push(layerState);
      localStorageController.setData("_layersState", source, layersState);
    },

    getLayersStateFromLocalStorage({ rootGetters, commit }) {
      if (!rootGetters.MANIFEST) {
        return;
      }

      let source = `${rootGetters.MANIFEST.info.applicationId}_${rootGetters.pageId}`;
      let layersState = localStorageController.getData("_layersState", source);
      commit("setLayersState", layersState);
    },

    setLocalBaseLayerState({ rootGetters }, baseLayerId) {
      let source = `${rootGetters.MANIFEST.info.applicationId}_${rootGetters.pageId}`;
      localStorageController.setData("_baseLayer", source, { baseLayerId });
    },

    getLocalBaseLayerState({ rootGetters, commit }) {
      if (!rootGetters.MANIFEST) {
        return;
      }

      let source = `${rootGetters.MANIFEST.info.applicationId}_${rootGetters.pageId}`;
      let baseLayerState = localStorageController.getData("_baseLayer", source);
      commit("setBaseLayerState", baseLayerState);
    },

    setLayerGroupsState({ rootGetters }, layerGroupState) {
      let source = `${rootGetters.MANIFEST.info.applicationId}_${rootGetters.pageId}`;
      let layerGroupsState = localStorageController.getData("_layerGroupsState", source);
      if (layerGroupsState === undefined) {
        layerGroupsState = [];
      }
      let oldLayerGroupStateIndex = _.findIndex(layerGroupsState, localLayerGroupState => localLayerGroupState.groupId === layerGroupState.groupId);
      if (oldLayerGroupStateIndex != -1) {
        layerGroupsState.splice(oldLayerGroupStateIndex, 1);
      }
      layerGroupsState.push(layerGroupState);
      localStorageController.setData("_layerGroupsState", source, layerGroupsState);
    },

    getLocalLayerGroupsState({ rootGetters, commit }) {
      if (!rootGetters.MANIFEST) {
        return;
      }

      let source = `${rootGetters.MANIFEST.info.applicationId}_${rootGetters.pageId}`;
      let layerGroupsState = localStorageController.getData("_layerGroupsState", source);
      commit("setLayerGroupsState", layerGroupsState);
    }
  }
};
