import actions from "./admin/actions";

import _ from "lodash";

const getDefaultState = () => {
  return {
    services: [],
    servicesForTable: [],
    users: [],
    usersForTable: [],
    dataLoading: false,
    dataLoadingError: null,
    roles: [],
    rolesForTable: [],
    permissions: [],
    scopes: [],
    applications: [],
    dataSources: [],
    shellPages: [],
    shellPagesInfo: [],
    shellManifest: null,
    serviceConfigurations: [],
    mapProjections: [],
    baseLayers: [],
    mapParameters: {},
    classes: [],
    instances: [],
    accesses: []
  };
};

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    DATA_LOADING_ERROR: state => {
      return state.dataLoadingError;
    },

    DATA_LOADING: state => {
      return state.dataLoading;
    },

    mapProjections: state => {
      return state.mapProjections;
    },

    baseLayers: state => {
      return state.baseLayers;
    },

    mapParameters: state => {
      return state.mapParameters;
    },

    serviceConfigurations: state => {
      return state.serviceConfigurations;
    },

    shellManifest: state => {
      return state.shellManifest;
    },

    shellPages: state => {
      return state.shellPages;
    },

    shellPagesInfo: state => {
      return state.shellPagesInfo;
    },

    users: state => {
      return state.users;
    },

    usersForTable: state => {
      return state.usersForTable;
    },

    services: state => {
      return state.services;
    },

    servicesForTable: state => {
      return state.servicesForTable;
    },

    roles: state => {
      return state.roles;
    },

    rolesForTable: state => {
      return state.rolesForTable;
    },

    permissions: state => {
      return state.permissions;
    },

    accesses: state => {
      return state.accesses;
    },

    scopes: state => {
      return state.scopes;
    },

    accessLevels: () => {
      return [
        {
          display: "Чтение",
          value: "r"
        },
        {
          display: "Запись",
          value: "w"
        },
        {
          display: "Чтение и запись",
          value: "rw"
        }
      ];
    },

    usersForSelect: state => {
      let users = state.users.map(user => ({ display: user.userInfo.name, value: user.id }));
      users.unshift({ display: "*", value: "*" });
      return users;
    },

    rolesForSelect: state => {
      let roles = state.roles.map(role => ({ display: role.name, value: role.id }));
      roles.unshift({ display: "*", value: "*" });
      return roles;
    },

    servicesForSelect: state => {
      let services = state.services.map(service => ({ display: service.serviceInfo.name, value: service.id }));
      services.unshift({ display: "*", value: "*" });
      return services;
    },

    permissionsForSelect: state => {
      let permissions = state.permissions.map(permission => {
        let role = _.find(state.roles, { id: permission.roleId });

        return { display: `${role.name} ${permission.scope} ${permission.resource} ${permission.access}`, value: permission.id };
      });
      permissions.unshift({ display: "*", value: "*" });
      return permissions;
    },

    configurationsForSelect: state => {
      let configurations = state.serviceConfigurations.map(configuration => ({ display: configuration.key, value: configuration.key }));
      configurations.unshift({ display: "*", value: "*" });
      return configurations;
    },

    pagesForSelect: state => {
      let shellPagesInfo = state.shellPagesInfo.map(page => ({ display: page.title, value: page.pageId }));
      shellPagesInfo.unshift({ display: "*", value: "*" });
      return shellPagesInfo;
    },

    dataSourcesForSelect: state => {
      let dataSources = state.dataSources.map(dataSource => ({ display: dataSource.name, value: dataSource.id }));
      dataSources.unshift({ display: "*", value: "*" });
      return dataSources;
    },

    applicationsForSelect: state => {
      let applications = state.applications.map(app => ({ display: app.manifest.info.name, value: app.id }));
      applications.unshift({ display: "*", value: "*" });
      return applications;
    },

    applications: state => {
      let applications = state.applications.map(app => ({
        display: app.manifest.info.name,
        value: app.manifest.info.applicationId
      }));
      return applications;
    },

    classesForSelect: state => {
      let classes = state.classes.map(clas => {
        let name = _.chain(clas.annotationProperties).find({ "type": "rdfs:label" }).get("value").value();

        return { display: name, value: clas.id };
      });
      return _.sortBy(classes, ["display"]);
    },

    instancesForSelect: state => {
      return _.sortBy(state.instances.map(item => {
        let name = _.find(item.dataProperties, { id: "95b1ff3e-497c-462f-b260-c1b7166021b5" });
        return { display: name?.value || item.id, value: item.id };
      }), ["display"]);
    }
  },

  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },

    SET_DATA_LOADING_ERROR: (state, value) => {
      state.dataLoadingError = value;
    },

    SET_DATA_LOADING: (state, value) => {
      state.dataLoading = value;
    },

    setMapProjections: (state, mapProjections) => {
      state.mapProjections = mapProjections;
    },

    setBaseLayers: (state, baseLayers) => {
      state.baseLayers = baseLayers;
    },

    setMapParameters: (state, mapParameters) => {
      state.MapParameters = mapParameters;
    },

    setServiceConfigurations: (state, configurations) => {
      state.serviceConfigurations = configurations;
    },

    setUsers: (state, users) => {
      state.users = users;
    },

    setUsersForTable: (state, users) => {
      state.usersForTable = users;
    },

    setServices: (state, services) => {
      state.services = services;
    },

    setServicesForTable: (state, services) => {
      state.servicesForTable = services;
    },

    setRoles: (state, roles) => {
      state.roles = roles;
    },

    setRolesForTable: (state, roles) => {
      state.rolesForTable = roles;
    },

    setPermissions: (state, permissions) => {
      state.permissions = permissions;
    },

    setAccesses: (state, accesses) => {
      state.accesses = accesses;
    },

    setScopes: (state, scopes) => {
      state.scopes = scopes;
    },

    setApplications: (state, applications) => {
      state.applications = applications;
    },

    setDataSources: (state, dataSources) => {
      state.dataSources = dataSources;
    },

    setShellPages: (state, shellPages) => {
      state.shellPages = shellPages;
    },

    setShellPagesInfo: (state, setShellPagesInfo) => {
      state.setShellPagesInfo = setShellPagesInfo;
    },

    setShellManifest: (state, shellManifest) => {
      state.shellManifest = shellManifest;
    },

    setClasses: (state, classes) => {
      state.classes = classes.items;
    },

    setInstances: (state, instances) => {
      state.instances = instances.items;
    }
  },
  actions
};