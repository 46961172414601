import chroma from "chroma-js";
import tinycolor from "tinycolor2";

const convertColorToRGBA = (color, alpha) => {
  return tinycolor(color).setAlpha(alpha).toRgbString();
};

export const defaultColor = "#deebff";
export const defaultOutlineColor = "#0747a6";

export const colorSchema = (color, outlineColor) => {
  let colorRGB = tinycolor(color || defaultColor).toRgb();
  let outlineColorRGB = tinycolor(outlineColor || defaultOutlineColor).toRgb();

  let scaleColorRGB = chroma
    .bezier(["white", tinycolor(colorRGB).toHex(), "black"])
    .scale()
    .colors(10);

  let scaleOutlineColorRGB = chroma
    .bezier(["white", tinycolor(outlineColorRGB).toHex(), "black"])
    .scale()
    .colors(8);

  return {
    fill: {
      main: convertColorToRGBA(colorRGB, colorRGB.a),
      clicked: convertColorToRGBA(colorRGB, colorRGB.a),
      hovered: convertColorToRGBA(scaleColorRGB[2], colorRGB.a),
      clickHovered: convertColorToRGBA(scaleColorRGB[3], colorRGB.a),
      blocked: convertColorToRGBA(scaleColorRGB[4], colorRGB.a)
    },
    outline: {
      main: convertColorToRGBA(outlineColorRGB, outlineColorRGB.a),
      clicked: convertColorToRGBA("#000000", outlineColorRGB.a),
      hovered: convertColorToRGBA(scaleOutlineColorRGB[2], outlineColorRGB.a),
      clickHovered: convertColorToRGBA(scaleOutlineColorRGB[3], outlineColorRGB.a),
      blocked: convertColorToRGBA(scaleOutlineColorRGB[4], outlineColorRGB.a)
    }
  };
};