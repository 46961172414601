<template>
  <div class="plato-checkbox">
    <input
      :id="_uid + 'checkbox'"
      v-model="checkedValue"
      class="plato-checkbox-input"
      type="checkbox"
      :indeterminate.prop="indeterminate"
      :disabled="disabled"
      @click.stop
    >
    <slot
      name="label"
      :for="_uid + 'checkbox'"
    >
      <label
        v-if="label"
        class="plato-checkbox-label"
        :for="_uid + 'checkbox'"
      >
        {{ label }}
      </label>
    </slot>
  </div>
</template>

<script>
export default {
  name: "PlatoCheckbox",
  props: {
    label: {
      type: String
    },
    value: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    indeterminate: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    checkedValue: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit("input", value);
        this.$emit("change", value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.plato-checkbox {
  margin: 3px 0;
  align-items: center;
  display: flex;

  &-input {
    appearance: none;
    background-repeat: no-repeat;

    margin-right: 0.5em;

    border-radius: 0.25em;
    cursor: pointer;
    width: 1em;
    height: 1em;
    padding: 3px 5px;
    border: solid 2px #dfe1e5;
    background-color: #fafbfc;

    &:indeterminate  {
      border-color: #0052cc;
      background-color: #0052cc;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M 7.3362328,2.9846124 H 0.74025241 V 5.0279217 H 7.3362328 Z'/%3e%3c/svg%3e");
    }

    &:checked {
      border-color: #0052cc;
      background-color: #0052cc;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }

    &:not(:disabled) {
      &:not(:checked):not(:indeterminate) {
        &:hover {
          background-color: #ebecf0;
        }
      }
      &:active {
        background-color: #b3d7ff;
        border-color: #b3d7ff;
      }
    }

    &:focus {
        &:not(:checked):not(:indeterminate) {
            border-color: #80bdff;
            background-color: #f4f5f7;
        }
    }

    &:disabled {
        border-color: #f4f5f7;
        background-color: #f4f5f7;
        &:checked {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23ccc' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
        }
    }
  }

  &-label {
    height: 100%;
    cursor: pointer;

    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none;
  }
}

</style>
