import httpClient from "./DefaultHttpClient";
import { rethrowPlatoApiError } from "./PlatoApiError";

const BASE_PATH = "/v1/SourceApi";

class DataSourceClient {
  @rethrowPlatoApiError
  async saveDataSource(dataSource, etag) {
    return httpClient.post(`${BASE_PATH}`, dataSource, {
      headers: {
        "If-Match": etag
      }
    })
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async deleteDataSource(id) {
    return httpClient.delete(`${BASE_PATH}/${id}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getDataSource(id) {
    return httpClient.get(`${BASE_PATH}/${id}`)
      .then(response => response);
  }

  @rethrowPlatoApiError
  async generateDataSourceByType(type) {
    return httpClient.get(`${BASE_PATH}/generateDatasource/${type}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getAllDataSources() {
    return httpClient.get(`${BASE_PATH}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getAllDataSourcesInfo() {
    return httpClient.get(`${BASE_PATH}/Info`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async copyDataSource(id) {
    return httpClient({
      method: "copy",
      url: `${BASE_PATH}/${id}`
    })
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async saveFilterTemplate(filterTemplate) {
    return httpClient.post(`${BASE_PATH}/filterTemplates`,
      {
        filterTemplate: filterTemplate
      })
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async deleteFilterTemplate(id) {
    return httpClient.delete(`${BASE_PATH}/filterTemplates/${id}`)
      .then(response => response.data);
  }

  @rethrowPlatoApiError
  async getAllFilterTemplates() {
    return httpClient.get(`${BASE_PATH}/filterTemplates`)
      .then(response => response.data);
  }
}

export default DataSourceClient;