<template>
  <div class="data-view-selector">
    <DataViewSelectorItem
      v-for="view in dataView"
      :key="`selector_${view.name}`"
      :view="view"
      :data-source-id="dataSourceId"
    />
  </div>
</template>
<script>
import _ from "lodash";
import DataViewSelectorItem from "./DataViewSelectorItem.vue";
export default {
  components: { DataViewSelectorItem },
  props: {
    dataSourceId: {
      type: String,
      required: true
    }
  },
  computed: {
    dataView() {
      return _.cloneDeep(this.$store.getters["dataSourceDataView"](this.dataSourceId));
    }
  }
};
</script>
<style scoped>
.data-view-selector{
    margin: 8px 0 1px 35px;
    display: flex;
    flex-wrap: wrap;
}
</style>