<template>
  <div class="range-filter">
    <div class="range-filter__body">
      <PlatoInlineEdit
        class="range-filter__body_input"
        :value="gteCurrentValue"
        placeholder="От"
        type="Text"
        @change="(val) => gteCurrentValue = val"
      />
      <!-- <div class="number-filter">
        <k-input :label="` ≥`" v-model="gteCurrentValue" />
      </div> -->

      <PlatoInlineEdit
        class="range-filter__body_input"
        :value="lteCurrentValue"
        placeholder="До"
        type="Text"
        @change="(val) => lteCurrentValue = val"
      />
      <!-- <div class="number-filter">
        <k-input :label="` ≤`" v-model="lteCurrentValue" />
      </div> -->
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  components: {},
  props: {
    dataSourceId: String,
    property: Object
  },
  computed: {
    currentFilter: function() {
      return _.find(
        this.$store.getters["filters/filters"](this.dataSourceId),
        filter => filter.alias === this.property.alias
      );
    },
    gteCurrentValue: {
      get: function() {
        return this.currentFilter?.value ? this.currentFilter.value.gteValue : "";
      },
      set: function(newValue) {
        let filter = {
          alias: this.property.alias,
          name: this.property.name,
          value: { gteValue: newValue, lteValue: this.lteCurrentValue },
          operator: { gteOp: 4, lteOp: 5 },
          type: this.property.type,
          active: true
        };
        this.$emit("filterChange", filter);
      }
    },
    lteCurrentValue: {
      get: function() {
        return this.currentFilter?.value ? this.currentFilter.value.lteValue : "";
      },
      set: function(newValue) {
        let filter = {
          alias: this.property.alias,
          name: this.property.name,
          value: { gteValue: this.gteCurrentValue, lteValue: newValue },
          operator: { gteOp: 4, lteOp: 5 },
          type: this.property.type,
          active: true
        };
        this.$emit("filterChange", filter);
      }
    }
  }
};
</script>

<style lang="scss">
.range-filter {
  &__label {
    font-size: 12px;
    color: #6b778c;
    padding-left: 6px;
  }
  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_input {
      flex: 1 1 auto;
    }
  }
}
</style>