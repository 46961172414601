<template>
  <DataTable
    :elements="tableProperties"
    :columns="tableColumns"
    :show-pagination="showPagination"
    :grouping="grouping"
    :count="count"
    :show-more-info="showMoreInfo"

    :can-edit-element="canEditElement"
    :can-edit="canEdit"
    :can-add="canAdd && !_.isEmpty(createObjectActions)"
    :can-click="canClick"

    :open-object-actions="openObjectActions"
    :create-object-actions="createObjectActions"

    @selectElement="element => $emit('selectElement', element)"
    @createButtonClick="actionId => $emit('createButtonClick', actionId)"
    @addButtonClick="$emit('addButtonClick')"

    @columnClick="columnClick"
    @group="group"
    @increaseTake="$emit('increaseTake')"
  />
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

import Converter from "./PlatoCardBodyLinkedPropertyTableConverter";
import DataTable from "@/components/package/Table/DataTable";
import { SORT_TYPES } from "@/components/utils/sorting/SortTypes";

export default {
  components: {
    DataTable
  },

  props: {
    linkedProperty: {
      type: Object,
      required: true
    },
    field: {
      type: Object,
      required: true
    },
    showPagination: {
      type: Boolean,
      default: false
    },
    canEditElement: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canAdd: {
      type: Boolean,
      default: false
    },
    canClick: {
      type: Boolean,
      default: false
    },
    selectedObjectId: {
      type: String,
      default: ""
    },
    loaded: {
      type: Boolean,
      default: false
    },
    showMoreInfo: {
      type: Boolean,
      default: false
    },
    metadata: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapGetters({
      linkedProps: "cardTable/linkedProps",
      sorting: "cardTable/sorting",
      grouping: "cardTable/grouping",
      classes: "classes",
      currentManifest: "MANIFEST"
    }),

    tableColumns() {
      return Converter.convertColumns(this.linkedProperty, this.sorting);
    },

    tableProperties() {
      return Converter.convertElements(this.linkedProps, this.classes, this.selectedObjectId, this.metadata.linkedProperties, this.linkedProperty);
    },

    count() {
      return this.field?.count;
    },

    openObjectActions() {
      return _.map(this.field?.editCardAction,
        action => ({ classId: action.classId, action: _.find(this.currentManifest.actions, { actionId: action.actionId }) }));
    },

    createObjectActions() {
      return _.map(this.field?.createCardAction,
        actionId => _.find(this.currentManifest.actions, { actionId }));
    }
  },

  mounted() {
    if (!this.loaded) {
      if (this.field.group && _.isObject(this.field.group) && this.field.group.propertyAlias) {
        this.group({
          property: this.field.group.propertyAlias,
          ownProperty: this.field.group.ownPropertyAlias,
          sort: SORT_TYPES.Asc
        });
      }

      if (this.field.sort) {
        this.addSorting({
          alias: this.tableColumns[0].ownProperty || this.tableColumns[0].property,
          sortType: SORT_TYPES.Asc
        });
      }
    }

    this.$emit("loaded");
  },

  methods: {
    ...mapActions({
      addSorting: "cardTable/addSorting",
      changeSortingType: "cardTable/changeSortingType",
      removeSorting: "cardTable/removeSorting",
      unshiftSorting: "cardTable/unshiftSorting"
    }),

    group(col) {
      let column = _.cloneDeep(col);

      if (column.sort) {
        this.removeSorting(this.grouping.ownPropertyAlias || this.grouping.propertyAlias);

        if (column.property !== "prop_class_name") {
          switch (column.sort) {
          case SORT_TYPES.Asc:
          case SORT_TYPES.Desc:
            column.sort = SORT_TYPES.Asc;
            this.unshiftSorting({
              alias: column.ownProperty || column.property,
              sortType: column.sort
            });
            this.$store.dispatch("cardTable/setGrouping", { propertyAlias: column.property, ownPropertyAlias: column.ownProperty });
            break;
          case SORT_TYPES.Unset:
            this.$store.dispatch("cardTable/setGrouping", { propertyAlias: "", ownPropertyAlias: "" });
            break;
          }
        }
        else {
          if (this.grouping.propertyAlias == column.property) {
            this.$store.dispatch("cardTable/setGrouping", { propertyAlias: "", ownPropertyAlias: "" });
          }
          else {
            this.$store.dispatch("cardTable/setGrouping", { propertyAlias: column.property, ownPropertyAlias: column.ownProperty });
          }
        }
      }
    },

    columnClick(col) {
      let column = _.cloneDeep(col);

      if (column.sort) {
        switch (column.sort) {
        case SORT_TYPES.Unset:
          column.sort = SORT_TYPES.Asc;
          this.addSorting({
            alias: column.ownProperty || column.property,
            sortType: column.sort
          });
          break;
        case SORT_TYPES.Asc:
          column.sort = SORT_TYPES.Desc;
          this.changeSortingType({
            alias: column.ownProperty || column.property,
            sortType: column.sort
          });
          break;
        case SORT_TYPES.Desc:
          this.removeSorting(column.ownProperty || column.property);
          if (column.property == this.grouping.propertyAlias && column.ownProperty == this.grouping.ownPropertyAlias) {
            this.$store.dispatch("cardTable/setGrouping", { propertyAlias: "", ownPropertyAlias: "" });
          }
          break;
        }
      }
    }
  }
};
</script>

<style>

</style>