<template>
  <div
    class="page-header"
    :class="{'sticky' : isSticky}"
  >
    <div
      v-if="hasBreadcrumb"
      class="page-header-breadcrumb-wrapper"
    >
      <div class="page-header-breadcrumb-container">
        <slot name="breadcrumb">
&nbsp;
        </slot>
      </div>
    </div>

    <div class="page-header-title-wrapper">
      <div class="page-header-title-container">
        <h1>
          <slot name="title" />
        </h1>
        <h2 v-if="hasSubtitle">
          <slot name="subtitle" />
        </h2>
      </div>

      <div
        v-if="hasActions"
        class="page-header-actions-wrapper"
      >
        <slot name="actions" />
      </div>
    </div>

    <div
      v-if="hasBottom"
      class="page-header-bottom-wrapper"
    >
      <slot name="bottom">
&nbsp;
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    isSticky: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasActions() {
      return !!this.$slots["actions"];
    },
    hasBottom() {
      return !!this.$slots["bottom"];
    },
    hasBreadcrumb() {
      return !!this.$slots["breadcrumb"];
    },
    hasSubtitle() {
      return !!this.$slots["subtitle"];
    }
  }
};
</script>

<style lang="scss">
.page-header {
  margin-top: 24px;
  margin-bottom: 16px;

  &.sticky {
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
    padding-top: 10px;
    margin-top: 14px;
  }

  /* TITLE */
  &-title-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &-title-container {
    width: 100%;
    flex-shrink: 1;
    min-width: 0;

    h1 {
      font-weight: 500;
      font-size: 22px;
      margin-top: 0;
    }

    h2 {
      font-size: 12px;
      margin-bottom: 5px;
      color: #6b778c;
    }
  }

  /* ACTIONS */
  &-actions-wrapper {
    white-space: nowrap;
    padding-left: 32px;
    min-width: 0;
    flex-shrink: 0;

    & > div {
      & > * {
        margin-left: 6px;
      }
    }
  }

  &-breadcrumb-container {
    display: flex;
    flex-wrap: wrap;
  }

  &-bottom-wrapper {
    display: flex;
    justify-content: flex-start;
    margin-top: 16px;

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;

      & > * {
        margin-right: 6px;
      }
    }
  }
}
</style>
