export default class LocalStorageController {
  setData(storageName, source, element) {
    const parsed = JSON.stringify(element);
    localStorage.setItem(
      source + storageName,
      parsed
    );
  }

  removeSearchKeywords(addId) {
    localStorage.removeItem(`${addId}_searchKeywords`);
  }

  getData(storageName, source) {
    let item = localStorage.getItem(source + storageName);
    if (item) {
      try {
        return JSON.parse(item);
      }
      catch (e) {
        localStorage.removeItem(
          source + storageName
        );
      }
    }
  }
}