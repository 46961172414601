<template>
  <div
    :hideSearch="hideSearch"
    :style="colorStyle"
    class="plato-card__header"
  >
    <div class="plato-card__header_line">
      <PlatoButton
        v-if="startPageId && openActionType !== 2"
        type="link"
        icon="arrow-left"
        class="plato-card__header_line_back-btn"
        @click="$store.dispatch('goPrevPage')"
      />
      <PlatoButton
        v-else-if="openActionType == 2"
        type="link"
        icon="arrow-left"
        class="plato-card__header_line_back-btn"
        @click="() => context.cancelCallback()"
      />
      <div class="plato-card__header_block">
        <div v-if="parentExploreInstance">
          <div
            :title="parentExploreInstanceName"
            :class="parentOpenCardAction ? 'plato-card__header_line_label_parent' : 'plato-card__header_line_label'"
            @click="onMoreParentInfoClick"
          >
            {{ parentExploreInstanceName }}
          </div>
          <div class="plato-card__header_line_class">
            {{ parentExploreInstance.class.name }}
          </div>
        </div>

        <Icon
          v-if="parentExploreInstance"
          icon="angle-right"
        />

        <Icon
          v-if="currentObject && _.get(currentObject, 'style.icon')"
          class="plato-card__header_icon"
          :icon="_.get(currentObject, 'style.icon')"
        />

        <div>
          <div
            :title="cardName"
            class="plato-card__header_line_label"
          >
            {{ cardName }}
          </div>
          <div
            v-if="metadata && metadata.class"
            class="plato-card__header_line_class"
          >
            {{ metadata.class.name }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="plato-card__header_line"
      :hideSearch="hideSearch"
    >
      <PlatoMarkSearch
        v-if="!hideSearch"
        placeholder="Поиск свойств и связей"
        search-selector="div.plato-card"
        @input="onSearch"
      />
      <PlatoButtonGroup class="plato-card__header_button">
        <PlatoButton
          v-if="!readOnly"
          :loading="loading"
          :disabled="isChangedFieldsEmpty && !isNew"
          type="primary"
          @click="save"
        >
          Сохранить
        </PlatoButton>
        <PlatoButton
          v-if="isUpdateButtonEnabled"
          :loading="loading"
          icon="arrows-rotate"
          title="Обновить объект"
          @click="updateInstanceProperties"
        />
        <PlatoButton
          v-if="hasGeometryField"
          icon="map-marker-alt"
          :disabled="!currentObject"
          @click="flyTo"
        />

        <PlatoContextMenu :disabled="!currentObject">
          <PlatoButton
            v-for="(createReportAction, index) in createReportActions"
            :key="index"
            class="plato-context-menu__btn"
            icon="file-chart-line"
            @click="triggerAction(createReportAction)"
          >
            {{ createReportAction.name }}
          </PlatoButton>
          <PlatoButton
            v-if="!isNew && !readOnly && !isChangedFieldsEmpty"
            icon="ban"
            class="plato-context-menu__btn"
            @click="cancel"
          >
            Отменить редактирование
          </PlatoButton>
          <PlatoButton
            :icon="showEmpty ? 'eye-slash' : 'eye'"
            class="plato-context-menu__btn"
            @click="onAction('empty')"
          >
            {{ showEmpty ? 'Скрыть пустые' : 'Показать пустые' }}
          </PlatoButton>
          <PlatoButton
            v-if="!isNew && writeAccess"
            icon="trash"
            type="danger-action"
            class="plato-context-menu__btn"
            @click="confirmDeleteInstance"
          >
            Удалить
          </PlatoButton>
        </PlatoContextMenu>
      </PlatoButtonGroup>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

import { ACTION_TYPES } from "@/components/utils/actions/ActionTypes";
import { annotationProperties, getAnnotationPropertyValue } from "@/components/utils/annotationProperty/AnnotationProperty.js";
import { colorSchema } from "@/components/ObjectColorSchema.js";
import { DATASOURCE_TYPES } from "@/components/utils/dataSource/types.js";
import { isMobile } from "@/components/utils/mobile/Mobile.js";

export default {
  props: {
    showEmpty: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    pageItemSettings: {
      type: Array,
      required: true
    },
    dataSourceId: {
      type: String,
      required: true
    },
    context: {
      type: Object,
      required: true
    },
    currentObject: {
      type: Object,
      default: null
    },
    writeAccess: {
      type: Boolean,
      default: false
    },
    hierarchicalProperty: {
      type: Object,
      default: () => { }
    },
    name: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      isMobile: isMobile(),
      dataSourceTypes: DATASOURCE_TYPES
    };
  },

  computed: {
    ...mapGetters({
      loading: "card/dataLoading",
      currentManifest: "MANIFEST",
      lastNewObjectInstanceId: "card/lastNewObjectInstanceId",
      linkedPropertyAlias: "card/linkedPropertyAlias",
      linkedObjectId: "card/linkedObjectId",
      startPageId: "card/startPageId",
      linkedDataSourceId: "card/linkedDataSourceId",
      parentExploreInstance: "card/parentExploreInstance",
      changedFields: "card/changedFields"
    }),

    colorStyle() {
      let color = colorSchema(null, _.get(this.currentObject, "style.outlineColor"));

      return {
        "--color": color.outline.main
      };
    },

    isUpdateButtonEnabled() {
      return !this.isNew && this.metadata.dataSourceType != this.dataSourceTypes.internal;
    },

    metadata() {
      return this.$store.getters["card/metadata"](this.dataSourceId);
    },

    isChangedFieldsEmpty() {
      return _.isEmpty(this.changedFields(this.dataSourceId));
    },

    parentExploreInstanceName() {
      return _.find(this.parentExploreInstance.dataProperties, { id: "95b1ff3e-497c-462f-b260-c1b7166021b5" })?.value;
    },

    parentOpenCardAction() {
      let editCardAction = null;

      if (this.manifestFieldParams && this.hierarchicalProperty) {
        this.manifestFieldParams.forEach(params => {
          params.groups.forEach(group => {
            group.lines.forEach(line => {
              let field = _.find(_.values(line.fields[0]), { propertyAlias: this.hierarchicalProperty.alias });
              if (field && field.editCardAction) {
                editCardAction = field.editCardAction[0]?.actionId;
              }
            });
          });
        });
      }

      return editCardAction;
    },

    manifestFieldParams() {
      return _.chain(this.pageItemSettings)
        .find(["pageItemSettingId", "Fields"])
        .get("value")
        .value();
    },

    hideSearch() {
      let showSearchSetting = _.chain(this.pageItemSettings)
        .find(["pageItemSettingId", "ShowSearch"])
        .get("value")
        .get("value")
        .value();

      return !showSearchSetting || this.isMobile;
    },

    ownProperties() {
      return _.reduce(
        this.metadata.ownProperties,
        (result, prop) => {
          let propValue = _.find(
            this.currentObject.ownProperties,
            valProp => valProp.alias == prop.alias
          );
          result.push({
            header: prop,
            value: _.get(propValue, "value") || null,
            isEmpty: _.isEmpty(prop.value),
            type: "own"
          });
          return result;
        },
        []
      );
    },

    createReportActions() {
      return _.filter(this.currentManifest.actions, { actionContextData: this.dataSourceId, type: ACTION_TYPES.CreateReport });
    },

    cardName() {
      if (!this.currentObject) {
        return "";
      }

      let setting = _.find(this.pageItemSettings, ["pageItemSettingId", "Name"])?.value;

      //TODO: Сменить у всех приложений настройку
      if (setting && setting.value && !setting.ownPropertyAlias) {
        return _.chain(this.currentObject.ownProperties)
          .find({ alias: setting.value })
          .get("value", this.name)
          .value() || this.name;
      }
      else if (setting) {
        if (setting.propertyAlias) {
          if (setting.ownPropertyAlias) {
            let linkedProperty = _.find(this.currentObject.linkedProperties, { alias: setting.propertyAlias });
            return _.chain(linkedProperty?.values)
              .find({ alias: setting.ownPropertyAlias })
              .get("value", this.name)
              .value() || this.name;
          }

          return _.chain(this.currentObject.ownProperties)
            .find({ alias: setting.propertyAlias })
            .get("value", this.name)
            .value() || this.name;
        }
      }

      return this.name;
    },

    hasGeometryField() {
      let hasInOwnProperty = _.some(this.metadata.ownProperties, {
        tableType: "Geometry"
      });

      let hasInLenkedProperty = _.some(this.metadata.linkedProperties, property => _.some(property.properties, {
        tableType: "Geometry"
      }));

      return hasInOwnProperty || hasInLenkedProperty;
    },

    openActionType() {
      return this.context.currentAction?.type;
    }
  },

  methods: {
    onMoreParentInfoClick() {
      if (this.parentOpenCardAction) {
        this.$eventBus.$emit("component:action", {
          actionId: this.parentOpenCardAction,
          id: this.parentExploreInstance.id
        });
      }
    },

    flyTo() {
      this.$store.dispatch("flyToObject/changeFlytoState", {
        dataSourceId: this.dataSourceId,
        objectId: this.currentObject.id
      });
    },

    cancel() {
      this.$eventBus.$emit("component:cancelEditing");
    },

    async save() {
      if (this.isNew) {
        await this.$store.dispatch("card/saveNew", this.dataSourceId);

        if (this.openActionType !== 2) {
          if (this.linkedPropertyAlias && this.linkedObjectId) {
            let linkedObjectPromisses = await this.$store.dispatch("card/getFullObject", { dataSourceId: this.linkedDataSourceId, instanceId: this.linkedObjectId });
            let linkedObject = linkedObjectPromisses.items[0];

            let deleted = [];

            const cardinality = getAnnotationPropertyValue(this.metadata,
              this.linkedPropertyAlias,
              annotationProperties.PLATO_CARDINALITY);

            if (cardinality > 0 && linkedObject.values.length >= cardinality) {
              let deleteCount = (linkedObject.values.length - cardinality) + 1;
              deleted = _.chain(linkedObject.values).slice(0, deleteCount).map(x => x[0].instanceId).value();
            }

            let field = {
              alias: this.linkedPropertyAlias,
              added: [this.lastNewObjectInstanceId],
              removed: deleted,
              instanceId: this.linkedObjectId
            };

            await this.$store.dispatch("currentObject/saveCurrentObject", { fields: [field], dataSourceId: this.linkedDataSourceId });
            this.$store.dispatch("changedObject/setChangedObjectDataSourceId", this.linkedDataSourceId);
          }

          this.$store.dispatch("goPrevPage");
        }
        else if (this.lastNewObjectInstanceId) {
          if (!this.linkedObjectId) {
            await this.context.saveCallback(this.lastNewObjectInstanceId, this.dataSourceId);
          }
        }
        this.notification.success({ title: "Успешное сохранение нового объекта" });
      }
      else {
        await this.$store.dispatch("card/saveChangedFields", this.dataSourceId);
        this.notification.success({ title: "Успешное сохранение" });
      }
    },

    onAction(action) {
      if (action == "empty") {
        this.toggleShowEmpty();
      }
    },

    confirmDeleteInstance() {
      this.Modal.confirm({
        title: "Удаление элемента",
        message: "Вы уверены, что хотите удалить элемент?",
        type: "danger",
        onConfirm: this.deleteInstance
      });
    },

    async deleteInstance() {
      await this.$store.dispatch("card/deleteInstance", { dataSourceId: this.dataSourceId, id: this.currentObject.id });
      this.notification.success({ title: "Объект удалён" });

      this.$store.dispatch("changedObject/setChangedObjectDataSourceId", this.dataSourceId);

      if (!this.isNew) {
        this.$store.dispatch("currentObject/removeCurrentObject", this.dataSourceId);
      }

      if (this.openActionType == 2) {
        this.context.cancelCallback();
      }
      else {
        this.$store.dispatch("goPrevPage");
      }
    },

    triggerAction(action) {
      this.$eventBus.$emit("component:action", { actionId: action.actionId, id: this.currentObject.id });
    },

    toggleShowEmpty() {
      this.$emit("changeShowEmpty", !this.showEmpty);
      this.$forceUpdate();
    },

    getInstance(prop) {
      if (prop.type == "linked") {
        return _.get(prop.value, [0, "instanceId"]);
      }
      else {
        return null;
      }
    },

    onSearch() {
      this.$scrollTo("mark", 0, { container: ".plato-card__body" });
    },

    async updateInstanceProperties() {
      try {
        let response = await this.$store.dispatch("card/updateExternalInstance", { dataSourceId: this.dataSourceId, instanceId: this.currentObject.id });
        if (response.items.length > 0) {
          let externalInstance = response.items[0];
          externalInstance.ownProperties.forEach(property => {
            if (!_.isEmpty(property.value)) {
              this.$store.dispatch("card/addChangedFields", {
                type: "own",
                alias: property.alias,
                value: property.value,
                dataSourceId: this.dataSourceId,
                instanceId: this.currentObject.id
              });
            }
          });
          let updatedInstance = _.cloneDeep(this.currentObject);
          updatedInstance.ownProperties = externalInstance.ownProperties;
          this.$store.dispatch("card/setEditingCurrentObject", updatedInstance);
          this.notification.success({
            title: "Свойства объекта обновлены"
          });
          return;
        }
        this.notification.danger({
          title: "Объект не был найден во внешнем источнике данных"
        });
      }
      catch (error) {
        console.error(error);
        this.notification.danger({ title: "Ошибка обновления объекта" });
      }
    }
  }
};
</script>

<style lang="scss">
@import "../styles/colors.scss";
@import "../styles/variables.scss";

.plato-card__header {
  padding: 18px 0 12px;
  margin: 0 15px;
  border-bottom: 2px solid var(--color);
  display: flex;
  flex-direction: column;

  &[hideSearch] {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &_icon {
    margin-right: -10px;
    color: var(--color);
    font-size: 28px;
  }

  &_block {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &_line {
    display: flex;
    align-items: center;
    max-width: 85%;

    .plato-card &_back-btn {
      color: #0646a3;
      padding-left: 0;

      .fa-arrow-left {
        margin-left: 0;
        font-size: 16px;
      }
    }

    &_class {
      font-size: 12px;
      color: #6b778c;
    }

    &_label {
      padding-bottom: 2px;
      font-size: 20px;
      color: #172b4d;
      min-width: 150px;

      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      &_parent {
        padding-bottom: 2px;
        font-size: 20px;
        color: #0052cc;
        text-decoration: underline;
        text-decoration-style: dashed;
        cursor: pointer;
        width: fit-content;

        &:hover {
          color: #347eee;
        }
      }
    }

    &_actions {
      align-self: flex-end;
      display: flex;
      margin-left: 10px;

      .plato-btn {
        margin-left: 8px;
      }
    }

    &~& {
      margin-top: 10px;

      &[hideSearch=true] {
        margin-top: 0px;
      }

      justify-content: space-between;
    }

    .plato-input {
      flex: 0 1 288px;
    }

    .plato-context-menu {

      // margin-left: 10px;
      .plato-context-menu__btn {
        width: 100%;
        margin: 0;
      }
    }
  }
}
</style>