import _ from "lodash";

import actions from "./navigator/actions";
import AnnotationPropertiesModule from "./navigator/AnnotationPropertiesModule";

const getDefaultState = () => {
  return {
    dataLoading: false,
    dataLoadingError: null,
    currentList: null,
    currentItem: null,
    filter: [],
    graphData: {
      nodes: [],
      links: []
    },
    currentClassInstances: {
      totalCount: 0,
      items: []
    },
    currentClassSummary: null,
    currentDataPropertySummary: null,
    currentObjectPropertySummary: null,
    currentDomainSummary: null,
    currentInstance: null,
    currentInstanceGraphData: [],
    currentInstanceGraphLinks: [],
    historyItems: [{
      id: 0,
      type: "main",
      name: "Главная"
    }],
    graphMode: "",
    instanceGraphRootNodeId: null,
    dataTypes: [],
    annotationProperties: [],
    dataProperties: [],
    objectProperties: [],
    domains: [],
    classesList: [],
    objectPropertiesList: [],
    dataPropertiesList: [],
    editable: true
  };
};

export default {
  namespaced: true,
  modules: {
    "annotation_properties": AnnotationPropertiesModule
  },
  state: getDefaultState,
  getters: {
    DATA_LOADING_ERROR: state => {
      return state.dataLoadingError;
    },
    DATA_LOADING: state => {
      return state.dataLoading;
    },
    currentList: state => {
      return state.currentList;
    },
    currentItem: state => {
      return state.currentItem;
    },
    graphData: state => {
      return state.graphData;
    },
    currentClassInstances: state => {
      return state.currentClassInstances;
    },
    currentClassSummary: state => {
      return state.currentClassSummary;
    },
    allClasses: state => {
      return _.orderBy(state.graphData.nodes, x => x.name);
    },
    classesList: state => {
      return _.orderBy(state.classesList, x => x.name);
    },
    objectPropertiesList: state => {
      return _.orderBy(state.objectPropertiesList, x => x.name);
    },
    dataPropertiesList: state =>{
      return _.orderBy(state.dataPropertiesList, x => x.name);
    },
    filter: state => {
      return state.filter;
    },
    classInstancesTotalCount: state => {
      return state.currentClassInstances.totalCount;
    },
    classInstancesItems: state => {
      return state.currentClassInstances.items;
    },
    currentInstance: state => {
      return state.currentInstance;
    },
    currentInstanceGraphData: state => {
      return state.currentInstanceGraphData;
    },
    currentInstanceGraphLinks: state => {
      return state.currentInstanceGraphLinks;
    },
    historyItems: state => {
      return state.historyItems;
    },
    graphMode: state => {
      return state.graphMode;
    },
    searchAreaItems: state => {
      if (state.graphMode === "instance") {
        return state.currentInstanceGraphData;
      }
      return state.graphData.nodes;
    },
    instanceGraphRootNodeId: state => {
      return state.instanceGraphRootNodeId;
    },
    dataTypes: state => {
      return state.dataTypes;
    },
    annotationProperties: state => {
      return state.annotationProperties;
    },
    dataProperties: state => {
      return state.dataProperties;
    },
    objectProperties: state => {
      return state.objectProperties;
    },
    domains: state => {
      return state.domains;
    },
    currentSummary: state => {
      switch (state.currentItem.type) {
      case "dataProperty":
        return state.currentDataPropertySummary;
      case "objectProperty":
        return state.currentObjectPropertySummary;
      case "class":
        return state.currentClassSummary;
      case "instance":
        return state.currentInstance;
      case "domain":
        return state.currentDomainSummary;
      }
    },
    editable: state => {
      return state.editable;
    }
  },
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    SET_DATA_LOADING_ERROR: (state, value) => {
      state.dataLoadingError = value;
    },
    SET_DATA_LOADING: (state, value) => {
      state.dataLoading = value;
    },
    setEditable(state, value) {
      state.editable = value;
    },
    setCurrentList(state, item) {
      state.currentList = item;
    },
    setCurrentItem(state, item) {
      state.currentItem = item;
    },
    setFilter(state, filter) {
      state.filter = filter;
    },
    setClassesList(state, list) {
      state.classesList = list;
    },
    setGraphData(state, data) {
      state.graphData = data;
    },
    setClassInstances(state, data) {
      state.currentClassInstances.totalCount = data.totalCount;
      // state.currentClassInstances.items = state.currentClassInstances.items.concat(data.items);
      state.currentClassInstances.items = data.items;
    },
    setCurrentClassSummary(state, data) {
      state.currentClassSummary = data;
    },
    setCurrentInstance(state, data) {
      state.currentInstance = data;
    },
    pushInstanceToInstanceGraphData(state, data) {
      state.currentInstanceGraphData.push(data);
    },
    pushInstanceLink(state, data) {
      state.currentInstanceGraphLinks.push(data);
    },
    clearInstanceGraphLinks(state) {
      state.currentInstanceGraphLinks = [];
    },
    clearInstanceGraphData(state) {
      state.currentInstanceGraphData = [];
    },
    resetClassInstancesList(state) {
      state.currentClassInstances.totalCount = 0;
      state.currentClassInstances.items = [];
    },
    pushHistoryItems(state, data) {
      let lastHistoryItem = state.historyItems[state.historyItems.length - 1];
      if (lastHistoryItem.id === data.id && lastHistoryItem.type === data.type) {
        return;
      }

      let types = ["classes", "domains", "dataProperties", "objectProperties", "instances"];
      if (_.isEqual(data.subtype, "first")) {
        state.historyItems = _.reject(state.historyItems, ["subtype", "secondary"]);

        if (!_.includes(types, lastHistoryItem.type)) {
          state.historyItems.pop();
        }
      }

      state.historyItems.push(data);
    },

    updateHistoryItem(state, data) {
      state.historyItems.pop();
      state.historyItems.push(data);
      state.currentItem = data;
      //state.historyItems[state.historyItems.length - 1] = data;
    },

    deleteLastItemFromHistory(state) {
      state.historyItems.pop();
    },

    setHistoryItems(state, data) {
      state.historyItems = data;
    },
    setGraphMode(state, data) {
      state.graphMode = data;
    },
    setInstanceGraphRootId(state, data) {
      state.instanceGraphRootNodeId = data;
    },
    setDataTypes(state, data) {
      state.dataTypes = data;
    },
    setAnnotationProperties(state, data) {
      state.annotationProperties = data;
    },
    addClass(state) {
      let newClass = {
        id: "",
        parents: [],
        children: [],
        classDomains: [],
        domainObjectProperties: [],
        rangeObjectProperties: [],
        annotationProperties: [],
        dataProperties: [{
          class: {
            id: "",
            name: "Новый класс"
          },
          items: []
        }]
      };
      state.currentClassSummary = newClass;
    },
    addInstance(state, instanceClass) {
      let newInstance = {
        id: "",
        class: instanceClass,
        name: "Новый экземпляр",
        annotationProperties: [],
        dataProperties: [],
        objectProperties: []
      };
      state.currentInstance = newInstance;
    },
    setCurrentDataProperty(state, dataProperty) {
      state.currentDataPropertySummary = dataProperty;
    },
    setDataProperties(state, data) {
      state.dataProperties = data;
    },
    setDataPropertiesList(state, data) {
      state.dataPropertiesList = data;
    },
    addDataProperty(state) {
      state.currentDataPropertySummary = {
        id: "",
        type: {
          id: "",
          type: "",
          table: ""
        },
        annotationProperties: [],
        classes: [],
        domains: []
      };
    },
    setObjectProperties(state, data) {
      state.objectProperties = data;
    },
    setObjectPropertiesList(state, data) {
      state.objectPropertiesList = data;
    },
    addObjectProperty(state) {
      state.currentObjectPropertySummary = {
        id: "",
        annotationProperties: [],
        inverseObjectProperties: [],
        inverseOfObjectProperties: [],
        domains: [],
        ranges: [],
        classDomain: []
      };
    },
    setCurrentObjectProperty(state, objectProperty) {
      state.currentObjectPropertySummary = objectProperty;
    },
    updateCurrentSummaryFields(state, { currentSummary, data }) {
      let keys = Object.keys(data);
      keys.forEach(key => {
        currentSummary[key] = data[key];
      });
    },

    setDomains(state, domains) {
      state.domains = domains;
    },

    setCurrentDomain(state, domain) {
      state.currentDomainSummary = domain;
    }
  },
  actions
};
