<template>
  <div class="message">
    <div class="message__inner">
      {{ errorText }}
    </div>
    <a
      href="/"
      class="message__link"
    >
      Вернуться на портал.
    </a>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    errorCode: {
      type: [String, Number],
      default: null
    }
  },

  data() {
    return {
      errorTexts: {
        403: "У вас нет доступа к этому ресурсу.",
        404: "Данной страницы не существует."
      }
    };
  },

  computed: {
    errorText() {
      return _.get(this.errorTexts, this.errorCode, "Что-то пошло не так.");
    }
  },

  destroyed() {
    this.$store.dispatch("setInnerErrorCode", null);
  }
};

</script>

<style lang="scss" scoped>
  .message {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    flex-direction: column;

    &__inner {
      font-size: 17px;
      font-weight: 500;
      color: #505f79;
      transition: color 0.1s linear;
    }

    &__link {
      padding-top: 10px
    }
  }
</style>