import _ from "lodash";
import actions from "./mapComponent/actions.js";
import { LAYER_TYPES } from "@/PlatoAPI/MapConstraints.js";
import { mapLayerColorSchema } from "@/components/MapLayerColorSchema.js";

function getLayerSettingsByLayerType(layerGroups, layerType) {
  let settings = [];
  _.each(layerGroups, group => {
    _.each(group.layers, layer => {
      if (layer.layerType === layerType) {
        settings.push(layer);
      }
    });
  });
  return settings;
}

function getLinkedPropertyOwnProperty(metadata, linkedPropertyAlias) {
  let linkedPropMetadata = _.find(metadata.linkedProperties, prop => prop.alias === linkedPropertyAlias);
  let linkedNamePropertyId = "95b1ff3e-497c-462f-b260-c1b7166021b5";
  let linkedNameProperty = _.find(linkedPropMetadata.properties, prop => prop.id === linkedNamePropertyId);
  if (linkedNameProperty) {
    return linkedNameProperty.alias;
  }
  else {
    return linkedPropMetadata.properties[0].alias;
  }
}
export default {
  namespaced: true,
  state: {
    mapParameters: null,
    baseLayer: null,
    layerGroups: [],
    layerGroupsForSwitcher: [],
    changedLayerVisibility: null,
    changedLayerGroupVisibility: null,
    changedLayerColor: null,
    baseLayerGroup: [],
    changedCurrentObjectGeometry: null,
    linkedObjects: null,
    changedLayerObjectCardsVisibility: null,
    changedDiagramsVisibility: null,
    changedClustersVisibility: null,
    changedSemanticConnectionsVisibility: null,
    mapNavigationObject: null,
    externalObjectsForPopup: [],
    searchData: [],
    geofileGeojson: null,
    drawSearch: null,
    searchLoadingState: false,
    dataSourceObjects: {},
    dataSourceMetadata: {},
    mapCurrentObject: null,
    mapLoadingState: false,
    searchObjectPopupCoordinates: null,
    geometrySearchGeojson: null,
    dataSourcesAliases: null,
    clickedObjectFromMapState: false,
    measureDrawMode: null
  },
  getters: {
    dataSourceObjects: state => dataSourceId => {
      return state.dataSourceObjects[dataSourceId];
    },
    dataSourceMetadata: state => dataSourceId => {
      return state.dataSourceMetadata[dataSourceId];
    },

    dataSourceAliases: state => dataSourceId => {
      if (state.dataSourcesAliases != null) {
        return state.dataSourcesAliases[dataSourceId];
      }
      return null;
    },

    allDataSourcesIds: (state, getters) => {
      return getters.dataSourceLayersSettings.map(layerSettings => {
        return layerSettings.parameters.dataSource.dataSourceId;
      });
    },

    isDataSourceExist: (state, getters) => dataSourceId => {
      return _.includes(getters.allDataSourcesIds, dataSourceId);
    },

    searchData: state => {
      return state.searchData;
    },

    isLayerParametersHidden: () => layerSettings => {
      if (Object.prototype.hasOwnProperty.call(layerSettings, "isLayerMapParameterHidden")) {
        return layerSettings.isLayerMapParameterHidden;
      }
      return false;
    },

    mapParameters: state => {
      return state.mapParameters;
    },

    baseLayer: state => {
      return state.baseLayer;
    },

    baseLayers: state => {
      return state.baseLayerGroup.layers;
    },

    layerGroups: state => {
      return state.layerGroups;
    },

    changedCurrentObjectGeometry: state => {
      return state.changedCurrentObjectGeometry;
    },

    layersGroupsForSwitcher: state => {
      return state.layerGroupsForSwitcher;
    },

    currentDataSourceLayerState: state => dataSourceId => {
      for (let layerGroup of state.layerGroupsForSwitcher) {
        for (let layer of layerGroup.layers) {
          if (Object.prototype.hasOwnProperty.call(layer, "dataSourceId") && layer.dataSourceId === dataSourceId) {
            return layer;
          }
        }
      }
    },

    dataSourceLayersSettings: state => {
      return getLayerSettingsByLayerType(state.layerGroups, LAYER_TYPES.DataSource);
    },

    rosreestrCadastrLayerSettings: state => {
      return getLayerSettingsByLayerType(state.layerGroups, LAYER_TYPES.RosreestrCadastre);
    },

    rosreestrZouitLayerSettings: state => {
      return getLayerSettingsByLayerType(state.layerGroups, LAYER_TYPES.RosreestrZouit);
    },

    rasterWMSLayersSettings: state => {
      return getLayerSettingsByLayerType(state.layerGroups, LAYER_TYPES.RasterWMS);
    },

    rasterXYZLayersSettings: state => {
      return getLayerSettingsByLayerType(state.layerGroups, LAYER_TYPES.RasterXYZ);
    },

    dataSourceLayerSettings: (state, getters) => dataSourceId => {
      return _.find(
        getters.dataSourceLayersSettings,
        dataSource => dataSource.parameters.dataSource.dataSourceId === dataSourceId
      );
    },

    dataSourceGeometryProperty: (state, getters) => dataSourceId => {
      return getters.dataSourceLayerSettings(dataSourceId).parameters.dataSource.geojsonProperty;
    },

    changedLayerVisibility: state => {
      return state.changedLayerVisibility;
    },

    changedLayerGroupVisibility: state => {
      return state.changedLayerGroupVisibility;
    },

    changedLayerColor: state => {
      return state.changedLayerColor;
    },

    changedLayerObjectCardsVisibility: state => {
      return state.changedLayerObjectCardsVisibility;
    },

    defaultLayerColor: (state, getters) => dataSourceId => {
      let layerSettings = getters.dataSourceLayerSettings(dataSourceId);
      let objectColorSchema = mapLayerColorSchema(layerSettings.parameters.dataSource.objectColor);
      return objectColorSchema.outline.main;
    },

    changedDiagramsVisibility: state => {
      return state.changedDiagramsVisibility;
    },

    changedClustersVisibility: state => {
      return state.changedClustersVisibility;
    },

    changedSemanticConnectionsVisibility: state => {
      return state.changedSemanticConnectionsVisibility;
    },

    linkedObjects: state => {
      return state.linkedObjects;
    },

    mapNavigationObject: state => {
      return state.mapNavigationObject;
    },

    externalObjectsForPopup: state => {
      return state.externalObjectsForPopup;
    },

    geofileGeojson: state => {
      return state.geofileGeojson;
    },

    drawSearch: state => {
      return state.drawSearch;
    },

    searchLoadingState: state => {
      return state.searchLoadingState;
    },

    diagramVisibleState: state => layerId => {
      for (let group of state.layerGroupsForSwitcher) {
        let layer = _.find(group.layers, groupLayer => groupLayer.id === layerId);
        if (layer && Object.prototype.hasOwnProperty.call(layer, "showDiagrams")) {
          return layer.showDiagrams;
        }
      }
      return null;
    },

    mapCurrentObject: state => {
      return state.mapCurrentObject;
    },

    mapRequestAliases: (state, getters) => dataSourceId => {
      /*
      each propertiesForDiagram //уже массив
      each semanticConnectionsSettings alias
      each conditionalStylingSettings conditionalParamerter
      each linkedPropertiesSettings alias
      */
      let requestAliases = [];
      let layerSettings = getters.dataSourceLayerSettings(dataSourceId);
      let dataSourceLayerSettings = layerSettings.parameters.dataSource;
      let metadata = getters.dataSourceMetadata(dataSourceId);
      if (!_.isEmpty(dataSourceLayerSettings.geojsonProperty)) {
        if (!_.isEmpty(dataSourceLayerSettings.geojsonOwnProperty)) {
          requestAliases.push(dataSourceLayerSettings.geojsonOwnProperty);
        }
        else {
          requestAliases.push(dataSourceLayerSettings.geojsonProperty);
        }
      }

      if (!_.isEmpty(dataSourceLayerSettings.nameProperty)) {
        if (!_.isEmpty(dataSourceLayerSettings.nameOwnProperty)) {
          requestAliases.push(dataSourceLayerSettings.nameOwnProperty);
        }
        else {
          requestAliases.push(dataSourceLayerSettings.nameProperty);
        }
      }

      if (Object.prototype.hasOwnProperty.call(dataSourceLayerSettings, "diagramProperties") && dataSourceLayerSettings.diagramProperties.length > 0) {
        _.each(dataSourceLayerSettings.diagramProperties, diagramProperty => {
          let ownProperty = _.find(metadata.ownProperties, prop => prop.alias === diagramProperty);
          if (ownProperty) {
            requestAliases.push(diagramProperty);
          }
          else {
            requestAliases.push(getLinkedPropertyOwnProperty(metadata, diagramProperty));
          }
        });
      }
      if (Object.prototype.hasOwnProperty.call(dataSourceLayerSettings, "semanticConnectionsSettings") && dataSourceLayerSettings.semanticConnectionsSettings.length > 0) {
        _.each(dataSourceLayerSettings.semanticConnectionsSettings, setting => {
          requestAliases.push(getLinkedPropertyOwnProperty(metadata, setting.alias));
        });
      }
      if (Object.prototype.hasOwnProperty.call(dataSourceLayerSettings, "conditionalStylingSettings") && dataSourceLayerSettings.conditionalStylingSettings.length > 0) {
        _.each(dataSourceLayerSettings.conditionalStylingSettings, setting => {
          if (setting.type === "ownProperty") {
            requestAliases.push(setting.conditionalParamerter);
          }
          else if (setting.type === "linkedProperty") {
            requestAliases.push(getLinkedPropertyOwnProperty(metadata, setting.conditionalParamerter));
          }
        });
      }
      if (Object.prototype.hasOwnProperty.call(dataSourceLayerSettings, "linkedPropertiesSettings") && dataSourceLayerSettings.linkedPropertiesSettings.length > 0) {
        _.each(dataSourceLayerSettings.linkedPropertiesSettings, setting => {
          requestAliases.push(getLinkedPropertyOwnProperty(metadata, setting.alias));
        });
      }

      return requestAliases;
    },

    mapLoadingState: state => {
      return state.mapLoadingState;
    },

    searchObjectPopupCoordinates: state => {
      return state.searchObjectPopupCoordinates;
    },

    geometrySearchGeojson: state => {
      return state.geometrySearchGeojson;
    },

    clickedObjectFromMapState: state => {
      return state.clickedObjectFromMapState;
    },

    measureDrawMode: state => {
      return state.measureDrawMode;
    }
  },
  mutations: {
    SET_DATA_LOADING_ERROR: (state, value) => {
      state.dataLoadingError = value;
    },

    SET_DATA_LOADING: (state, value) => {
      state.dataLoading = value;
    },

    setSearchData: (state, data) => {
      state.searchData.push(...data);
    },

    clearSearchData: state => {
      state.searchData = [];
    },

    removeSearchData: (state, dataSourceId) => {
      state.searchData = _.filter(state.searchData, data => data.dataSourceId != dataSourceId);
    },

    setMapParameters: (state, mapParameters) => {
      state.mapParameters = mapParameters;
    },

    setBaseLayer: (state, baseLayer) => {
      state.baseLayer = baseLayer;
    },

    setLayerGroups: (state, layerGroups) => {
      state.layerGroups = layerGroups;
    },

    setLayerVisibility: (state, changedLayerVisibility) => {
      state.changedLayerVisibility = changedLayerVisibility;
    },

    setLayerGroupVisibility: (state, changedLayerGroupVisibility) => {
      state.changedLayerGroupVisibility = changedLayerGroupVisibility;
    },

    setLayerObjectCardsVisibility: (state, changedLayerObjectCardsVisibility) => {
      state.changedLayerObjectCardsVisibility = changedLayerObjectCardsVisibility;
    },

    setDiagramsVisibility: (state, changedDiagramsVisibility) => {
      state.changedDiagramsVisibility = changedDiagramsVisibility;
    },

    setClustersVisibility: (state, changedClustersVisibility) => {
      state.changedClustersVisibility = changedClustersVisibility;
    },

    setLayerColor: (state, changedLayerColor) => {
      state.changedLayerColor = changedLayerColor;
    },

    setLayerSemanticConnectionsVisibility: (state, changedSemanticConnectionsVisibility) => {
      state.changedSemanticConnectionsVisibility = changedSemanticConnectionsVisibility;
    },

    setBaseLayerGroup: (state, baseLayerGroup) => {
      state.baseLayerGroup = baseLayerGroup;
    },

    setCurrentObjectGeometry: (state, changedGeometry) => {
      state.changedCurrentObjectGeometry = changedGeometry;
    },

    setLinkedObjects: (state, linkedObjects) => {
      state.linkedObjects = linkedObjects;
    },

    setLayerGroupsForSwitcher: (state, layerGroupsForSwitcher) => {
      state.layerGroupsForSwitcher = layerGroupsForSwitcher;
    },

    setMapNavigationObject: (state, navObject) => {
      state.mapNavigationObject = navObject;
    },

    addExternalObjectsForPopup: (state, externalObjects) => {
      state.externalObjectsForPopup = _.concat(state.externalObjectsForPopup, externalObjects);
    },

    clearExternalObjectsForPopup: state => {
      state.externalObjectsForPopup.splice(0);
    },

    setGeofileGeometry: (state, geometry) => {
      let geojson = JSON.parse(geometry);
      state.geofileGeojson = geojson;
    },

    enableDrawSearch: (state, searchSettings) => {
      state.drawSearch = searchSettings;
    },

    disableDrawSearch: state => {
      state.drawSearch = null;
    },

    setSearchLoadingState: (state, searchLoadingState) => {
      state.searchLoadingState = searchLoadingState;
    },

    setDataSourceObjects: (state, { dataSourceId, data }) => {
      state.dataSourceObjects[dataSourceId] = data;
    },

    setDataSourceMetadata: (state, { dataSourceId, metadata }) => {
      state.dataSourceMetadata[dataSourceId] = metadata;
    },

    setMapCurrentObject: (state, newMapCurrentObject) => {
      state.mapCurrentObject = newMapCurrentObject;
    },

    setMapLoadingState: (state, mapLoadingState) => {
      state.mapLoadingState = mapLoadingState;
    },

    setObjectPopupCoordinates: (state, coordinates) => {
      state.searchObjectPopupCoordinates = coordinates;
    },

    setGeometrySearchGeojson: (state, geojson) => {
      state.geometrySearchGeojson = geojson;
    },

    setDataSourcesAliases: (state, dataSourcesAliases) => {
      state.dataSourcesAliases = dataSourcesAliases;
    },

    setClickedObjectFromMapState: (state, clickedObjectFromMapState) => {
      state.clickedObjectFromMapState = clickedObjectFromMapState;
    },

    setMeasureDrawMode: (state, measureDrawMode) => {
      state.measureDrawMode = measureDrawMode;
    }
  },
  actions
};