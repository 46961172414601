import _ from "lodash";
import actions from "./actions";

const getDefaultState = () => {
  return {
    searchKeywords: [],
    dataLoadingError: null,
    dataLoading: null
  };
};

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    dataLoadingError: state => {
      return state.dataLoadingError;
    },

    dataLoading: state => {
      return state.dataLoading;
    },

    searchKeywords: state => dataSourceId => {
      return _.chain(state.searchKeywords)
        .find({ dataSourceId })
        .get("searchKeyword", "")
        .value();
    },

    searchKeywordsForUrl: state => {
      return state.searchKeywords;
    }
  },
  mutations: {
    setDataLoadingError: (state, value) => {
      state.dataLoadingError = value;
    },

    setDataLoading: (state, value) => {
      state.dataLoading = value;
    },

    resetState: state => {
      Object.assign(state, getDefaultState());
    },

    setSearchKeyword: (state, searchInfo) => {
      let searchKeywords = _.find(state.searchKeywords, { dataSourceId: searchInfo.dataSourceId });

      if (searchKeywords) {
        searchKeywords.searchKeyword = searchInfo.searchKeyword;
      }
      else {
        state.searchKeywords.push({ dataSourceId: searchInfo.dataSourceId, searchKeyword: searchInfo.searchKeyword });
      }
    },

    setSearchKeywords: (state, searchKeywords) => {
      state.searchKeywords = searchKeywords;
    },

    removeSearchKeyword: (state, dataSourceId) => {
      state.searchKeywords = _.reject(state.searchKeywords, { dataSourceId });
    }
  },
  actions
};