<template>
  <Transition
    v-if="visible"
    name="modal"
  >
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="title">
              <span class="modal-header-default">{{ title }}</span>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="message">
              <div v-html="message" />
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <div class="modal-footer-buttons">
                <PlatoButton
                  :type="type"
                  @click="close({ ok: true })"
                >
                  OK
                </PlatoButton>
                <PlatoButton
                  v-if="showCancel"
                  @click="close({ ok: false })"
                >
                  Отмена
                </PlatoButton>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: "PlatoModal",
  props: {
    type: {
      type: String,
      default: "primary"
    },
    title: {
      type: String,
      default: "Заголовок модального окна"
    },
    message: {
      type: String,
      default: "Текст модального окна"
    },
    cancel: {
      type: Boolean,
      default: true
    },
    showCancel: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      visible: true
    };
  },

  created() {
    const escapeHandler = e => {
      if (e.key === "Escape") {
        this.close({ ok: false });
      }

      if (e.key === "Enter") {
        this.close({ ok: true });
      }
    };

    document.addEventListener("keydown", escapeHandler);

    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", escapeHandler);
    });
  },

  mounted() {
    this.$el.querySelector(".modal-footer button:first-child").focus();
  },

  methods: {
    close(params) {
      this.visible = false;
      this.$emit("close", params);
    }
  }
};
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 300px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
    text-align: center;
    border-radius: 10px;
}

.modal-header-default {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
}

.modal-body {
    margin: 20px 0;
}

.modal-footer-buttons {
    display: flex;
    justify-content: space-around;
}

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>
