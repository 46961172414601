import _ from "lodash";

export default class NavigationObjectConverter {
  color = "#000000";
  icon = "compass";
  createNavigationObjectForCard(exploreInstance, objectName) {
    let navObject = {
      id: exploreInstance.id,
      classId: exploreInstance.class.id,
      name: objectName,
      className: exploreInstance.class.name,
      ownProperties: _.chain(exploreInstance.dataProperties).cloneDeep()
        .filter(prop => prop.tableType !== "Geometry")
        .filter(prop => prop.value != objectName).value(),
      objectStyle: {
        icon: this.icon,
        outlineColor: { main: this.color }
      }
    };

    let linkedProps = exploreInstance.objectProperties;

    let groupedProps = _.groupBy(linkedProps, prop => prop.id);
    let linkedProperties = [];
    _.each(groupedProps, prop => {
      let linkedProperty = {
        id: prop[0].id,
        name: prop[0].name,
        values: []
      };

      _.each(prop, propItem => {
        // Преобразование в navigationObject
        let object = {
          alias: linkedProperty.alias,
          instanceId: propItem.value.id,
          value: propItem.value.name
        };

        linkedProperty.values.push([object]);
      });
      linkedProperties.push(linkedProperty);
    });

    navObject.linkedProperties = linkedProperties;

    return navObject;
  }
}