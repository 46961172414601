import _ from "lodash";
import actions from "./actions";

const getDefaultState = () => {
  return {
    objects: [],
    metadata: [],
    dataLoadingError: null,
    dataLoading: null,
    skip: 0,
    take: 100000
  };
};

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    dataLoadingError: state => {
      return state.dataLoadingError;
    },

    dataLoading: state => {
      return state.dataLoading;
    },

    skip: state => {
      return state.skip;
    },

    take: state => {
      return state.take;
    },

    metadata: state => dataSourceId => {
      return _.chain(state.metadata)
        .find({ dataSourceId })
        .get("metadata", [])
        .value();
    },

    objects: state => dataSourceId => {
      return _.chain(state.objects)
        .find({ dataSourceId })
        .get("objects", [])
        .value();
    },

    totalObjectCount: state => dataSourceId => {
      return _.chain(state.objects)
        .find({ dataSourceId })
        .get("objects", [])
        .get("length", 0)
        .value();
    }
  },
  mutations: {
    setDataLoadingError: (state, value) => {
      state.dataLoadingError = value;
    },

    setDataLoading: (state, value) => {
      state.dataLoading = value;
    },

    resetState: state => {
      Object.assign(state, getDefaultState());
    },

    setMetadata: (state, { data, dataSourceId }) => {
      let metadata = _.find(state.metadata, { dataSourceId });

      if (metadata) {
        metadata.metadata = data;
      }
      else {
        state.metadata.push({ dataSourceId, metadata: data });
      }
    },

    setObjects: (state, { data, dataSourceId }) => {
      let objects = _.find(state.objects, { dataSourceId });

      if (objects) {
        objects.objects = data;
      }
      else {
        state.objects.push({ dataSourceId, objects: data });
      }
    },

    setTake: (state, take) => {
      state.take = take;
    },

    clearData: (state, dataSourceId) => {
      state.objects = _.reject(state.objects, { dataSourceId });
    }
  },
  actions
};