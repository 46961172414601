<template>
  <div class="plato-list__body_group">
    <div
      v-if="showHeader"
      class="plato-list__body_group_header"
    >
      <div
        class="plato-list__body_group_header_left"
        @click="toggleContent()"
      >
        <PlatoCheckbox
          :value="allGroupItemsChecked"
          @click.native.stop="allGroupItemsChecked=!allGroupItemsChecked"
          @change="allGroupItemsChecked=!allGroupItemsChecked"
        />

        <div class="plato-list__body_group_header_text">
          {{ name }}
        </div>

        <div class="plato-list__body_group_header_icon">
          <Icon
            v-if="showContent"
            icon="chevron-down"
          />
          <Icon
            v-else
            icon="chevron-right"
          />
        </div>

        <div class="plato-badge plato-badge--standard">
          {{ totalCount }}
        </div>
      </div>
      <div class="plato-list__body_group_header_right">
        <PlatoContextMenu
          v-if="!_.isEmpty(addObjectActions)"
          icon="plus"
        >
          <PlatoButton
            v-for="action in addObjectActions"
            :key="`menu-item-${action.actionId}`"
            class="plato-context-menu__btn"
            @click="triggerAction(action)"
          >
            {{ action.name }}
          </PlatoButton>
        </PlatoContextMenu>
        <PlatoFilterLine
          class="plato-list__body_group_header_right_filter"
          :data-source-id="dataSourceId"
          :show-checkbox="false"
          :show-search="false"
          :show-grouping="true"
          :show-data-view-selector="false"
          :metadata="metadata"
          :all-items-checked="allGroupItemsChecked"
          @check-all-items="checkAllItems"
        />
      </div>
    </div>
    <div
      v-show="showContent"
      class="plato-list__body_group_body"
    >
      <div
        v-if="showHeader"
        class="plato-list__body_group_body_selector"
      >
        <DataViewSelector
          v-if="dataView.length > 0"
          :data-source-id="dataSourceId"
        />
      </div>

      <div
        v-for="(group, groupedIndex) in groupedData"
        :key="groupedIndex"
        class="group"
      >
        <div
          v-if="grouping && group.name !== 'element'"
          class="group-header"
        >
          <!--Выбрать все из группы-->
          <!-- <PlatoCheckbox
            class="group-header_checkbox"
            max-width-400
            :value="_.every(group.values, element => element.isChecked === true)"
            @change="value => changeSelections(group.values, value)"
          /> -->

          <div class="group-header_collapser">
            <PlatoButton
              type="subtle"
              :icon="group.collapsed ? 'chevron-right' : 'chevron-down'"
              style="padding: 0 7px"
              @click="changeCollapse(group.name)"
            />
          </div>

          <div>
            {{ group.name }}
          </div>
          <div>
            ({{ group.values.length }})
          </div>
        </div>

        <div v-if="!group.collapsed">
          <PlatoListCard
            v-for="(item, index) in group.values"
            :key="`card_${item.id}`"
            :index="getListIndex(index, groupedIndex)"
            :data-source-id="dataSourceId"
            :left-column-width="item.leftColumnWidth"
            :icon="item.style.icon"
            :outline-color="item.style.outlineColor"
            :id-field="item.idField"
            :status-field="item.statusField"
            :num-field="item.numField"
            :num-icon-class="item.numIconClass"
            :object-fields="item.objectFields"
            :other-object-fields="item.otherObjectFields"
            :object="item.object"
            :geojeson="item.geojeson"
            :is-selected="item.isSelected"
            :is-checked="item.isChecked"
            :action-open-object="getOpenObjectAction(item.classId)"
            :action-create-report="getCreateReportAction(item.classId)"
            :aliases="aliases"
            :show-more-info="showMoreInfo"
            @select-element="selectElement(item.id)"
            @click="selectElement(item.id)"
            @check-click="checkElement(item)"
          />
        </div>
      </div>


      <div
        v-if="dataLoading || take < totalCount"
        class="plato-list__body_group_paginator"
      >
        <PlatoLoader
          v-if="dataLoading"
          mini
        />
        <PlatoButton
          v-else
          class="plato-list__body_group_paginator_button"
          type="subtle"
          @click="increaseTake(dataSourceId)"
        >
          Показать ещё
        </PlatoButton>
      </div>
    </div>
  </div>
</template>

<script>
const GROUP_FIELD_TYPE = {
  objectFields: "objectFields",
  otherObjectFields: "otherObjectFields",
  statusField: "statusField",
  numField: "numField"
};

import _ from "lodash";
import CadastreNumbersConverter from "@/PlatoAPI/CadastreNumbersConverter.js";
import FileObjectConverter from "@/PlatoAPI/FileObjectConverter.js";

import PlatoListCard from "./PlatoListCard";

import { mapActions, mapGetters } from "vuex";

import { scopes } from "@/store/Access";

export default {
  name: "PlatoListGroup",

  components: {
    PlatoListCard
  },

  props: {
    params: {
      type: Object,
      required: true
    },
    allItemsChecked: {
      type: Boolean,
      required: true
    },
    showHeader: {
      type: Boolean,
      required: true
    },
    dataSourceId: {
      type: String,
      required: true
    },
    aliases: {
      type: Array,
      required: true
    },
    showMoreInfo: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isChecked: false,
      showContent: true,
      collapsedGroups: []
    };
  },

  computed: {
    ...mapGetters({
      appManifest: "MANIFEST",
      dataLoading: "list/dataLoading",
      currentObject: "currentObject/currentObject",
      changedObjectDataSourceId: "changedObject/changedObjectDataSourceId",
      skip: "list/skip"
    }),

    take() {
      return this.$store.getters["list/take"](this.dataSourceId);
    },

    selectedObjects() {
      return this.$store.getters["selectedObjects/selectedObjects"](this.dataSourceId);
    },

    metadata() {
      return this.$store.getters["list/metadata"](this.dataSourceId);
    },

    dataView() {
      return this.$store.getters.dataSourceDataView(this.dataSourceId);
    },

    /**
     * Подготовка данных для рендеринга карточек списка
     */
    dataPrepared() {
      return _.map(this.dsData, obj => {
        return {
          id: obj.id,
          classId: obj.classId,
          leftColumnWidth: this.params.leftColumnWidth,
          style: obj.style,
          numIconClass: this.params.numIconClass,
          idField: _.get(this.prepareField(obj, this.params["idField"]), 0),
          // statusField: this.findParamValue(obj, "statusField"),
          statusField: _.get(
            this.prepareField(obj, this.params["statusField"]),
            0
          ),
          numField: _.get(this.prepareField(obj, this.params["numField"]), 0),

          objectFields: this.objectFields(obj),
          otherObjectFields: this.otherObjectFields(obj),

          object: this.createData(obj),
          isSelected: !!(this.currentObject && this.currentObject.id == obj.id),
          isChecked: _.includes(this.selectedObjects, obj.id)
        };
      });
    },

    groupOptions() {
      let options = [];

      if (this.params.fields.length > 0) {
        let op = this.fieldToOption(this.params.fields, GROUP_FIELD_TYPE.objectFields);
        options = _.concat(options, op);
      }

      if (this.params.otherFields.length > 0) {
        let op = this.fieldToOption(this.params.otherFields, GROUP_FIELD_TYPE.otherObjectFields);
        options = _.concat(options, op);
      }

      if (!_.isEmpty(this.params.numField.propertyAlias)) {
        let op = this.fieldToOption([this.params.numField], GROUP_FIELD_TYPE.numField);
        options = _.concat(options, op);
      }

      if (!_.isEmpty(this.params.statusField.propertyAlias)) {
        let op = this.fieldToOption([this.params.statusField], GROUP_FIELD_TYPE.statusField);
        options = _.concat(options, op);
      }

      return options;
    },

    grouping() {
      return this.$store.getters["grouping/grouping"](this.dataSourceId);
    },

    groupedData() {
      if (_.isEmpty(this.dataPrepared)) {
        return [];
      }
      if (_.isEmpty(this.grouping) || _.isEmpty(this.grouping.propertyAlias) || this.dataPrepared.length === 1) {
        return [{ name: "element", values: this.dataPrepared }];
      }
      let group = {};
      let obj = {};
      let index = -1;

      let field = _.get(_.find(this.groupOptions, { ownPropertyAlias: this.grouping.ownPropertyAlias, propertyAlias: this.grouping.propertyAlias }), "field");

      switch (field) {
      case GROUP_FIELD_TYPE.objectFields:
        obj = _.find(this.dataPrepared, data => {
          return _.some(data.objectFields, field => field[0]?.propAlias == this.grouping.propertyAlias);
        });

        if (_.isEmpty(obj)) {
          group = { name: undefined, values: this.dataPrepared };
          break;
        }

        index = _.findIndex(obj.objectFields, el => el[0]?.propAlias == this.grouping.propertyAlias);

        group = _.groupBy(this.dataPrepared, element => element.objectFields?.[index]?.[0]?.value);

        break;
      case GROUP_FIELD_TYPE.otherObjectFields:
        obj = _.find(this.dataPrepared, el => el.otherObjectFields.length > 0);

        if (_.isEmpty(obj)) {
          group = { name: undefined, values: this.dataPrepared };
          break;
        }

        index = _.findIndex(obj.otherObjectFields, el => el?.propAlias == this.grouping.propertyAlias);

        group = _.groupBy(this.dataPrepared, element => element.otherObjectFields[index]?.value);

        break;
      case GROUP_FIELD_TYPE.statusField:
        group = _.groupBy(this.dataPrepared, element => element.statusField?.value);

        break;
      case GROUP_FIELD_TYPE.numField:
        group = _.groupBy(this.dataPrepared, element => element.numField?.value);

        break;
      default: group = { name: undefined, values: this.dataPrepared };
        break;
      }

      let arrOfGroups = [];

      for (const [key, value] of Object.entries(group)) {
        let newKey = key == "undefined" ? "[Нет данных]" : key;

        let group = { name: newKey, values: value };

        if (_.some(this.collapsedGroups, name => name == newKey)) {
          group.collapsed = true;
        }

        arrOfGroups.push(group);
      }

      arrOfGroups = _.orderBy(arrOfGroups, ["name"], ["asc"]);

      let noData = _.find(arrOfGroups, { name: "[Нет данных]" });
      if (!_.isEmpty(noData)) {
        arrOfGroups = _.reject(arrOfGroups, noData);
        arrOfGroups.push(noData);
      }

      return arrOfGroups;
    },

    allGroupItemsChecked: {
      get: function() {
        if (_.isEmpty(this.dsData)) {
          return false;
        }
        return _.difference(_.map(this.dsData, "id"), this.selectedObjects).length === 0;
      },
      set: function(setValue) {
        this.toggleAllItems(setValue);
      }
    },

    dsData() {
      return this.$store.getters["list/objects"](this.dataSourceId);
    },

    sorting() {
      return this.$store.getters["sorting/sorting"](this.dataSourceId);
    },

    name() {
      let metadata = this.metadata;
      return metadata ? metadata.class.name : "Неизвестно";
    },

    totalCount() {
      return this.$store.getters["list/totalObjectCount"](this.dataSourceId);
    },

    addObjectActions() {
      return _.map(this.params.actionAddObject, action => _.find(
        this.appManifest.actions, { actionId: action }
      ));
    },

    writeAccess() {
      return this.$store.getters.writeAccess({ scope: scopes.APPLICATION_DATA, resource: this.dataSourceId });
    },

    activeFilters() {
      return this.$store.getters["filters/activeFilters"](this.dataSourceId);
    },

    geometryFilterGeojson() {
      return this.$store.getters["filters/geometryFilterGeojson"];
    }
  },

  watch: {
    geometryFilterGeojson(value) {
      let filter = {
        alias: this.params["geojson"].ownPropertyAlias || this.params["geojson"].propertyAlias,
        operator: 22,
        type: "Geojson",
        name: "Фильтрафия по гео данным",
        active: true
      };

      if (filter.alias) {
        if (value) {
          filter.value = value;
          this.$store.dispatch("filters/createFilter", { dataSourceId: this.dataSourceId, filter });
        }
        else {
          this.$store.dispatch("filters/removeFilter", { dataSourceId: this.dataSourceId, filter });
        }
      }
    },

    allItemsChecked: function(newVal) {
      this.toggleAllItems(newVal);
    },

    async activeFilters(newValue, oldValue) {
      if (!(_.isEmpty(newValue) && _.isEmpty(oldValue)) && this.filterSetsDiffer(newValue, oldValue)) {
        await this.$store.dispatch("list/getDataSourceData", { dataSourceId: this.dataSourceId, aliases: this.aliases });
        this.$store.dispatch("list/resetTake", this.dataSourceId);
      }
    },

    sorting: {
      deep: true,
      async handler() {
        await this.$store.dispatch("list/getDataSourceData", { dataSourceId: this.dataSourceId, aliases: this.aliases });
        this.$store.dispatch("list/resetTake", this.dataSourceId);
      }
    },

    async changedObjectDataSourceId({ dataSourceId }) {
      if (dataSourceId && dataSourceId == this.dataSourceId) {
        await this.$store.dispatch("list/getDataSourceData", { dataSourceId: this.dataSourceId, aliases: this.aliases });
      }
    },

    async take() {
      await this.$store.dispatch("list/getDataSourceData", { dataSourceId: this.dataSourceId, aliases: this.aliases });
    }
  },

  async mounted() {
    await this.$store.dispatch("list/getDataSourceData", { dataSourceId: this.dataSourceId, aliases: this.aliases });

    this.$store.dispatch("grouping/setGroupingOptions", { dataSourceId: this.dataSourceId, options: this.groupOptions });
  },

  methods: {
    ...mapActions({
      increaseTake: "list/increaseTake"
    }),

    filterSetsDiffer(set1, set2) {
      let isFilterApplicable = filter => filter.active;
      return !_.isEqual(_.filter(set1, isFilterApplicable), _.filter(set2, isFilterApplicable));
    },

    /**
     * Возвращает значение обьекта на основе параметра
     */
    findParamValue(object, param) {
      return this.params[param]
        ? _.get(
          _.find(
            object.ownProperties,
            ownProp => ownProp.alias === this.params[param]
          ),
          "value"
        )
        : null;
    },

    getOpenObjectAction(classId) {
      let relations = this.params.actionOpenObject;
      let classAction = _.find(relations, { classId });

      return _.find(this.appManifest.actions, { actionId: classAction?.actionId });
    },

    getCreateReportAction(classId) {
      let relations = this.params.actionCreateReport;
      let classAction = _.find(relations, { classId });

      return _.find(this.appManifest.actions, { actionId: classAction?.actionId });
    },

    getActionAccess() {
      return this.$store.getters.writeAccess({ scope: scopes.APPLICATION_DATA, resource: this.actionAddObject.actionContextData });
    },

    /**
     * Запуск событие
     */
    triggerAction(action) {
      this.$eventBus.$emit("component:action", {
        actionId: action.actionId
      });
    },

    toggleContent() {
      this.showContent = !this.showContent;
    },

    /**
     * Подготовка основных полей для списка которые выводятся слева
     *
     * @param {Object} object Обьект с ownProperties и linkedProperties
     * @returns {Array}
     */
    objectFields(object) {
      // let fields = {};
      // this.params.fields.forEach((field) => {
      //   let prop = _.find(object.ownProperties, {
      //     alias: field.propertyAlias,
      //   });
      //   fields[field.order] = {
      //     order: field.order,
      //     property: _.find(
      //       object.ownProperties,
      //       (ownProp) => ownProp.alias === field.propertyAlias
      //     ),
      //   };
      // });

      // this.params.fields;

      return _.reduce(
        _.orderBy(this.params.fields, ["order"], ["asc"]),
        (result, fildParams) => {
          result[fildParams.order - 1] = this.prepareField(object, fildParams);
          return result;
        },
        []
      );
    },

    /**
     * Подготовка значений для тэгов
     *
     * @param {Object} object Обьект с ownProperties и linkedProperties
     * @returns {Array}
     */
    otherObjectFields(object) {
      return _.reduce(
        _.orderBy(this.params.otherFields, ["order"], ["asc"]),
        (result, fildParams) => {
          return [...result, ...this.prepareField(object, fildParams)];
        },
        []
      );
    },
    /**
     * Подготовка поля в формат для карточки списка
     *
     * @param {Object} object Обьект с ownProperties и linkedProperties
     * @param {Object} fildParams настройки поля из манифеста с настройками
     * {
     *
     *  propertyAlias: "", //id поля
     * }
     * @returns {Array} всегда возвращает массив готовых к выводу значений
     * (массив потому что linkedProperties может хранить несколько значений который нужно вывести)
     */
    prepareField(object, fildParams) {
      let type = "own";

      if (_.some(this.metadata.linkedProperties, { alias: fildParams.propertyAlias })) {
        type = "linked";
      }

      let propMetadata = _.find(
        this.metadata[
          type == "linked" ? "linkedProperties" : "ownProperties"
        ],
        { alias: fildParams.propertyAlias }
      );

      if (type == "own") {
        let prop = _.find(object.ownProperties, {
          alias: fildParams.propertyAlias
        });
        if (prop) {
          let field = {
            alias: propMetadata.name,
            tableType: propMetadata.tableType,
            propAlias: prop.alias,
            order: fildParams.order,
            value: this.$options.filters.propOutputFormat(
              prop.value,
              propMetadata.tableType
            ),
            style: prop.style
          };

          if (propMetadata.tableType == "File" || propMetadata.tableType == "Cadastre") {
            let values = [];

            if (propMetadata.tableType == "File") {
              values = FileObjectConverter.getSplitedFiles(prop.value);
            }
            else if (propMetadata.tableType == "Cadastre") {
              values = CadastreNumbersConverter.getSplitedNumbers(prop.value);
            }

            return _.map(values, value => {
              let newField = _.cloneDeep(field);
              newField.value = value;

              return newField;
            });
          }

          return [field];
        }
      }
      else {
        let prop = _.find(object.linkedProperties, {
          alias: fildParams.propertyAlias
        });
        if (prop) {
          let groupedProps = _.groupBy(
            _.get(prop, "values"),
            prop => prop.instanceId
          );

          return _.map(groupedProps, val => {
            let field = {
              alias: propMetadata.name,
              propAlias: prop.alias,
              instanceId: _.get(_.map(val), [0, "instanceId"]),
              order: fildParams.order,
              value: _.get(_.find(val, { alias: fildParams.ownPropertyAlias }), "value", _.get(_.map(val), [0, "value"])),
              style: _.get(_.find(val, { alias: fildParams.ownPropertyAlias }), "style")
            };

            let dataSourceId = propMetadata.linkedDataSourceId;

            if (dataSourceId) {
              field.dataSourceId = dataSourceId;
              field.navType = "dataSource";
            }

            return field;
          });
        }
      }
      return [];
    },
    createData(item) {
      let element = { fields: {} };
      this.params.fields.forEach(field => {
        element.fields[field.order] = {
          order: field.order,
          property: _.find(
            item.ownProperties,
            ownProp => ownProp.alias === field.propertyAlias
          )
        };
      });
      element.id = item.id;
      element.dataSourceId = this.params.dataSourceId;
      return element;
    },
    selectElement(id) {
      this.$emit("select-element", {
        id: id,
        dataSourceId: this.dataSourceId,
        wasSelected: this.currentObject && this.currentObject.id == id
      });
    },
    checkElement(item) {
      let selectedObjects = _.cloneDeep(this.selectedObjects);

      if (_.includes(selectedObjects, item.id)) {
        item.isChecked = false;
        this.$emit("change-all-items-checkbox", false);
        selectedObjects = _.filter(selectedObjects, i => i != item.id);
      }
      else {
        item.isChecked = true;
        selectedObjects.push(item.id);
        if (
          _.difference(_.map(this.dsData, "id"), selectedObjects).length === 0
        ) {
          this.$emit("change-all-items-checkbox", true);
        }
      }

      this.$store.dispatch("selectedObjects/setSelectedObjects", { selectedObjects, dataSourceId: this.dataSourceId });
    },

    checkAllItems() {
      this.allGroupItemsChecked = !this.allGroupItemsChecked;
    },

    toggleAllItems(value) {
      let selectedObjects = _.cloneDeep(this.selectedObjects);
      if (value === true) {
        selectedObjects = _.map(this.dsData, "id");
      }
      else if (value == false) {
        selectedObjects = [];
      }

      this.$store.dispatch("selectedObjects/setSelectedObjects", { selectedObjects, dataSourceId: this.dataSourceId });
    },

    getListIndex(elementIndex, groupIndex) {
      let groups = _.slice(this.groupedData, 0, groupIndex);

      let count = 1;

      groups.forEach(group => {
        count += group.values.length;
      });

      return elementIndex + count;
    },

    changeSelections(group, value) {
      let selectedObjects = _.cloneDeep(this.selectedObjects);

      _.forEach(group, el => {
        el.isChecked = value;
        if (_.includes(selectedObjects, el.id)) {
          if (value === false) {
            selectedObjects = _.filter(selectedObjects, i => i != el.id);
          }
          return;
        }
        else {
          selectedObjects.push(el.id);
        }
      });

      this.$store.dispatch("selectedObjects/setSelectedObjects", { selectedObjects, dataSourceId: this.dataSourceId });
    },

    changeCollapse(group) {
      if (_.some(this.collapsedGroups, name => name == group)) {
        this.collapsedGroups = _.filter(this.collapsedGroups, name => name !== group);
      }
      else {
        this.collapsedGroups.push(group);
      }
    },

    fieldToOption(fields, groupType) {
      let options = [];

      _.forEach(fields, el =>{
        let name = null;

        if (el?.ownPropertyAlias || el?.type === "linked") {
          name = _.get(_.find(this.metadata.linkedProperties, { alias: el.propertyAlias }), "name");
        }
        else {
          name = _.get(_.find(this.metadata.ownProperties, { alias: el.propertyAlias }), "name");
        }

        let opt = {
          ownPropertyAlias: _.isEmpty(el?.ownPropertyAlias) ? "" : el?.ownPropertyAlias,
          propertyAlias: el?.propertyAlias,
          label: name,
          field: groupType
        };
        options.push(opt);
      });

      return options;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
@import "../styles/variables.scss";

.plato-list__body_group {
  display: flex;
  flex-direction: column;

  &_paginator {
    display: flex;
    justify-content: center;
    padding: 20px 0;

    &_button {
      width: 100%;
    }
  }

  &_header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-top: 10px;
    margin: 0 15px;

    &_left {
      display: flex;
      align-items: center;
      cursor: pointer;
      flex: 1 1 auto;
    }
    &_right {
      display: flex;
      &_filter {
        margin-right: -15px;
        margin-left: -10px;
      }
    }
    &_text {
      margin-left: 7px;
      font-size: 16px;
      font-weight: 500;
    }
    &_icon {
      margin: 0 8px
    }
  }

  &_body {
    &_selector {
      margin-left: 10px
    }
  }

  .group {
    &-header {
      padding: 7px 0 7px 7px;
      display: flex;
      align-items: center;
      font-weight: bold;
      color: #6b778c;
      text-align: left;
      border-bottom: 1px solid #dfe1e6;

      &_collapser {
        padding-right: 7px;
      }

      &_checkbox {
        padding-right: 2px;
        padding-left: 8px
      }

      &_count {
        padding-left: 10px;
      }
    }

    &-footer__count {
      font-weight: bold;
    }
  }

  .button.plato-context-menu__btn {
    width: -webkit-fill-available;
    justify-content: start;
    background-color: #ffffff;
  }
  .plato-context-menu__header{
    margin:10px;
    font-weight: 600;
  }
}
</style>


