<template>
  <div
    class="plato-filters-line"
    :class="{'plato-filters-line--padding-bottom': dataView.length === 0}"
  >
    <div
      class="plato-filters-line__controls"
    >
      <div
        v-if="showCheckbox || showSearch"
        class="plato-filters-line__controls_left"
      >
        <div class="plato-filters-line__controls_left_checkbox">
          <PlatoCheckbox
            v-if="showCheckbox"
            :value="allItemsChecked"
            @change="$emit('check-all-items')"
          />
        </div>

        <PlatoDataSourceSearch
          v-if="showSearch"
          class="plato-filters-line__controls_left_search"
          :search-init="searchInit"
          @search-change="currentSearchValueChange"
        />
      </div>
      <div
        v-if="!collapsedHeader"
        class="plato-filters-line__controls_right"
      >
        <PlatoButtonGroup>
          <PlatoButton
            :disabled="availableFilters.length === 0 && filtersCount === 0"
            :type="filterValues.length > 0 ? 'primary' : 'light-blue'"
            icon="bars-filter"
            title="Фильтры"
            @click="toogleFilterPanel"
          >
            <div v-if="filtersCount">
              {{ filtersCount }}
            </div>
          </PlatoButton>
          <PlatoButton
            v-if="filterValues.length > 0"
            type="danger-action"
            icon="trash-list"
            title="Очистить фильтры"
            @click="clearFilters"
          />
        </PlatoButtonGroup>
        <PlatoContextMenu
          max-width-400
          :icon="appliedSorting.length === 0 ? 'arrow-up-arrow-down' : sortingButtonIcon"
          title="Сортировка"
        >
          <SortingMenu
            :data-source-id="dataSourceId"
            :metadata="metadata"
          />
        </PlatoContextMenu>

        <PlatoContextMenu
          v-if="showGrouping"
          max-width-400
          icon="list-tree"
          title="Групировка"
          :type="typeGroupingButton"
        >
          <div class="plato-context-menu__header">
            Группировка
          </div>

          <PlatoButtonGroup
            vertical
            have-subgroup
            table-context-menu
          >
            <PlatoButton
              v-for="(option,index) in groupingOptions"
              :key="index"
              class="plato-context-menu__btn"
              type="subtle"
              :active="getIsColumnGrouped(option)"
              @click="setGroup(option)"
            >
              {{ option.label }}
            </PlatoButton>
          </PlatoButtonGroup>
        </PlatoContextMenu>

        <PlatoButton
          :disabled="availableFilters.length === 0 && ownPropertiesSortings.length === 0 && linkedPropertiesSortings.length === 0"
          icon="cog"
          title="Настройки представления данных"
          @click="toogleDataViewPanel"
        />
      </div>
    </div>
    <DataViewSelector
      v-if="dataView.length > 0 && showDataViewSelector"
      :data-source-id="dataSourceId"
    />
    <PlatoAside
      v-if="filterVisible"
      ref="filterAside"
      placement="right"
      width="60%"
      @closed="toogleFilterPanel"
    >
      <PlatoFilterPanel
        slot-scope="inner"
        :metadata="metadata"
        :data-source-id="dataSourceId"
        @close="inner.close()"
      />
    </PlatoAside>
    <PlatoAside
      v-if="isDataViewPanelOpened"
      ref="DataViewAside"
      placement="right"
      width="60%"
      @closed="toogleDataViewPanel"
    >
      <DataViewPanel
        slot-scope="inner"
        :data-source-id="dataSourceId"
        :metadata="metadata"
        :columns-params="columnsParams"
        @close="inner.close()"
      />
    </PlatoAside>
  </div>
</template>

<script>
import _ from "lodash";

import DataViewPanel from "./DataViewPanel.vue";
import PlatoDataSourceSearch from "@/components/package/FilterLine/PlatoDataSourceSearch.vue";
import SortingMenu from "./SortingPanelComponents/SortingMenu.vue";

export default {
  name: "PlatoFilterLine",

  components: { PlatoDataSourceSearch, DataViewPanel, SortingMenu },

  props: {
    collapsedHeader: {
      type: Boolean
    },

    dataSourceId: {
      type: String,
      required: true
    },

    searchInit: {
      type: String,
      default: ""
    },

    closeAside: {
      type: Boolean,
      default: false
    },

    showCheckbox: {
      type: Boolean,
      default: true
    },

    showSearch: {
      type: Boolean,
      default: true
    },

    showGrouping: {
      type: Boolean,
      default: false
    },

    allItemsChecked: {
      type: Boolean
    },

    showDataViewSelector: {
      type: Boolean,
      default: true
    },

    metadata: {
      type: Object,
      default: () => {}
    },
    columnsParams: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      searchValue: this.searchInit,
      show: false,
      isSortPanelOpened: false,
      isDataViewPanelOpened: false,
      filterVisible: false
    };
  },

  computed: {
    filtersCount: function() {
      return _.reject(this.activeFilters, "isDefault").length;
    },

    filterValues() {
      let filters = this.$store.getters["filters/filters"](this.dataSourceId);
      return _.sortBy(_.filter(filters, "value"), "alias");
    },

    dataView() {
      return this.$store.getters.dataSourceDataView(this.dataSourceId);
    },

    appliedSorting() {
      return this.$store.getters["sorting/sorting"](this.dataSourceId);
    },

    ownPropertiesSortings() {
      return this.$store.getters["sorting/ownPropertiesSortingData"](this.metadata);
    },

    linkedPropertiesSortings() {
      return this.$store.getters["sorting/linkedPropertiesSortingData"](this.metadata);
    },

    activeFilters() {
      return this.$store.getters["filters/activeFilters"](this.dataSourceId);
    },

    availableFilters() {
      return this.$store.getters["filters/availableFilters"](this.metadata);
    },

    sortingButtonIcon() {
      return this.appliedSorting[0].sortType === "asc" ? "sort-amount-up" : "sort-amount-down";
    },

    sortingButtonName() {
      if (this.appliedSorting.length === 1) {
        return this.getSortingName(this.appliedSorting[0]);
      }

      return `${this.getSortingName(this.appliedSorting[0])} + ${this.appliedSorting.length - 1}`;
    },

    groupingOptions() {
      return this.$store.getters["grouping/listGroupingOptions"](this.dataSourceId);
    },

    grouping() {
      return this.$store.getters["grouping/grouping"](this.dataSourceId);
    },

    typeGroupingButton() {
      if (_.isEmpty(this.grouping) || _.isEmpty(this.grouping.propertyAlias)) {
        return "";
      }
      return "primary";
    }
  },

  watch: {
    closeAside(value) {
      if (value && this.filterVisible) {
        this.toogleFilterPanel();
      }
    }
  },

  methods: {
    getSortingName(sorting) {
      return sorting.type === "linked"
        ? `${sorting.parentName}: ${sorting.name}`
        : sorting.name;
    },

    toogleSortPanel() {
      if (this.isSortPanelOpened) {
        this.isSortPanelOpened = false;
      }
      else {
        this.isSortPanelOpened = true;
        this.$nextTick(() => this.$refs.SortAside.open());
      }
    },
    toogleDataViewPanel() {
      if (this.isDataViewPanelOpened) {
        this.isDataViewPanelOpened = false;
      }
      else {
        this.isDataViewPanelOpened = true;
        this.$nextTick(() => this.$refs.DataViewAside.open());
      }
    },
    toogleFilterPanel() {
      if (this.filterVisible) {
        this.filterVisible = false;
        this.$emit("aside-close");
      }
      else {
        this.filterVisible = true;
        this.$emit("aside-open");
        this.$nextTick(() => this.$refs.filterAside.open());
      }
    },

    /**
     * Выбор слова для поиска из списка подсказок
     */
    currentSearchValueChange(value) {
      this.$emit("search-change", value);
      this.searchValue = value;
    },

    /**
     * Обработка нажатия на сброс всех фильтров
     */
    clearFilters() {
      this.$store.dispatch("filters/removeFilters", { dataSourceId: this.dataSourceId, refreshUrl: true });
      this.$store.dispatch("filters/setGeometryFilter", "");
    },

    getIsColumnGrouped(option) {
      return (this.grouping?.ownPropertyAlias === option.ownPropertyAlias &&
      this.grouping?.propertyAlias === option.propertyAlias);
    },

    setGroup(option) {
      if (this.grouping &&
        this.grouping.label == option.label) {
        this.$store.dispatch("grouping/clearGrouping", this.dataSourceId);
        return;
      }
      this.$store.dispatch("grouping/setGrouping", {
        dataSourceId: this.dataSourceId,
        property: {
          label: option.label,
          ownPropertyAlias: option.ownPropertyAlias,
          propertyAlias: option.propertyAlias
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
@import "../styles/variables.scss";

//Основная часть
.plato-filters-line {
  padding: 0 15px 0px 10px;
  display: flex;
  flex-direction: column;

  &--padding-bottom{
    padding-bottom: 15px;
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    align-content: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    &_left {
      display: flex;
      align-items: center;
      flex: 1 1 auto;
      margin-left: 5px;
      @media screen and (max-width: 768px) {
        margin-left: 0px;
      }
      &_checkbox {
        margin-right: 5px
      }
      &_filter-btns {
        margin-left: 7px;
        display: flex;
        align-items: center;
        .multiselect {
          min-height: auto;
        }
        ::v-deep {
          i {
            font-size: 12px;
          }
          .plato-btn {
            // margin-right: 40px;
            & ~ .plato-btn {
              margin-left: 8px;
            }
          }
        }
      }
      &_filters {
        flex: 1 1 auto;
      }
      .plato-tags {
        justify-content: flex-start;
        ::v-deep {
          .plato-tag {
            background: #deebff;
            color: #0052cc;
            height: 32px;
            display: flex;
            align-items: center;
            padding: 0 5px;
            &:hover {
              background: #b3d4ff99;
            }
            &:active {
              color: #0052cc;
              background: #caddf8;
            }
          }
        }
      }
    }
    &_right {
      display: flex;
      align-self: flex-end;
      gap: 5px;

      @media screen and (max-width: 768px) {
        margin-top: 5px;
      }
      .plato-btn {
        max-width: 200px;
      }
    }
    .plato-context-menu__header{
    margin:10px;
    font-weight: 600;
    color:#6b778c
  }
  }
}
</style>