import httpClient from "./DefaultHttpClient";
import { rethrowPlatoApiError } from "./PlatoApiError";

const BASE_PATH = "/v1/explore";

class ExploreClient {

    @rethrowPlatoApiError
  async getAllAnnotationProperties() {
    return httpClient.get(`${BASE_PATH}/annotation_property`)
      .then(response => response.data);
  }

    @rethrowPlatoApiError
    async getAllDataProperties() {
      return httpClient.get(`${BASE_PATH}/data_property`)
        .then(response => response.data);
    }

    @rethrowPlatoApiError
    async getAllObjectProperties() {
      return httpClient.get(`${BASE_PATH}/object_property`)
        .then(response => response.data);
    }

    @rethrowPlatoApiError
    async getAllClasses(filter = {}) {
      return httpClient.post(`${BASE_PATH}/classes`, filter)
        .then(response => response.data);
    }

    @rethrowPlatoApiError
    async getClass(id) {
      return httpClient.get(`${BASE_PATH}/class/${id}`)
        .then(response => response.data);
    }

    @rethrowPlatoApiError
    async getInstanceById(id) {
      return httpClient.get(`${BASE_PATH}/instance/${id}`)
        .then(response => response.data);
    }

    @rethrowPlatoApiError
    async getInstances(classId, pageNumber, size = 30) {
      return httpClient.get(`${BASE_PATH}/instance`, {
        params: {
          classId: classId,
          page: pageNumber,
          pageSize: size
        }
      })
        .then(response => response.data);
    }

    /**
     * @typedef InstanceBody
     * @property {number} id
     * @property {number} classId
     * @property {string} iri
     * @property {Array<AnnotationPropertyBody>} annotationProperties
     * @property {Array<AnnotationPropertyBody>} dataProperties
     * @param {InstanceBody} instance
     */
    @rethrowPlatoApiError
    async saveInstance(instance) {
      const response = await httpClient.post(`${BASE_PATH}/instance`, instance);
      return response.data.id;
    }

    async deleteInstance(instanceId) {
      await httpClient.delete(`${BASE_PATH}/instance/${instanceId}`);
    }

    /**
     * Удаляет все инстансы класса
     * @param {string} classId Идентификатор класса
     */
    async deleteInstancesOfClass(classId) {
      await httpClient.delete(`${BASE_PATH}/class/${classId}/instances`);
    }

    /**
     * Запрашивает список DataType
     * @typedef DataType
     * @property {number} id - идентификатор
     * @property {string} type - тип данных
     * @property {string} table - таблица БД в которой хранятся данные
     * @returns { Array<DataType> }
     */
    @rethrowPlatoApiError
    async getDataTypes() {
      const response = await httpClient.get(`${BASE_PATH}/data_type`);
      return response.data.dataTypes;
    }

    /**
     * Запрашивает список AnnotationProperty
     * @typedef AnnotationProperty
     * @property {number} id - Идентификатор
     * @property {string} type - rdfs тип
     * @returns { Array<AnnotationProperty> }
     */
    @rethrowPlatoApiError
    async getAnnotationProperties() {
      const response = await httpClient.get(`${BASE_PATH}/annotation_property`);
      return response.data.annotationProperties;
    }

    /**
     * Запрашивает список DataProperty
     * @typedef DataProperty
     * @property {number} id - идентификатор
     * @property {string} iri - IRI
     * @property {DataType} type - тип свойства
     * @property {Array<AnnotationProperty>} annotationProperties - служебные свойства
     * @returns {Array<DataProperty>}
     */
    @rethrowPlatoApiError
    async getDataProperties(filter = {}) {
      const response = await httpClient.post(`${BASE_PATH}/data_properties`, filter);
      return response.data.dataProperties;
    }

    /**
     * @typedef AnnotationPropertyBody
     * @property {number} id - идентификатор
     * @property {string} value - значение свойства
     * @typedef DataPropertyBody
     * @property {number} id - идентификатор
     * @property {string} iri - IRI
     * @property {number} typeId - тип свойства
     * @property {Array<AnnotationPropertyBody>} annotationProperties - служебные свойства
     * Сохраняет DataProperty в БД
     * @param {DataPropertyBody} dataProperty
     */
    @rethrowPlatoApiError
    async saveDataProperty(dataProperty) {
      const response = await httpClient.post(`${BASE_PATH}/data_property`, dataProperty);
      return response.data.id;
    }

    @rethrowPlatoApiError
    async deleteDataProperty(dataPropertyId) {
      await httpClient.delete(`${BASE_PATH}/data_property/${dataPropertyId}`);
    }

    /**
     * @typedef ObjectProperty
     * @property {number} id
     * @property {string} iri
     * @property {Array<AnnotationProperty>} annotationProperties
     * @property {{id:number}} inverseObjectProperties
     */
    /**
     * Запрашивает список ObjectProperty
     * @returns {Array<ObjectProperty>}
     */
    @rethrowPlatoApiError
    async getObjectProperties(filter = {}) {
      const response = await httpClient.post(`${BASE_PATH}/object_properties`, filter);
      return response.data.objectProperties;
    }

    @rethrowPlatoApiError
    async getObjectProperty(id) {
      const response = await httpClient.get(`${BASE_PATH}/object_property/${id}`);
      return response.data;
    }

    /**
     * @typedef ObjectPropertyBody
     * @property {number} id
     * @property {string} iri
     * @property {Array<AnnotationPropertyBody>} annotationProperties
     * @property {Array<number>} domains - список идентификаторов классов
     * @property {Array<number>} ranges - список идентификаторов классов
     */
    /**
     * Сохраняет ObjectProperty в БД
     * @param {ObjectPropertyBody} objectProperty
     */
    @rethrowPlatoApiError
    async saveObjectProperty(objectProperty) {
      const response = await httpClient.post(`${BASE_PATH}/object_property`, objectProperty);
      return response.data.id;
    }

    @rethrowPlatoApiError
    async deleteObjectProperty(objectPropertyId) {
      await httpClient.delete(`${BASE_PATH}/object_property/${objectPropertyId}`);
    }

    /**
     * @typedef ClassBody
     * @property {number} id
     * @property {string} iri
     * @property {Array<number>} parents
     * @property {Array<AnnotationPropertyBody>} annotationProperties
     * @property {Array<number>} dataProperties
     */
    /**
     * Сохраняет класс в БД
     * @param {ClassBody} classBody
     */
    @rethrowPlatoApiError
    async saveClass(classBody) {
      const response = await httpClient.post(`${BASE_PATH}/class`, classBody);
      return response.data.id;
    }

    @rethrowPlatoApiError
    async deleteClass(classId) {
      await httpClient.delete(`${BASE_PATH}/class/${classId}`);
    }

    @rethrowPlatoApiError
    async getDomains() {
      const response = await httpClient.get(`${BASE_PATH}/domains`);
      return response.data.domains;
    }
    async getDomain(id) {
      const response = await httpClient.get(`${BASE_PATH}/domain/${id}`);

      return response.data;
    }

    @rethrowPlatoApiError
    async saveDomain(domain) {
      const response = await httpClient.post(`${BASE_PATH}/domain`, domain);
      return response.data.id;
    }

    @rethrowPlatoApiError
    async deleteDomain(id) {
      await httpClient.delete(`${BASE_PATH}/domain/${id}`);
    }
}

export default ExploreClient;