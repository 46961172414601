<template>
  <PlatoInlineEdit
    :value="currentValue"
    :label="label"
    type="Select"
    :context="{options: fieldsForSelect, groupSelect: true}"
    @change="value => $emit('change', value)"
  />
</template>

<script>
import _ from "lodash";

export default {
  props: {
    value: {
      type: String,
      default: ""
    },
    dsMetadata: {
      type: Object,
      default: () => {}
    },
    ownProperties: {
      type: Array,
      default: () => []
    },
    linkedProperties: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ""
    },
    removeRichText: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      currentValue: null
    };
  },

  computed: {
    fieldsForSelect() {
      let fields = [];

      let ownProps = !_.isEmpty(this.ownProperties) ? this.ownProperties : this.dsMetadata?.ownProperties;
      fields.push({
        display: "Собственные свойства",
        options: this.getOptions(ownProps)
      });

      let linkedProps = !_.isEmpty(this.linkedProperties) ? this.linkedProperties : this.dsMetadata?.linkedProperties;
      let linked = _.map(linkedProps, linkedField => ({
        display: linkedField.name,
        alias: linkedField.alias,
        options: this.getOptions(linkedField.properties, linkedField)
      }));

      linked = _.sortBy(linked, "display");

      fields.push(...linked);

      return fields;
    }
  },

  watch: {
    value(value) {
      this.setValue(value);
    }
  },

  mounted() {
    this.setValue(this.value);
  },

  methods: {
    setValue(value) {
      this.currentValue = value;

      if (value) {
        let field = _.find(this.fieldsForSelect, { alias: this.value });
        if (field) {
          this.currentValue = field.options[0]?.value;
        }
      }
    },

    getOptions(properties, linkedField) {
      let options = _.chain(properties)
        .map(field => ({
          value: field.alias,
          display: field.name,
          tableType: field.tableType,
          icon: this.getIcon(field.tableType),
          group: linkedField?.alias,
          groupName: linkedField?.name,
          type: "own"
        }))
        .sortBy("display")
        .value();

      _.remove(options, prop => {
        return (this.removeRichText && prop.tableType == "RichText");
      });

      return options;
    },

    getIcon(type) {
      switch (type) {
      case "Link":
        return "link";
      case "File":
        return "file";
      case "Text":
        return "text";
      case "Geometry":
        return "book-atlas";
      case "DateTimeUTC":
        return "timer";
      case "Numeric":
        return "input-numeric";
      case "Boolean":
        return "power-off";
      case "Cadastre":
        return "clipboard-user";
      case "RichText":
        return "text-size";
      default:
        return "circle-question";
      }
    }
  }
};
</script>

<style>

</style>