<template>
  <div
    class="data-table"
  >
    <Table
      :elements="elements"
      :columns="tableColumns"
      :selected="selected"
      select-by="instanceId"
      :count="count"
      :indexes="indexes"
      :checkboxes="checkboxes"
      :grouping-property="groupingProperty"
      :grouping-own-property="groupingOwnProperty"
      :show-select-all="showSelectAll"
      :hoverable="canClick"
      :show-icons="true"
      @click="onPropClick"
      @change="value => $emit('checkElements', value)"
      @increaseTake="$emit('increaseTake')"
      @columnClick="onColumnClick"
    >
      <template
        v-for="(column, index) in specialTableColumns"
        #[column.property]="{ element }"
      >
        <PlatoTags
          :key="index"
          :tags="getTags(element, column)"
          :visible-element-count="5"
        />
      </template>

      <template
        v-for="(column, index) in linkedTableColumns"
        #[column.ownProperty]="{ element }"
      >
        <template
          v-if="column.stringView"
        >
          {{ getStringView(element, column) }}
        </template>

        <PlatoTags
          v-else
          :key="index"
          :tags="getTags(element, column)"
          :visible-element-count="5"
          :show-more-info="showMoreInfo"
        />
      </template>

      <template #header_actions>
        <PlatoButtonGroup
          class="data-table-row-actions-group__header"
        >
          <template v-if="showActions">
            <PlatoContextMenu
              v-if="canAdd && createObjectActions.length > 1"
              icon="plus"
            >
              <div class="data-table-row-actions-group__menu-item">
                <PlatoButtonGroup
                  vertical
                  table-context-menu
                >
                  <PlatoButton
                    v-for="(action, index) in createObjectActions"
                    :key="`group-btn-${index}`"
                    class="plato-context-menu__btn"
                    type="subtle"
                    @click="$emit('createButtonClick', action.actionId)"
                  >
                    {{ action.name }}
                  </PlatoButton>
                </PlatoButtonGroup>
              </div>
            </PlatoContextMenu>

            <PlatoButton
              v-if="canAdd && createObjectActions.length == 1"
              key="group-btn-0"
              class="plato-context-menu__btn"
              icon="plus"
              :title="`${createObjectActions[0].name}`"
              @click="$emit('createButtonClick', createObjectActions[0].actionId)"
            />

            <PlatoButton
              :disabled="!canEdit"
              title="Редактировать"
              icon="pen"
              @click="$emit('addButtonClick')"
            />
          </template>

          <PlatoContextMenu
            icon="sliders"
            title="Параметры списка"
          >
            <div class="plato-context-menu__header">
              Параметры списка
            </div>
            <div class="data-table-row-actions-group__menu-item">
              <PlatoButtonGroup
                vertical
                have-subgroup
                table-context-menu
              >
                <PlatoButtonGroup
                  v-for="(column, index) in groupingColumns"
                  :key="`group-btn-${index}`"
                >
                  <PlatoButton
                    v-if="column.property"
                    :active="getIsColumnGrouped(column)"
                    :icon="column.hidden ? '' : 'check'"
                    type="subtle"
                    @click="visibilityChange(column)"
                  />
                  <PlatoButton
                    class="plato-context-menu__btn"
                    :active="getIsColumnGrouped(column)"
                    type="subtle"
                    :title="column.label"
                    @click="setGroupingProp(column)"
                  >
                    {{ column.label }}
                  </PlatoButton>
                </PlatoButtonGroup>
              </PlatoButtonGroup>
            </div>
          </PlatoContextMenu>
        </PlatoButtonGroup>

        <PlatoButtonGroup
          class="data-table-row-actions-group__header"
        />
      </template>

      <template #actions="{ element }">
        <PlatoButtonGroup
          class="data-table-row-actions-group__body"
        >
          <PlatoButton
            v-if="getOpenObjectAction(element.classId)"
            type="link-grey small"
            :icon="canEditElement ? 'pen' : 'eye'"
            @click="openObject(element)"
          />

          <PlatoContextMenu
            v-if="isDeletable || !_.isEmpty(getCreateReportAction(element.classId))"
            type="link-grey small"
          >
            <PlatoButton
              v-if="!_.isEmpty(getCreateReportAction(element.classId))"
              icon="file-chart-line"
              class="plato-context-menu__btn"
              @click="openReport(element)"
            >
              {{ getCreateReportAction(element.classId).name }}
            </PlatoButton>

            <PlatoButton
              v-if="isDeletable && canEditElement"
              icon="trash"
              type="danger-action"
              class="plato-context-menu__btn"
              @click="$emit('deleteElement', element.instanceId)"
            >
              Удалить
            </PlatoButton>
          </PlatoContextMenu>
        </PlatoButtonGroup>
      </template>

      <div
        v-if="elements.length == 0"
        slot="body"
        class="plato-card__body_no-content_table"
      >
        Нет данных
      </div>
    </Table>

    <div
      v-if="showPagination"
      class="data-table__paginator"
      :style="`left: ${scrollLeft}px`"
    >
      <PlatoLoader
        v-if="loading"
        mini
      />
      <PlatoButton
        v-else
        class="data-table__paginator-button"
        type="subtle"
        @click="$emit('increaseTake')"
      >
        Показать ещё
      </PlatoButton>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

import CadastreNumbersConverter from "@/PlatoAPI/CadastreNumbersConverter.js";
import FileObjectConverter from "@/PlatoAPI/FileObjectConverter.js";
import { isMobile } from "@/components/utils/mobile/Mobile.js";
import { SORT_TYPES } from "@/components/utils/sorting/SortTypes";

export default {
  props: {
    elements: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    openObjectActions: {
      type: Array,
      default: () => []
    },
    createReportActions: {
      type: Array,
      default: () => []
    },
    createObjectActions: {
      type: Array,
      default: () => []
    },
    canAdd: {
      type: Boolean,
      default: true
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    canEditElement: {
      type: Boolean,
      default: true
    },
    showActions: {
      type: Boolean,
      default: true
    },
    isDeletable: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default: () => []
    },
    count: {
      type: Boolean,
      default: false
    },
    indexes: {
      type: Boolean,
      default: false
    },
    checkboxes: {
      type: Boolean,
      default: false
    },
    grouping: {
      type: Object,
      default: () => ({})
    },
    showSelectAll: {
      type: Boolean,
      default: true
    },
    canClick: {
      type: Boolean,
      default: true
    },
    scrollLeft: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    showPagination: {
      type: Boolean,
      default: false
    },
    hiddenColumns: {
      type: Array,
      default: () => []
    },
    showMoreInfo: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      switchedVisibilityList: [],
      groupingProperty: "",
      groupingOwnProperty: "",
      isMobile: isMobile(),
      groupingLabel: ""
    };
  },

  computed: {
    ...mapGetters({
      currentManifest: "MANIFEST",
      activeTour: "onboarding/activeTour"
    }),

    linkedTableColumns() {
      return _.filter(this.tableColumns, column => column.ownProperty) || [];
    },

    specialTableColumns() {
      return _.filter(this.tableColumns, column => column.tableType == "File"
      || column.tableType == "Cadastre") || [];
    },

    tableColumns() {
      let columns = _.cloneDeep(this.columns);

      columns.forEach(column => {
        if (_.some(this.switchedVisibilityList, hiddenColumn => column.property == hiddenColumn.property && column.ownProperty == hiddenColumn.ownProperty)) {
          column.hidden = !column.hidden;
        }
      });

      return [...columns, {
        property: "actions",
        label: "",
        align: "right"
      }];
    },

    groupingColumns() {
      let columns = _.cloneDeep(this.columns);

      columns.forEach(column => {
        if (_.some(this.switchedVisibilityList, hiddenColumn => column.property == hiddenColumn.property && column.ownProperty == hiddenColumn.ownProperty)) {
          column.hidden = !column.hidden;
        }
      });

      return columns;
    }
  },

  watch: {
    grouping: {
      deep: true,
      immediate: true,
      handler(grouping) {
        this.groupingProperty = grouping.propertyAlias;
        this.groupingOwnProperty = grouping.ownPropertyAlias;
        this.groupingLabel = grouping.label;
      }
    },

    hiddenColumns: {
      deep: true,
      immediate: true,
      handler(value) {
        this.switchedVisibilityList = value;
      }
    }
  },

  methods: {
    setGroupingProp(column) {
      if (!this.getIsColumnGrouped(column)) {
        this.groupingProperty = column.property;
        this.groupingOwnProperty = column.ownProperty;
        this.groupingLabel = column.label;
        column.sort = SORT_TYPES.Asc;
      }
      else {
        this.groupingProperty = "";
        this.groupingOwnProperty = "";
        this.groupingLabel = "";
        column.sort = SORT_TYPES.Unset;
      }

      this.$emit("group", column);
    },

    getIsColumnGrouped(column) {
      return column.property == this.groupingProperty && (column.ownProperty == this.groupingOwnProperty || !column.ownProperty);
    },

    getOpenObjectAction(classId) {
      return _.find(this.openObjectActions, { classId })?.action;
    },

    getCreateReportAction(classId) {
      return _.find(this.createReportActions, { classId })?.action;
    },

    onPropClick(item) {
      if (this.canClick) {
        this.$emit("selectElement", item);
        if (this.isMobile) {
          this.openObject(item);
        }
      }
    },

    onColumnClick(column) {
      if (column.property != "actions") {
        this.$emit("columnClick", column);
      }
    },

    visibilityChange(column) {
      if (_.some(this.switchedVisibilityList, hiddenColumn => column.property == hiddenColumn.property && column.ownProperty == hiddenColumn.ownProperty)) {
        this.switchedVisibilityList = _.reject(this.switchedVisibilityList, hiddenColumn => column.property == hiddenColumn.property && column.ownProperty == hiddenColumn.ownProperty);
      }
      else {
        this.switchedVisibilityList.push({ property: column.property, ownProperty: column.ownProperty, label: column.label });
      }

      this.$emit("visibilityChange", this.switchedVisibilityList);
      this.$store.dispatch("onboarding/nextTourStep");
    },

    getStringView(element, column) {
      let props = _.filter(element[column.property], { alias: column.ownProperty });

      return _.reduce(props, (res, prop) => {
        if (res === "") {
          return res = prop.value;
        }
        else {
          return res += ", " + prop.value;
        }
      }, "");
    },

    getTags(element, column) {
      let tags = [];
      if (element && !_.isEmpty(element[column.property])) {
        let ownColumns = _.filter(element[column.property], { alias: column.ownProperty }) || [];
        ownColumns.forEach(element => {
          let tag = {
            alias: column.label,
            style: element.style,
            instanceId: element.instanceId,
            propAlias: column.property,
            tableType: column.tableType,
            value: element.value
          };
          if (!_.isEmpty(element.dataSourceId)) {
            tag.dataSourceId = element.dataSourceId;
            tag.navType = "dataSource";
          }
          tags.push(tag);
        });

        if (!_.isObject(element[column.property])) {
          let field = {
            alias: column.label,
            style: element.style,
            propAlias: column.property,
            tableType: column.tableType
          };

          let values = [];

          if (column.tableType == "File") {
            values = FileObjectConverter.getSplitedFiles(element[column.property]);
          }
          else if (column.tableType == "Cadastre") {
            values = CadastreNumbersConverter.getSplitedNumbers(element[column.property]);
          }

          tags.push(..._.map(values, value => {
            let newField = _.cloneDeep(field);
            newField.value = value;

            return newField;
          }));
        }
      }

      return _.sortBy(tags, tag => tag.value);
    },

    openObject(element) {
      this.$eventBus.$emit("component:action", {
        actionId: this.getOpenObjectAction(element.classId)?.actionId,
        id: element.instanceId
      });
    },

    openReport(element) {
      this.$eventBus.$emit("component:action", {
        actionId: this.getCreateReportAction(element.classId)?.actionId,
        id: element.instanceId
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.data-table {
  width: 98%;

  &__paginator {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      padding: 20px 0;
      position: relative;

      &-button {
        width: 100%;

      }
    }

  &-row-actions-group {
    justify-content: flex-end;

    &__header {
      margin-right: 8px;
    }

    &__body {
      margin-right: 15px;
    }
  }

  .button.plato-context-menu__btn {
    width: -webkit-fill-available;
    justify-content: start;
  }
  .plato-context-menu__header{
    margin:10px;
    font-weight: 600;
  }
}
</style>