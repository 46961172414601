import _ from "lodash";
import vm from "@/main.js";

function encode(any) {
  return btoa(unescape(encodeURIComponent(JSON.stringify(any))));
}

function decode(b64) {
  return JSON.parse(decodeURIComponent(escape(atob(b64))));
}

export default {
  getters: {
    queryParams: () => {
      return vm.$route.query;
    }
  },

  actions: {
    goHome(_context) {
      vm.$router.push({ path: "/" });
    },

    goPrevPage({ dispatch, getters }) {
      if (window.history.length > 2) {
        vm.$router.go(-1);
      }
      else {
        dispatch("openPage", getters["card/startPageId"]);
      }
    },

    urlToStore({ dispatch }) {
      dispatch("setPageId", { pageId: vm.$route.params.pageId || "", refreshUrl: false });

      let params = vm.$router.currentRoute.query;

      dispatch("card/setSectorId", { sectorId: params.sectorId, refreshUrl: false });
      dispatch("card/setStartPageId", { startPageId: params.startPageId, refreshUrl: false });

      dispatch("card/setLinkedPropertyAlias", { linkedPropertyAlias: params.linkedPropertyAlias, refreshUrl: false });
      dispatch("card/setLinkedObjectId", { linkedObjectId: params.linkedObjectId, refreshUrl: false });
      dispatch("card/setLinkedDataSourceId", { linkedDataSourceId: params.linkedDataSourceId, refreshUrl: false });

      if (params.currentObjectId) {
        dispatch("currentObject/changeCurrentObject", { id: params.currentObjectId, dataSourceId: params.currentObjectDataSourceId, refreshUrl: false });
      }

      if (params.filters) {
        dispatch("filters/setUrlFilters", decode(params.filters));
      }

      if (params.sorting) {
        dispatch("sorting/setUrlSorting", decode(params.sorting));
      }

      if (params.searchKeywords) {
        dispatch("searchKeywords/setSearchKeywords", decode(params.searchKeywords));
      }

      if (params.selectedObjects) {
        dispatch("selectedObjects/setAllSelectedObjects", decode(params.selectedObjects));
      }
    },

    refreshUrl({ getters }, { pushToHistory = false }) {
      let urlParams = {};

      let sectorId = getters["card/sectorId"];
      if (sectorId) {
        urlParams.sectorId = sectorId;
      }

      let startPageId = getters["card/startPageId"];
      if (startPageId) {
        urlParams.startPageId = startPageId;
      }

      let linkedPropertyAlias = getters["card/linkedPropertyAlias"];
      if (linkedPropertyAlias) {
        urlParams.linkedPropertyAlias = linkedPropertyAlias;
      }

      let linkedObjectId = getters["card/linkedObjectId"];
      if (linkedObjectId) {
        urlParams.linkedObjectId = linkedObjectId;
      }

      let linkedDataSourceId = getters["card/linkedDataSourceId"];
      if (linkedDataSourceId) {
        urlParams.linkedDataSourceId = linkedDataSourceId;
      }

      let currentObject = getters["currentObject/currentObject"];
      if (!_.isEmpty(currentObject)) {
        urlParams.currentObjectId = currentObject.id;
        urlParams.currentObjectDataSourceId = currentObject.dataSourceId;
      }

      let filters = getters["filters/filtersForUrl"];
      if (!_.isEmpty(filters)) {
        urlParams.filters = encode(filters);
      }

      let searchKeywords = getters["searchKeywords/searchKeywordsForUrl"];
      if (!_.isEmpty(searchKeywords)) {
        urlParams.searchKeywords = encode(searchKeywords);
      }

      let sorting = getters["sorting/sortingForUrl"];
      if (!_.isEmpty(sorting)) {
        urlParams.sorting = encode(sorting);
      }

      let selectedObjects = getters["selectedObjects/selectedObjectsForUrl"];
      if (!_.isEmpty(selectedObjects)) {
        urlParams.selectedObjects = encode(selectedObjects);
      }

      let currentRoute = vm.$router.currentRoute;
      if (!(currentRoute.path == `/${getters.applicationId}/${getters.pageId}` && _.isEqual(currentRoute.query, urlParams))) {
        if (pushToHistory) {
          vm.$router.push({ path: `/${getters.applicationId}/${getters.pageId}`, query: urlParams });
        }
        else {
          vm.$router.replace({ path: `/${getters.applicationId}/${getters.pageId}`, query: urlParams });
        }
      }
    }
  }
};