import _ from "lodash";

const scopes = {
  ALL: "*",
  APPLICATION_CONSTRUCTOR: "applicationConstructor",
  DATASOURCE_CONSTRUCTOR: "dataSourceConstructor",
  APPLICATION_DATA: "applicationData",
  APPLICATION: "application",
  NAVIGATOR: "navigator",
  ADMIN_USERS: "adminUsers",
  ADMIN_SERVICES: "adminServices",
  ADMIN_ROLES: "adminRoles",
  ADMIN_PERMISSIONS: "adminPermissions",
  ADMIN_PAGES: "adminPages",
  ADMIN_SHELL: "adminShell",
  ADMIN_CONFIGURATIONS: "adminConfigurations",
  ADMIN_MAP: "adminMap",
  ADMIN_DATABASE: "adminDatabase"
};

const resources = {
  ALL: "*"
};

const getPermissions = (permissions, scope, resource) => {
  return _.chain(permissions)
    .filter(p => (p.scope == "*" && p.resource == "*") ||
                 (p.scope == scope && p.resource == "*") ||
                 (p.scope == scope && p.resource == resource)
    )
    .map("access")
    .join("")
    .value();
};

const getPermissionsForScopes = (permissions, scope) => {
  return _.chain(permissions)
    .filter(p => p.scope == "*" || p.scope == scope)
    .map("access")
    .join("")
    .value();
};

export { getPermissions, getPermissionsForScopes, scopes, resources };
