import _ from "lodash";
import actions from "./actions";

const getDefaultState = () => {
  return {
    selectedObjects: []
  };
};

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    selectedObjectsGroups: state => {
      return state.selectedObjects;
    },

    allSelectedObjects: state => {
      return state.selectedObjects;
    },

    selectedObjects: state => dataSourceId => {
      return _.chain(state.selectedObjects)
        .find({ dataSourceId })
        .get("selectedObjects", [])
        .value();
    },

    selectedObjectsForUrl: state => {
      return _.chain(state.selectedObjects)
        .filter(filter => !_.isEmpty(filter.selectedObjects))
        .value();
    }
  },
  mutations: {
    resetState: state => {
      Object.assign(state, getDefaultState());
    },

    setAllSelectedObjects: (state, selectedObjects) => {
      state.selectedObjects = selectedObjects;
    },

    setSelectedObjects: (state, data) => {
      let selectedObjects = _.find(state.selectedObjects, { dataSourceId: data.dataSourceId });
      if (selectedObjects) {
        selectedObjects.selectedObjects = data.selectedObjects;
      }
      else {
        state.selectedObjects.push({ dataSourceId: data.dataSourceId, selectedObjects: data.selectedObjects });
      }
    },

    removeSelectedObjects: (state, dataSourceId) => {
      state.selectedObjects = _.reject(state.selectedObjects, { dataSourceId });
    }

  },
  actions
};