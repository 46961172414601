<template>
  <div
    :ref="`title ${_uid}`"
    v-click-outside="clickOutside"
    :class="tag.color ? `plato-tag--${tag.color}` : ''"
    :style="tagColorStyle"
    class="plato-tag"
    @mouseenter="isOpenPopper = true"
    @mouseleave="isOpenPopper = false"
  >
    <div
      class="plato-tag__label"
      @click.prevent="onClick"
    >
      {{ tagValue }}
    </div>

    <Icon
      v-if="withClose"
      icon="times"
      @click.native.stop.prevent="$emit('click-close')"
    />

    <PlatoPopper
      v-if="isOpenPopper && !hidePopper || clicked"
      class="plato-popper"
      :target-element="$refs[`title ${_uid}`]"
      placement="top"
      :offset="[0, 8]"
      @click.native.stop.prevent="() => {}"
    >
      <div class="plato-tag__hidden_item">
        <div class="plato-tag__hidden_item_alias">
          {{ tag.alias }}
        </div>
        <div>{{ tagValue }}</div>
      </div>

      <div
        v-if="loading"
        class="plato-tag__loader-wrap"
      >
        <PlatoLoader
          class="plato-tag__loader"
          mini
        />
      </div>

      <div
        v-if="!loading && showMoreInfo && navigationObjectLoaded"
        class="info-wrapper"
      >
        <div
          v-for="(prop, index) in navigationObject.ownProperties"
          :key="index"
          class="own-property"
        >
          <div class="own-property__name">
            {{ prop.name }}
          </div>

          <div class="own-property__value">
            {{ prop.value }}
          </div>
        </div>
      </div>
    </PlatoPopper>
  </div>
</template>

<script >
import _ from "lodash";

import FileObjectConverter from "@/PlatoAPI/FileObjectConverter.js";
import { colorSchema } from "@/components/ObjectColorSchema.js";

import NavigationObjectConverter from "./NavigationObjectConverter.js";
const navigationObjectConverter = new NavigationObjectConverter();

import Rosreestr from "@/components/mapControls/Rosreestr.js";
const rosreestr = new Rosreestr();

export default {
  name: "PlatoTag",

  props: {
    tag: {
      type: Object,
      required: true
    },
    withClose: {
      type: Boolean,
      default: false
    },
    hidePopper: {
      type: Boolean,
      default: false
    },
    showMoreInfo: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isOpenPopper: false,
      loading: false,
      clicked: false
    };
  },

  computed: {
    tagValue() {
      return this.$options.filters.propOutputFormat(
        this.tag.value,
        this.tag.tableType
      );
    },

    tagColorStyle() {
      let color = colorSchema(this.tag.style?.color, this.tag.style?.outlineColor);

      return {
        "--backgound-tag-color": `${color.fill.main}`,
        "--backgound-hovered-tag-color": `${color.fill.hovered}`,
        "--backgound-clicked-tag-color": `${color.fill.clickHovered}`,
        "--text-tag-color": `${color.outline.main}`,
        "--text-hovered-tag-color": `${color.outline.hovered}`,
        "--text-clicked-tag-color": `${color.outline.clickHovered}`
      };
    },

    navigationObjectLoaded() {
      return this.navigationObject && this.tag.instanceId == this.navigationObject.id;
    },

    navigationObject() {
      let exploreInstance = this.$store.getters["EXPLORE_INSTANCE"];
      if (exploreInstance) {
        let navigationObjectName = this.$store.getters["getNavigationObjectName"](exploreInstance);
        return navigationObjectConverter.createNavigationObjectForCard(exploreInstance, navigationObjectName);
      }
      return null;
    }
  },

  methods: {
    clickOutside() {
      this.clicked = false;
    },

    async onClick() {
      if (this.tag.tableType == "File") {
        location.href = FileObjectConverter.getUrl(FileObjectConverter.parseFile(this.tag.value));
      }
      else if (this.tag.tableType == "Cadastre") {
        let id = _.chain(this.tag.value).split(":").map(_.toFinite).join(":").value();
        this.$store.dispatch("navigationObject/changeNavigationObject", {
          id: id,
          type: rosreestr.getRosreestrObjectType(id),
          navType: "external"
        });
      }
      else {
        this.$store.dispatch("navigationObject/changeNavigationObject", this.tag);

        if (this.showMoreInfo && !this.navigationObjectLoaded) {
          this.clicked = true;
          this.loading = true;
          await this.$store.dispatch("GET_EXPLORE_INSTANCE", this.tag.instanceId);
          this.loading = false;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.plato-popper {
  user-select: text;
  cursor: auto;
}

.info-wrapper {
  max-width: 500px;
  overflow: auto;
  max-height: 400px;
  margin-top: 5px;
  padding: 0 5px;
}
.own-property {
  margin-top: 10px;
  &__name {
    font-size: 12px;
    display: -webkit-box;
    color: #6b778c;
    text-wrap: wrap;
    text-align: left;
  }
  &__value {
    font-size: 14px;
    color: #172b4d;
    overflow: hidden;
    display: -webkit-box;
    max-width: 500px;
    -webkit-line-clamp: 3;
    overflow-wrap: break-word;
    text-wrap: wrap;
    text-align: left;
  }
}

.plato-tag {
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  height: 16px;
  font-size: 11px;
  margin: 3px;
  user-select: none;
  cursor: pointer;

  background-color: var(--backgound-tag-color);
  color: var(--text-tag-color);
  border: 1px solid var(--text-tag-color);

  &:hover {
    background-color: var(--backgound-hovered-tag-color);
    color: var(--text-hovered-tag-color);
    border: 1px solid var(--text-hovered-tag-color);
  }

  &:active {
    background-color: var(--backgound-clicked-tag-color);
    color: var(--text-clicked-tag-color);
    border: 1px solid var(--text-clicked-tag-color);
  }

  .fas {
    font-size: 12px;
    margin-right: 4px;
  }

  &__hidden {
    &_item {
      align-items: flex-start;
      font-weight: normal;
      font-size: 14px;
      & ~ & {
        margin-top: 10px;
      }
      &_alias {
        margin-right: 15px;
        margin-bottom: 4px;
        font-size: 12px;
        font-weight: normal;
        color: #6b778c;
        text-align: left;
      }
      .plato-tag {
        height: auto;
      }
    }
  }
  &__label {
    display: inline-block;
    outline: none;
    padding: 2px 0;
    // font-size: 11px;
    margin: 0 5px;
    max-width: 180px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>

