<template>
  <div class="plato-component-header__right">
    <div class="actions">
      <PlatoButton
        v-if="avaliableActions.length == 1"
        type="primary"
        :icon="changeAddActionView ? 'plus' : ''"
        :title="firstAction.name"
        @click="triggerAction(firstAction)"
      >
        <div v-if="!changeAddActionView">
          {{ firstAction.name }}
        </div>
      </PlatoButton>
      <PlatoContextMenu
        v-else-if="createActions.length > 1"
        :text="changeAddActionView ? '' : 'Добавить'"
        :icon="changeAddActionView ? 'plus' : ''"
        title="Добавить"
        type="primary"
      >
        <template slot-scope="inner">
          <PlatoButton
            v-for="action in createActions"
            :key="`menu-item-${action.actionId}`"
            class="plato-context-menu__btn"
            :title="action.name"
            @click="triggerAction(action, () => inner.hide())"
          >
            {{ action.name }}
          </PlatoButton>
        </template>
      </PlatoContextMenu>
      <PlatoContextMenu
        v-if="!_.isEmpty(reportActions) || showDeleteButton"
        :icon="'ellipsis'"
      >
        <template slot-scope="inner">
          <PlatoButton
            v-for="action in reportActions"
            :key="`menu-item-${action.actionId}`"
            class="plato-context-menu__btn"
            :title="action.name"
            @click="triggerAction(action, () => inner.hide())"
          >
            {{ action.name }}
          </PlatoButton>
          <PlatoButton
            v-if="showDeleteButton"
            :disabled="disableDeleteButton"
            icon="trash"
            class="plato-context-menu__btn"
            type="danger-action"
            @click="$emit('confirmDeleteInstance')"
          >
            Удалить
          </PlatoButton>
        </template>
      </PlatoContextMenu>
      <PlatoButton
        v-if="isMobile"
        :icon="collapsed ? 'chevron-down' : 'chevron-up'"
        @click="click()"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { ACTION_TYPES } from "@/components/utils/actions/ActionTypes";
import { isMobile } from "@/components/utils/mobile/Mobile.js";
import { mapGetters } from "vuex";

import { scopes } from "@/store/Access";


export default {
  props: {
    actions: {
      type: Array,
      default: () => []
    },
    showDeleteButton: {
      type: Boolean,
      default: false
    },
    disableDeleteButton: {
      type: Boolean,
      default: false
    },
    requestAliases: {
      type: Array,
      default: () => []
    },
    changeAddActionView: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      collapsed: true,
      isMobile: isMobile()
    };
  },

  computed: {
    ...mapGetters({
      appManifest: "MANIFEST"
    }),

    firstAction() {
      return this.avaliableActions.length > 0 ? this.avaliableActions[0] : null;
    },

    createActions() {
      return _.filter(this.actions, action => (this.isCreateAction(action) && this.getWriteAccess(action)));
    },

    reportActions() {
      return _.filter(this.actions, action => this.isReportAction(action));
    },

    avaliableActions() {
      return _.filter(this.actions, action => (this.isCreateAction(action) && this.getWriteAccess(action)));
    }
  },

  methods: {
    click() {
      this.collapsed = !this.collapsed;
      this.$emit("change-header-view");
    },

    triggerAction(action, hide) {
      let manifestAction = _.find(
        this.appManifest.actions,
        manifestAction => manifestAction.actionId == action.actionId
      );

      let aliases = _.chain(this.requestAliases).find({ dataSourceId: manifestAction.actionContextData }).get("aliases").value();

      this.$emit("triggerAction", { actionId: action.actionId, aliases: aliases });
      if (hide) {
        hide();
      }
    },

    getWriteAccess(action) {
      return this.$store.getters.writeAccess({ scope: scopes.APPLICATION_DATA, resource: action.actionContextData });
    },

    isCreateAction(action) {
      let page = _.find(this.appManifest.pages, page => page.pageId == action.actionContext);
      if (!page) {
        return false;
      }

      return page.type == 3;
    },

    isReportAction(action) {
      return action.type == ACTION_TYPES.CreateReport
      || action.type == ACTION_TYPES.ExportShapefile
      || action.type == ACTION_TYPES.ExportExcel
      || action.type == ACTION_TYPES.ExportKml
      || action.type == ACTION_TYPES.ExportGeojson;
    }
  }
};
</script>

<style lang="scss">
div.plato-component-header__right {
  div.actions {
    gap: 5px;
    display: flex;
    .plato-btn {
      max-width: 180px;
      @media screen and (max-width: 768px) {
        max-width: 160px;
      }
    }
  }
}
</style>