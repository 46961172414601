import _ from "lodash";
export function validateGeometryTypes(geojson) {
  let allGeometryTypes = [];
  _.each(geojson.features, feature => {
    if (feature.geometry?.type) {
      allGeometryTypes.push(feature.geometry.type);
    }
  });
  let uniqueTypes = _.uniq(allGeometryTypes);
  if (uniqueTypes.length === 1) {
    return true;
  }
  else if (uniqueTypes.length === 2) {
    let compareUniqueTypes = (uniqueType1, uniqueType2, geometryType) => {
      return (uniqueType1 === geometryType || uniqueType1 === `Multi${geometryType}`) &&
      (uniqueType2 === geometryType || uniqueType2 === `Multi${geometryType}`);
    };
    if (
      compareUniqueTypes(uniqueTypes[0], uniqueTypes[1], "Point") ||
      compareUniqueTypes(uniqueTypes[0], uniqueTypes[1], "LineString") ||
      compareUniqueTypes(uniqueTypes[0], uniqueTypes[1], "Polygon")
    ) {
      return true;
    }
    else {
      return false;
    }
  }
  return false;
}

export function convertGeometryToPolygon(geojson) {
  _.each(geojson.features, feature => {
    switch (feature.geometry.type) {
    case "Point": {
      let polygonGeometry = {
        type: "Polygon",
        coordinates: [
          [
            feature.geometry.coordinates,
            feature.geometry.coordinates,
            feature.geometry.coordinates
          ]
        ],
        crs: feature.geometry.crs
      };
      feature.geometry = polygonGeometry;
      break;
    }
    case "MultiPoint": {
      let multiPointCoordinates = feature.geometry.coordinates;
      let multiPolygonCoordinates = [_.map(multiPointCoordinates, point => {
        return [
          point,
          point,
          point
        ];
      })
      ];

      let multiPolygonGeometry = {
        type: "MultiPolygon",
        coordinates: multiPolygonCoordinates,
        crs: feature.geometry.crs
      };
      feature.geometry = multiPolygonGeometry;
      break;
    }
    case "LineString": {
      let lineCoordinates = feature.geometry.coordinates;
      let reversedLineCoordinates = _.reverse(_.cloneDeep(lineCoordinates));
      let polygonCoordinates = [
        _.concat(lineCoordinates, reversedLineCoordinates)
      ];
      let polygonGeometry = {
        type: "Polygon",
        coordinates: polygonCoordinates,
        crs: feature.geometry.crs
      };
      feature.geometry = polygonGeometry;
      break;
    }
    case "MultiLineString": {
      let multiLineCoordinates = feature.geometry.coordinates;
      let multiPolygonCoordinates = [
        _.map(multiLineCoordinates, line => {
          let reversedLine = _.reverse(_.cloneDeep(line));
          return _.concat(line, reversedLine);
        })
      ];
      let multiPolygonGeometry = {
        type: "MultiPolygon",
        coordinates: multiPolygonCoordinates,
        crs: feature.geometry.crs
      };
      feature.geometry = multiPolygonGeometry;
      break;
    }
    }
  });
}