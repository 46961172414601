<template>
  <div
    v-if="currentObject && property && !dataLoading"
    class="plato-linkedprop-edit"
  >
    <div class="plato-linkedprop-edit__body">
      <PlatoCardBodyLinkedPropertyTable
        :linked-property="property"
        :field="field"
        :show-pagination="take < totalCount"
        :can-edit="canEdit"
        :can-click="canClick"
        :can-add="canAdd"
        :can-edit-element="canEditElement"
        :read-only="readOnly"
        :is-new="isNew"
        :selected-object-id="_.get(navigationObject, 'instanceId', '')"
        :loaded="loaded"
        :show-more-info="showMoreInfo"
        :metadata="metadata"
        @selectElement="onLinkedPropClick"
        @createButtonClick="onCreatePropClick"
        @addButtonClick="onAddPropsClick"
        @increaseTake="increaseTake"
        @loaded="tableLoaded"
      />
    </div>

    <PlatoAside
      v-if="addAsideOpened"
      :ref="`addAside`"
      placement="right"
      width="60%"
      @closed="toogleAside"
    >
      <PlatoLinkedPropEdit
        :linked-prop-edit-object="linkedPropEditObject"
        :metadata="metadata"
        @saveLinkedProps="saveLinkedProps"
        @cancelLinkedPropEdit="cancelLinkedPropEdit"
        @close="inner.close()"
      />
    </PlatoAside>
  </div>

  <PlatoLoader
    v-else-if="dataLoading"
  />
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

import platoCardBodyDataMixin from "../platoCardBodyDataMixin.js";
import PlatoCardBodyLinkedPropertyTable from "./PlatoCardBodyLinkedPropertyTable";
import PlatoLinkedPropEdit from "@/components/package/InlineEdit/PlatoLinkedPropEdit/PlatoLinkedPropEdit.vue";

import { annotationProperties, getAnnotationPropertyValue } from "@/components/utils/annotationProperty/AnnotationProperty.js";
import { scopes } from "@/store/Access";

export default {
  components: {
    PlatoCardBodyLinkedPropertyTable,
    PlatoLinkedPropEdit
  },

  mixins: [platoCardBodyDataMixin],

  props: {
    sector: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: true
    },
    isNew: {
      type: Boolean,
      required: true
    },
    dataSourceId: {
      type: String,
      required: true
    },
    showEmpty: {
      type: Boolean,
      required: true
    },
    nameSetting: {
      type: Object,
      required: true
    },
    showMoreInfo: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      addAsideOpened: false,
      searchKeyword: "",
      linkedProperties: null,
      mounting: true,
      loaded: false
    };
  },

  computed: {
    ...mapGetters({
      linkedProps: "cardTable/linkedProps",
      dataLoading: "cardTable/dataLoading",
      take: "cardTable/take",
      skip: "cardTable/skip",
      totalCount: "cardTable/totalCount",
      sorting: "cardTable/sorting",
      changedObjectDataSourceId: "changedObject/changedObjectDataSourceId",
      pageCurrentObject: "currentObject/currentObject",
      changedFields: "card/changedFields",
      loading: "card/dataLoading",
      navigationObject: "navigationObject/navigationObject"
    }),

    field() {
      let fields = _.chain(this.sector.groups).flatMapDeep("lines").flatMapDeep("fields").value();

      if (fields.length == 1 && fields[0]["field-0"] && !fields[0]["field-0"].ownPropertyAlias && fields[0]["field-0"].propertyAlias) {
        return fields[0]["field-0"];
      }

      return null;
    },

    aliases() {
      let aliases = [];

      let photoProps = _.filter(this.metadata.ownProperties, property => property.tableType == "File"
        && getAnnotationPropertyValue(this.metadata, property.alias, annotationProperties.PLATO_IS_PHOTO));

      aliases.push(..._.map(photoProps, "alias"));

      if (this.nameSetting) {
        aliases.push(this.nameSetting.ownPropertyAlias);
        aliases.push(this.nameSetting.propertyAlias);
        aliases.push(this.nameSetting.value);
      }

      return aliases;
    },

    linkedAliases() {
      return _.chain(this.field)
        .get("columns", [])
        .flatMap(column => column.ownPropertyAlias ? column.ownPropertyAlias : column.propertyAlias)
        .value();
    },

    writeAccess() {
      return this.$store.getters.writeAccess({ scope: scopes.APPLICATION_DATA, resource: this.dataSourceId });
    },

    property() {
      let property = _.find(this.linkedProperties, linkedProperty => linkedProperty.header.alias == this.field.propertyAlias);

      if (!property) {
        return null;
      }

      property.field = this.field;
      property.header.linkedProperties = this.getIncludedLinkedProperties(property.header.alias);
      property.values = _.map(this.linkedProps, prop => ([{ instanceId: prop.id }]));

      return property;
    },

    disableInlineEdit() {
      return _.get(this.property.field, "disableInlineEdit", false);
    },

    canEdit() {
      return !this.readOnly && this.property.header.isEditable && !this.property.header.parentAlias && this.writeAccess && !this.disableInlineEdit;
    },

    canClick() {
      return this.property.header.isEditable;
    },

    canAdd() {
      return !this.readOnly && !this.isNew && !!this.field?.createCardAction && this.property.header.isEditable && !this.property.header.parentAlias && this.writeAccess;
    },

    canEditElement() {
      return !this.readOnly && !this.isNew && !!this.field?.editCardAction && this.property.header.isEditable && this.writeAccess;
    }
  },

  watch: {
    async take() {
      if (!this.mounting) {
        await this.getLinkedProps();
      }
    },

    async currentObject(newCurrentObject) {
      if (!this.mounting) {
        if (newCurrentObject) {
          await this.getLinkedProps();
        }
      }
    },

    sorting: {
      deep: true,
      async handler() {
        if (!this.mounting) {
          this.resetTake();
          await this.getLinkedProps();
        }
      }
    }
  },

  async mounted() {
    this.$store.dispatch("cardTable/resetState");

    if (this.pageCurrentObject && this.pageCurrentObject.dataSourceId == this.dataSourceId) {
      await this.getLinkedProps();
    }

    this.mounting = false;
  },

  // beforeDestroy() {
  //   this.$store.dispatch("cardTable/resetState");
  // },

  methods: {
    ...mapActions({
      increaseTake: "cardTable/increaseTake",
      saveChangedFields: "card/saveChangedFields",
      removeNavigationObject: "navigationObject/removeNavigationObject",
      changeNavigationObject: "navigationObject/changeNavigationObject"
    }),

    tableLoaded() {
      this.loaded = true;
    },

    async saveProperty(type, prop, value) {
      if (this.isNew) {
        this.$store.dispatch("card/changeNewObjectField", {
          type: type,
          alias: prop.header.alias,
          value: value,
          dataSourceId: this.dataSourceId
        });
      }
      else {
        await this.$store.dispatch("card/addChangedFields", {
          type: type,
          dataSourceId: this.dataSourceId,
          alias: prop.header.alias,
          value: value,
          instanceId: this.currentObject.id
        });

        await this.saveChangedFields(this.dataSourceId);
        await this.getLinkedProps();
        this.notification.success({ title: "Успешное сохранение" });
      }
    },

    getIncludedLinkedProperties(alias) {
      let properties = _.filter(this.linkedProperties, linkedProperty => linkedProperty.header.parentAlias == alias);

      let collectedProperties = [...properties];

      properties.forEach(property => {
        collectedProperties = [...collectedProperties,
          ...this.getIncludedLinkedProperties(property.header.alias)];
      });

      return collectedProperties;
    },

    cancelLinkedPropEdit() {
      this.addAsideOpened = false;
    },

    resetTake() {
      this.$store.dispatch("cardTable/resetTake", this.dataSourceId);
    },

    getCardinality(property) {
      return getAnnotationPropertyValue(this.metadata,
        property.alias,
        annotationProperties.PLATO_CARDINALITY);
    },

    getEditCardActionId(classId) {
      return _.find(this.property.field?.editCardAction, { classId })?.actionId;
    },

    toogleAside() {
      if (this.addAsideOpened) {
        this.addAsideOpened = false;
      }
      else {
        this.addAsideOpened = true;
        this.$nextTick(() => this.$refs.addAside.open());
      }
    },

    async getLinkedProps() {
      await this.$store.dispatch("cardTable/getLinkedProps", {
        alias: this.field.propertyAlias,
        aliases: this.linkedAliases,
        searchKeyword: this.searchKeyword
      });
    },

    onLinkedPropClick(item) {
      if (this.navigationObject != null && this.navigationObject.instanceId === item.instanceId) {
        this.removeNavigationObject();
      }
      else {
        let navItem = {
          dataSourceId: item.dataSourceId,
          instanceId: item.instanceId,
          openCardAction: this.getEditCardActionId(item.classId)
        };

        let dataSourceId = _.get(this.property, "header.linkedDataSourceId");
        if (dataSourceId) {
          navItem.navType = "dataSource";
        }

        this.changeNavigationObject(navItem);
      }
    },

    async saveLinkedProps(saveData) {
      await this.saveProperty("linked", saveData.prop, saveData.value);
      this.cancelLinkedPropEdit();
    },

    async saveCallback(id) {
      let deleted = [];

      const cardinality = getAnnotationPropertyValue(this.metadata,
        this.property.header.alias,
        annotationProperties.PLATO_CARDINALITY);

      if (cardinality > 0 && this.property.values.length >= cardinality) {
        let deleteCount = (this.property.values.length - cardinality) + 1;
        deleted = _.chain(this.property.values).slice(0, deleteCount).map(x => x[0].instanceId).value();
      }

      const saveData = {
        value: { added: [id], removed: deleted, objects: [..._.flatMap(this.property.values)] },
        prop: this.property
      };

      await this.saveLinkedProps(saveData);
    },

    onAddPropsClick() {
      this.linkedPropEditObject = {
        prop: _.cloneDeep(this.property),
        datasourceId: this.dataSourceId,
        metadata: this.metadata
      };

      this.toogleAside();
    },

    onCreatePropClick(actionId) {
      this.$eventBus.$emit("component:action", {
        actionId: actionId,
        saveCallback: this.saveCallback,
        linkedPropertyAlias: this.property.header.alias,
        linkedObjectId: this.$store.getters["currentObject/currentObject"].id,
        linkedDataSourceId: this.dataSourceId
      });
    }
  }
};
</script>

<style lang="scss">
.plato-linkedprop-edit {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &_left-group {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      &_title {
        margin: 0;
      }
      &_count {
        font-size: 12px;
        color: #6b778c;
      }
    }

    &_func-buttons {
      display: flex;
      flex-direction: row;
      margin-left: 5px;
    }
  }
  &__body {
    flex: 1 1 auto;
    overflow: auto;

    &_group-name {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      color: #505f79;
      margin-bottom: 5px;
    }
    &_table {
      border-collapse: collapse;
      width: 100%;
      margin-bottom: 30px;
      thead {
        th {
          padding-top: 8px;
          padding-bottom: 7px;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          color: #6b778c;
          text-align: left;
        }
      }
      tr {
        border-bottom: 1px solid #dfe1e6;
      }
      tbody {
        td {
          padding-top: 22px;
          padding-bottom: 9px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          color: #172b4d;
        }
      }
      &_checkbox {
        width: 24px;
        height: 24px;
        label {
          margin-left: 5px;
        }
      }
    }
  }
}

.aside-search {
  margin-top: 10px;
}
</style>