import _ from "lodash";
import StateControllerBase from "@/store/StateControllerBase";
import { DataClient, DataSourceClient, ExploreClient } from "@/PlatoAPI";
import { setLoadingState } from "@/store/decorators/setLoadingState";
import { v4 } from "uuid";

const dataSourceClient = new DataSourceClient();
const exploreClient = new ExploreClient();
const dataClient = new DataClient();

class Actions extends StateControllerBase {
  async SET_DATA_LOADING_ERROR(context, value) {
    context.commit("SET_DATA_LOADING_ERROR", value);
  }

  async SET_DATA_LOADING(context, value) {
    context.commit("SET_DATA_LOADING", value);
  }

  setCurrentDataSource(context, value) {
    context.commit("setCurrentDataSource", value);
  }

  setCurrentStep(context, value) {
    context.commit("setCurrentStep", value);
  }

  @setLoadingState
  async getDataSource({ commit }, id) {
    const response = await dataSourceClient.getDataSource(id);
    commit("setCurrentDataSource", response.data);
    commit("setDataSourceEtag", response.headers.etag);
  }

  async generateDataSourceByType(context, dataSourceType) {
    const response = await dataSourceClient.generateDataSourceByType(dataSourceType);
    return response.generatedDataSource;
  }

  @setLoadingState
  async getDataSourcesInfo(context) {
    const data = await dataSourceClient.getAllDataSourcesInfo();
    context.commit("setDataSourcesInfo", data);
  }

  @setLoadingState
  async getAnnotationProperties(context) {
    const data = await exploreClient.getAllAnnotationProperties();
    context.commit("setAnnotationProperties", data.annotationProperties);
  }

  @setLoadingState
  async getDataProperties(context) {
    const data = await exploreClient.getDataProperties();

    context.commit("setDataProperties", data);
  }

  @setLoadingState
  async getObjectProperties(context) {
    const data = await exploreClient.getObjectProperties();

    context.commit("setObjectProperties", data);
  }

  @setLoadingState
  async getClasses(context) {
    const data = await exploreClient.getAllClasses();

    context.commit("setClasses", data);
  }

  setSelectedObjectProperties({ commit }, data) {
    commit("setSelectedObjectProperties", data);
  }

  resetState({ commit }, data) {
    commit("resetState", data);
  }

  setDataSourceName({ commit }, data) {
    commit("setDataSourceName", data);
  }

  updateStyle({ commit, getters }, { property, value }) {
    let style = _.cloneDeep(_.find(getters.styles, { styleId: getters.styleToEdit?.styleId }) || getters.defaultStyle);
    _.set(style, property, value);
    if (style.styleId === getters.defaultStyle.styleId) {
      commit("setDefaultStyle", style);
    }
    else {
      commit("updateStyleById", style);
    }
  }

  setStyles({ commit }, data) {
    commit("setStyles", data);
  }

  setConditions({ commit }, data) {
    commit("setConditions", data);
  }

  updateCondition({ commit, getters }, { property, value }) {
    let condition = _.cloneDeep(_.find(getters.conditions, { conditionId: getters.conditionToEdit?.conditionId }) || getters.defaultCondition);
    _.set(condition, property, value);
    if (condition.conditionId === getters.defaultCondition.conditionId) {
      commit("setDefaultCondition", condition);
    }
    else {
      commit("updateConditionById", condition);
    }
  }

  updateConditionById({ commit }, condition) {
    commit("updateConditionById", condition);
  }

  updatePropertyByPath({ commit }, data) {
    commit("updatePropertyByPath", data);
  }

  setPreviewMode({ commit }, check) {
    commit("setPreviewMode", check);
  }

  setSelectedDataProperties({ commit }, data) {
    commit("setSelectedDataProperties", data);
  }

  setObjectToEdit({ commit }, data) {
    commit("setObjectToEdit", data);
  }

  deletePropTreeItem({ commit }, index) {
    commit("deletePropTreeItem", index);
  }

  setDataSourceClassTree({ commit, getters }) {
    commit("setDataSourceClassTree", getters.clearClassTree);
  }

  resetClassTree({ commit }) {
    commit("resetClassTree");
  }

  setClearClassTree({ commit }, dataSourceClassTree) {
    commit("setClearClassTree", dataSourceClassTree);
  }

  setFilters({ commit }, filters) {
    commit("setFilters", filters);
  }

  setTreeItemCheck({ commit, getters, dispatch }, { path, check }) {
    if (!_.isEmpty(getters.selectedClasses)) {
      let selectedBranch = String(getters.selectedClasses[0].path)[0];
      if (path[0] !== selectedBranch) {
        dispatch("setSelectedClasses", []);
        dispatch("resetClassTree");
      }
    }
    commit("setTreeItemCheck", { path, check });

    let item = getters.treeItem(path);
    if (!_.isEmpty(item.children) && _.some(item.children, "checked")) {
      dispatch("setTreeItemMark", { path, mark: true });
    }
    else {
      dispatch("setTreeItemMark", { path, mark: false });
    }
  }

  setTreeItemCheckToValue({ commit }, { path, check }) {
    commit("setTreeItemCheckk", { path, check });
  }

  setFullClasses({ commit }, classes) {
    commit("setFullClasses", classes);
  }

  setTreeItemMark({ commit }, { path, mark }) {
    commit("setTreeItemMark", { path, mark });
  }

  checkParent({ getters, dispatch }, parentPath) {
    let parent = getters.treeItem(parentPath);
    if (!parent) {
      return;
    }
    else if (!parent.checked && _.some(parent.children, child => child.checked || child.marked)) {
      dispatch("setTreeItemMark", { path: parent.path, mark: true });
    }
    else if (parent.marked && !_.some(parent.children, child => child.checked || child.marked)) {
      dispatch("setTreeItemMark", { path: parent.path, mark: false });
    }
    dispatch("checkParent", parent.parentPath);
  }

  setPreviewData({ commit }, data) {
    commit("setPreviewData", data);
  }

  setSelectedClasses({ commit }, data) {
    commit("setSelectedClasses", data);
  }

  addStyle({ commit }, style) {
    commit("addStyle", style);
  }

  addCondition({ commit }, condition) {
    commit("addCondition", condition);
  }

  setStyleToEdit({ commit }, styleId) {
    commit("setStyleToEdit", styleId);
  }

  setConditionToEdit({ commit }, conditionId) {
    commit("setConditionToEdit", conditionId);
  }

  setDefaultStyle({ commit }, style) {
    commit("setDefaultStyle", style);
  }

  setDefaultCondition({ commit }, condition) {
    commit("setDefaultCondition", condition);
  }

  deleteStyle({ commit, getters }, styleId) {
    if (getters.styleToEdit.styleId == styleId) {
      commit("setStyleToEdit", { styleId: getters.defaultStyle.styleId });
    }
    commit("deleteStyle", styleId);
  }

  deleteCondition({ commit, getters }, conditionId) {
    commit("deleteCondition", conditionId);

    if (getters.conditionToEdit.conditionId == conditionId) {
      if (!_.isEmpty(getters.conditions)) {
        commit("setConditionToEdit", { conditionId: getters.conditions[0].conditionId });
      }
      else {
        commit("setConditionToEdit", { conditionId: getters.defaultCondition.conditionId });
      }
    }
  }

  assembleDataSource({ commit, getters }) {
    let subClasses = [];
    if (getters.selectedClasses.length > 1) {
      getters.selectedClasses.forEach(selectedClass => {
        if (_.includes(selectedClass.path, getters.mainClass.path) && selectedClass.path != getters.mainClass.path) {
          subClasses.push(selectedClass.id);
        }
      });
    }
    let dataSource = {
      name: getters.dataSourceName,
      query: {
        class: {
          alias: v4().split("-")[0],
          dataProperties: getters.selectedDataProperties,
          filters: getters.filters,
          id: getters.mainClass.id,
          objectProperties: getters.selectedObjectProperties,
          subclasses: subClasses
        }
      },
      styles: getters.dataSourceStyles,
      conditions: _.filter(getters.dataSourceConditions),
      type: getters.dataSourceType
    };
    if (getters.currentDatasource != null) {
      dataSource.id = getters.currentDatasource.id;
    }

    commit("setAssembledDataSource", dataSource);
  }

  @setLoadingState
  async deleteDataSource(_context, dsId) {
    await dataSourceClient.deleteDataSource(dsId);
  }

  @setLoadingState
  async saveDataSource({ commit, getters }, dataSource) {
    const data = await dataSourceClient.saveDataSource(dataSource, getters.dataSourceEtag);
    commit("setSavedDataSource", data);
  }

  @setLoadingState
  async getDataPreview(context, dataSource) {
    const body = {
      dataSource: dataSource,
      skip: 0,
      take: 20
    };

    const data = await dataClient.getDataPreview(body);
    context.commit("setDataPreview", data);
  }

  async getDataSourceInstances(context, dataSource) {
    let nameProperty = _.find(dataSource.query.class.dataProperties, { id: "95b1ff3e-497c-462f-b260-c1b7166021b5" });

    let firstProperty = "";

    if (dataSource.query.class.dataProperties.length > 0) {
      firstProperty = dataSource.query.class.dataProperties[0].alias || dataSource.query.class.objectProperties[0]?.properties[0]?.alias;
    }

    const body = {
      dataSource: dataSource,
      requestedPropertiesAliases: nameProperty ? [nameProperty.alias] : [firstProperty],
      skip: 0,
      take: 1000000
    };

    if (nameProperty || firstProperty) {
      const data = await dataClient.getDataPreview(body);
      context.commit("setDataSourceInstances", data);
    }
  }

  async getClassInstances({ commit }, classId) {
    let instances = await exploreClient.getInstances(classId, 0, 1000);
    commit("addClassInstances", { classId, instances });
  }

  async setClassInstances({ commit }, data) {
    commit("setClassInstances", data);
  }

  setDataSourceType({ commit }, type) {
    commit("setDataSourceType", type);
  }
}

export default (new Actions).asPlainObject();