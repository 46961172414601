import _ from "lodash";

import { SORT_TYPES } from "@/components/utils/sorting/SortTypes";

export default {
  setReducedValue(linkedProperties, reducedValue, instanceId) {
    linkedProperties.forEach(linkedProperty => {
      _.flatMapDeep(linkedProperty.values).forEach(linkedPropertyValue => {
        if (linkedPropertyValue.linkedInstanceId == instanceId
          && !_.some(reducedValue[linkedProperty.header.alias], { alias: linkedPropertyValue.alias, instanceId: linkedPropertyValue.instanceId })) {
          let obj = reducedValue[linkedProperty.header.alias] || [];
          _.set(reducedValue, linkedProperty.header.alias, [linkedPropertyValue, ...obj]);
          this.setReducedValue(linkedProperties, reducedValue, linkedPropertyValue.instanceId);
        }
      });
    });
  },

  convertElements(property, instanceId, classes, sortedColumn) {
    let items = property.values.map(value => {
      let reducedValue = _.reduce(value, (acc, instance) => {
        _.set(acc, instance.alias || "name", instance.value);

        return _.set(acc, "active", instance.instanceId == instanceId);
      }, { instanceId: value[0].instanceId, classId: value[0].classId });

      if (property.header.linkedProperties) {
        this.setReducedValue(property.header.linkedProperties, reducedValue, reducedValue.instanceId);
      }

      let currentClass = _.find(classes, { id: reducedValue.classId });

      reducedValue.style = value.style;

      if (currentClass) {
        reducedValue.prop_class_name = _.chain(currentClass.annotationProperties)
          .find({ type: "rdfs:label" })
          .get("value", "Неизвестно")
          .value();
      }
      else {
        reducedValue.prop_class_name = "Неизвестно";
      }

      return reducedValue;
    });

    if (sortedColumn) {
      if (sortedColumn.ownProperty) {
        let property = _.get(items, "[0][sortedColumn.property]");
        if (property) {
          let index = _.findIndex(property, item => item.alias == sortedColumn.ownProperty);
          return _.orderBy(items, [`${sortedColumn.property}[${index}].value`], [sortedColumn.sort]);
        }
      }

      return _.orderBy(items, [sortedColumn.property], [sortedColumn.sort]);
    }

    return items;
  },

  convertColumns(sortedColumn, property) {
    let linkedProperty = property.header;

    let columns = _.chain(linkedProperty.properties)
      .filter(prop => prop.tableType != "Geometry" && prop.tableType != "RichText")
      .map(prop => ({
        property: prop.alias,
        tableType: prop.tableType,
        label: prop.name,
        sort: SORT_TYPES.Unset
      })).value();

    if (linkedProperty.linkedProperties) {
      linkedProperty.linkedProperties.forEach(linkedProperty => {
        let linkedColumns = _.chain(linkedProperty.header.properties)
          .filter(prop => prop.tableType != "Geometry" && prop.tableType != "RichText")
          .map(prop => ({
            property: linkedProperty.header.alias,
            ownProperty: prop.alias,
            tableType: prop.tableType,
            sort: SORT_TYPES.Unset,
            label: linkedProperty.header.properties.length == 1 ? linkedProperty.header.name
              : linkedProperty.header.name + ` (${prop.name})`
          })).value();

        columns.push(...linkedColumns);
      });
    }

    if (!_.isEmpty(property.field?.columns)) {
      let newColumns = [];

      property.field.columns.forEach(fieldColumn => {
        let foundColumn = _.find(columns, column => {
          if (column.property == fieldColumn.propertyAlias) {
            if (fieldColumn.ownPropertyAlias) {
              if (column.ownProperty !== fieldColumn.ownPropertyAlias) {
                return false;
              }
            }

            return true;
          }
        });
        if (foundColumn) {
          foundColumn.stringView = fieldColumn.stringView;
          newColumns.push(foundColumn);
        }
      });

      columns = newColumns;
    }

    columns.push({
      label: "Класс",
      property: "prop_class_name",
      sort: SORT_TYPES.Unset,
      tableType: "Text",
      hidden: true
    });

    if (sortedColumn) {
      let columnForSort = _.find(columns, column => column.property == sortedColumn.property
        && column.ownProperty == sortedColumn.ownProperty);

      if (columnForSort) {
        columnForSort.sort = sortedColumn.sort;
      }
    }

    return columns;
  }
};
