<template>
  <div
    class="plato-card__body_content_col"
    :class="{ 'plato-card__body_content_col--stretch': isTableView }"
  >
    <PlatoInlineEditLinked
      v-for="(prop, key) in properties"
      :key="`info_header_linked_${key}`"
      class="plato-card__body_content_col_field"
      :metadata="metadata"
      :property="prop"
      :can-edit="getCanEdit(prop)"
      :can-click="getCanClick(prop)"
      :can-add="getCanAdd(prop)"
      :can-edit-element="getCanEditElement(prop)"
      :field="getField(prop)"
      :is-table-view="isTableView"
      :data-source-id="dataSourceId"
      :show-more-info="showMoreInfo"
      @setLinkedProperties="$emit('setLinkedProperties')"
      @saveField="saveField"
      @saveChangedFields="saveChangedFields"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions } from "vuex";

import { scopes } from "@/store/Access";

export default {
  props: {
    properties: {
      type: Array,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: true
    },
    isNew: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Array,
      default: () => []
    },
    dataSourceId: {
      type: String,
      required: true
    },
    showMoreInfo: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    metadata() {
      return this.$store.getters["card/metadata"](this.dataSourceId);
    },

    isTableView() {
      return this.properties.length == 1 && this.fields.length == 1;
    },

    writeAccess() {
      return this.$store.getters.writeAccess({ scope: scopes.APPLICATION_DATA, resource: this.dataSourceId });
    }
  },

  methods: {
    ...mapActions({
      saveChangedFields: "card/saveChangedFields"
    }),

    async saveField(type, prop, value, endCallback) {
      this.$emit("saveField", type, prop, value, endCallback);
    },

    getField(prop) {
      return _.find(this.fields, { propertyAlias: prop.header.alias });
    },

    getCanEdit(prop) {
      return !this.readOnly && prop.header.isEditable && !prop.header.parentAlias && this.writeAccess;
    },

    getCanClick(prop) {
      return prop.header.isEditable;
    },

    getCanAdd(prop) {
      return !this.readOnly && !this.isNew && !!this.getField(prop)?.createCardAction && prop.isEditable && !prop.header.parentAlias && this.writeAccess;
    },

    getCanEditElement(prop) {
      return !this.readOnly && !this.isNew && !!this.getField(prop)?.editCardAction && prop.isEditable && this.writeAccess;
    }
  }
};
</script>

<style scoped>
.plato-card__body_content_col--stretch {
  width: 100%;
}
</style>