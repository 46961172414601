<template>
  <PlatoSearch
    :value="value"
    @change="updateSearch"
    @keyup.native="onKeyUp"
  />
</template>

<script>
import Mark from "mark.js";

export default {
  name: "PlatoMarkSearch",

  props: {
    value: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "Поиск"
    },
    searchSelector: {
      type: String,
      default: "body"
    }
  },

  data() {
    return {
      search: this.value,
      markInstance: null,
      preserveSearch: true,
      defaultOptions: {},
      highlightOptions: {
        className: "mark-highlight"
      }
    };
  },

  mounted() {
    this.markInstance = new Mark(document.querySelector(this.searchSelector));
  },

  methods: {
    markUsingDefaultStyle() {
      this.markInstance.unmark();
      this.markInstance.mark(this.search, this.defaulttOptions);
    },

    markUsingHighlightStyle() {
      this.markInstance.unmark();
      this.markInstance.mark(this.search, this.highlightOptions);
    },

    onKeyUp(event) {
      if (event.keyCode == 13) {
        this.markUsingHighlightStyle();
        setTimeout(() => this.markUsingDefaultStyle(), 100);
      }
    },

    updateSearch(query) {
      this.preserveSearch = !!query;
      this.search = query;
      this.markUsingDefaultStyle();
      this.$emit("input", query);
    }
  }
};
</script>

<style>

</style>>