// import _ from "lodash";
import actions from "./actions";

const getDefaultState = () => {
  return {
    activeTour: null,
    listToursEnd: false
  };
};

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    activeTour: state => {
      return state.activeTour;
    },

    listToursEnd: state => {
      return state.listToursEnd;
    }
  },
  mutations: {
    setActiveTour: (state, tour) => {
      state.activeTour = tour;
    },

    setListToursEnd: (state, value) => {
      state.listToursEnd = value;
    }
  },
  actions
};