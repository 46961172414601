export default {
  namespaced: true,
  state: {
    changedFlytoState: null
  },
  getters: {
    changedFlytoState: state => {
      return state.changedFlytoState;
    }
  },
  mutations: {
    setFlytoState: (state, flyToState) => {
      state.changedFlytoState = flyToState;
    }
  },
  actions: {
    changeFlytoState({ commit }, flyToState) {
      commit("setFlytoState", flyToState);
    }
  }
};