<template>
  <div
    v-click-outside="hideOptions"
    class="plato-search"
  >
    <PlatoInput
      ref="plato-input"
      :value="searchValue"
      :placeholder="placeholder"
      :read-only="disabled"
      search-input
      :show-search-icon="showSearchIcon"
      @input="change"
      @paste="v => change(v.target.value)"
      @focus="showOptions()"
      @enter="searchClick"
      @keydown.native="key"
    >
      <Icon
        v-if="value || searchValue"
        class="plato-search__clear_icon"
        icon="times"
        @click.native="change('')"
      />
      <Icon
        v-if="showSearchIcon"
        class="plato-search__search_icon"
        icon="search"
      />
    </PlatoInput>

    <div
      v-if="isOptionsOpened && showOptionsPanel && (!_.isEmpty(options) || loading)"
      ref="options"
      class="plato-search__options"
    >
      <div
        v-for="(option, index) in options"
        :key="index"
        class="plato-search__option"
        :selected="selectedIndex === index"
        @click="select(option)"
      >
        <slot
          v-if="!_.isEmpty(option)"
          name="option"

          :option="option"
          :index="index"
        >
          <div
            class="plato-search__slot-option"
          >
            {{ option }}
          </div>
        </slot>
      </div>

      <div
        v-if="loading"
        class="plato-search__loading-wrap"
      >
        <PlatoLoader
          v-if="loading"
          mini
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  props: {
    options: {
      type: [Array, Object],
      default: () => []
    },
    showOptionsPanel: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "Поиск"
    },
    value: {
      type: String,
      default: ""
    },
    hideAfterSelect: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    showSearchIcon: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showDrawSearchOptions: {
      type: Boolean,
      default: false
    },
    isMapSearch: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isOptionsOpened: false,
      searchValue: this.value,
      selectedIndex: -1
    };
  },

  computed: {
    ...mapGetters({
      activeTour: "onboarding/activeTour"
    })
  },

  watch: {
    showDrawSearchOptions(isDrawSearchOptionsOpened) {
      if (isDrawSearchOptionsOpened) {
        this.showOptions();
      }
      else {
        this.hideOptions();
      }
    },
    disabled(isInputDisabled) {
      if (isInputDisabled) {
        this.change("");
      }
    },
    activeTour(newTour, oldTour) {
      if (newTour && oldTour) {
        if (newTour.id != oldTour.id) {
          this.isOptionsOpened = false;
        }
      }
    }
  },

  methods: {
    key(event) {
      switch (event.key) {
      case "ArrowUp":
        if (this.selectedIndex > -1) {
          this.selectedIndex--;
        }
        else {
          this.selectedIndex = this.options.length - 1;
        }
        this.scroll();
        break;
      case "ArrowDown":
        if (this.selectedIndex < this.options.length - 1) {
          this.selectedIndex++;
        }
        else {
          this.selectedIndex = -1;
        }
        this.scroll();
        break;
      default:
        break;
      }
    },

    scroll() {
      this.$nextTick(() => {
        let options = {
          container: ".plato-search__options",
          easing: "ease-in-out",
          lazy: false,
          offset: 0,
          force: false,
          cancelable: true,
          x: false,
          y: true
        };

        let element = document.querySelector("[selected]");
        this.$scrollTo(element, 100, options);
      });
    },

    focus() {
      this.$refs["plato-input"].focus();
    },

    select(value) {
      this.focus();
      this.$emit("select", value);
      if (this.isMapSearch) {
        this.searchValue = value.ownProperties[0].value;
      }
      else {
        this.searchValue = value;
      }
      this.selectedIndex = _.indexOf(this.options, value);

      if (this.hideAfterSelect) {
        this.hideOptions();
      }
    },

    change(value) {
      this.selectedIndex = -1;
      this.searchValue = value;
      this.$emit("change", value);
      this.$emit("input", value);
    },

    showOptions() {
      if (!this.isOptionsOpened) {
        this.isOptionsOpened = true;
      }
    },

    hideOptions() {
      if (!this.activeTour) {
        this.isOptionsOpened = false;
      }
    },

    searchClick() {
      if (this.selectedIndex === -1) {
        this.$emit("click", this.searchValue);
      }
      else {
        this.select(this.options[this.selectedIndex]);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .plato-search {
    position: relative;
    display: flex;
    flex-grow: 1;

    &__select {
      margin-left: -2px
    }

    &__search_icon {
      cursor: text;
    }

    &__clear_icon {
      cursor: pointer;
      margin-left: -19px;
    }

    &__options {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 30px;
      z-index: 10;
      max-height: 500px;
      overflow-x: hidden;
      overflow-y: auto;
      background: #ffffff;
      width: calc(100% - 2px);
      border: 1px solid #dfe1e6;
      border-radius: 3px;
      box-shadow: 0 20px 32px -8px rgb(9 30 66 / 25%);
      z-index: 2999;
    }

    &__option {
      cursor: pointer;

      &:hover {
        background: whitesmoke;
      }

      &[selected] {
        background: whitesmoke;
      }
    }

    &__slot-option {
      padding: 7px;
    }

    &__loading-wrap {
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>