
export default {
  GUEST_ROLE_ID: "615171e306fbc676e2fb015c",
  GUEST_USER_ID: "615171f1e9a5599d67cb174c",

  ADMIN_USER_ID: "6148890ce3b38d35b3fef575",
  ADMIN_ROLE_ID: "61488b44326d0442b6bd3c8f",
  ADMIN_PERMISSION_ID: "61488a86f70cea0f16575528",
  AUTHENTICATION_RESULT_REASON_TYPES: {
    VALID_CREDENTIAL: 0,
    INVALID_CREDENTIAL: 1,
    IS_LOCKED: 2,
    AUTHENTICATION_ERROR: 3,
    INVALID_REFRESH_TOKEN: 4
  }
};