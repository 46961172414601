<template>
  <span class="breadcrumb-item">
    <span
      ref="link"
      :class="['breadcrumb-item-inner', to || href ? 'breadcrumb-link' : '']"
    >
      <slot />
    </span>
    <Icon
      v-if="separatorIcon"
      :type="separatorIcon"
      class="breadcrumb-item-icon"
    />
    <span
      v-else
      class="breadcrumb-item-separator"
    >{{ separator }}</span>
  </span>
</template>

<script>
export default {
  name: "BreadcrumbItem",
  inject: ["BreadcrumbParent"],
  props: {
    to: {
      type: Object,
      required: false,
      default: null
    },
    href: {
      type: String,
      default: ""
    },
    replace: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      separator: "",
      separatorIcon: ""
    };
  },
  mounted() {
    this.separator = this.BreadcrumbParent.separator;
    this.separatorIcon = this.BreadcrumbParent.separatorIcon;
    const link = this.$refs.link;

    link.addEventListener("click", e => {
      this.$emit("click", e);
      if (this.href) {
        window.location.href = this.href;
        return;
      }

      const { to, $router } = this;

      if (!to || !$router) {
        return;
      }
      this.replace ? $router.replace(to) : $router.push(to);
    });
  }
};
</script>
