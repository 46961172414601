import StateControllerBase from "@/store/StateControllerBase";
import { DataClient, MapClient } from "@/PlatoAPI";
import { LAYER_TYPES } from "@/PlatoAPI/MapConstraints.js";
import { setLoadingState } from "@/store/decorators/setLoadingState";

import { convertFilter } from "../FilterConverter.js";

import GeometryFileClient from "@/PlatoAPI/GeometryFileClient.js";

import { ConvertFilename, ConvertObjectProperties, GetRequestedAliases } from "@/components/utils/Files/GeometryFileConverter.js";

import { Download } from "@/components/utils/Files/FileDownloader.js";

import { convertGeometryToPolygon, validateGeometryTypes } from "@/components/utils/Files/ShapefileUtils.js";

import { MAP_OBJECT_INFO_OPTIONS } from "@/components/utils/dataSourceLayers/MapObjectinfoOptions.js";

import { mapLayerColorSchema } from "@/components/MapLayerColorSchema.js";

import _ from "lodash";

const mapClient = new MapClient();
const dataClient = new DataClient();

function getsimplifyTolerance(zoom) {
  if (0 <= zoom && zoom <= 5) {
    return 0.1;
  }
  if (5 <= zoom && zoom <= 7) {
    return 0.01;
  }
  if (7 <= zoom && zoom <= 8) {
    return 0.001;
  }
  if (8 <= zoom && zoom <= 12) {
    return 0.0001;
  }
  if (12 <= zoom && zoom <= 15) {
    return 0.00001;
  }
  return 0.00000001;
}

class Actions extends StateControllerBase {
  async SET_DATA_LOADING_ERROR(context, value) {
    context.commit("SET_DATA_LOADING_ERROR", value);
  }

  async SET_DATA_LOADING(context, value) {
    context.commit("SET_DATA_LOADING", value);
  }

  @setLoadingState
  async getMapParameters({ commit }) {
    let mapParameters = await mapClient.getMap();
    commit("setMapParameters", mapParameters);
  }

  changeBaseLayer({ commit, getters }, baseLayerId = null) {
    let baseLayerGroup = _.find(getters.mapParameters.mapLayers, group => group.id === getters.mapParameters.baseLayersGroupId);

    if (baseLayerId !== null) {
      let changedBaseLayer = _.find(baseLayerGroup.layers, layer => {
        return layer.id === baseLayerId;
      });
      if (changedBaseLayer) {
        commit("setBaseLayer", changedBaseLayer);
        return;
      }
    }

    let defaultBaseLayer = _.find(baseLayerGroup.layers, layer => {
      return layer.id === getters.mapParameters.defaultBaseLayerId;
    });

    if (defaultBaseLayer) {
      commit("setBaseLayer", defaultBaseLayer);
      return;
    }

    commit("setBaseLayer", baseLayerGroup.layers[0]);
  }

  getLayerGroups({ commit, getters }, manifestLayerGroups) {
    let layerGroups = _.cloneDeep(getters.mapParameters.mapLayers);
    let baseLayersGroupIndex = _.findIndex(layerGroups, group => group.id === getters.mapParameters.baseLayersGroupId);
    commit("setBaseLayerGroup", layerGroups.splice(baseLayersGroupIndex, 1)[0]);
    if (manifestLayerGroups) {
      layerGroups = layerGroups.concat(manifestLayerGroups);
    }
    commit("setLayerGroups", layerGroups);
  }

  clearSearchData({ commit }) {
    commit("clearSearchData");
  }

  async getSearchData({ commit, getters }, { dataSourceId, searchKeyword }) {
    commit("removeSearchData", dataSourceId);

    if (searchKeyword == "") {
      return;
    }
    let layerSettings = getters.dataSourceLayerSettings(dataSourceId);
    if (layerSettings.parameters.dataSource.nameProperty) {
      const body = {
        skip: 0,
        take: 5,
        filters: "",
        searchKeyword: searchKeyword,
        requestedPropertiesAliases: [layerSettings.parameters.dataSource.nameProperty]
      };

      let response = await dataClient.getData(dataSourceId, body);
      if (response.items == null) {
        response.items = [];
      }

      response.items.forEach(item => {
        item.dataSourceId = dataSourceId;
      });

      commit("removeSearchData", dataSourceId);
      commit("setSearchData", response.items);
    }
  }

  getLayerGroupsForSwitcher({ commit, getters, rootGetters }) {
    let layerGroups = _.cloneDeep(getters.layerGroups);
    _.forEach(layerGroups, layerGroup => {
      let localGroupState = rootGetters["localStorage/localLayerGroupState"](layerGroup.id);
      if (localGroupState) {
        layerGroup.isHide = localGroupState.isHide;
      }
      else {
        layerGroup.isHide = Object.prototype.hasOwnProperty.call(layerGroup, "isHide") ? layerGroup.isHide : false;
      }
      let layersForSwitcher = [];
      _.forEach(layerGroup.layers, layer => {
        if (!getters.isLayerParametersHidden(layer)) {
          let localLayerState = rootGetters["localStorage/localLayerState"](layer.id);
          let layerForSwitcher = {
            id: layer.id,
            icon: layer.icon,
            name: layer.name,
            isActive: localLayerState != null ? localLayerState.isActive : layer.defaultEnabled,
            layerType: layer.layerType
          };
          if (layer.layerType === LAYER_TYPES.DataSource) {
            if (!layer.parameters.dataSource.geojsonProperty) {
              return;
            }
            let objectColorSchema = mapLayerColorSchema(layer.parameters.dataSource.objectColor);
            layerForSwitcher.mainColor = objectColorSchema.outline.main;
            layerForSwitcher.dataSourceId = layer.parameters.dataSource.dataSourceId;
            if (layer.parameters.dataSource.mapObjectInfo) {
              layerForSwitcher.mapObjectInfo = layer.parameters.dataSource.mapObjectInfo;
            }
            else if (layer.parameters.dataSource.isObjectCardsEnabled && layer.parameters.dataSource.mapObjectInfo != MAP_OBJECT_INFO_OPTIONS.None) {
              layerForSwitcher.mapObjectInfo = MAP_OBJECT_INFO_OPTIONS.Cards;
            }
            else if (layer.parameters.dataSource.diagramProperties?.length > 0 && layer.parameters.dataSource.mapObjectInfo != MAP_OBJECT_INFO_OPTIONS.None) {
              layerForSwitcher.mapObjectInfo = MAP_OBJECT_INFO_OPTIONS.Diagrams;
            }
            else {
              layerForSwitcher.mapObjectInfo = MAP_OBJECT_INFO_OPTIONS.None;
            }

            if (layerForSwitcher.mapObjectInfo === MAP_OBJECT_INFO_OPTIONS.Cards) {
              layerForSwitcher.showObjectCards = localLayerState != null && Object.prototype.hasOwnProperty.call(localLayerState, "showObjectCards") ? localLayerState.showObjectCards : layer.parameters.dataSource.showObjectCards;
              layerForSwitcher.isObjectCardsEnabled = layer.parameters.dataSource.isObjectCardsEnabled;
            }

            if (layerForSwitcher.mapObjectInfo === MAP_OBJECT_INFO_OPTIONS.Diagrams) {
              layerForSwitcher.showDiagrams = localLayerState != null && Object.prototype.hasOwnProperty.call(localLayerState, "showDiagrams") ? localLayerState.showDiagrams : true;
            }

            if (layerForSwitcher.mapObjectInfo === MAP_OBJECT_INFO_OPTIONS.Cluster) {
              layerForSwitcher.showClusters = localLayerState != null && Object.prototype.hasOwnProperty.call(localLayerState, "showClusters") ? localLayerState.showClusters : layer.parameters.dataSource.showClusters;
            }

            if (Object.prototype.hasOwnProperty.call(layer.parameters.dataSource, "showSemanticConnections") && layer.parameters.dataSource.semanticConnectionsSettings?.length > 0) {
              layerForSwitcher.showSemanticConnections = localLayerState != null && Object.prototype.hasOwnProperty.call(localLayerState, "showSemanticConnections") ? localLayerState.showSemanticConnections : layer.parameters.dataSource.showSemanticConnections;
            }
          }
          layersForSwitcher.push(layerForSwitcher);
        }
      });
      layerGroup.layers = layersForSwitcher;
    });
    commit("setLayerGroupsForSwitcher", layerGroups);
  }

  changeLayerVisibility({ commit }, changedLayerVisibility) {
    commit("setLayerVisibility", changedLayerVisibility);
  }

  changeLayerGroupVisibility({ commit }, changedLayerGroupVisibility) {
    commit("setLayerGroupVisibility", changedLayerGroupVisibility);
  }

  changeLayerColor({ commit }, changedLayerColor) {
    commit("setLayerColor", changedLayerColor);
  }

  changeLayerObjectCardsVisibility({ commit }, changedObjectCardVisibility) {
    commit("setLayerObjectCardsVisibility", changedObjectCardVisibility);
  }

  changeLayerDiagramsVisibility({ commit }, changedDiagramsVisibility) {
    commit("setDiagramsVisibility", changedDiagramsVisibility);
  }

  changeLayerClusterVisibility({ commit }, changedClustersVisibility) {
    commit("setClustersVisibility", changedClustersVisibility);
  }

  setCurrentObjectGeometry({ commit }, { geojson, flyTo }) {
    commit("setCurrentObjectGeometry", { geojson, flyTo });
  }

  changeLayerSemanticConnectionsVisibility({ commit }, changedSemanticConnectionVisibility) {
    commit("setLayerSemanticConnectionsVisibility", changedSemanticConnectionVisibility);
  }

  async getLinkedObjects({ commit, getters }, { linkedProperties, currentObjectDataSourceId }) {
    let settings = getters.dataSourceLayerSettings(currentObjectDataSourceId).parameters.dataSource;
    let currentObjectLinkedPropertiesSettings = Object.prototype.hasOwnProperty.call(settings, "linkedPropertiesSettings") ? settings.linkedPropertiesSettings : null;
    let allLinkedObjects = {};
    let promises = [];
    if (currentObjectLinkedPropertiesSettings) {
      for (let setting of currentObjectLinkedPropertiesSettings) {
        //поиск linkedProperty из настроек в свойствах текущего объекта
        let property = _.find(linkedProperties, { alias: setting.alias });
        if (property) {
          for (let dataSourceId of setting.dataSources) {
            let propertyValueIds = _.map(_.flatten(property.values), "instanceId");
            let allAliases = getters.dataSourceAliases(dataSourceId);
            if (propertyValueIds.length > 0) {
              let body = {
                skip: 0,
                take: propertyValueIds.length,
                filters: [],
                instanceIds: propertyValueIds,
                searchKeyword: "",
                requestedPropertiesAliases: allAliases.layerAliases,
                optimization: {
                  simplifyGeometry: true,
                  tolerance: getsimplifyTolerance(14)
                }
              };
              promises.push(
                dataClient.getData(dataSourceId, body).then(response => {
                  allLinkedObjects[dataSourceId] = response.items;
                })
              );
            }
          }
        }
      }
    }
    await Promise.all(promises);
    commit("setLinkedObjects", allLinkedObjects);
  }

  clearLinkedObjects({ commit }) {
    commit("setLinkedObjects", []);
  }

  setMapNavigationObject({ commit }, navObject) {
    commit("setMapNavigationObject", navObject);
  }

  addExternalObjectsForPopup({ commit }, externalObjects) {
    commit("addExternalObjectsForPopup", externalObjects);
  }

  clearExternalObjectsForPopup({ commit }) {
    commit("clearExternalObjectsForPopup");
  }

  setGeofileGeometry({ commit }, geometry) {
    commit("setGeofileGeometry", geometry);
  }

  async downloadLayerGeometry({ getters }, { layer, fileFormat }) {
    let filename = ConvertFilename(layer.name);
    let metadata = getters.dataSourceMetadata(layer.dataSourceId);
    let layerSettings = getters.dataSourceLayerSettings(layer.dataSourceId);
    let geometryProperty = layerSettings.parameters.dataSource.geojsonProperty;
    let aliases = GetRequestedAliases(metadata);
    aliases.push(geometryProperty);
    let body = {
      skip: 0,
      take: 10000,
      filters: [],
      searchKeyword: "",
      requestedPropertiesAliases: aliases,
      optimization: {
        simplifyGeometry: false
      }
    };
    let response = await dataClient.getData(layer.dataSourceId, body);
    let dataSourceObjects = response.items;
    let layerFeatures = [];
    _.each(dataSourceObjects, object => {
      let objectGeometry = _.find(object.ownProperties, prop => prop.alias === geometryProperty);
      if (objectGeometry) {
        let objectFeature = {
          type: "Feature",
          geometry: objectGeometry.value,
          properties: ConvertObjectProperties(object.ownProperties, object.linkedProperties, metadata)
        };
        layerFeatures.push(objectFeature);
      }
    });
    let layerGeojson = {
      type: "FeatureCollection",
      features: layerFeatures
    };
    let file = null;
    if (layerFeatures.length > 0) {
      if (fileFormat === ".json") {
        file = JSON.stringify(layerGeojson);
        filename = filename + fileFormat;
      }
      else {
        if (fileFormat === ".shp" && !validateGeometryTypes(layerGeojson)) {
          convertGeometryToPolygon(layerGeojson);
        }
        let geometryFileClient = new GeometryFileClient();
        let convertedFile = await geometryFileClient.convertGeojsonToGeofile(layerGeojson, fileFormat, filename);
        file = convertedFile.data;
        filename = convertedFile.filename;
      }
      Download(file, filename);
    }
    else {
      throw new Error("Слой не содержит геометрию");
    }
  }

  enableDrawSearch({ commit }, { geometrySearch, drawInteraction }) {
    commit("enableDrawSearch", { geometrySearch, drawInteraction });
  }

  disableDrawSearch({ commit, dispatch }) {
    commit("disableDrawSearch");
    dispatch("clearSearchData");
  }

  setGeometrySearchGeojson({ commit }, geojson) {
    commit("setGeometrySearchGeojson", geojson);
  }

  async getObjectsByGeojson({ getters, commit, dispatch }) {
    dispatch("clearSearchData");
    dispatch("setSearchLoadingState", true);
    let dataSourceIds = {};

    getters.layersGroupsForSwitcher.forEach(group => {
      group.layers.forEach(layer => {
        if (layer.layerType === LAYER_TYPES.DataSource) {
          let layerSettings = getters.dataSourceLayerSettings(layer.dataSourceId);
          if (layer.isActive && layer.dataSourceId && layerSettings.parameters.dataSource.geojsonProperty && layerSettings.parameters.dataSource.nameProperty) {
            if (Object.prototype.hasOwnProperty.call(layerSettings.parameters.dataSource, "geojsonOwnProperty") && !_.isEmpty(layerSettings.parameters.dataSource.geojsonOwnProperty)) {
              dataSourceIds[layer.dataSourceId] = layerSettings.parameters.dataSource.geojsonOwnProperty;
            }
            else {
              dataSourceIds[layer.dataSourceId] = layerSettings.parameters.dataSource.geojsonProperty;
            }
          }
        }
      });
    });
    let promises = [];
    for (let dataSourceId in dataSourceIds) {
      const body = {
        skip: 0,
        take: 5,
        filters: [{
          alias: dataSourceIds[dataSourceId],
          method: 22,
          value: getters.geometrySearchGeojson != null ? JSON.stringify(getters.geometrySearchGeojson.geometry) : ""
        }],
        searchKeyword: "",
        requestedPropertiesAliases: [getters.dataSourceLayerSettings(dataSourceId).parameters.dataSource.nameProperty]
      };
      promises.push(dataClient.getData(dataSourceId, body).then(response => {
        response.items.forEach(item => {
          item.dataSourceId = dataSourceId;
        });
        commit("setSearchData", response.items);
      }));
    }
    await Promise.all(promises);
    dispatch("setSearchLoadingState", false);
  }

  setSearchLoadingState({ commit }, loadingState) {
    commit("setSearchLoadingState", loadingState);
  }

  async getDataSourceObjects({ commit, getters, rootGetters }, { dataSourceId, alias, bbox, zoom }) {
    let allAliases = getters.dataSourceAliases(dataSourceId);
    let filters = [{
      alias: alias,
      method: 22,
      value: JSON.stringify(bbox)
    }];
    let mapFilteredDataSource = rootGetters["filters/mapFilteredDataSource"];
    if (mapFilteredDataSource === dataSourceId) {
      let dataSourceFilters = rootGetters["filters/activeFilters"](mapFilteredDataSource);
      let convertedFilters = _.flatMap(dataSourceFilters, filter => convertFilter(filter));
      filters = filters.concat(convertedFilters);
    }
    let body = {
      skip: 0,
      take: 10000,
      filters,
      searchKeyword: "",
      requestedPropertiesAliases: allAliases.layerAliases,
      optimization: {
        simplifyGeometry: true,
        tolerance: getsimplifyTolerance(zoom)
      }
    };
    let response = await dataClient.getData(dataSourceId, body);
    commit("setDataSourceObjects", {
      dataSourceId,
      data: response.items
    });
  }

  setEmptyDataSourceObjects({ commit }, dataSourceId) {
    commit("setDataSourceObjects", {
      dataSourceId,
      data: []
    });
  }

  async getDataSourceMetadata({ commit }, dataSourceId) {
    let response = await dataClient.getMetadata(dataSourceId);
    commit("setDataSourceMetadata", { metadata: response, dataSourceId });
  }

  async getMapCurrentObject({ commit, getters, dispatch }, { dataSourceId, id }) {
    if (getters.clickedObjectFromMapState) {
      let dataSourceObjects = getters.dataSourceObjects(dataSourceId);
      let object = _.find(dataSourceObjects, dataSourceObject => dataSourceObject.id === id);
      if (!_.isEmpty(object)) {
        commit("setMapCurrentObject", {
          dataSourceId,
          object: object
        });
        return;
      }
    }
    let aliases = getters.dataSourceAliases(dataSourceId);
    let body = {
      skip: 0,
      take: 1,
      filters: [],
      searchKeyword: "",
      instanceIds: [id],
      requestedPropertiesAliases: aliases.layerAliases,
      optimization: {
        simplifyGeometry: true,
        tolerance: getsimplifyTolerance(14)
      }
    };
    let response = await dataClient.getDataWithHeaders(dataSourceId, body);
    commit("setMapCurrentObject", {
      dataSourceId,
      object: response.data.items[0]
    });
    dispatch("currentObject/setETag", response.headers.etag, { root: true });
  }

  removeMapCurrentObject({ commit }) {
    commit("setMapCurrentObject", null);
  }

  findObjectById({ getters }, objectId) {
    let dataSourceRequests = [];
    for (let dataSourceId of getters.allDataSourcesIds) {
      let aliases = getters.dataSourceAliases(dataSourceId);
      let body = {
        skip: 0,
        take: 1,
        filters: [],
        searchKeyword: "",
        instanceIds: [objectId],
        requestedPropertiesAliases: aliases.geometryCardAlias,
        optimization: {
          simplifyGeometry: true,
          tolerance: 1
        }
      };
      dataSourceRequests.push({
        dataSourceId,
        request: dataClient.getData(dataSourceId, body)
      });
    }

    return dataSourceRequests;
  }

  async getDataSourceObject({ getters }, { dataSourceId, id }) {
    let body = {
      skip: 0,
      take: 1,
      filters: [],
      searchKeyword: "",
      instanceIds: [id],
      optimization: {
        simplifyGeometry: true,
        tolerance: getsimplifyTolerance(14)
      }
    };
    let aliases = getters.dataSourceAliases(dataSourceId);
    if (!_.isEmpty(aliases)) {
      body.requestedPropertiesAliases = aliases.layerAliases;
    }

    return dataClient.getData(dataSourceId, body);
  }

  getObjectsGeometry(_context, { dataSourceId, properties, tolerance, instanceIds }) {
    let body = {
      skip: 0,
      take: 10000,
      filters: [],
      searchKeyword: "",
      optimization: {
        simplifyGeometry: true,
        tolerance
      },
      instanceIds,
      requestedPropertiesAliases: properties
    };
    return dataClient.getData(dataSourceId, body);
  }

  changeMapLoadingState({ commit }, mapLoadingState) {
    commit("setMapLoadingState", mapLoadingState);
  }

  createObjectPopupFromSearch({ commit }, coordinates) {
    commit("setObjectPopupCoordinates", coordinates);
  }

  removeSearchObjectPopup({ commit }) {
    commit("setObjectPopupCoordinates", null);
  }

  addGeometryFilter({ getters, dispatch }) {
    let filterValue = getters.geometrySearchGeojson != null ? JSON.stringify(getters.geometrySearchGeojson.geometry) : "";
    dispatch("filters/setGeometryFilter", filterValue, { root: true });
  }

  setDataSourcesAliases({ commit, getters }) {
    let dataSourcesAliases = {};
    _.each(getters.dataSourceLayersSettings, layerSettings => {
      let dataSourceLayerSettings = layerSettings.parameters.dataSource;
      let layerAliases = getters.mapRequestAliases(dataSourceLayerSettings.dataSourceId);
      let metadata = getters.dataSourceMetadata(dataSourceLayerSettings.dataSourceId);
      //для отрисовки геометрии брать aliases для слоя и перезапрашивать геометрию в зависимости от зума
      /**
       * пока карточка текущего объекта свернута использовать уже имеющиеся данные для ее отображения
       * при развороте карточки отправить запрос с aliases где не будет alias геометрии
       * для карточки геометрии отправлять запрос на получение всей геометрии объекта без tolerance
       *
       */
      let currentObjectCardAliases = [];
      for (let property of metadata.ownProperties) {
        if (property.tableType != "Geometry") {
          currentObjectCardAliases.push(property.alias);
        }
      }

      for (let linkedProperty of metadata.linkedProperties) {
        for (let property of linkedProperty.properties) {
          if (property.tableType != "Geometry") {
            currentObjectCardAliases.push(property.alias);
          }
        }
      }
      let geometryCardAlias = [dataSourceLayerSettings.geojsonProperty];
      dataSourcesAliases[dataSourceLayerSettings.dataSourceId] = {
        layerAliases,
        currentObjectCardAliases,
        geometryCardAlias
      };
    });
    commit("setDataSourcesAliases", dataSourcesAliases);
  }

  getFullMapObjectForCard({ getters }, { id, dataSourceId }) {
    let aliases = getters.dataSourceAliases(dataSourceId);
    let body = {
      skip: 0,
      take: 1,
      filters: [],
      searchKeyword: "",
      instanceIds: [id],
      requestedPropertiesAliases: aliases.currentObjectCardAliases
    };
    return dataClient.getData(dataSourceId, body);
  }

  setClickedObjectFromMapState({ commit }, clickedObjectFromMapState) {
    commit("setClickedObjectFromMapState", clickedObjectFromMapState);
  }

  disableMeasure({ commit }) {
    commit("setMeasureDrawMode", null);
  }

  enableMeasure({ commit }, type) {
    commit("setMeasureDrawMode", type);
  }

}
export default (new Actions).asPlainObject();