<template>
  <div class="plato-aside__header">
    <div class="row">
      <div class="plato-aside__header_left">
        <PlatoButton
          type="link"
          icon="arrow-left"
          class="plato-aside__header_back"
          @click="$emit('close')"
        />
        <div class="plato-aside__header_text">
          <slot name="header_text">
            {{ header }}
          </slot>
        </div>
      </div>
      <div class="plato-aside__header_right">
        <slot name="advanced" />

        <PlatoButtonGroup>
          <slot />

          <PlatoButton
            icon="times"
            type="danger-action"
            @click="$emit('close')"
          />
        </PlatoButtonGroup>
      </div>
    </div>
    <div class="row">
      <slot name="search" />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["header"],
  data: function() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";
@import "../../styles/variables.scss";
.plato-aside__header {
  position: sticky;
  top: 0px;
  padding-top: 20px;
  z-index: 2;
  background: white;
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &_left {
    display: flex;
    align-items: center;
  }
  &_right {
    display: flex;

    ::v-deep {
      .content-editable {
        &-wrapper {
          width: 300px;
        }
        .string-line-wrapper {
          margin-left: auto;
          // margin-right: 67px;
        }
        .pencil-icon {
          margin-left: 10px;
        }
        input {
          text-align: right;
        }
      }
    }
  }
}
</style>