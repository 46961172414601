import StateControllerBase from "@/store/StateControllerBase";
import Vue from "vue";
class Actions extends StateControllerBase {
  setActiveTour({ commit }, tour) {
    commit("setActiveTour", tour);
  }

  setListToursEnd({ commit }, value) {
    commit("setListToursEnd", value);
  }

  nextTourStep({ rootGetters }) {
    if (rootGetters["onboarding/activeTour"]) {
      Vue.nextTick(() => {
        rootGetters["onboarding/activeTour"].next();
      });
    }
  }
}

export default (new Actions).asPlainObject();