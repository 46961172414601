export const MAP_OBJECT_INFO_OPTIONS = {
  None: 0,
  Diagrams: 1,
  Cards: 2,
  Cluster: 3
};

export const MAP_OBJECT_INFO_OPTIONS_LABELS = {
  [MAP_OBJECT_INFO_OPTIONS.None]: "Без информации",
  [MAP_OBJECT_INFO_OPTIONS.Diagrams]: "Диаграммы",
  [MAP_OBJECT_INFO_OPTIONS.Cards]: "Карточки",
  [MAP_OBJECT_INFO_OPTIONS.Cluster]: "Кластеризация"
};
