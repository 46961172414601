<template>
  <PlatoLoader v-if="loading" />
  <div
    v-else
    class="plato-list"
  >
    <div :class="{ 'display-none' : scrollDown && isMobile}">
      <PlatoComponentHeader
        :title="name"
        :subtitle="`Количество: ${elementsCount}`"
        :actions="actions"
        :show-delete-button="showDeleteButton"
        :disable-delete-button="disableDeleteButton"
        @confirmDeleteInstance="confirmDeleteInstance"
        @triggerAction="triggerAction"
        @change-header-view="changeHeaderView"
      />

      <PlatoFilterLine
        v-if="dataSources.length === 1"
        :collapsed-header="collapsedHeader"
        :data-source-id="listParams[0].dataSourceId"
        :show-filters="dataSources.length <= 1"
        :show-search="true"
        :show-grouping="dataSources.length === 1"
        :search-init="getCurrentSearchValue()"
        :all-items-checked="allItemsChecked"
        :metadata="firstDSMetadata"
        @search-change="value => searchInputVаlueChange(value)"
        @check-all-items="checkAllItems"
      />

      <PlatoDataSourceSearch
        v-else
        class="plato-list-search"
        :search-init="getCurrentSearchValue()"
        @search-change="value => searchInputVаlueChange(value)"
      />
    </div>

    <div
      class="plato-list__body"
      @scroll="changeScrollPosition"
    >
      <PlatoListGroup
        v-for="dsParams in listParams"
        :key="`plato-list-body_${dsParams.dataSourceId}`"
        :show-header="dataSources.length > 1"
        :data-source-id="dsParams.dataSourceId"
        :aliases="getAliases(dsParams.dataSourceId)"
        :params="dsParams"
        :all-items-checked="allItemsChecked"
        :show-more-info="showMoreInfo"
        @select-element="selectElement"
        @change-all-items-checkbox="changeAllItemsCheckbox"
      />
    </div>
  </div>
</template>

<script>
import PlatoComponentHeader from "../ComponentHeader/PlatoComponentHeader.vue";
import PlatoDataSourceSearch from "@/components/package/FilterLine/PlatoDataSourceSearch.vue";
import PlatoListGroup from "./PlatoListGroup";

import { parseFilter, parseSorting } from "@/components/utils/urlDTOConverters/Converters.js";
import { scopes } from "@/store/Access";

import { isMobile } from "@/components/utils/mobile/Mobile.js";

import _ from "lodash";
import { asyncScheduler, Subject } from "rxjs";
import { distinctUntilChanged, throttleTime } from "rxjs/operators";
import { mapGetters } from "vuex";

export default {
  name: "PlatoList",

  components: {
    PlatoComponentHeader,
    PlatoListGroup,
    PlatoDataSourceSearch
  },

  props: {
    pageItemSettings: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: true
    },
    dataSourceId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      allItemsChecked: false,
      loading: true,
      actionsName: ["actionAddObject", "actionOpenObject", "actionCreateReport"],
      searchSubject: new Subject(),
      collapsedHeader: true,
      oldScroll: 0,
      scrollDown: false,
      isMobile: isMobile()
    };
  },

  computed: {
    ...mapGetters({
      currentManifest: "MANIFEST",
      totalObjectCount: "list/totalObjectCount",
      allSelectedObjects: "selectedObjects/allSelectedObjects",
      urlFilters: "filters/urlFilters",
      urlSorting: "sorting/urlSorting",
      metadata: "list/metadata"
    }),

    selectedObjects() {
      return _.flatMap(this.dataSources, ds => this.$store.getters["selectedObjects/selectedObjects"](ds));
    },

    listSelectedObjects() {
      return _.filter(this.allSelectedObjects, objects => _.some(this.dataSources, dsId => dsId == objects.dataSourceId));
    },

    firstDSMetadata() {
      return this.metadata(this.dataSources[0]);
    },

    showDeleteButton() {
      return !_.isEmpty(this.selectedObjects);
    },

    elementsCount() {
      let result = _.reduce(this.listParams, (acc, param) => {
        acc += this.totalObjectCount(param.dataSourceId);
        return acc;
      }, 0);

      return result;
    },

    listParams() {
      let param = _.find(this.pageItemSettings, ["pageItemSettingId", "ItemParams"]);
      return param ? param.value : [];
    },

    showMoreInfo() {
      let param = _.find(this.pageItemSettings, ["pageItemSettingId", "ShowMoreInfo"]);
      return _.get(param, "value.value");
    },

    addObjectActions() {
      return _.reduce(this.listParams, (result, props) => {
        let actions = _.filter(
          this.currentManifest.actions,
          manifestAction => _.some(props.actionAddObject, prop => prop == manifestAction.actionId)
        );

        if (actions) {
          result.push(...actions);
        }

        return [...new Set(result)];
      },
      []);
    },

    createReportActions() {
      return _.reduce(this.listParams, (result, props) => {
        let actionIds = _.map(props.actionCreateReport, "actionId");

        let action = _.filter(
          this.currentManifest.actions,
          manifestAction => _.some(actionIds, actionId => actionId == manifestAction.actionId)
        );

        if (action) {
          result.push(...action);
        }

        return [...new Set(result)];
      },
      []);
    },

    actions() {
      return [...this.addObjectActions, ...this.createReportActions];
    },

    disableDeleteButton() {
      return _.some(this.allSelectedObjects, dataSourceId => !this.$store.getters.writeAccess({ scope: scopes.APPLICATION_DATA, resource: dataSourceId }));
    },

    isMultipleDelete() {
      return this.selectedObjects.length >= 2;
    },

    dataSources() {
      return _.map(this.listParams, "dataSourceId");
    }
  },

  async created() {
    for (const dataSourceId of this.dataSources) {
      await this.$store.dispatch("list/getMetadata", dataSourceId);

      if (_.isEmpty(this.urlFilters(dataSourceId)) && _.isEmpty(this.urlSorting(dataSourceId))) {
        await this.$store.dispatch("tryApplayDefaultDataView", { dataSourceId, metadata: this.metadata(dataSourceId) });
      }
      else {
        this.convertDTOFilters(dataSourceId);
        this.convertDTOSorting(dataSourceId);
      }
    }

    const searchObs = this.searchSubject.asObservable();
    searchObs
      .pipe(throttleTime(500, asyncScheduler, { leading: false, trailing: true }))
      .pipe(distinctUntilChanged())
      .subscribe(async v => {
        await this.searchChange(v);
      });

    this.loading = false;
  },

  mounted() {
    this.collapsedHeader = this.isMobile;
  },

  methods: {
    changeScrollPosition(e) {
      if (e) {
        let currentScroll = e.target.scrollTop;
        if (currentScroll > this.oldScroll) {
          this.scrollDown = true;
        }
        else {
          this.scrollDown = false;
        }
        this.oldScroll = currentScroll;
      }
    },

    changeHeaderView() {
      if (this.isMobile) {
        this.collapsedHeader = !this.collapsedHeader;
      }
    },

    async searchInputVаlueChange(value) {
      this.searchSubject.next(value);
    },

    getAlias(field, metadata) {
      let isOwn = _.some(metadata.ownProperties, { alias: field.propertyAlias });

      if (isOwn) {
        return field.propertyAlias;
      }
      else {
        if (field.ownPropertyAlias) {
          return field.ownPropertyAlias;
        }

        let linkedProperty = _.find(metadata.linkedProperties, {
          alias: field.propertyAlias
        });

        return _.get(linkedProperty, "properties[0].alias");
      }
    },

    getAliases(dataSourceId) {
      let param = _.find(this.listParams, { dataSourceId });
      if (param) {
        let metadata = this.metadata(dataSourceId);

        let aliases = [];
        aliases.push(..._.map(param.fields, field => this.getAlias(field, metadata)));
        aliases.push(..._.map(param.otherFields, field => this.getAlias(field, metadata)));
        if (param.geojson && param.geojson.propertyAlias) {
          aliases.push(this.getAlias(param.geojson, metadata));
        }
        if (param.idField && param.idField.propertyAlias) {
          aliases.push(this.getAlias(param.idField, metadata));
        }
        if (param.numField && param.numField.propertyAlias) {
          aliases.push(this.getAlias(param.numField, metadata));
        }
        if (param.statusField && param.statusField.propertyAlias) {
          aliases.push(this.getAlias(param.statusField, metadata));
        }

        return aliases;
      }
      return [];
    },

    async searchChange(value) {
      let keyword = null;
      if (value != "") {
        keyword = value;
      }

      // this.$store.dispatch("searchKeywords/editSearchKeyword", {
      //   searchInfo: {
      //     dataSourceId: this.dataSourceId,
      //     searchKeyword: keyword
      //   },
      //   refreshUrl: true
      // });

      // for (const dataSourceId of this.dataSources) {
      //   await this.$store.dispatch("list/getDataSourceData", { dataSourceId, aliases: this.getAliases(dataSourceId) });
      // }
      // this.$store.dispatch("list/resetTake", this.dataSourceId);


      for (const dataSourceId of this.dataSources) {
        this.$store.dispatch("searchKeywords/editSearchKeyword", {
          searchInfo: {
            dataSourceId: dataSourceId,
            searchKeyword: keyword
          },
          refreshUrl: true
        });

        await this.$store.dispatch("list/getDataSourceData", { dataSourceId, aliases: this.getAliases(dataSourceId) });

        this.$store.dispatch("list/resetTake", dataSourceId);
      }
    },

    getCurrentSearchValue() {
      let searchKeywords = [];
      _.each(this.dataSources, dataSourceId => {
        searchKeywords.push(this.$store.getters["searchKeywords/searchKeywords"](dataSourceId));
      });

      searchKeywords = _.uniq(searchKeywords);

      return _.isEmpty(searchKeywords) ? "" : searchKeywords[0];
    },

    convertDTOFilters(dataSourceId) {
      let filters = this.urlFilters(dataSourceId);
      if (!_.isEmpty(filters)) {
        this.$store.dispatch("filters/setFilters", { dataSourceId, filters: _.map(filters, filter => parseFilter(this.metadata(dataSourceId), filter)) });
      }
    },

    convertDTOSorting(dataSourceId) {
      let sorting = this.urlSorting(dataSourceId);
      if (!_.isEmpty(sorting)) {
        this.$store.dispatch("sorting/setSorting", { dataSourceId, sorting: _.map(sorting, sort => parseSorting(this.metadata(dataSourceId), sort)) });
      }
    },

    triggerAction(context) {
      this.$eventBus.$emit("component:action", context);
    },

    selectElement(data) {
      this.$store.dispatch("currentObject/removeCurrentObject", { refreshUrl: false });

      if (!data.wasSelected) {
        this.$store.dispatch("currentObject/changeCurrentObject", {
          id: data.id,
          dataSourceId: data.dataSourceId
        });
      }
    },

    checkAllItems() {
      this.allItemsChecked = !this.allItemsChecked;
    },

    changeAllItemsCheckbox(newStatus) {
      this.allItemsChecked = newStatus;
    },

    confirmDeleteInstance() {
      this.Modal.confirm({
        title: "Удаление элементов",
        message: this.isMultipleDelete ? "Вы уверены, что хотите удалить элементы?" : "Вы уверены, что хотите удалить элемент?",
        type: "danger",
        onConfirm: this.deleteInstances
      });
    },

    async deleteInstances() {
      this.listSelectedObjects.forEach(async objects => {
        await this.$store.dispatch("list/deleteInstances", { dataSourceId: objects.dataSourceId, ids: objects.selectedObjects });
        this.notification.success({ title: this.isMultipleDelete ? "Объекты удалены" : "Объект удалён" });
        await this.$store.dispatch("list/getDataSourceData", { dataSourceId: objects.dataSourceId, aliases: this.getAliases(objects.dataSourceId) });
        this.$store.dispatch("selectedObjects/removeSelectedObjects", { dataSourceId: objects.dataSourceId });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
@import "../styles/variables.scss";

.plato-list {
  color: $text-color;
  height: 100%;
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  &__body {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    border-top: 1px solid $border-color;

    @media screen and (max-width: 768px) {
      overscroll-behavior: none;
      padding-bottom: 30px;
    }
  }

  &-search{
  padding: 0 10px 10px 10px;
  display: flex;
  flex-direction: column;
  }
}
.display-none {
  display: none;
}
</style>